import { documentSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

const sysSchema = z.object({
  id: z.string()
})

const linkSchema = z.object({
  __typename: z.string('ContentfulLink'),
  text: z.string(),
  sys: sysSchema
})

const jumpLinkSchema = z.object({
  __typename: z.string('ContentfulJumpLink'),
  identifier: z.string(),
  sys: sysSchema
})

export type JumpLinkSchema = TypeOf<typeof jumpLinkSchema>

const textPageReference = z.object({
  __typename: z.string('ContentfulTextPage'),
  slug: z.string(),
  sys: sysSchema
})

export const textReferencesSchema = z.union([
  jumpLinkSchema,
  textPageReference,
  linkSchema
])

export type TextReferences = TypeOf<typeof textReferencesSchema>

export const formQuerySchema = z.object({
  fields: z.object({
    items: z
      .object({
        label: z.string(),
        placeholder: z.string(),
        type: z.any(),
        name: z.string()
      })
      .array()
  }),
  button: z.object({
    text: z.string()
  })
})

export type FormQuerySchema = TypeOf<typeof formQuerySchema>

export const legalTextPageSchema = z.object(
  {
    sys: z.object({
      id: z.string()
    }),
    title: z.string(),
    breadcrumbTitle: z.string(),
    text: z.object({
      json: documentSchema,
      links: z.object({
        entries: z.object({
          hyperlink: z.array(
            z.object({
              slug: z.string(),
              sys: z.object({
                id: z.string()
              })
            })
          ),
          inline: z.array(
            z.object({
              __typename: z.string(),
              identifier: z.string(),
              url: z.string(),
              text: z.string(),
              sys: sysSchema
            })
          )
        })
      })
    }),
    slug: z.string(),
    form: formQuerySchema.nullish()
  },
  { message: 'Legal Text Page is not valid' }
)

export type LegalTextPage = TypeOf<typeof legalTextPageSchema>
