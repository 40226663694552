import { pipe } from 'fp-ts/lib/function'

import {
  CHANGE_MONITORING_URL,
  CHANGE_MONITORING_URL_REPLACEMENT,
  CHOOSE_MONITORING_URL,
  CHOOSE_MONITORING_URL_REPLACEMENT,
  ODMON_247_PLAN_NAME,
  ODMON_247_PLAN_NAME_REPLACEMENT,
  ODMON_OVERNIGHT_PLAN_NAME,
  ODMON_OVERNIGHT_PLAN_NAME_REPLACEMENT,
  TOP_EXISTING_PLAN_NAME,
  TOP_EXISTING_PLAN_NAME_REPLACEMENT
} from './constants'

export const renameLineItemProps = (lineItemProps: {
  readonly itemDescriptionContent: React.ReactElement
  readonly itemName: string
}) => {
  const itemDescriptionContentElement: React.ReactElement = <
    React.ReactElement
  >lineItemProps.itemDescriptionContent

  const name = lineItemProps.itemName
  const newName = replacePlanName(name)

  const descriptionRaw: string =
    itemDescriptionContentElement?.props.productInformation?.description?.raw ||
    ''
  const newDescriptionRaw = pipe(
    descriptionRaw,
    replacePlanName,
    replaceMonitoringUrl
  )

  const newItemDescriptionContentElement: React.ReactElement = isChanged(
    descriptionRaw,
    newDescriptionRaw
  )
    ? {
        ...itemDescriptionContentElement,
        props: {
          ...itemDescriptionContentElement.props,
          productInformation: {
            ...itemDescriptionContentElement.props.productInformation,
            description: {
              raw: newDescriptionRaw
            }
          }
        }
      }
    : itemDescriptionContentElement

  const newProps =
    isChanged(name, newName) || isChanged(descriptionRaw, newDescriptionRaw)
      ? {
          ...lineItemProps,
          itemName: newName,
          itemDescriptionContent: newItemDescriptionContentElement
        }
      : lineItemProps

  return newProps
}

export const renameLineItem = (lineItem: { readonly itemName: string }) => ({
  ...lineItem,
  itemName: replacePlanName(lineItem.itemName)
})

export const renameString = (s: string) => replacePlanName(s)

const replacePlanName = (s: string) =>
  s
    .replaceAll(TOP_EXISTING_PLAN_NAME, TOP_EXISTING_PLAN_NAME_REPLACEMENT)
    .replaceAll(
      ODMON_OVERNIGHT_PLAN_NAME,
      ODMON_OVERNIGHT_PLAN_NAME_REPLACEMENT
    )
    .replaceAll(ODMON_247_PLAN_NAME, ODMON_247_PLAN_NAME_REPLACEMENT)

const replaceMonitoringUrl = (s: string) =>
  s
    .replaceAll(CHOOSE_MONITORING_URL, CHOOSE_MONITORING_URL_REPLACEMENT)
    .replaceAll(CHANGE_MONITORING_URL, CHANGE_MONITORING_URL_REPLACEMENT)

const isChanged = (older: string, newer: string) =>
  older && newer && older !== newer
