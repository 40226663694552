import { ContentfulImageSchema } from '@ecomm/contentful/components'
import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import { Modal } from '@ecomm/ss-react-components'
import React, { useState } from 'react'

export function LinkModal({
  headline,
  text,
  image
}: {
  readonly headline: string
  readonly text: string
  readonly image: ContentfulImageSchema
}) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <button
        className="cursor-pointer border-none bg-transparent p-0 text-sm text-[#0F2648] underline hover:no-underline"
        onClick={() => setOpen(true)}
      >
        {headline.toLowerCase()}
      </button>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)} size="small">
        <div
          className="flex max-w-screen-lg flex-col-reverse gap-8 p-8 lg:flex-row"
          data-component="modal_content"
        >
          <div className="relative flex-1 overflow-hidden rounded-2xl">
            <ContentfulImage {...image} className="h-full w-full" />
          </div>
          <div className="my-auto flex-1 justify-items-start py-6 pl-0 pr-12 text-xl">
            <h3 className="h3" data-component="modal_headline">
              {headline}
            </h3>
            <ContentfulRichText raw={text} />
          </div>
        </div>
      </Modal>
    </>
  )
}
