import { OutOfStockMessage } from '@ecomm/ss-react-components'
import React from 'react'

type Props = {
  readonly includedInPackage?: boolean
  readonly lowStockTemplate?: boolean
  readonly restockDate: string
}

function BackorderMessage({
  includedInPackage = false,
  lowStockTemplate = false,
  restockDate
}: Props) {
  // make sure restock date is in the future
  const showBackorderMessage =
    new Date(restockDate).getTime() > new Date().getTime()

  return showBackorderMessage ? (
    <p
      className={'text-alert-100 mb-0 font-medium'}
      data-component="BackorderMessage"
    >
      <OutOfStockMessage
        backInStockDate={restockDate}
        includedInPackage={includedInPackage}
        lowStockMessage={lowStockTemplate}
      />
    </p>
  ) : null
}

export default BackorderMessage
