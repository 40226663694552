import { PaymentCallbackContainer } from '@ecomm/checkout/payment-components'
import { TrackingProvider } from '@ecomm/tracking'
import { configureNewRelic } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { PageBody, PageSection, PageWrapper } from '@ecomm/ss-react-components'
import { graphql } from 'gatsby'
import { useEffect } from 'react'

function PaymentCallbackPageTemplate() {
  useEffect(() => {
    // This sets up New Relic with our configuration from ecomm-data
    configureNewRelic()
  }, [])

  /**
   * bare bones page template for the payment
   * callback page. We do not need the following
   * dependencies that we currently have on other
   * page templates since this page is
   * only visible to customers in the zuora iframe
   * during payment processing on the payment page (which already has these dependencies)
   * 1) country redirect modal
   * 2) pagetoaster
   * 3) sitewidemessages
   * 4) ApplyPromoCode
   * 5) livechat
   * 6) useUtmContent
   * 7) setOptimizelyParams
   * 8) useAwinTracking
   * 9) window.scrollTo
   * Keeping newrelic in place to track JS errors.
   */

  return (
    <TrackingProvider metaTitle={''}>
      <div className={'bg-neutral-light-50 [&>div]:bg-inherit'}>
        <PageWrapper>
          <PageBody>
            <PageSection className="min-w-screen min-h-screen">
              <PaymentCallbackContainer />
            </PageSection>
          </PageBody>
        </PageWrapper>
      </div>
    </TrackingProvider>
  )
}
export default PaymentCallbackPageTemplate

export const query = graphql`
  #graphql
  query PaymentCallbackPage($id: String) {
    contentfulPaymentCallbackPage(id: { eq: $id }) {
      site
      url
    }
  }
`
