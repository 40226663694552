import { useSetOdmonToken } from '@ecomm/checkout/shipping-hooks'
import { getSearchParams } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import {
  IOAddToCart,
  IODeleteCart,
  IOUpdateCart
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { identity, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { navigate } from 'gatsby'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

/**
 * Accepts a string, parses it to a number, and ensures it's between 1 & 2, defaulting to 1.
 *
 * Exported for test.
 */
export const validateQuantity = (quantityParam: string): number =>
  pipe(
    // parse it to an int
    O.fromNullable(Number.parseInt(quantityParam)),
    // shunt to left if not a number
    O.filter(quantity => !Number.isNaN(quantity)),
    // hard set to 1 <= x <= 2
    O.map(quantity => (quantity < 1 ? 1 : quantity)),
    O.map(quantity => (quantity > 2 ? 2 : quantity)),
    // if nothing, return 1 else return its value
    O.match(() => 1, identity)
  )

/**
 * React component that builds the cart we want, shows no contents, and
 * navigates the user to checkout
 */
export function OutdoorMonitoringCartBuilder() {
  const dispatch = useDispatch()

  const queryParams = getSearchParams(useLocation())
  const tokenOption = O.fromNullable(queryParams['oneTimeToken'])

  // set token for reference; used to determine eligibility for experience
  useSetOdmonToken(tokenOption, true)

  const quantity = validateQuantity(queryParams['quantity'])

  /**
   * Forward on to checkout
   */
  const moveOn = () => {
    navigate('/cart/checkout')
  }

  /**
   * Add our customer group to the current cart.
   * On success, move along.
   */
  const addCustomerGroupToCart = useCallback(() => {
    dispatch(
      IOUpdateCart(
        [
          {
            action: 'setCustomerGroup',
            customerGroup: 'odmonAlpha',
            userId: 'dummy'
          }
        ],
        () => null,
        () => moveOn()
      )
    )
  }, [dispatch])

  /**
   * Add products to the cart (will create if not present).
   * On success, add customer group.
   */
  const createCart = useCallback(() => {
    const addToCartProducts = [
      // audio enhancer
      {
        quantity: quantity,
        sku: 'CM017-02DUS'
      },
      // Shield mount
      {
        quantity: quantity,
        sku: 'CM009-02DWW'
      },
      // Shield cable
      {
        quantity: quantity,
        sku: 'CMCBL1'
      },
      // ODMON booklet -- only 1
      {
        quantity: 1,
        sku: 'LT021'
      }
    ]

    dispatch(
      IOAddToCart(
        {
          products: addToCartProducts
        },
        () => null,
        () => addCustomerGroupToCart()
      )
    )
  }, [addCustomerGroupToCart, dispatch, quantity])

  /**
   * Delete existing cart if present
   */
  const deleteCart = useCallback(() => {
    dispatch(IODeleteCart())
  }, [dispatch])

  /**
   * Actual program flow:
   *  delete cart
   *  add products to generate a new one
   *    now follows success handler callbacks to add
   *    customer group and navigate to checkout
   */
  useEffect(() => {
    pipe(deleteCart(), createCart)
  }, [createCart, deleteCart])

  return null
}
