import { useLocale } from '@ecomm/data-hooks'
import { configureNewRelic } from '@ecomm/error-handling'
import { ErrorBoundary } from '@ecomm/error-handling'
import {
  Breadcrumbs,
  BreadcrumbsProps,
  Header as HeaderRedesign
} from '@ecomm/header-redesigned'
import { ApplyPromoCode, PageToaster } from '@ecomm/promotions-components'
import {
  Footer,
  PopupQuoteWizard,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { PageBody, PageMasthead, PageWrapper } from '@ecomm/ss-react-components'
import { useMediaQuery } from '@ecomm/ss-react-components'
import { useOptimizelyParams } from '@ecomm/tracking'
import { devParams } from '@ecomm/tracking'
import { path } from '@simplisafe/ewok'
import { safePath, safeProp } from '@simplisafe/monda'
import { IOPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions'
import classNames from 'classnames'
import { Maybe, None } from 'monet'
import defaultTo from 'ramda/src/defaultTo'
import React from 'react'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import Skeleton from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import {
  ContentfulBannerTopPage,
  ContentfulFooter,
  ContentfulHeroBanner,
  ContentfulModalPromoPopup
} from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { useHeaderRedesignQuery } from '../../experiments/useHeaderRedesignQuery'
import useAwinTracking from '../../hooks/useAwinTracking'
import useEnableLiveChat from '../../hooks/useEnableLiveChat'
import useScrollToHash from '../../hooks/useScrollToHash'
import useUtmContent from '../../hooks/useUtmContent'
import { SmallTextSectionRedirect } from '../../templates/DefaultPage'
import { trackEventIsMobile } from '../../util/analytics'
import SEO from '../../util/seo'
import AuthenticationComponent from '../AuthenticationComponent'
import ContentfulVariationContainerComponent from '../ContentfulVariationContainerComponent'
import DefaultBannerComponent from '../DefaultBannerComponent'
import FloatingPromoWidgetComponent from '../FloatingPromoWidgetComponent'
import FooterComponent from '../FooterComponent'
import GlobalPromotionalComponent from '../GlobalPromotionalComponent'
import HeaderComponent from '../Header'
import { ContentfulHeaderFragment } from '../Header/query'
import HeaderPartnerComponent from '../HeaderPartnerComponent'
import HeaderProgressBarComponent from '../HeaderProgressBarComponent'
import HeroBannerComponent from '../HeroBannerComponent'
import HeroBannerFinancingComponent from '../HeroBannerFinancing'
import LiveChat from '../LiveChat'
import ModalExitIntentComponent from '../ModalExitIntentComponent'
import ModalPromoPopupComponent from '../ModalPromoPopupComponent'
import PageBannerComponent from '../PageBannerComponent'
import ScoutHardcodedComponent from '../ScoutHardcodedComponent'
import SimpleFooterComponent from '../SimpleFooterComponent'
import TopPageBanner from '../TopPageBanner'
import CountryRedirectModals from './CountryRedirectModals'
import { footerData } from './footerData'
import { getPageLayoutComponents, renderPageComponents } from './helpers'
import PromoBanner from './PromoBanner'
import {
  MappedComponentProps,
  PageComponent,
  PageLayoutComponent,
  PageProps
} from './types'

export const isBrowser = typeof window !== 'undefined'
export const getWindow = () => window

/*
NOTE: we need to continue importing getMappedComponent into this file for now.
Somehow GroupSection was unable to dynamically render ContentfulModal using getMappedComponent.
If we move this function back into helpers, be sure to check the choose monitoring page can
still render the 'Skip this step' modal to proceed without monitoring. (UK build)

UPDATE: exploration shows that if this function is moved to helpers.tsx, then they key for
ContentfulModal in the componentMappings.componentMappings map is null when it should be the
ModalComponent component. ModalComponent as an import is unchanged, it is present and correct.
But as a value in the map it's null. Unclear why this happens when moving the function from
one file to another but that's what causes the above condition.
*/
const mapPageComponentToTemplate = (
  pageComponent: PageComponent,
  pageContext?: PageProps['pageContext'],
  location?: PageProps['location']
) => {
  const Component = getMappedComponent<MappedComponentProps>(pageComponent)

  return Component ? (
    <ErrorBoundary key={pageComponent.id}>
      <React.Suspense
        fallback={<Skeleton className="h-[100rem] w-full" count={4} />}
      >
        <Component
          data={pageComponent}
          key={pageComponent.id}
          location={location}
          pageContext={pageContext}
        />
      </React.Suspense>
    </ErrorBoundary>
  ) : null
}

function Page({ data, pageContext, location }: any) {
  useEffect(() => {
    configureNewRelic()
    isBrowser && getWindow().scrollTo(0, 0)
  }, [])

  useScrollToHash()

  const liveChatStatus = useEnableLiveChat(data)

  const liveChatId = liveChatStatus && liveChatStatus.liveChatId
  const liveChatEnabled = liveChatStatus && liveChatStatus.liveChatEnabled
  const locale = useLocale()

  const isTabletUp = useMediaQuery('TabletAndUp')
  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)
  // this is the value from contentful that we will no longer be using
  const globalPromoCode = defaultTo('')(
    path(['contentfulPage', 'pageLayout', 'promoCode'], data)
  )
  const pagePath = path(['contentfulPage', 'pageUrl'], data) || ''

  useUtmContent()
  useAwinTracking(locale === 'en-GB')

  useOptimizelyParams()

  const pageTitle: string =
    path(['contentfulPage', 'seoDetails', 'metaTitle'], data) || ''
  const { trackEvent } = useTracking()

  const breadcrumbTitle: string =
    path(['contentfulPage', 'breadcrumbTitle'], data) || ''

  const breadcrumbType: BreadcrumbsProps['type'] =
    path(['contentfulPage', 'breadcrumbType'], data) || ''

  useEffect(() => {
    trackEventIsMobile(trackEvent, isTabletUp)
  }, [isTabletUp, trackEvent])

  const components: readonly PageLayoutComponent[] = safePath(
    ['contentfulPage', 'pageLayout', 'components'],
    data
  ).orJust([])
  const allComps = getPageLayoutComponents(components)

  const pageComponents: Maybe<readonly PageComponent[]> = safePath(
    ['contentfulPage', 'pageComponents'],
    data
  ).chain(x => Maybe.fromNull(x))
  const pageHeroBanner: Maybe<ContentfulHeroBanner> = safePath(
    ['contentfulPage', 'pageHeroBanner'],
    data
  ).chain(x => Maybe.fromNull(x))
  const belowComponents: readonly PageLayoutComponent[] = safePath(
    ['contentfulPage', 'pageLayout', 'belowComponents'],
    data
  ).orJust([])
  const countryRedirectModal: Maybe<SmallTextSectionRedirect> = safeProp(
    'contentfulSmallTextSection',
    data
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type '(component: PageComponent) => JS... Remove this comment to see the full error message
  const belowPageLayoutCompoments = belowComponents.map(
    (component: PageComponent) => mapPageComponentToTemplate(component)
  )
  const productId: Maybe<string> = safePath(
    ['contentfulPage', 'productId'],
    data
  )
  const pageUrl: string = safePath(
    ['contentfulPage', 'pageUrl'],
    data
  ).getOrElse('')
  const pageSchema = safePath(
    ['contentfulPage', 'seoDetails', 'pageSchema'],
    data
  ).orUndefined()
  const pageTopBanner: ContentfulBannerTopPage = safePath(
    ['contentfulPage', 'pageTopBanner'],
    data
  ).orUndefined()
  const siteLocales = data.contentfulPage?.site

  useEffect(() => {
    toast.remove('promoToast')
  }, [pageUrl])

  const dispatch = useDispatch()
  const partnerName = getValueFromPartnerCookie('partnerName')
  const partnerGroup = getValueFromPartnerCookie('partnerGroup')
  useEffect(() => {
    // Fetch partner banner to display in place of default site-wide promo banner
    partnerName &&
      partnerGroup &&
      dispatch(IOPartnerBanner(partnerName, partnerGroup))
  }, [dispatch, partnerName, partnerGroup])

  const renderPageBody = () =>
    pageContext && (
      <ErrorBoundary>
        <PageBody>
          {pageComponents.cata(
            () => null,
            renderPageComponents(pageContext, location)
          )}
        </PageBody>
      </ErrorBoundary>
    )

  const headerData = useHeaderRedesignQuery()

  const headerComponent = allComps.contentfulHeader
    .map(data => <HeaderComponent data={data} key={data.id} />)
    .orNull()

  const footerComponent = (
    <div key="Footer-wrapper">
      {allComps.simpleFooter.cata(
        () =>
          allComps.contentfulFooter
            .map((data: ContentfulFooter) => (
              <div key="FooterComponent-wrapper">
                <FooterComponent data={data} id={data.id} key={data.id} />
              </div>
            ))
            .orNull(),
        simpleFooter => (
          <div key="SimpleFooter-wrapper">
            {/* @ts-expect-error TS(2322): Type 'PageLayoutComponent' is not assignable to ty... Remove this comment to see the full error message */}
            <SimpleFooterComponent
              data={simpleFooter}
              // @ts-expect-error TS(2339): Property 'id' does not exist on type 'PageLayoutCo... Remove this comment to see the full error message
              id={simpleFooter.id}
              // @ts-expect-error TS(2339): Property 'id' does not exist on type 'PageLayoutCo... Remove this comment to see the full error message
              key={simpleFooter.id}
            />
          </div>
        )
      )}
    </div>
  )

  return (
    <PageWrapper key={pageContext.id}>
      <CountryRedirectModals
        countryRedirectModals={
          countryRedirectModal || None<SmallTextSectionRedirect>()
        }
        hidePopups={!!hidePopups}
      />
      <PageToaster />
      <ApplyPromoCode />
      <GlobalPromotionalComponent
        data={{ globalPromoCode }}
        key="GlobalPromotionalComponent"
        pageContext={pageContext}
      />
      {pageTopBanner ? <TopPageBanner data={pageTopBanner} /> : null}
      {allComps.pageBanner
        .map(data => <PageBannerComponent data={data} key={data.id} />)
        .orNull()}
      <PromoBanner pageData={data} />
      {
        /* eslint-disable @typescript-eslint/consistent-type-assertions*/
        allComps.contentfulHeaderVariationContainer
          .map(data =>
            Maybe.fromNull(
              <ContentfulVariationContainerComponent
                data={data}
                key={data.id}
                // @ts-expect-error TS(2339) FIXME: Property 'internal' does not exist on type 'Varian... Remove this comment to see the full error message
                renderVariant={variant =>
                  variant.internal.type === 'ContentfulHeader' ? (
                    <HeaderComponent
                      data={variant as ContentfulHeaderFragment}
                    />
                  ) : null
                }
              />
            )
          )
          .orNull()
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
      }
      {allComps.contentfulHeader
        .map(() => (
          <HeaderRedesign
            {...headerData}
            type={
              pageUrl === 'choose-monitoring' ? 'plain-with-no-link' : 'full'
            }
          />
        ))
        .orNull()}
      {allComps.headerProgressBar
        .map(data => <HeaderProgressBarComponent data={data} key={data.id} />)
        .orNull()}
      {allComps.headerPartner
        .map(data => <HeaderPartnerComponent data={data} key={data.id} />)
        .orNull()}
      {allComps.modalExitIntent
        .map(data => (
          // @ts-expect-error TS(2322) FIXME: Type 'PageLayoutComponent' is not assignable to ty... Remove this comment to see the full error message
          <ModalExitIntentComponent
            data={data}
            key={data.id}
            location={location}
            pageContext={pageContext}
          />
        ))
        .orNull()}
      {allComps.modalPromoPopup
        .map(data => <ModalPromoPopupComponent data={data} key={data.id} />)
        .orNull()}
      {allComps.contentfulModalPromoPopupVariationContainer
        .map(data =>
          Maybe.fromNull(
            <ContentfulVariationContainerComponent
              data={data}
              key={data.id}
              // Assert type since TypeScript doesn't infer type from variant.internal.type check

              renderVariant={variant => (
                <ModalPromoPopupComponent
                  data={variant as ContentfulModalPromoPopup}
                />
              )}
            />
          )
        )
        .orNull()}
      {breadcrumbTitle || breadcrumbType ? (
        <Breadcrumbs
          bgClassName={
            breadcrumbType === 'Crime Near You'
              ? breadcrumbTitle
                ? 'bg-neutral-black'
                : 'mb-3 lg:mb-0'
              : ''
          }
          steps={[
            ...(breadcrumbTitle
              ? [
                  {
                    label: breadcrumbTitle,
                    slug: !!location?.search
                      ? `${location?.pathname?.substring(1) || ''}${
                          location.search
                        }`
                      : pageUrl
                  }
                ]
              : [])
          ]}
          template="Legacy/PLP"
          textClassName={
            breadcrumbType === 'Crime Near You' && breadcrumbTitle
              ? 'text-white'
              : ''
          }
          type={breadcrumbType}
        />
      ) : null}
      {
        pageHeroBanner
          .map(data =>
            data?.internal?.type === 'ContentfulVariationContainer' ? (
              /* eslint-disable @typescript-eslint/consistent-type-assertions */
              // @ts-expect-error TS(2322) FIXME: Type 'ContentfulHeroBanner' is not assignable to t... Remove this comment to see the full error message
              <ContentfulVariationContainerComponent
                data={data}
                renderVariant={variant => {
                  return variant?.internal?.type ===
                    'ContentfulScoutPlaceholder' ? (
                    <ScoutHardcodedComponent data={variant} />
                  ) : (
                    <HeroBannerComponent
                      data={variant as ContentfulHeroBanner}
                      key="hero-banner"
                    />
                  )
                }}
              />
            ) : data?.internal?.type === 'ContentfulScoutPlaceholder' ? (
              <ScoutHardcodedComponent data={data} />
            ) : data?.internal?.type === 'ContentfulHeroBannerFinancing' ? (
              <HeroBannerFinancingComponent data={data} />
            ) : (
              <div className={classNames(breadcrumbTitle && 'mt-3 lg:mt-0')}>
                <HeroBannerComponent
                  data={data as ContentfulHeroBanner}
                  key="hero-banner"
                />
              </div>
            )
          )
          .orNull()
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
      }
      <div key="TrustpilotReviewBanner-wrapper">
        {allComps.trustpilotBanner
          .map(data => (
            <TrustpilotUKTopBanner
              className="mt-5 md:mt-9"
              key={data.id}
            ></TrustpilotUKTopBanner>
          ))
          .orNull()}
      </div>
      {allComps.contentfulDefaultBanner.cata(
        () => null,
        data => (
          <PageMasthead key={data.id}>
            <DefaultBannerComponent data={data} />
          </PageMasthead>
        )
      )}
      {allComps.contentfulFloatingPromoWidget
        .map(data => (
          <div key="floating-promo-widget">
            <FloatingPromoWidgetComponent
              data={data}
              key={data.id}
              liveChatAppId={liveChatId}
            />
          </div>
        ))
        .orNull()}
      {allComps.authentication.cata(renderPageBody, data => (
        <AuthenticationComponent
          // @ts-expect-error TS(2322) FIXME: Type 'PageLayoutComponent' is not assignable to ty... Remove this comment to see the full error message
          data={data}
          key="AuthenticationComponent"
          location={location}
          pageContext={pageContext}
        >
          {renderPageBody()}
        </AuthenticationComponent>
      ))}
      {allComps.popupWizard
        .map(data => (
          <ErrorBoundary key={data.id}>
            <PopupQuoteWizard data={headerData.quoteWizard} />
          </ErrorBoundary>
        ))
        .orNull()}
      {
        // I think I have this logic correct, but it was hard to tell from the original code
        // If simplefooter is Just, render it, otherwise if contentfulFooter is Just, render that, otherwise render null
        locale === 'en-GB' ? (
          footerComponent
        ) : (
          <Footer data={footerData} type="Full" />
        )
      }
      {belowPageLayoutCompoments}
      <SEO
        key="page-seo"
        lang={path(['contentfulPage', 'node_locale'], data)}
        metaDescription={path(
          [
            'contentfulPage',
            'seoDetails',
            'metaDescription',
            'metaDescription'
          ],
          data
        )}
        metaKeyword={path(
          ['contentfulPage', 'seoDetails', 'keywords', 'keywords'],
          data
        )}
        metaTitle={pageTitle}
        pageSchema={pageSchema}
        pageUrl={pageUrl}
        productId={productId}
        seoDetails={safePath(['contentfulPage', 'seoDetails'], data).getOrElse(
          {}
        )}
        sharedLocaleUrl={siteLocales ? siteLocales.length > 1 : false}
      />
      {liveChatEnabled ? <LiveChat appId={liveChatId} /> : null}
    </PageWrapper>
  )
}

export { Page as default, mapPageComponentToTemplate }

export type { PageComponent, PageProps }
