import { footerSchema, headerSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const layoutSchema = z.object({
  __typename: z.literal('ContentfulLayout'),
  contentful_id: z.string(),
  footer: footerSchema.optional(),
  header: headerSchema.optional(),
  id: z.string(),
  internalTitle: z.string(),
  promoBannerStyle: z.string(),
  ready: z.boolean().default(true),
  liveChat: z.boolean().default(false)
})

export const layoutNoHeaderSchema = layoutSchema.omit({ header: true })

export type LayoutFragment = TypeOf<typeof layoutSchema>
