// extracted by mini-css-extract-plugin
export var bottomText = "GridContentBlock-module--bottomText--3a91d";
export var check = "GridContentBlock-module--check--ee6ae";
export var comparisonTable = "GridContentBlock-module--comparisonTable--932f9";
export var contentTable = "GridContentBlock-module--contentTable--24d6b";
export var contentText = "GridContentBlock-module--contentText--6ff82";
export var footerTxt = "GridContentBlock-module--footerTxt--1dda6";
export var gridBlock = "GridContentBlock-module--gridBlock--579e2";
export var gridContentTable = "GridContentBlock-module--gridContentTable--2a219";
export var gridContentTableForPlan = "GridContentBlock-module--gridContentTableForPlan--56bdf";
export var gridLogo = "GridContentBlock-module--gridLogo--c7eeb";
export var griddBlockContent = "GridContentBlock-module--griddBlockContent--290e2";
export var imgTick = "GridContentBlock-module--imgTick--287b4";
export var planHead = "GridContentBlock-module--planHead--cc6e0";
export var planTable = "GridContentBlock-module--planTable--a2f7e";
export var planTd = "GridContentBlock-module--planTd--25236";
export var securityFeature = "GridContentBlock-module--securityFeature--bfa82";
export var securityTitle = "GridContentBlock-module--securityTitle--c88c5";
export var ssMonitoringPlan = "GridContentBlock-module--ssMonitoringPlan--9d7c0";
export var ssMonitoringPlanContent = "GridContentBlock-module--ssMonitoringPlanContent--eb71a";
export var tableIcon = "GridContentBlock-module--tableIcon--a0615";
export var title = "GridContentBlock-module--title--35101";
export var titleText = "GridContentBlock-module--titleText--27aa7";
export var wrapper = "GridContentBlock-module--wrapper--2bfcd";