import { useCookies } from 'react-cookie'
import { CookieSetOptions } from 'universal-cookie'

import {
  Cookie,
  COOKIE_ATAT_TOKEN,
  COOKIE_CHAT_APP_ID,
  COOKIE_CHAT_OPENED,
  COOKIE_DRUPAL_UID,
  COOKIE_EMAIL,
  COOKIE_FCP_ORDER_ID,
  COOKIE_LEAD_DATA,
  COOKIE_MONITORING_GIFT_ITEM,
  COOKIE_MONITORING_PLAN,
  COOKIE_NON_MONITORING_GIFT_ITEM,
  COOKIE_PARTNERS_DATA,
  COOKIE_PREACTIVATION,
  COOKIE_PURCHASED_CART_ID,
  COOKIE_VID,
  CookieNames
} from './constants'

export const useTrackingCookies = (): readonly [
  Cookie,
  <T>(name: CookieNames, value: T, options?: CookieSetOptions) => void,
  (name: CookieNames, options?: CookieSetOptions) => void
] => {
  const [cookies, setCookie, removeCookie] = useCookies<CookieNames, Cookie>([
    COOKIE_ATAT_TOKEN,
    COOKIE_CHAT_APP_ID,
    COOKIE_CHAT_OPENED,
    COOKIE_DRUPAL_UID,
    COOKIE_FCP_ORDER_ID,
    COOKIE_LEAD_DATA,
    COOKIE_MONITORING_PLAN,
    COOKIE_MONITORING_GIFT_ITEM,
    COOKIE_NON_MONITORING_GIFT_ITEM,
    COOKIE_PREACTIVATION,
    COOKIE_PURCHASED_CART_ID,
    COOKIE_VID,
    COOKIE_PARTNERS_DATA,
    COOKIE_EMAIL
  ])
  return [cookies, setCookie, removeCookie]
}
