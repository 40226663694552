import { useFormStyles } from '@ecomm/promotions-hooks'
import { Link } from '@reach/router'
import { voidFn } from '@simplisafe/ewok'
import { leadingSlashIt } from '@simplisafe/ewok'
import classNames from 'classnames'

type PromoButtonProps = {
  readonly className?: string
  readonly href?: string
  readonly isSubmitting?: boolean
  readonly onClick?: VoidFunction
  readonly type?: 'button' | 'reset' | 'submit'
  readonly text: string
}

export function PromoButton({
  className = '',
  href = '',
  isSubmitting = false,
  onClick = voidFn,
  type = 'submit',
  text
}: PromoButtonProps) {
  const isLink = !!href
  const { buttonStyles } = useFormStyles()

  return isLink ? (
    <Link
      className={classNames(
        'block h-[51px] w-full cursor-pointer rounded-xl border-none p-[16px] text-center text-[16px] no-underline hover:brightness-90',
        buttonStyles,
        className
      )}
      data-component="PromoButton"
      onClick={onClick}
      to={leadingSlashIt(href)}
    >
      {text}
    </Link>
  ) : (
    <button
      className={classNames(
        'h-[51px] w-[170px] cursor-pointer rounded-xl border-none p-[16px] text-[16px] hover:brightness-90',
        buttonStyles,
        className
      )}
      data-component="PromoButton"
      disabled={isSubmitting}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  )
}
