import React, { ReactNode } from 'react'

import { CartSecuritySystemWarning } from './CartSecuritySystemWarning'
import CartWarningMessageItem from './CartWarningMessageItem'

export type CartWarningMessagesProps = {
  readonly mixedComponentsMessage?: ReactNode
  readonly showWithoutSystemMessage?: boolean
  readonly showMixedComponentsWarning?: boolean
  readonly showScoutWarning?: boolean
}

function CartWarningMessages({
  showWithoutSystemMessage,
  showMixedComponentsWarning,
  mixedComponentsMessage,
  showScoutWarning
}: CartWarningMessagesProps) {
  return (
    <>
      {showMixedComponentsWarning && mixedComponentsMessage && (
        <CartWarningMessageItem
          iconSrc="//images.ctfassets.net/br4ichkdqihc/1YbER6o3QQAGkeRvxBRSs/5a4dd142ebb4f0318dfdc4759be27856/Icon_-_Caution__Grey_.png"
          key="mixedcomponents"
          message={mixedComponentsMessage}
          showIcon
        />
      )}

      {(showWithoutSystemMessage || showScoutWarning) && (
        <CartSecuritySystemWarning />
      )}
    </>
  )
}

export default CartWarningMessages
