import { Accordion, ContentfulRichText } from '@ecomm/shared-components'
import { Text as Typography } from '@ecomm/ss-react-components'
import React from 'react'

import { Output as ComponentProps } from '../../hooks/useLocalizedData'
import { schema } from './schema'
import { useOnFaqExpand } from './useOnFaqExpand'

type FAQsProps = ComponentProps<typeof schema>

export function FAQs({ data }: { readonly data: FAQsProps }) {
  const { title, questions } = data
  const handleToggle = useOnFaqExpand()

  return (
    <div data-component="ScoutFAQs">
      {title && (
        <Typography useTailwind>
          <div className="text-center text-4xl font-bold">
            <ContentfulRichText raw={title} />
          </div>
        </Typography>
      )}
      {questions.map((question, idx) => {
        return (
          <div className="my-4" key={idx}>
            <Accordion
              {...question}
              onToggle={isOpen => handleToggle(idx + 1, isOpen)}
            />
          </div>
        )
      })}
    </div>
  )
}
