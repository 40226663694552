import classNames from 'classnames'
import React, { FC } from 'react'

import Text from '../Text'

export type TestimonialCardProps = {
  readonly dataComponent?: string
  readonly quote: string
  readonly noBackground: boolean
  readonly company: string
  readonly subtext?: string
}

/** @deprecated Do not use ss-react-components*/
const TestimonialCard: FC<TestimonialCardProps> = ({
  dataComponent = TestimonialCard.name,
  quote,
  noBackground,
  company,
  subtext
}: TestimonialCardProps) => {
  return (
    <div
      className={classNames(
        'rounded-base flex flex-col justify-center px-8 py-4 md:px-12 md:py-8',
        {
          'bg-neutral-light-50': !noBackground,
          'bg-transparent': noBackground
        }
      )}
      data-component={dataComponent}
    >
      <Text className="mb-4 text-center text-2xl font-light" useTailwind={true}>
        {`"${quote}"`}
      </Text>
      <Text
        className="m-0 text-center font-bold"
        textAlignment="center"
        useTailwind={true}
      >
        {company}
      </Text>
      <Text
        className="m-0 text-center"
        fontWeight="medium"
        textAlignment="center"
        useTailwind={true}
      >
        <span className="text-base">{subtext}</span>
      </Text>
    </div>
  )
}

export default TestimonialCard
