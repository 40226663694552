import { Header } from '@ecomm/header-redesigned'
import {
  ApplyPromoCode,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Footer } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { graphql, PageProps } from 'gatsby'

import ManualGrid from '../../components/ManualPage/ManualGrid'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import type { SeoNodeSchema } from '../../schemas/seo'
import { parseManualSchema } from './schema'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function ManualTemplate<
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>({ data, pageContext: { locale, seoDetails } }: Props<T>) {
  const {
    footer,
    content: { content }
  } = parseManualSchema(data)
  const headerData = useHeaderRedesignQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={'manual'}>
      <SEO
        canonicalLink={seoDetails.canonicalLink ?? ''}
        isNofollow={seoDetails.isNofollow}
        isNoindex={seoDetails.isNoindex}
        lang={locale}
        metaDescription={seoDetails.metaDescription?.metaDescription ?? ''}
        metaKeywords={seoDetails.metaKeywords ?? []}
        metaTitle={seoDetails.metaTitle}
      />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <ApplyPromoCode />
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={'standard'}
        />
        <Header {...headerData} />
        <div className="max-w-8xl mx-auto my-16 w-full px-4 md:px-8">
          <h1 className="w-full text-center">User Manuals</h1>
          <div className="flex w-full flex-col gap-4 md:gap-8 lg:gap-12">
            {content.map((grid, index) => (
              <ManualGrid
                headline={grid.headline}
                items={grid.gridItems}
                key={`manual-grid-${index}`}
              />
            ))}
          </div>
        </div>
        {footer ? <Footer data={footer} type={footer.type} /> : null}
      </div>
    </TrackingProvider>
  )
}

export const query = graphql`
  #graphql
  query Manual($footerId: String) {
    content: contentfulContentCollection(
      contentful_id: { eq: "7CWinz8OsmMvg3oQAzabtu" }
    ) {
      contentful_id
      content {
        ... on ContentfulGrid {
          __typename
          contentful_id
          headline
          gridItems {
            ... on ContentfulImageLink {
              ...miscImageLinkFragment
            }
          }
        }
        ... on ContentfulSmallText {
          __typename
          contentful_id
          text {
            raw
          }
        }
      }
    }
    footer: contentfulFooter(contentful_id: { eq: $footerId }) {
      ...footerFragment
    }
  }
`
