import React, { HTMLAttributes, ReactElement } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  readonly text?: string
}

export function Copyright({
  text = '',
  className = '',
  style
}: Props): ReactElement {
  return (
    <div className={`${className}`} style={style}>
      <span className="text-sm">{text}</span>
    </div>
  )
}
