//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import path from 'ramda/src/path'
import React, { FC, ReactElement, ReactNode } from 'react'

import * as Icons from '../icons'
import * as gridCss from '../scss/grid/grid.module.scss'
import Square from '../Square'
import * as css from './GridContentBlock.module.scss'

export type itemRow = {
  readonly type?: string
  readonly value?: ReactElement | ReactNode
  readonly iconPopup?: readonly []
}

export interface iconItemRow extends itemRow {
  readonly type: 'icon'
}

export type row = {
  readonly row: readonly itemRow[]
}

export type table = {
  readonly header: readonly itemRow[]
  readonly rows: readonly row[]
  readonly footer?: ReadonlyArray<ReadonlyArray<string>>
}

export type GridContentBlockProps = {
  readonly title: string
  /** The content of the component */
  readonly content?: ReactNode
  readonly tableList: table
  /** Handle the icon with a modal */
  readonly iconPopup: boolean
  readonly dataComponent?: string
}

// TODO Handle invalid icon names without causing the component/page to break. Possibly also better typing for itemRow
// since it only really handles 'icon' and 'text'
export const renderIcon = (rowItem: iconItemRow, iconPopup?: boolean) => {
  if (rowItem.type !== 'icon') {
    return null
  } else {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/consistent-type-assertions -- legacy code */
    const icon = ifElse(
      equals(true),
      always('Info'),
      always(rowItem.value as string)
    )(iconPopup)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const Component = Icons[icon]
    return <Component />
  }
}

const renderComparisonTable = (
  tableList: table,
  thClasses: string,
  rowTextClass: string,
  rowIconClass: string,
  tableClass: string,
  tableSelector: string,
  iconPopup?: boolean
) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const footerData = path(['footer'], tableList) as ReadonlyArray<
    ReadonlyArray<string>
  >

  const renderEmptyCell = (index: number) => <td key={`rowiteam-${index}`}></td>

  const renderCell = (
    rowItem: iconItemRow | itemRow,
    index: number,
    iconPopup?: boolean
  ) => {
    // TODO This needs some further clean up:
    // Why do we pass rowItem to renderIcon when ww we know the type is incompatible?
    // What does the combination of footerData being not nil and index being 0 mean? And why do we do the above when it happens?
    // rowiteam is a typo or intentional?
    // why does one key have a dash and the other doesn't?

    return rowItem.type === 'text' ? (
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      <td className={rowTextClass} key={'rowiteam' + index}>
        {rowItem.value}{' '}
        {!isNil(footerData) && index === 0 && (
          <span className={classNames(css['tableIcon'])}>
            {renderIcon(rowItem as iconItemRow, iconPopup)}
          </span>
        )}
      </td>
    ) : (
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      <td className={rowIconClass} key={`rowiteam-${index}`}>
        {rowItem.value !== '' ? renderIcon(rowItem as iconItemRow) : ''}
      </td>
    )
  }

  return (
    <div className={tableClass}>
      <table className={tableSelector}>
        <thead>
          <tr>
            {tableList.header.map((row: itemRow, index) =>
              row.type ? (
                row.type === 'icon' ? (
                  <th
                    className={classNames(css['gridLogo'])}
                    id="gridLogo"
                    key={`header-${index}`}
                  >
                    {row.value ? row.value : ''}
                  </th>
                ) : (
                  <th className={thClasses} key={`header-${index}`}>
                    {row.value}
                  </th>
                )
              ) : (
                <th
                  className={classNames(css['planHead'])}
                  key={`header-${index}`}
                ></th>
              )
            )}
          </tr>
        </thead>

        <tbody>
          {tableList.rows.map((row: row, index) => (
            <tr key={`row-${index}`}>
              {row.row.map((rowItem: itemRow, index) => {
                const hasType = !isNil(rowItem.type)

                return hasType
                  ? renderCell(rowItem, index, iconPopup)
                  : renderEmptyCell(index)
              })}
            </tr>
          ))}
        </tbody>
        {!isNil(footerData) && (
          <tfoot data-component="tfoot">
            {footerData.map((footerRow: readonly string[], index: number) => (
              <tr key={`row-${index}`}>
                {footerRow.map(
                  (row: string, i: number) =>
                    index !== 1 && (
                      <td
                        className={classNames(css['bottomText'])}
                        key={`cell-${i}`}
                      >
                        {row}{' '}
                        {index === 0 && i === 1 && (
                          <span className={classNames(css['footerTxt'])}>
                            {path(['footer', '1', '1'], tableList)}
                          </span>
                        )}
                      </td>
                    )
                )}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  )
}

/** @deprecated Do not use ss-react-components*/
const GridContentBlock: FC<GridContentBlockProps> = ({
  content,
  iconPopup,
  dataComponent = GridContentBlock.name,
  tableList,
  title
}: GridContentBlockProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const thClasses = classNames(
    css['securityTitle'],
    css['mediumText'],
    css['planHead']
  )
  const rowTextClass = classNames(css['securityFeature'], css['planTd'])
  const rowIconClass = iconPopup
    ? classNames(css.imgTick, css.planTd)
    : css.check
  const tableSelector = iconPopup ? css.planTable : css.contentTable
  const tableClass = iconPopup
    ? classNames(css['gridContentTableForPlan'])
    : classNames(css['gridContentTable'])

  return (
    <>
      {ifElse(
        equals(true),
        always(
          <div
            className={classNames(css['ssMonitoringPlan'])}
            data-component={dataComponent}
          >
            <div className={classNames(css['ssMonitoringPlanContent'])}>
              <h2 className={classNames('h2', css['title'])}>{title}</h2>
              {content}
            </div>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code */}
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}{' '}
            {/* @ts-expect-error */}
            <div className={classNames(css.ssMonitoringPlanTable)}>
              {renderComparisonTable(
                tableList,
                thClasses,
                rowTextClass,
                rowIconClass,
                tableClass,
                tableSelector,
                iconPopup
              )}
            </div>
          </div>
        ),
        always(
          <div
            className={classNames(gridCss['row'], css.wrapper)}
            data-component={dataComponent}
          >
            <div
              className={classNames(
                'paddingLarge',
                gridCss['col-xs-12'],
                gridCss['col-sm-12'],
                gridCss['col-md-5'],
                css['gridBlock']
              )}
            >
              <div className={classNames(css['griddBlockContent'])}>
                <h2 className={classNames('h2', css['title'])}>{title}</h2>
                {content}
              </div>
            </div>
            <Square
              className={classNames(
                css['comparisonTable'],
                gridCss['col-xs-12'],
                gridCss['col-sm-12'],
                gridCss['col-md-7'],
                'shadowMedium'
              )}
            >
              {renderComparisonTable(
                tableList,
                thClasses,
                rowTextClass,
                rowIconClass,
                tableSelector,
                tableClass
              )}
            </Square>
          </div>
        )
      )(iconPopup)}
    </>
  )
}

export default GridContentBlock
