//@ts-nocheck
import classNames from 'classnames'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- I have no idea why this component uses prop-types
// @ts-expect-error
import PropTypes from 'prop-types'
import equals from 'ramda/src/equals'
import isNil from 'ramda/src/isNil'
import prop from 'ramda/src/prop'
import React, { ChangeEvent, FC, ReactNode, useState } from 'react'
import { useTracking } from 'react-tracking'

import { SSInput } from '..'
import { useMediaQuery } from '../hooks'
import { ReactComponent as CloseGraySVG } from '../icons/svg/close-gray.svg'
import QuantityChanger, { QuantityChangerProps } from '../QuantityChanger'
import QuantitySelect, { QuantitySelectProps } from '../QuantitySelect'
import { SSInputProps } from '../SSInput/index'
import * as css from './CartLineItem.module.scss'
import SubItems, { SubItemsProps } from './SubItems'

export type CartLineItemProps = {
  readonly itemName: string
  readonly sku?: string
  // changed to be optional, we can pass isFreeItem without price in particular scenario
  readonly price?: string
  readonly isFreeItem?: boolean
  readonly isImmutable?: boolean
  /** The content of the item description */
  readonly itemDescriptionContent?: ReactNode
  readonly subItems?: SubItemsProps['items']
  readonly itemLink?: string
  readonly onClickRemove?: () => void
  readonly outOfStockMessage?: ReactNode
  readonly quantity?: QuantityChangerProps
  readonly quantitySelect?: QuantitySelectProps
  readonly isOrderSummaryRedesign?: boolean
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
  // @ts-expect-error
  readonly quantityItem?: ReactNode<SSInputProps>
  readonly isHighlightedLineItem?: boolean
  readonly className?: string
  readonly quantityText?: string
  readonly theme?: 'customLineItem' | 'default' | 'subLineItem'
  readonly dataComponent?: string
  readonly clickLink?: (url: string) => void
}

const renderOutOfStockMessage = (
  outOfStockMessage: ReactNode,
  subItems?: SubItemsProps['items']
) =>
  !isNil(outOfStockMessage) && (
    <div
      className={classNames(
        `rounded-base text-alert-100 mt-4 pb-6 empty:hidden ${
          isNil(subItems) || subItems.length === 0
            ? 'w-full md:mr-6'
            : 'md:mx-6'
        }`
      )}
      data-component={'outOfStockMessage'}
    >
      {outOfStockMessage}
    </div>
  )

const getDefaultQuantityValue = (
  stepperQuantity: number | undefined,
  selectQuantity: number | undefined
) => {
  return stepperQuantity || selectQuantity || 0
}

/** @deprecated Do not use ss-react-components*/
const CartLineItem: FC<CartLineItemProps> = ({
  itemLink,
  itemName,
  price,
  itemDescriptionContent,
  subItems,
  isFreeItem,
  onClickRemove,
  outOfStockMessage,
  isHighlightedLineItem,
  isOrderSummaryRedesign,
  quantity,
  quantitySelect,
  className,
  theme = 'default',
  quantityText,
  quantityItem
}: CartLineItemProps) => {
  const isTabletAndUp = useMediaQuery('TabletAndUp')

  const { Track, trackEvent } = useTracking()

  const [quantityValue, setQuantityValue] = useState<number>(
    getDefaultQuantityValue(quantity?.defaultValue, quantitySelect?.selected)
  )

  const onChangeEvents = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    checkbox: (
      e: ChangeEvent<HTMLInputElement>,
      quantity: ReactNode<SSInputProps>
    ) => handleQuantityChange(e.target.checked, quantity.props.onChange),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    select: (
      e: ChangeEvent<HTMLSelectElement>,
      quantity: PropTypes.QuantitySelectProps
    ) => handleQuantityChange(e.target.value, quantity.onChange),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    stepper: (
      e: ChangeEvent<HTMLInputElement>,
      quantity: PropTypes.QuantityChangerProps
    ) => handleQuantityChange(e, quantity.onChange)
  }

  const RenderStepper = (quantity: QuantityChangerProps) => (
    <QuantityChanger
      {...quantity}
      max={prop('max', quantity)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
      // @ts-expect-error
      onChange={(e: number) => onChangeEvents.stepper(e, quantity)}
    />
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/no-typos
  RenderStepper.propTypes = { quantity: PropTypes.QuantityChangerProps }

  const RenderSelect = (quantitySelect: QuantitySelectProps) => (
    <QuantitySelect
      className={css.selectQuantity}
      {...quantitySelect}
      max={prop('max', quantitySelect)}
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        onChangeEvents.select(e, quantitySelect)
      }
    />
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/no-typos
  RenderSelect.propTypes = { quantitySelect: PropTypes.QuantitySelectProps }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
  // @ts-expect-error
  const RenderCheckbox = (quantityItem: ReactNode<SSInputProps>) => (
    <div className={css.quantityItem}>
      <SSInput
        {...quantityItem.props}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChangeEvents.checkbox(e, quantityItem)
        }
      />
    </div>
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/no-typos
  RenderCheckbox.propTypes = { quantityItem: PropTypes.SSInputProps }

  const RenderQuantityText = () =>
    !isNil(quantityText) && (
      <span data-component="quantityText">{quantityText}</span>
    )

  const handleQuantityChange = (
    newQuantity: number,
    onChange:
      | QuantityChangerProps['onChange']
      | QuantitySelectProps['onChange']
      | SSInputProps['onChange']
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
    // @ts-expect-error
    onChange && onChange(newQuantity)
    setQuantityValue(newQuantity)
    trackEvent({
      addRemove: quantityValue < newQuantity ? 'add' : 'remove',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
      // @ts-expect-error
      event: 'addRemoveEvent',
      pageTitle: document.title,
      sensor: itemName
    })
  }

  return (
    <div
      className={classNames({
        [css.shoppingProdList]:
          equals(theme, 'default') || equals(theme, 'subLineItem'),
        [css.cartSummaryList]: equals(theme, 'customLineItem'),
        [css.cartLineItemRedesignMargin]: isOrderSummaryRedesign === true,
        className
      })}
      data-component="CartLineItem"
    >
      <div
        className={classNames({
          [css.hasQuantityInput]:
            !isNil(quantity) ||
            !isNil(quantitySelect) ||
            !isNil(quantityText) ||
            !isNil(quantityItem)
        })}
      >
        <Track>
          {!isNil(quantity) && RenderStepper(quantity)}
          {RenderQuantityText()}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code */}
          {/* @ts-expect-error */}
          {!isNil(quantitySelect) && RenderSelect(QuantitySelect)}
          {!isNil(quantityItem) && RenderCheckbox(quantityItem)}
        </Track>
      </div>
      <div className={css.main}>
        <div
          className={classNames(css.line, {
            [css.freeItemText]: isHighlightedLineItem
          })}
        >
          <span className={css.title}>{itemName}</span>
        </div>
        {itemDescriptionContent ? (
          <div
            className={classNames(
              'prose md:prose-md prose-p:text-neutral-medium-120',
              css.shoppingProddesc
            )}
          >
            {itemDescriptionContent}
          </div>
        ) : null}
        {!isNil(subItems) && subItems.length > 0 && (
          <div
            className={classNames(
              'prose md:prose-md prose-li:text-neutral-medium-120',
              css.shoppingProddesc,
              { [css.shoppingProddescMargin]: !isOrderSummaryRedesign }
            )}
          >
            <SubItems
              className={classNames({
                hideMobile: !equals('subLineItem', theme)
              })}
              isExpandEnabled={isTabletAndUp ? !isOrderSummaryRedesign : null}
              isOrderSummaryRedesign={isOrderSummaryRedesign}
              itemLink={itemLink}
              items={subItems}
            />
          </div>
        )}
        {renderOutOfStockMessage(outOfStockMessage, subItems)}
      </div>
      <div
        className={classNames(
          css.shoppingProdPrice,
          'mediumText',
          isHighlightedLineItem && css.freeItemText
        )}
      >
        <span className={classNames(isFreeItem && 'decorationLineThrough')}>
          {price}
        </span>{' '}
        {isFreeItem ? (
          <span className={classNames(css.freeItemText, css.crossedOut)}>
            Free
          </span>
        ) : null}
      </div>

      {!equals(theme, 'subLineItem') && !isNil(onClickRemove) && (
        <div className={css.shoppingProdremove}>
          <button
            aria-label={'Remove the item'}
            className={classNames(css.shoppingProdremoveButton)}
            onClick={onClickRemove}
            type={'button'}
          >
            <span aria-hidden={true} className={classNames('fontSize21')}>
              <CloseGraySVG />
            </span>
          </button>
        </div>
      )}
    </div>
  )
}

export default CartLineItem
