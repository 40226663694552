import { flow } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useEffect, useState } from 'react'

import { parseExperiments } from './lib/parseConfig'
import { useReadyClient } from './lib/useReadyClient'

export const useOptimizelyVariations = () => {
  const [experiments, setExperiments] = useState<
    O.Option<Record<string, readonly string[]>>
  >(O.none)

  const client = useReadyClient()

  useEffect(() => {
    client().then(
      flow(
        O.chain(c => O.fromNullable(c.getOptimizelyConfig())),
        O.map(c => c.experimentsMap),
        O.map(parseExperiments),
        setExperiments
      )
    )
  }, [])

  return experiments
}
