import type { DiscountCodeInfo } from '@commercetools/platform-sdk'
import type { CartLineItemProps } from '@ecomm/ss-react-components'
import type { QuantityChangerProps } from '@ecomm/ss-react-components'
import type { OptimizelyPurchaseEvent } from '@ecomm/tracking'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import { transformObject } from '@simplisafe/ewok'
import { safeFind, safeProp } from '@simplisafe/monda'
import type {
  ImmutableCart,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  formatCTPriceToNumber,
  localizedDisplayPrice
} from '@simplisafe/ss-ecomm-data/commercetools/price'
import { Just } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import concat from 'ramda/src/concat'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import omit from 'ramda/src/omit'
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import subtract from 'ramda/src/subtract'
import unless from 'ramda/src/unless'

import { formatDisplayPrice, toMaybeOrNone } from '../../commercetools/price'
import { locale } from '../../commercetools/utils'
import type { TrackEvent } from '../../util/analytics'
import { trackAddLineItemToCartEvent } from '../../util/analytics/addToCart'
import { trackRemoveCartEvent } from '../../util/analytics/removeFromCart'
import { Child } from './getCartDetails'
import { SubItem } from './types'

export const getNameWithUSFallback = (item: {
  readonly name: Record<string, string>
}): string => {
  const usName: string = path(['name', 'en-US'], item)

  const localeName: string = path(['name', locale], item)

  return localeName || usName
}

const toTextQuantity = (quantity: number) => concat(quantity.toString(), 'x')

const toQuantity = (onQuantityChange: any) =>
  applySpec<QuantityChangerProps>({
    defaultValue: prop('quantity'),

    onChange: x => (quantity: any, type: any) =>
      onQuantityChange(prop('lineItemId', x), quantity, x, type)
  })

// TODO move this out of here and into contentful or commercetools
/** This is more hardcoded than it should be, but it works for now. */
/**
 * Adds the word "Included" before skus that end with "-INC".
 */
export function prefixIncSkuNameWithIncludedText<
  T extends { readonly sku: string; readonly name: Record<string, string> }
>(item: T) {
  return item && item.sku && item.sku.includes('-INC')
    ? {
        ...omit(['name'], item),
        name: {
          ...Object.keys(item.name).reduce(
            (acc, i) => ({
              ...acc,
              [i]: `Included ${item.name[i]}`
            }),
            {}
          )
        }
      }
    : item
}

export const toSubItem = transformObject<Child, SubItem>({
  subItemIsFree: prop<Child, 'isFree'>('isFree'),
  subItemName: pipe(prefixIncSkuNameWithIncludedText, getNameWithUSFallback), // something weird is going on and this function can be called with an undefined object.
  subItemQuantity: prop<Child, 'quantity'>('quantity'),
  subItemSku: prop<Child, 'sku'>('sku')
})

const toItem =
  (
    trackEvent: TrackEvent,
    lineItems: readonly LineItem[],
    onQuantityChange: any,
    onRemoveProduct: any,
    onClickProduct: any,
    linkText?: string
  ) =>
  (x: any): CartLineItemProps => {
    const trackQuantityChange = (newQuantity: number, oldQuantity: number) => {
      const netQuantityChange = Math.abs(newQuantity - oldQuantity)
      oldQuantity < newQuantity &&
        trackAddLineItemToCartEvent(x, trackEvent, lineItems, netQuantityChange)
      oldQuantity > newQuantity &&
        trackRemoveCartEvent(x, trackEvent, lineItems, netQuantityChange)
    }

    const lineItemProp = {
      isBms: pipe(
        path(['custom', 'fields', 'product_is_bms']),
        defaultTo(false)
      ),
      isFreeItem: prop('isGift'),
      isImmutable: () => path(['custom', 'fields', 'immutableLineItem'], x),
      // TODO: fix the type for child
      // the hild prop does not exist on unknown
      // @ts-expect-error TS(2345): Argument of type '<O extends { child: V; }, T exte... Remove this comment to see the full error message
      itemLink: pipe<LineItem, LineItem['child'], string>(
        prop('child'),
        unless(isEmpty, always(linkText))
      ),
      itemName: getNameWithUSFallback,

      lineItemDisplayName: path(['custom', 'fields', 'lineItemDisplayName']),
      onClickRemove: always(
        unless(
          isNil,
          always(() => onRemoveProduct(x))
        )(onRemoveProduct)
      ),
      onClickProduct: always(
        unless(
          isNil,
          always(() => onClickProduct(x))
        )(onClickProduct)
      ),
      packageParentId: path(['custom', 'fields', 'package_parent_id']),
      price: pipe(prop('price'), (p: number) =>
        formatDisplayPrice(p).orJust('')
      ),
      sku: prop('sku'),
      subItems: pipe(prop('child'), unless(isNil, map(toSubItem))),
      trackQuantityChange: always(trackQuantityChange)
    }
    //Quantity text will be shown when there is no quantity callback action related to quantity update
    return ifElse(
      equals(true),
      always(
        applySpec<CartLineItemProps>({
          ...lineItemProp,
          quantityText: pipe(prop('quantity'), toTextQuantity)
        })(x)
      ),
      always(
        applySpec<CartLineItemProps>({
          ...lineItemProp,
          quantity: toQuantity(onQuantityChange)
        })(x)
      )
    )(
      isNil(onQuantityChange) ||
        path(['custom', 'fields', 'immutableLineItem'], x) ||
        prop('isGift', x) ||
        propEq('productType', 'service')(x) ||
        propEq('sku', 'SSPSH-ON')(x) || // ECP-6912 we don't want Pro Setup Help to have quantity selector
        propEq('sku', 'SSPSH')(x) ||
        propEq('sku', 'SSPSH-UK')(x)
    )
  }

export const toItemList =
  (lineItems: any) =>
  (
    trackEvent: TrackEvent,
    onQuantityChange?: any,
    onRemoveProduct?: any,
    onClickProduct?: any,
    linkText?: string
  ) =>
    Just(lineItems).map(x =>
      map(
        toItem(
          trackEvent,
          lineItems,
          onQuantityChange,
          onRemoveProduct,
          onClickProduct,
          linkText
        ),
        x
      )
    )

/**
 * Returns display string for cart subtotal, which represents the cart total less discounts,
 * less shipping, and optionally less taxes. en-GB is VAT-included pricing, so that remains.
 *
 * TODO move this logic to ecomm-data or maybe CT if aware of locale-based tax inclusion
 *
 * @param locale
 * @param cart
 */
export const getCartSubtotal = (locale: string) => (cart: ImmutableCart) => {
  const shouldIncludeTax = locale === 'en-GB'
  return Just(cart.get('totalPrice'))
    .map(totalPrice =>
      subtract(totalPrice, getShippingInfoPrice(cart).orJust(0))
    )
    .map(totalPrice =>
      shouldIncludeTax
        ? totalPrice
        : subtract(totalPrice, cart.get('taxedPrice').orJust(0))
    )
    .chain(localizedDisplayPrice(locale))
}

/**
 * @see transformLineItem.getCartSubtotal with local locale included
 */

export const getLocalizedCartSubtotal = getCartSubtotal(locale)

export const toTrackCartItem = transformObject<
  LineItem,
  OptimizelyPurchaseEvent
>({
  price: x => prop('totalPrice', x),
  productType: x => prop('productType', x),
  qty: x => prop('quantity', x),
  sku: x => prop('sku', x)
})

export const toTrackCartItemsList = (lineItems: ReadonlyArray<LineItem>) =>
  lineItems.map(x => toTrackCartItem(x))

export const getShippingInfoPrice = (c: ImmutableCart) =>
  safeProp('shippingInfo', c)
    .chain(toMaybeOrNone)
    .chain(safeProp('price'))
    .map(formatCTPriceToNumber)

export const getCartDiscountValue = (cart: ImmutableCart): string | null => {
  const discountAmount = cart.totalCartDiscount * -1

  return discountAmount < 0
    ? formatDisplayPrice(discountAmount).orJust('')
    : null
}

export const getCartDiscountCode = (c: ImmutableCart) =>
  safeProp('discountCodes', c)
    .chain(toMaybeOrNone)
    .chain(safeFind<DiscountCodeInfo>(propEq('state', 'MatchesCart')))
    .chain(safeProp('discountCode'))
    .chain(safeProp('obj'))
    .chain(safeProp('code'))
    .getOrElse('')

export const getShippingMethodId = (cart: ImmutableCart) =>
  safeProp('shippingInfo', cart)
    .chain(toMaybeOrNone)
    .chain(safeProp('shippingMethod'))
    .chain(safeProp('id'))

export const getCustomCartValues = (cart: ImmutableCart) =>
  safeProp('custom', cart).chain(toMaybeOrNone).chain(safeProp('fields'))
