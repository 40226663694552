import { voidFn } from '@simplisafe/ewok'
import { window } from 'browser-monads-ts'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { BrazeProps } from './braze'

export function getBraze<T>(fn: (b: BrazeProps, fallback?: T) => T): T {
  const braze = O.fromNullable(window.braze)
  // @ts-expect-error -- there isn't a way to tell TS that T could be void in a way that doesn't break the implementation
  return pipe(braze, O.fold(voidFn, fn))
}
