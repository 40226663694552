import { devError } from '@ecomm/error-handling'
import { voidFn } from '@simplisafe/ewok'
import React, { forwardRef, useEffect } from 'react'

import { useWhichFramework } from './useWhichFramework'

const RemixLink = React.lazy(() =>
  import('@remix-run/react').then(m => ({ default: m.Link }))
)

const GatsbyLink = React.lazy(() =>
  import('gatsby').then(m => ({ default: m.Link }))
)

type Props = JSX.IntrinsicAttributes & {
  /** @deprecated - This is Gatsby specific and will not work on Remix. Use tailwind classes instead */
  readonly activeClassName?: string
  readonly children: React.ReactNode
  readonly className?: string
  readonly onClick?: React.MouseEventHandler<HTMLAnchorElement>
  readonly onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>
  readonly onTouchStart?: React.TouchEventHandler<HTMLAnchorElement>
  readonly tabIndex?: number
  readonly to: string
  readonly 'data-testid'?: string
}

/**
 * Link that works with both Gatsby and Remix.
 *
 * Feel free to add props as needed, just make sure they work for both Gatsby and Remix.
 */
export const Link = forwardRef(function (
  {
    to,
    children,
    className,
    onMouseEnter,
    onTouchStart,
    onClick,
    tabIndex,
    activeClassName,
    ...rest
  }: Props,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const framework = useWhichFramework()
  const testId = rest['data-testid']

  useEffect(() => {
    if (framework === 'gatsby' && !!activeClassName) {
      return devError(
        '<Link /> - activeClassName is deprecated and will stop working as we migrate to Remix. Please fix ASAP!'
      )
    } else {
      return voidFn()
    }
  }, [activeClassName, framework])

  return (
    <React.Suspense
      fallback={
        <a className={className} href={to} {...rest} data-testid={testId}>
          {children}
        </a>
      }
    >
      {framework === 'gatsby' ? (
        <GatsbyLink
          activeClassName={activeClassName}
          className={className}
          data-component="Link"
          data-testid={testId || 'gatsby-link'}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onTouchStart={onTouchStart}
          // @ts-expect-error - Gatsby has a different type for ref
          ref={ref}
          tabIndex={tabIndex}
          to={to}
          {...rest}
        >
          {children}
        </GatsbyLink>
      ) : (
        <RemixLink
          className={className}
          data-component="Link"
          data-testid={testId || 'remix-link'}
          onClick={onClick}
          ref={ref}
          tabIndex={tabIndex}
          to={to}
          {...rest}
        >
          {children}
        </RemixLink>
      )}
    </React.Suspense>
  )
})
