import { addAtomDebugLabel } from '@ecomm/utils'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'
import { match } from 'ts-pattern'

import { useCartValue } from './lib/useCart'

const cartDiscountCodeAtom = atom<string | null>(null)
addAtomDebugLabel(cartDiscountCodeAtom, 'Cart - Discount Code')

export const useCartDiscountCode = () => {
  const cart = useCartValue()

  const [discountCode, setDiscountCode] = useAtom(cartDiscountCodeAtom)

  useEffect(() => {
    match(cart)
      .with({ _tag: 'not_initialized' }, _ => setDiscountCode(null))
      .with({ _tag: 'with_items' }, cart =>
        setDiscountCode(cart.val.discountCode)
      )
      .with({ _tag: 'updating' }, cart =>
        setDiscountCode(cart.val.discountCode)
      )
      .with({ _tag: 'error' }, _ => setDiscountCode(null))
      .with({ _tag: 'empty' }, _ => setDiscountCode(null))
      .with({ _tag: 'no_cart_id' }, _ => setDiscountCode(null))
      .exhaustive()
  }, [cart, setDiscountCode])

  return discountCode
}
