import { useLocale } from '@ecomm/data-hooks'
import {
  monthlyToLocalizedDailyPrice,
  PriceProvider,
  usePriceContext
} from '@ecomm/data-price'
import { parseJSONDataFromContentful } from '@ecomm/scout'
import * as Icons from '@ecomm/shared-icons'
import { IconTypes } from '@ecomm/shared-icons'
import { match } from 'fp-ts/lib/Option'

import { useMonitoringQuery } from './query'

type MonitoringProps = {
  readonly title: string
  readonly description: string
  readonly icons: readonly {
    readonly icon: IconTypes
    readonly text: string
  }[]
  readonly relatedInformation: string
  readonly extraPriceText: string
  readonly productSKU: string
}

export function MonitoringWrapper() {
  const data = useMonitoringQuery()
  const copy: {
    readonly title: string
    readonly description: string
    readonly icons: readonly {
      readonly icon: IconTypes
      readonly text: string
    }[]
    readonly relatedInformation: string
    readonly extraPriceText: string
    readonly productSKU: string
  } = parseJSONDataFromContentful(data, 'contentfulJson')
  const locale = useLocale()

  return (
    <PriceProvider locale={locale} skus={[copy.productSKU]}>
      <Monitoring {...copy} />
    </PriceProvider>
  )
}

function Monitoring(data: MonitoringProps) {
  const { getPrice } = usePriceContext()
  const locale = useLocale()

  const price = match(
    () => null,
    (price: number) => monthlyToLocalizedDailyPrice(locale, price)
  )(getPrice(data.productSKU))

  return (
    <div className="p-4 md:p-8 lg:px-20 lg:py-16">
      <h2 className="font-default mb-4 text-3xl font-medium text-gray-600 md:mb-10 md:text-4xl">
        {data.title}
      </h2>
      <div className="flex flex-col gap-10 md:flex-row">
        <div className="flex w-full flex-col justify-between md:w-1/2 lg:w-7/12">
          <p className="text-base text-gray-600 md:text-lg lg:mb-16">
            {data.description}
          </p>
          <div>
            {price ? (
              <p className="text-primary-100 text-base">
                <span>{`${price}/day`}</span> {data.extraPriceText}
              </p>
            ) : null}
            <p className="text-xs font-medium text-gray-600">
              {data.relatedInformation}
            </p>
          </div>
        </div>
        <div className="grid w-full grid-cols-3 gap-y-6 text-center text-xs font-medium md:w-1/2 lg:w-5/12 lg:gap-x-8 lg:gap-y-12">
          {data.icons.map((icon, i) => {
            const Icon = Icons[icon.icon]
            return (
              <div className="flex flex-col" key={i}>
                <Icon className="mx-auto h-14 w-14" />
                <p className="mx-auto w-[100px] text-gray-600">{icon.text}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
