import {
  TrustpilotBanner,
  trustPilotUKHomePageCarousel,
  trustPilotUKPageTopBanner,
  trustPilotUKReviewPageCarousel,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import { TrustpilotProps } from './schema'

//This component is temporary to speed up things, but it will be deleted
//and all the logic of the type field will be placed on the shared trustpilot banner in other PR
const widgets = {
  'Home Page Carousel': trustPilotUKHomePageCarousel,
  'Top Banner': trustPilotUKPageTopBanner,
  'Reviews Page Carousel': trustPilotUKReviewPageCarousel
}
export default function HomeLandingTrustpilotBanner({
  type,
  className = ''
}: TrustpilotProps & { readonly className?: string }) {
  const widget = widgets[type]
  return widget ? (
    type === 'Top Banner' ? (
      <TrustpilotUKTopBanner />
    ) : (
      <TrustpilotBanner
        className={classNames(
          '-mb-4 mt-6 md:-mb-6 md:mt-8 lg:mt-12',
          className
        )}
        widget={widget}
      />
    )
  ) : null
}
