import classNames from 'classnames'
import { useField } from 'formik'
import React, { FC } from 'react'

import { SSSelect } from '..'

export type FormSelectProps = {
  readonly className?: string
  readonly classNameLabel?: string
  readonly disabled?: boolean
  readonly fullWidth?: boolean
  readonly label?: string
  /** Field name, note that this prop is being used to wire the field to an specific formik field */
  readonly name: string
  /** Options list to be rendered within the select */
  readonly options: ReadonlyArray<string>
  /** Placeholder text rendered when no option is selected */
  readonly placeholder?: string
}

/** @deprecated Do not use ss-react-components*/
const FormTextInput: FC<FormSelectProps> = ({
  className,
  classNameLabel,
  disabled,
  fullWidth,
  label,
  name,
  options,
  placeholder
}: FormSelectProps) => {
  const [field] = useField(name)
  return (
    <SSSelect
      className={classNames(
        'rounded-base border-neutral-medium-100 my-3 h-14 border border-solid px-4',
        { '!max-w-[303px]': !fullWidth },
        className
      )}
      classNameLabel={classNameLabel}
      disabled={disabled}
      id={name}
      label={label}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
      // @ts-expect-error
      name={name}
      options={options}
      placeholder={placeholder}
      {...field}
    />
  )
}

export default FormTextInput
