import classNames from 'classnames'
import React from 'react'

import { BannerSectionSchema } from './schema'

export function BannerSection({
  data,
  lastSection
}: {
  readonly data: BannerSectionSchema
  readonly lastSection: boolean
}) {
  return (
    <div
      className="border-neutral-medium-120 col-span-12 border-b-0 border-l-0 border-r-0 border-t-0 border-solid last:border-r-0 md:border-r-2"
      data-component="BannerSection"
    >
      <div className="flex md:block md:px-24 md:text-center">
        <h3
          className={classNames(
            'md:text-heading-3-size mb-2 mr-4 text-[18px] md:mr-0 md:leading-none',
            { 'text-complementary-red-100': lastSection }
          )}
        >
          {data.title}
        </h3>
        <p className="mb-0 text-[14px]">{data.disclaimer}</p>
      </div>
    </div>
  )
}
