import { useField } from 'formik'

export type FormRadioProps = {
  readonly name: string
  readonly label: string
  readonly sublabel?: string
  readonly description?: string
  readonly value: string
  readonly checked?: boolean
}

function RadioItem({
  label,
  sublabel = '',
  name,
  value,
  description = ''
}: FormRadioProps) {
  const [field] = useField({
    name,
    type: 'radio',
    value: value
  })

  return (
    <div
      className={`flex w-full items-center justify-start  border border-solid px-4 py-2 ${
        field.checked
          ? ' rounded-base border-[#006FEE] bg-white '
          : ' border-transparent '
      }`}
      data-component="RadioItem"
    >
      <input
        className="border-neutral-medium-100 inline-block h-4 w-4 cursor-pointer  rounded-full checked:bg-[#006FEE]"
        id={value}
        type="radio"
        {...field}
        name={name}
        value={value}
      />
      <label className="w-full cursor-pointer pl-2" htmlFor={value}>
        <div className="flex flex-col justify-start gap-1">
          <span className={'m-0 text-lg font-bold leading-none'}>{label}</span>
          {sublabel ? <span className="m-0">{sublabel}</span> : null}
          {description ? <p className="m-0 mt-1">{description}</p> : null}
        </div>
      </label>
    </div>
  )
}

export { RadioItem }
