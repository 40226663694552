//@ts-nocheck
import classNames from 'classnames'
import React, { FC, useState } from 'react'

import * as css from './CartLineItem.module.scss'

type SubItem = {
  readonly subItemName: string
  readonly subItemQuantity: number
  readonly subItemSku?: string
}

export type SubItemsProps = {
  readonly itemLink?: string
  readonly isExpandEnabled?: boolean
  readonly items: readonly SubItem[]
  readonly className?: string
  readonly isOrderSummaryRedesign?: boolean
}

const SubItems: FC<SubItemsProps> = ({
  itemLink,
  items,
  isExpandEnabled,
  className,
  isOrderSummaryRedesign
}: SubItemsProps) => {
  const [expand, setExpand] = useState(false)
  const onClickDetails = () => setExpand(true)

  return (
    <div className={classNames(className)}>
      {isExpandEnabled || expand ? (
        <ul
          className={classNames({
            [css.subItems]: !isOrderSummaryRedesign,
            [css.subItemsRedesign]: isOrderSummaryRedesign === true
          })}
          key={'sub-items'}
        >
          {items.map(subItem => (
            <li
              key={subItem.subItemName}
            >{`${subItem.subItemQuantity} ${subItem.subItemName}`}</li>
          ))}
        </ul>
      ) : (
        <a
          className={classNames('colorPrimary', {
            [css.subItemsLink]: !isOrderSummaryRedesign,
            [css.subItemsLinkAllDevices]: isOrderSummaryRedesign === true
          })}
          onClick={onClickDetails}
        >
          {' '}
          {itemLink}
        </a>
      )}
    </div>
  )
}

export default SubItems
