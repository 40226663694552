import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { trackClickBuildASystemCta } from '@ecomm/cdp-tracking'
import {
  ContentfulRichTextSchema,
  GatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { usePriceContext } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  ContentfulRichText,
  NewFlag,
  QuantitySelector
} from '@ecomm/shared-components'
import {
  SecureCheckmarkIcon,
  ShippingTruck,
  SupportIcon
} from '@ecomm/shared-icons'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { Link } from 'gatsby'
import { ReactNode, useCallback, useEffect, useState } from 'react'

import { ProductSchema } from '../../templates/Fpp/featuredProductPageSchema'
import AddToCartButton from '../AddToCartButton'
import { ColorSelectorSlim } from '../ColorSelector'
import { ImageGallery } from './components/ImageGallery'
import { IconTextSchema } from './components/schema'

const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="text-xl font-light md:text-[1.4rem]">{children}</p>
    ),
    // Using HEADING_6 as disclaimer
    [BLOCKS.HEADING_6]: (_, children) => (
      <h6 className="my-0 text-xs font-normal">{children}</h6>
    )
  }
}

const icons: Record<IconTextSchema, () => ReactNode> = {
  'professional-monitoring': () => (
    <SupportIcon
      className="full-current mr-2 shrink-0"
      height="26"
      width="26"
    />
  ),
  'shipping-truck': () => (
    <ShippingTruck className="mr-2 shrink-0" height="26" width="26" />
  ),
  shield: () => (
    <SecureCheckmarkIcon className="mr-2 shrink-0" height="26" width="26" />
  )
}

/**
 * Temporary function to parse a product into product hero data
 */
function toProductHeroData(product: ProductSchema): ProductSchema & {
  readonly customerIncentives: readonly IconTextSchema[]
  readonly showNewFlag: boolean
} {
  return {
    ...product,
    customerIncentives: ['shipping-truck', 'shield', 'professional-monitoring'],
    showNewFlag: product.isNew ?? false
  }
}

export default function FeaturedProduct({
  product,
  productNameOverride = O.none,
  productDescriptionOverride = O.none
}: {
  readonly product: ProductSchema
  readonly productNameOverride?: O.Option<string>
  readonly productDescriptionOverride?: O.Option<ContentfulRichTextSchema>
}) {
  const [imageCarousel, setImageCarousel] = useState<
    readonly GatsbyImageSchema[]
  >([])
  const sku = product.sku
  const featuredProduct = toProductHeroData(product)
  const microCopy = useMicroCopy()

  const { getFormattedPrice } = usePriceContext()

  const price = pipe(
    getFormattedPrice(sku),
    O.getOrElse(() => '')
  )

  const [quantity, setQuantity] = useState(1)

  const handleChange = useCallback(
    (increase: boolean) => {
      increase ? setQuantity(quantity + 1) : setQuantity(quantity - 1)
    },
    [quantity]
  )

  const handleChangeColor = (sku: string) => {
    const isMainColor = sku === product.sku
    return isMainColor
      ? setImageCarousel(product.imageCarousel)
      : setImageCarousel(
          product.imageCarouselColorVariant ?? product.imageCarousel
        )
  }

  const productDescription = pipe(
    productDescriptionOverride,
    O.getOrElse(() => featuredProduct.description)
  )

  useEffect(() => {
    setImageCarousel(product.imageCarousel)
  }, [product.imageCarousel])

  return (
    <div
      className="breakLine prose md:prose-md lg:prose-lg inline-grid max-w-none grid-cols-12 items-stretch gap-x-4 text-left md:gap-x-6 lg:gap-x-8"
      data-component="FeaturedProduct"
    >
      <ImageGallery productImages={imageCarousel} />
      <div className="col-span-12 flex flex-col justify-center md:col-span-12 lg:col-span-6 lg:justify-end">
        <div className="mb-3 mt-0">
          {featuredProduct.showNewFlag ? (
            <NewFlag hasBackground={true} />
          ) : null}
        </div>
        <h2 className="mb-6 mt-0">
          {pipe(
            productNameOverride,
            O.getOrElse(() => featuredProduct.name)
          )}
        </h2>
      </div>
      <div className="col-span-12 lg:col-span-6">
        <div className="mb-6 mt-0" data-component="Description">
          <ContentfulRichText
            optionsCustom={descriptionCustomOptions}
            raw={productDescription?.raw}
          />
        </div>
        {product.variant ? (
          <ColorSelectorSlim
            data={product.variant}
            defaultSku={sku}
            onChangeColor={sku => handleChangeColor(sku)}
            placement="FeaturedProduct"
          />
        ) : null}
        <div className="border-neutral-light-50 mb-3 inline-flex w-full items-center rounded-lg border-2 border-solid p-3 md:w-auto">
          <div className="mr-4 flex text-[22px] font-bold">{price}</div>
          <div className="mx-4">
            <QuantitySelector
              maxQuantity={product.maxQuantity}
              minQuantity={1}
              name={''}
              onDecrease={() => handleChange(false)}
              onIncrease={() => handleChange(true)}
              quantity={quantity}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-col md:flex-row lg:h-14">
            <AddToCartButton.Product
              className="mb-4 w-full md:w-64"
              quantity={quantity}
              sku={sku}
            />
            <Link to="/build-my-system">
              <button
                className="btn btn-outlined btn-outlined-primary mb-4 w-full md:w-64"
                onClick={trackClickBuildASystemCta}
                type="button"
              >
                Build a system
              </button>
            </Link>
          </div>
          {featuredProduct.customerIncentives ? (
            <ul className="list-none p-0">
              {featuredProduct.customerIncentives.map((incentive, idx) => (
                <li className={'mb-3'} key={idx}>
                  <span className="flex">
                    {icons[incentive]()}
                    {microCopy[incentive]}
                  </span>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  )
}
