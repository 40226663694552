import { useContentful } from '@ecomm/shared-apollo'
import { getLocale } from '@ecomm/utils'
import { useExperience } from '@ninetailed/experience.js-react'
import { ExperienceMapper } from '@ninetailed/experience.js-utils'

import { NT_FEATURE_FLAG_QUERY } from './nt_feature_flag_query'
import type { NinetailedFeatureFlagQueryDataSchema } from './schema'

const getData = (data: NinetailedFeatureFlagQueryDataSchema) => {
  return {
    id: data.ntFeatureFlag.sys.id,
    title: data.ntFeatureFlag.title,
    json: data.ntFeatureFlag.json,
    nt_experiences: getMappedExperiences(data)
  }
}

const getMappedExperiences = (data: NinetailedFeatureFlagQueryDataSchema) =>
  (data.ntFeatureFlag?.ntExperiencesCollection?.items || [])
    .map(experience => ({
      id: experience.sys.id,
      name: experience.ntName,
      type: experience.ntType,
      config: experience.ntConfig,
      // This syntax accounts for the possibility of an audience not being set on an experiment
      ...(experience.ntAudience
        ? {
            audience: {
              name: experience.ntAudience.ntName,
              id: experience.ntAudience.sys.id
            }
          }
        : {}),
      variants: experience.ntVariantsCollection.items
        .filter(item => item.__typename === 'NtFeatureFlag')
        .map(variant => ({
          id: variant.sys.id, // Required
          title: variant.title,
          json: variant.json
        }))
    }))
    .filter(experience => ExperienceMapper.isExperienceEntry(experience))
    .map(experience => ExperienceMapper.mapExperience(experience))

export type BaselineIdProps = {
  readonly baselineId: string
}

export const useNinetailedFeatureFlag = ({ baselineId }: BaselineIdProps) => {
  const locale = getLocale()
  const { data: contentfulData } = useContentful(
    NT_FEATURE_FLAG_QUERY({ baselineId })
  )

  const featureFlagData = <NinetailedFeatureFlagQueryDataSchema>contentfulData
  const data = getData(featureFlagData)
  const experiences = getMappedExperiences(featureFlagData)
  const maybeUseExperience =
    locale === 'en-US' &&
    useExperience({
      baseline: { id: baselineId },
      experiences
    })

  const variantIndex =
    (maybeUseExperience && maybeUseExperience.variantIndex) || 0
  const isControl = variantIndex === 0

  return {
    data,
    experiences,
    baseline: { id: baselineId },
    variantIndex,
    isControl,
    isVariant: !isControl
  }
}
