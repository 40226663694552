import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { graphql, useStaticQuery } from 'gatsby'
import { useEffect } from 'react'

function Content<
  T extends { readonly id: string; readonly json: { readonly tag: string } }
>(props: T) {
  useEffect(() => {
    console.debug(`Ninetailed A/A - ${props?.json?.tag}`)
  }, [])

  return null
}

export function NinetailedAA() {
  const { contentfulNinetailedFeatureFlag } = useStaticQuery(graphql`
    # create a base fragment for the content type that you want to add an experience to
    fragment aa_featureFlagBase on ContentfulNinetailedFeatureFlag {
      # VERY IMPORTANT: the contentful_id MUST be mapped to id
      id: contentful_id
      title
      # the feature flag content type can be used to store JSON to decide on hard-coded components outside of contentful
      json {
        id
        tag
      }
    }

    # create a fragment to get details for the experience
    # this can't be a shared fragment because you need to reference the base fragment for each content type
    # YOU NEED ALL OF THESE OR THE EXPERIENCE WON'T WORK
    # Please pay attention to the field aliases!
    fragment aa_featureFlagExperience on ContentfulNinetailedExperience {
      id: contentful_id
      name: nt_name
      type: nt_type
      audience: nt_audience {
        id: contentful_id
        name: nt_name
      }
      config: nt_config {
        components {
          baseline {
            id
          }
          variants {
            id
            hidden
          }
        }
        traffic
        distribution
      }
      variants: nt_variants {
        ... on ContentfulNinetailedFeatureFlag {
          ...aa_featureFlagBase
        }
      }
    }

    # Combine them all together for your actual query or fragment
    query Feature {
      contentfulNinetailedFeatureFlag(
        contentful_id: { eq: "7Ew4CsCgEf8Jn2DFLiT5nD" }
      ) {
        ...aa_featureFlagBase
        nt_experiences {
          ...aa_featureFlagExperience
        }
      }
    }
  `)

  return (
    <Experience
      component={Content}
      experiences={mapExperiences(
        contentfulNinetailedFeatureFlag?.nt_experiences
      )}
      {...contentfulNinetailedFeatureFlag}
    />
  )
}
