import { parseContentfulJson } from '@ecomm/micro-copy'
import {
  type VideoBannerProps,
  VideoBanner
} from '@ecomm/monitoring/components'
import {
  type ComparisonTableProps,
  AppWidget,
  CameraBanner,
  ComparisonTable,
  ConfidenceBar,
  ExpertSection,
  ExpertsReviews,
  GuaranteeSection,
  safeParseJSONDataFromContentful,
  transformToComparisonTableData,
  VideoButtonWrapper
} from '@ecomm/scout'
import {
  type AppWidgetData,
  type ConfidenceBarProps,
  type ExpertsReviewsProps,
  parseJSONDataFromContentful
} from '@ecomm/scout'
import {
  AffirmBanner,
  AlarmMoments,
  PageWrapper,
  TrustpilotBanner,
  trustPilotUKHomePageCarousel,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { type UserReviewsProps, UserReviews } from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useEnv } from '@ecomm/utils'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { useVideoButtonAssetQuery } from '../../data/queries'
import {
  appRatingImages,
  getAppWidgetData,
  getExpertReviews
} from '../../data/transformData'
import useOdmonVariant from '../ExperimentUtils/useOdmonVariant'
import { QuizBanner } from '../QuizBanner'
import type { LanderBelowTheFoldSchema } from './schema'

type LanderBelowTheFoldContent = {
  readonly data: LanderBelowTheFoldSchema
}

type ExperienceContentProps = {
  readonly id: string
  readonly json: { readonly tag: string }
  readonly children: React.ReactNode
  readonly variantKey: string
}

function ExperienceContentWrapper({
  json,
  children,
  variantKey
}: ExperienceContentProps) {
  return json.tag === variantKey ? children : null
}

export function BelowTheFold({ data }: LanderBelowTheFoldContent) {
  const { locale } = useEnv()
  const isUSSite = locale === 'en-US'
  const isOdmonVariant = useOdmonVariant()

  const expertReviewsData: ExpertsReviewsProps = parseJSONDataFromContentful(
    data,
    'expertReviews'
  )
  const expertReviews = getExpertReviews(
    data.expertReviewsAssets.nodes,
    locale,
    expertReviewsData.reviews
  )

  const videoButtonAssetData = useVideoButtonAssetQuery()
  const expertReviewsAdditionalData: O.Option<ExpertsReviewsProps> =
    safeParseJSONDataFromContentful(data, 'expertReviewsAdditional')

  const appWidgetOdmonJson: O.Option<AppWidgetData> =
    safeParseJSONDataFromContentful(data, 'appWidgetOdmonVariant')

  const appWidgetJson: AppWidgetData = pipe(
    appWidgetOdmonJson,
    O.filter(() => isOdmonVariant),
    O.getOrElse(() => parseJSONDataFromContentful(data, 'appWidget'))
  )

  const appWidgetData = {
    tabs: getAppWidgetData(
      data.appFeaturesCarouselAssets.nodes,
      locale,
      appWidgetJson.tabs,
      isOdmonVariant
    ),
    title: appWidgetJson.title,
    appRatingImages
  }
  const guaranteeSectionCopy = parseContentfulJson(data, 'guaranteeCopy')
  const confidenceBarData: ConfidenceBarProps = parseJSONDataFromContentful(
    data,
    'confidenceBar'
  )
  const comparisonTableData: ComparisonTableProps =
    transformToComparisonTableData(data)

  const userReviewsDataOdmonJson: O.Option<UserReviewsProps> =
    safeParseJSONDataFromContentful(data, 'userReviewsOdmonVariant')

  const userReviewsData: UserReviewsProps = pipe(
    userReviewsDataOdmonJson,
    O.filter(() => isOdmonVariant),
    O.getOrElse(() => parseJSONDataFromContentful(data, 'userReviewsData'))
  )

  const videoBannerData: O.Option<VideoBannerProps> =
    safeParseJSONDataFromContentful(data, 'videoBanner')

  const videoAndAffirmBanner = (
    <div className="grid h-36 gap-4 md:h-80 lg:h-full lg:grid-cols-2 lg:gap-8">
      <VideoButtonWrapper videoButtonAsset={videoButtonAssetData} />
      <AffirmBanner data={data.contentfulAffirmBanner} />
    </div>
  )

  return (
    <PageWrapper>
      <div
        className="max-w-8xl mx-auto mb-16 flex flex-col gap-6 px-4 md:gap-8 md:px-8 lg:gap-12"
        data-component="BelowTheFold"
      >
        {isUSSite ? (
          <>
            {data.hideVideoAndAffirmBannerExperiment ? (
              <Experience
                {...data.hideVideoAndAffirmBannerExperiment}
                component={ExperienceContentWrapper}
                experiences={mapExperiences<ExperienceContentProps>(
                  data.hideVideoAndAffirmBannerExperiment.nt_experiences
                )}
                passthroughProps={{
                  children: videoAndAffirmBanner,
                  variantKey: 'control'
                }}
              />
            ) : (
              videoAndAffirmBanner
            )}

            <ConfidenceBar data={confidenceBarData} />
            <QuizBanner
              image={data.quoteWizardAsset}
              quoteWizard={data.quoteWizard}
            />

            {isOdmonVariant && O.isSome(videoBannerData) ? (
              <VideoBanner data={videoBannerData.value} />
            ) : (
              <CameraBanner image={data.cameraBannerAsset} />
            )}
          </>
        ) : (
          <>
            <TrustpilotUKTopBanner />
            <ConfidenceBar data={confidenceBarData} />
            <QuizBanner
              image={data.quoteWizardAsset}
              quoteWizard={data.quoteWizard}
            />
            <TrustpilotBanner
              className="pt-4 md:pt-8"
              widget={trustPilotUKHomePageCarousel}
            />
          </>
        )}
        <ComparisonTable data={comparisonTableData} />
        <AppWidget data={{ ...appWidgetData }} />
        {data.alarmMomentsExperiment &&
        data.contentfulAlarmMoments &&
        isUSSite ? (
          <Experience
            {...data.alarmMomentsExperiment}
            component={ExperienceContentWrapper}
            experiences={mapExperiences<ExperienceContentProps>(
              data.alarmMomentsExperiment.nt_experiences
            )}
            passthroughProps={{
              children: <AlarmMoments {...data.contentfulAlarmMoments} />,
              variantKey: 'variant'
            }}
          />
        ) : null}
        <UserReviews {...userReviewsData} />
        <ExpertsReviews reviews={expertReviews} />
        {O.isSome(expertReviewsAdditionalData) ? (
          <ExpertsReviews
            reviews={getExpertReviews(
              data.expertReviewsAdditionalAssets!.nodes,
              locale,
              expertReviewsAdditionalData.value.reviews
            )}
          />
        ) : null}
        <GuaranteeSection
          copy={guaranteeSectionCopy}
          image={data.guaranteeAsset}
          quoteWizard={data.quoteWizard}
        />
        <ExpertSection data={{ image: data.expertSectionAsset }} />
      </div>
    </PageWrapper>
  )
}
