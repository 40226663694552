import { ContentfulRichText } from '@ecomm/shared-components'
import { Modal } from '@ecomm/ss-react-components'
import { useTrackingSeeDetailsPlanSelection } from '@ecomm/tracking'
import { useState } from 'react'

type Props = {
  readonly modalContent: string
}

export function ProMonitoringLegalDisclaimer({ modalContent }: Props) {
  const trackSeeDetails = useTrackingSeeDetailsPlanSelection()
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false)

  return (
    <>
      <p className={'mb-8 px-2 text-xs'}>
        First month free, no contract, cancel any time. After trial, monitoring
        is charged separately for $1.66/day.
        <br />
        SimpliSafe® Active Guard outdoor protection supports up to 3 Outdoor
        Cameras.{' '}
        <button
          className="cursor-pointer whitespace-nowrap border-none bg-transparent p-0 cursor-pointer text-xs underline hover:no-underline"
          data-component="SeeDetailsLink"
          onClick={() => {
            trackSeeDetails()
            setIsOpenDetailsModal(true)
          }}
        >
          See Details
        </button>
      </p>
      <Modal
        isOpen={isOpenDetailsModal}
        onRequestClose={() => setIsOpenDetailsModal(false)}
      >
        <div className="p-8 md:max-w-[864px]" data-component="modal_content">
          <h2
            className="border-neutral-light-100 -mx-8 mt-0 flex flex-col border-x-0 border-b border-t-0 border-solid px-8 pb-6 text-2xl"
            data-component="content_headline"
          >
            Pro Monitoring
          </h2>
          <div
            className="prose-h4:text-lg"
            data-component="content_description"
          >
            <ContentfulRichText raw={modalContent} />
          </div>
        </div>
      </Modal>
    </>
  )
}
