import { useLocale } from '@ecomm/data-hooks'
import { cartAddItem, CartValue } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { selectCurrentPromoVariationId } from '@simplisafe/ss-ecomm-data/redux/select'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/lib/Option'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useSetCart } from './useCart'

/**
 * Adds an item to the cart.
 */

type Products = readonly {
  readonly sku: string
  readonly quantity: number
}[]

export const useCartAddItem = (products: Products) => {
  const cartId = getCartId()
  const setCart = useSetCart()
  const locale = useLocale()
  const variationId = useSelector(selectCurrentPromoVariationId).orUndefined()

  return useCallback(
    (successFn: (t: CartValue) => void) => {
      const fn = async (id?: string) => {
        setCart(['set_cart_updating'])

        const cart = await cartAddItem(locale, products, id, variationId)()

        pipe(
          cart,
          E.fold(
            err => setCart(['set_cart_error', err]),
            t => {
              setCart(['update_cart', t])
              successFn(t)
            }
          )
        )
      }

      pipe(
        O.fromNullable(cartId),
        O.fold(
          () => fn(),
          id => fn(id)
        )
      )
    },
    [cartId, setCart, locale, products, variationId]
  )
}
