import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import { SmallTextSchema } from './schema'

export default function SmallText({ text, textAlignment }: SmallTextSchema) {
  return (
    <div
      className={classNames('w-full', {
        'text-left': textAlignment === 'Left',
        'text-center': textAlignment === 'Center',
        'text-right': textAlignment === 'Right'
      })}
      data-component="SmallText"
    >
      <ContentfulRichText {...text} />
    </div>
  )
}
