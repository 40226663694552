import { addAtomDebugLabel } from '@ecomm/utils'
import { localStorage } from '@simplisafe/ewok'
import { atom } from 'jotai'

import { Action, cartReducer } from './cartReducer'
import { Cart } from './cartStates'

export const baseCartAtom = atom<Cart>({
  _tag: !localStorage.get('cartId') ? 'no_cart_id' : 'not_initialized'
})

export const cartAtom = atom(
  get => get(baseCartAtom),
  (get, set, update: Action) => {
    const prev = get(baseCartAtom)
    const next = cartReducer(prev, update)
    set(baseCartAtom, next)
  }
)

addAtomDebugLabel(cartAtom, 'Cart')
