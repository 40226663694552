import { createFragmentRegistry } from '@apollo/client/cache/index.js'
import {
  type NormalizedCacheObject,
  ApolloClient,
  from,
  InMemoryCache
} from '@apollo/client/index.js'

import { readCacheFromDom } from './cache'
import { fragments } from './fragments'
import {
  defaultOptions,
  errorLink,
  httpLink,
  persistedQueryLink
} from './lib/apollo'

export const makeClient = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    ssrMode: true,
    link: from([errorLink, persistedQueryLink, httpLink]),
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(fragments)
    }).restore(readCacheFromDom()),
    defaultOptions: defaultOptions()
  })

export const client = makeClient()
