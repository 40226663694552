import { footerSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const parseReferAFriendSchema = <T extends { readonly footer: U }, U>(
  data: T
): ReferAFriendPageSchema => {
  const { footer } = referAFriendPageSchema.parse(data)

  return {
    footer
  }
}
export const referAFriendPageSchema = z.object({
  footer: footerSchema.nullish()
})

export type ReferAFriendPageSchema = TypeOf<typeof referAFriendPageSchema>
