import React from 'react'
import {
  HeadsetPerson,
  SecureHomeIcon,
  IconProps,
  PoliceCar,
  Fire,
  Video,
  PhoneRinging,
  ShieldCheck,
  Phone,
  Contract,
  Discount
} from '@ecomm/shared-icons'
import { ContentfulRichText, IconAndTextSchema } from '@ecomm/shared-components'

const iconMap: Record<string, React.FC<IconProps>> = {
  HeadsetPerson: HeadsetPerson,
  SecureHomeIcon: SecureHomeIcon,
  PoliceCar: PoliceCar,
  Fire: Fire,
  Video: Video,
  PhoneRinging: PhoneRinging,
  ShieldCheck: ShieldCheck,
  Phone: Phone,
  Contract: Contract,
  Discount: Discount
}

export default function IconTextGrid({
  items
}: {
  readonly items: readonly IconAndTextSchema[]
}) {
  return (
    <ul className="bg-neutral-black m-0 box-border grid basis-[45%] list-none auto-rows-[fr1] grid-cols-2 gap-6 rounded-b-2xl px-4 pb-6 pt-4 text-sm font-bold text-white md:grid-rows-4 md:gap-1 md:rounded-r-2xl md:rounded-bl-none md:px-2 md:pb-[5.5rem] md:pt-16 md:text-base lg:pl-[3.75rem] lg:pr-[2.375rem]">
      {items.map(({ text, icon }, idx) => {
        const Icon = iconMap[icon]
        return (
          <li
            className="prose-p:text-white prose-p:mb-0 flex items-center gap-2"
            key={`additional-features-${idx}`}
          >
            {Icon ? <Icon className="h-12 w-12 shrink-0" /> : null}
            <ContentfulRichText {...text} />
          </li>
        )
      })}
    </ul>
  )
}
