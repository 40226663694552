import React, { FC } from 'react'

/**
 * An HoC to convert a component with multiple props into a component that takes a single data prop.
 * Useful for using new components in component mappings in @ecomm/legacy.
 *
 * Passing in multiple props is better for performance than passing in 1 prop that is an object.
 *
 * @example
 * const componentMappings = {
 *  ContentfulDivider: withLegacyDataProp(Divider),
 * }
 *
 * @example
 * // this component
 * <Component id="str" count={10} />
 *
 * // becomes this component
 * <Component data={{ id: "str", count: 10 }} />
 */
function withLegacyDataProp<T extends JSX.IntrinsicAttributes>(
  Component: FC<T>
) {
  return ({ data }: { readonly data: T }) => {
    return <Component {...data} />
  }
}

export default withLegacyDataProp
