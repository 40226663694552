import { Header } from '@ecomm/header-redesigned'
import {
  ApplyPromoCode,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Footer } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { graphql, PageProps } from 'gatsby'

import UnsubscribeForm from '../../components/UnsubscribeEmailPage/UnsubscribeForm'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import { parseUnsubscribeMailData } from './schema'

export type PageContext = {
  readonly locale: string
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function UnsubscribeEmailTemplate<
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>({ data }: Props<T>) {
  const { footer, content } = parseUnsubscribeMailData(data)
  const headerData = useHeaderRedesignQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={'unsubscribe_email'}>
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <ApplyPromoCode />
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={'standard'}
        />
        <Header {...headerData} />
        <div className="max-w-8xl prose-h2:text-2xl mx-auto mb-16">
          <UnsubscribeForm {...content} />
        </div>
        {footer ? <Footer data={footer} type={footer.type} /> : null}
      </div>
    </TrackingProvider>
  )
}

export const query = graphql`
  #graphql
  query UnsubscribeEmail($footerId: String) {
    content: contentfulJson(contentful_id: { eq: "5jdnfKrTf58xxJiN1RSeYQ" }) {
      contentful_id
      content {
        internal {
          content
        }
      }
    }
    footer: contentfulFooter(contentful_id: { eq: $footerId }) {
      ...footerFragment
    }
  }
`
