import { CartValue } from '@ecomm/data-simplisafe-api'
import { match, P } from 'ts-pattern'

import { Cart } from './cartStates'

export type Action =
  | readonly ['no_cart_id']
  | readonly ['set_cart_empty']
  | readonly ['set_cart_error', Error]
  | readonly ['set_cart_updating']
  | readonly ['update_cart', CartValue]

export const cartReducer = (cart: Cart, action: Action): Cart => {
  return match(action)
    .with(['update_cart', P.any], ([_, payload]): Cart => {
      const isEmpty = !payload.lineItems.length

      return !payload.isActive
        ? { _tag: 'not_initialized' }
        : isEmpty
          ? { _tag: 'empty' }
          : {
              _tag: 'with_items',
              val: payload
            }
    })
    .with(
      ['set_cart_updating'],
      ([_]): Cart =>
        match(cart)
          .with(
            { _tag: 'with_items' },
            (c): Cart => ({
              _tag: 'updating',
              val: c.val
            })
          )
          .with(
            { _tag: 'updating' },
            (c): Cart => ({
              _tag: 'updating',
              val: c.val
            })
          )
          .otherwise(() => cart)
    )
    .with(['set_cart_empty'], (): Cart => ({ _tag: 'empty' }))
    .with(
      ['set_cart_error', P.any],
      ([_, payload]): Cart => ({
        _tag: 'error',
        val: payload
      })
    )
    .with(['no_cart_id'], (): Cart => ({ _tag: 'no_cart_id' }))
    .exhaustive()
}
