import { useCartValue } from './lib/useCart'

/**
 * Returns the current state of the cart.
 *
 * This can be used to see if a cart is updating to disable user input.
 *
 * @example
 * import { useCartState } from '@ecomm/data-cart'
 *
 * function Component() {
 *   const cartState = useCartState()
 *   return (
 *    <p>{cartState === 'loading' ? 'cart is loading...' : 'cart is ready'}</p>
 *   )
 *  }
 */
export const useCartState = () => {
  const cart = useCartValue()
  return cart._tag
}
