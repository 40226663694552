import { EcommEventBase, ProductPayload } from '@ecomm/cdp-tracking'
import { getLocale } from '@ecomm/utils'
import { prop } from '@simplisafe/ewok'
import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'

import type {
  PackageProductSchema,
  ProductSchema,
  RudderProductProps
} from './schema'

type RudderstackEventProps = {
  readonly productSku: string
  readonly includedProducts: PackageProductSchema['products']
  readonly components: readonly ProductSchema[]
  readonly totalPrice: number
}

export const bmsToRudderstackProducts = ({
  productSku,
  includedProducts,
  components,
  totalPrice
}: RudderstackEventProps): readonly ProductPayload[] => {
  return [
    packageProductToRudderstackProduct({
      productId: productSku,
      price: totalPrice,
      brand: 'bms'
    }),
    ...ssProductsToRudderstackProducts(includedProducts, true),
    ...ssProductsToRudderstackProducts(components, true)
  ]
}

const packageProductToRudderstackProduct = ({
  productId,
  price,
  quantity,
  name,
  brand
}: RudderProductProps): ProductPayload => ({
  brand,
  product_id: productId,
  name: name ?? 'bms',
  price: Math.round((price + Number.EPSILON) * 100) / 100,
  quantity: quantity ?? 1
})

export const ssProductsToRudderstackProducts = (
  products: PackageProductSchema['products'] | readonly ProductSchema[],
  isBms = false,
  price?: number,
  brand?: string
): readonly ProductPayload[] =>
  products.map(product => {
    const hasQty = 'quantity' in product
    const quantity = hasQty ? product.quantity : 1
    const productPrice = price
      ? Math.round((price + Number.EPSILON) * 100) / 100
      : 0

    return packageProductToRudderstackProduct({
      brand: isBms ? 'bms' : brand,
      productId: product.sku,
      price: productPrice,
      quantity,
      name: product.name
    })
  })

const packageChildrenToRudderstackProducts = (
  children: readonly Partial<LineItem>[],
  isBms: boolean,
  brand: string
): readonly ProductPayload[] =>
  children.map(child => {
    const locale = getLocale()
    const hasQty = 'quantity' in child
    const quantity = hasQty ? child.quantity : 1
    const productName = child.name[locale] ?? child.name['en-US']

    return packageProductToRudderstackProduct({
      brand: isBms ? 'bms' : brand,
      productId: child.sku ?? '',
      price: 0,
      quantity,
      name: productName
    })
  })

export const ctProductToRudderstackProducts = (
  product: Pick<Product, 'masterSku' | 'name' | 'price'>,
  quantity: number,
  locale: string,
  price?: number
): readonly ProductPayload[] => {
  const productPrice = price ?? product.price
  const productName = product.name[locale] ?? product.name['en-US']
  return [
    packageProductToRudderstackProduct({
      productId: product.masterSku,
      price: productPrice,
      quantity,
      name: productName
    })
  ]
}

const buildEcommEventBase = (
  locale: string,
  products: readonly ProductPayload[],
  total: number
): EcommEventBase => ({
  currency: locale === 'en-GB' ? 'GBP' : 'USD',
  products,
  total
})

export function getRudderstackDataFromLineItem(
  lineItem: LineItem
): EcommEventBase {
  const locale = getLocale()
  const price = lineItem.totalPrice
  const discountedPrice = prop('discountedPricePerQuantity', lineItem)
  const productPrice = discountedPrice.getOrElse(price)

  const rudderProducts = lineItemToRudderstackProducts(
    lineItem,
    locale,
    productPrice
  )

  return buildEcommEventBase(locale, rudderProducts, productPrice)
}

export function getRudderstackDataFromProduct(
  product: Product,
  quantity: number
): EcommEventBase {
  const locale = getLocale()
  const price = product.price
  const discountedPrice = prop('discountedPrice', product)
  const productPrice = discountedPrice.getOrElse(price)

  const rudderProducts = ctProductToRudderstackProducts(
    product,
    quantity,
    locale,
    productPrice
  )

  return buildEcommEventBase(locale, rudderProducts, productPrice)
}

export const lineItemToRudderstackProducts = (
  lineItem: LineItem,
  locale: string,
  price?: number
): readonly ProductPayload[] => {
  const trackingProduct: ProductSchema = {
    sku: lineItem.sku,
    name: lineItem.sku,
    quantity: lineItem.quantity
  }

  // iterate over children if this is a package parent
  if (lineItem.productType === 'package_parent') {
    const packageHasChildren = !!lineItem.child?.length

    // unlikely case, just making typescript happy
    if (!lineItem.child || !packageHasChildren) {
      return ssProductsToRudderstackProducts([trackingProduct], true)
    }

    const packageName = lineItem.name[locale] ?? lineItem.name['en-US']
    const isBms = lineItem.custom?.fields['product_is_bms']

    const brand = isBms ? 'bms' : packageName

    // incrememt a package in cart
    return [
      {
        brand,
        product_id: lineItem.sku,
        name: lineItem.name[locale] ?? lineItem.name['en-US'],
        price: price ?? lineItem.price,
        quantity: lineItem.quantity
      },
      ...packageChildrenToRudderstackProducts(lineItem.child, isBms, brand)
    ]
  }

  return [
    {
      product_id: lineItem.sku,
      name: lineItem.name[locale] ?? lineItem.name['en-US'],
      price: price ?? lineItem.price,
      quantity: lineItem.quantity
    }
  ]
}
