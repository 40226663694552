import { isNotEmpty } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import safeProp from '../safe/safeProp'
import stringToObject from '../stringToObject'
/**
 * Takes in an object like string and attempts to convert it into an object.
 * If it fails it will log an error and return None.
 *
 * @package internal use only
 */
export function parseContent(
  content: string
): O.Option<Record<string, string>> {
  return !isNotEmpty(content)
    ? O.none
    : O.fromEither(stringToObject<string>(content))
}

/**
 * Finds an object in an array with the expected value on a key.
 *
 * @package internal use only
 */
export const findMatchingObjectInArray =
  <T>(key: keyof T) =>
  (expectedValue: string) =>
  (array: ReadonlyArray<T>) =>
    array.find(t => t[key] === expectedValue)

/**
 * Takes in a parsed fragment from a GraphQL query with variations and an Optimizely variation key and
 * returns the matching variation from the fragment if one is available.
 */
function parseVariants<
  T extends {
    readonly contentful_id: string
  },
  U extends {
    readonly variations: {
      readonly meta: {
        readonly internal: {
          readonly content: string
        }
      }
      readonly variations: readonly T[]
    }
  },
  V extends T & U
>(variationKey: string, input: V) {
  /**
   * Parse the input to get the internal content which is an object like string,
   * convert it to an object, and then get the contentful_id that belongs to the optimizely variation key.
   */
  const matchingId = pipe(
    O.of(input),
    O.chain(safeProp('variations')),
    O.chain(safeProp('meta')),
    O.chain(safeProp('internal')),
    O.chain(safeProp('content')),
    O.chain(parseContent),
    O.chain(safeProp(variationKey))
  )

  /**
   * This is the possible array of variations on the input.
   */
  const variations = pipe(
    input,
    safeProp('variations'),
    O.chain(safeProp('variations'))
  )

  /**
   * By calling this with the first argument we can pass in the type parameter of T
   */
  const findByContentfulId = findMatchingObjectInArray<T>('contentful_id')

  /**
   * Matches up the correct variation based on the contentful_id
   */
  const matchingVariation = pipe(
    O.of(findByContentfulId),
    O.ap(matchingId),
    O.ap(variations),
    O.chain(O.fromNullable)
  )

  const inputWithoutVariations = (() => {
    const { variations: _, ...clone } = input
    return clone
  })()

  return O.toUndefined(matchingVariation) || inputWithoutVariations
}

export default parseVariants
