import {
  AFFIRM_TERM_MONTHS_SYSTEM,
  AFFIRM_TERM_MONTHS_SYSTEM_EXPERMIENT
} from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { usePriceContext } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import React from 'react'

type Props = {
  readonly sku: string
  readonly quantity: number
  readonly forFree?: boolean
  readonly isAffirmMonthlyExperiment: boolean
}

function DraftCartPrice({
  sku,
  quantity,
  forFree = false,
  isAffirmMonthlyExperiment = false
}: Props) {
  const isUs = useLocale() === 'en-US'
  const { getPrice, isLoading, formatPrice } = usePriceContext()
  const microCopy = useMicroCopy()

  const getAffirmMonths = () =>
    isAffirmMonthlyExperiment
      ? AFFIRM_TERM_MONTHS_SYSTEM_EXPERMIENT
      : AFFIRM_TERM_MONTHS_SYSTEM

  const priceToDisplay = O.toNullable(
    forFree
      ? O.of(microCopy['free-text'])
      : pipe(
          getPrice(sku, isUs ? getAffirmMonths() : 1),
          O.map(p => p * quantity),
          formatPrice,
          O.map(p => (isUs ? `${p}/mo` : p))
        )
  )

  return (
    <span
      className={classNames('text-lg', {
        'text-neutral-black': !forFree,
        'text-sale': forFree
      })}
    >
      {!isLoading && priceToDisplay}
    </span>
  )
}

export default DraftCartPrice
