import { parseContentfulJson } from '@ecomm/micro-copy'
import {
  ComparisonTable,
  ConfidenceBar,
  ConfidenceBarProps,
  FAQs,
  FeatureSection,
  FeatureSectionDataProps,
  GuaranteeSection,
  parseJSONDataFromContentful,
  transformToComparisonTableData,
  useCompetitivePageFAQsData
} from '@ecomm/scout'
import { UserReviews, UserReviewsProps } from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/ss-react-components'
import React from 'react'

import { HeroBanner, HeroBannerData, HeroBannerProps } from '../HeroBanner'
import {
  OptionsComparison,
  OptionsComparisonDataProps
} from '../OptionsComparison'
import { getSystemBannerData, SystemBanner } from '../SystemBanner'
import { TextSection, TextSectionProps } from '../TextSection'
import { useCompetitiveComponentsQuery } from './useCompetitiveComponentsQuery'
import {
  CompetitiveComponentsProps,
  getComparisonTableDataByCompetitor,
  getHeroBannerTitleByCompetitor
} from './utils'

export function CompetitiveComponents({
  competitor
}: CompetitiveComponentsProps) {
  const faqsData = useCompetitivePageFAQsData()
  const isTabletAndUp = useMediaQuery('TabletAndUp')
  const staticQuery = useCompetitiveComponentsQuery()

  const quoteWizardData = staticQuery.quoteWizard

  const heroBannerInfo: HeroBannerData = parseJSONDataFromContentful(
    staticQuery,
    'heroBanner'
  )
  const heroBannerData: HeroBannerProps = {
    image: isTabletAndUp
      ? staticQuery.heroBannerAsset
      : staticQuery.mobileheroBannerAsset,
    title: getHeroBannerTitleByCompetitor(competitor, heroBannerInfo.title),
    text: heroBannerInfo?.text
  }
  const confidenceBarData: ConfidenceBarProps = parseJSONDataFromContentful(
    staticQuery,
    'confidenceBar'
  )
  const guaranteeSectionCopy = parseContentfulJson(staticQuery, 'guaranteeCopy')
  const userReviewsData: UserReviewsProps = parseJSONDataFromContentful(
    staticQuery,
    'userReviewsData'
  )
  const comparisonTableData = getComparisonTableDataByCompetitor(
    competitor,
    transformToComparisonTableData(staticQuery)
  )
  const systemBannerData = getSystemBannerData({
    copy: staticQuery.systemBanner,
    image: isTabletAndUp
      ? staticQuery.tabletAndUpSystemBannerAsset
      : staticQuery.mobileSystemBannerAsset,
    quoteWizard: quoteWizardData
  })
  const textSectionData: TextSectionProps = parseJSONDataFromContentful(
    staticQuery,
    'textSection'
  )
  const featureSectionData: FeatureSectionDataProps =
    parseJSONDataFromContentful(staticQuery, 'featureSection')

  const optionsComparisonData: OptionsComparisonDataProps =
    parseJSONDataFromContentful(staticQuery, 'optionsComparison')
  const optionsComparisonImages = [
    staticQuery.optionsComparisonDIYAsset,
    isTabletAndUp
      ? staticQuery.optionsComparisonProInstallAsset
      : staticQuery.mobileOptionsComparisonProInstallAsset
  ]

  return (
    <>
      <HeroBanner data={heroBannerData} />
      <ComparisonTable data={comparisonTableData} />
      <TextSection data={textSectionData} />
      <ConfidenceBar data={confidenceBarData} />
      <GuaranteeSection
        copy={guaranteeSectionCopy}
        image={staticQuery.guaranteeAsset}
        quoteWizard={quoteWizardData}
      />

      <FeatureSection
        data={featureSectionData}
        image={staticQuery.featureSectionAsset}
      />

      <OptionsComparison
        data={optionsComparisonData}
        images={optionsComparisonImages}
      />
      <UserReviews {...userReviewsData} />
      <FAQs data={faqsData} />
      <SystemBanner data={systemBannerData} />
    </>
  )
}
