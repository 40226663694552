import { logError } from '@ecomm/error-handling'
import { localStorage, MaybeT } from '@simplisafe/ewok'
import {
  fetchUserCheckoutDataWithPopup,
  UserCheckoutData
} from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
const { get } = localStorage

export const getReturningCustomerAddressByDeviceId = (
  e: React.MouseEvent<HTMLElement>,
  handleError: (e: Error) => void,
  handleSuccess: (userCheckoutData: MaybeT<UserCheckoutData>) => void
) => {
  e.preventDefault()
  return fetchUserCheckoutDataWithPopup(get('deviceId') || '')((e: Error) => {
    logError(Error('CheckoutForm: UserCheckoutData is empty'))
    handleError && handleError(e)
  })(handleSuccess)
}
