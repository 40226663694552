import { useProduct } from '@ecomm/data-products'
import { useMicroCopy } from '@ecomm/micro-copy'
import { Caution } from '@ecomm/ss-react-components'
import { overloadMaybe } from '@simplisafe/ewok'
import classNames from 'classnames'
import { daysToWeeks, differenceInDays, isAfter, parseISO } from 'date-fns'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { lookup } from 'fp-ts/lib/Record'
import React, { useState } from 'react'

type Props = {
  readonly sku: string
}

export default function OutOfStock({ sku }: Props) {
  const [displayMessaging, setDisplayMessaging] = useState(false)

  const reduxProduct = useProduct(sku)
  const restockDate = pipe(
    overloadMaybe(reduxProduct.toMaybe()),
    O.chain(lookup('restockDate')),
    O.map(date => parseISO(date))
  )

  const microCopy = useMicroCopy()

  const messaging = pipe(
    restockDate,
    O.map(date => differenceInDays(date, Date.now())),
    O.map(days =>
      days < 8
        ? `${days} days`
        : `${daysToWeeks(days)} - ${daysToWeeks(days) + 1} weeks`
    ),
    O.map(microCopy['package-item-ooo']),
    O.toNullable
  )

  const isOOO = pipe(
    restockDate,
    O.map(date => isAfter(date, Date.now())),
    O.getOrElse(() => false)
  )

  return isOOO ? (
    <div className="relative">
      <div
        className="flex items-center"
        onMouseEnter={() => setDisplayMessaging(true)}
        onMouseLeave={() => setDisplayMessaging(false)}
      >
        <Caution data-component="caution-icon" />
      </div>
      <span
        className={classNames(
          'border-neutral-light-100 absolute bottom-full left-full w-40 rounded border border-solid bg-white p-1 text-xs',
          { hidden: !displayMessaging }
        )}
      >
        {messaging}
      </span>
    </div>
  ) : null
}
