import { BigChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React, { useState } from 'react'

export type Cell = JSX.Element | string
export type Row = {
  readonly cells: readonly Cell[]
  readonly category: string
  readonly collapsed?: boolean
  readonly isSectionTitle?: boolean
}

export const Themes = {
  default: 'default',
  planComparison: 'plan-comparison'
}

export type TableProps = {
  readonly headRows: readonly Row[]
  readonly bodyRows: readonly Row[]
  readonly theme?: (typeof Themes)[keyof typeof Themes]
}

const isThirdColumn = (index: number) => index === 2
const isLastRow = (index: number, rowsLength: number) =>
  index === rowsLength - 1

export default function ComparisonTable({
  headRows,
  bodyRows,
  theme = Themes.default
}: TableProps) {
  const [rows, setRows] = useState(bodyRows)

  const handleToggleSection = (category: string) => {
    setRows(prev =>
      prev.map(r => ({
        ...r,
        collapsed: category === r.category ? !r.collapsed : r.collapsed
      }))
    )
  }

  return (
    <table
      className={classNames(
        'table w-full table-fixed border-separate place-items-center items-center',
        {
          'border-spacing-y-2': theme === Themes.default,
          'border-spacing-y-0 rounded-3xl bg-[#f2f2f2] px-4 py-6 md:px-8 md:py-8':
            theme === Themes.planComparison
        }
      )}
      data-testid="MonitoringComparisonTable"
    >
      <caption>
        <h2 className="sr-only">{headRows[0].cells[0]}</h2>
      </caption>
      <thead>
        {headRows.map((hrow, i) => (
          <tr key={`comparison-table-head-row-${i}`}>
            {hrow.cells.map((cell, j) => (
              <th
                className={classNames('first:text-left', {
                  'pb-6 text-xs md:pb-8 md:text-base first:md:text-4xl':
                    theme === Themes.default,
                  'border-b-neutral-medium-100 border-b border-t-0 border-solid pb-2 text-sm font-normal first:font-bold md:pb-6 md:text-lg':
                    theme === Themes.planComparison,
                  'border-l-0 border-r-0':
                    theme === Themes.planComparison && !isThirdColumn(j),
                  'border-l border-r':
                    theme === Themes.planComparison && isThirdColumn(j)
                })}
                colSpan={j === 0 ? 2 : 1}
                key={`comparison-table-head-row-${i}-cell-${j}`}
              >
                {cell}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((brow, i) =>
          brow.isSectionTitle ? (
            <tr key={`comparison-table-body-row-${i}`}>
              <td
                className="border-b-neutral-black border-0 border-b-2 border-solid py-2 font-bold"
                colSpan={brow.cells.length}
                key={`comparison-table-body-row-${i}-cell-1`}
              >
                {brow.category}
              </td>
              <td
                className="border-b-neutral-black border-0 border-b-2 border-solid py-2 text-center"
                key={`comparison-table-body-row-${i}-cell-2`}
              >
                <button
                  aria-label={`Collapse ${brow.category}`}
                  className={classNames(
                    'm-0 cursor-pointer appearance-none border-none bg-transparent p-0',
                    { 'rotate-180': !brow.collapsed }
                  )}
                  onClick={() => handleToggleSection(brow.category)}
                >
                  <BigChevronDown className="h-4 w-4" />
                </button>
              </td>
            </tr>
          ) : !brow.collapsed ? (
            <tr key={`comparison-table-body-row-${i}`}>
              {brow.cells.map((cell, j) => (
                <td
                  className={classNames('text-center first:text-left', {
                    'border-b-neutral-medium-100 prose-p:leading-tight border-spacing-y-2 border-x-0 border-b border-t-0 border-solid py-2 text-base font-bold leading-tight':
                      theme === Themes.default,
                    'border-l border-r text-sm md:text-2xl':
                      theme === Themes.planComparison &&
                      isLastRow(i, rows.length),
                    'border-b-neutral-medium-100 border-b border-t-0 border-solid py-2 text-sm md:py-6 md:text-base':
                      theme === Themes.planComparison &&
                      !isLastRow(i, rows.length),
                    'border-l-0 border-r-0':
                      theme === Themes.planComparison && !isThirdColumn(j),
                    'border-y-0 border-l border-r border-solid':
                      theme === Themes.planComparison && isThirdColumn(j)
                  })}
                  colSpan={j === 0 ? 2 : 1}
                  key={`comparison-table-body-row-${i}-cell-${j}`}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ) : null
        )}
      </tbody>
    </table>
  )
}
