import { BannerLoading } from '@ecomm/ss-react-components'
import React from 'react'

function LoadingBanner() {
  return (
    <BannerLoading dataComponent="PackageLoading">
      <h2 className="h2">Loading your package…</h2>
    </BannerLoading>
  )
}

export default LoadingBanner
