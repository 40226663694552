import { TypeOf, z } from '@simplisafe/ewok'

import {
  monitoringPlanCardSchema,
  planFeatureSchema
} from '../MonitoringPlanCard/schema'
import { monitoringPlanModalSchema } from '../MonitoringPlanModal/schema'

const buttonSchema = z.object({
  contentful_id: z.string(),
  __typename: z.literal('ContentfulButton'),
  url: z.string(),
  buttonText: z.string()
})

const referencesSchema = z.union([planFeatureSchema, buttonSchema])

export const expandableMonitoringSchema = z.object({
  __typename: z.literal('ContentfulMonitoringPlanComparison'),
  headline: z.string().nullish(),
  monitoringCards: monitoringPlanCardSchema.array(),
  comparisonTable: z.object({
    raw: z.string(),
    references: referencesSchema.array().optional()
  }),
  comparisonDisclaimer: z.object({
    raw: z.string(),
    references: z.array(monitoringPlanModalSchema).optional()
  })
})

export type ExpandableMonitoringSchema = TypeOf<
  typeof expandableMonitoringSchema
>
