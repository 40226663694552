import { mapExperiences } from '@ecomm/shared-ninetailed'
import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import React from 'react'

import { AffirmFeatureFlagSchema } from '../../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../../experiments/AffirmAPR/useAffirmExperimentQuery'
import DraftCartLineList from '.'

function AffirmDraftCartLineListWrapper() {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <div
      data-component={
        isAffirmExperience
          ? 'AffirmDraftCartLineListVariant'
          : 'AffirmDraftCartLineListControl'
      }
    >
      <DraftCartLineList isAffirmMonthlyExperiment={isAffirmExperience} />
    </div>
  )
}

export default AffirmDraftCartLineListWrapper
