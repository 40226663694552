import { GatsbyImage } from '@ecomm/shared-components'
import React from 'react'

import RatingBar from './RatingBar'
import { RatingsSchema } from './schema'

function AppRating({
  appleRatings,
  appleStars,
  googleRatings,
  googleStars,
  appleLogo,
  googlePlayLogo,
  simpliSafeLogo
}: RatingsSchema) {
  return (
    <div
      className="flex flex-row justify-center md:justify-start"
      data-component="AppRating"
    >
      <div className="hidden pt-1 md:mr-3 md:block">
        <GatsbyImage
          className="p-auto m-auto h-16 w-16"
          image={simpliSafeLogo}
        />
      </div>
      <div className="flex flex-col">
        {appleRatings && appleStars ? (
          <RatingBar
            logo={appleLogo}
            ratings={appleRatings}
            stars={appleStars}
            url="https://apps.apple.com/us/app/simplisafe-home-security-app/id555798051"
          />
        ) : null}
        {googleRatings && googleStars ? (
          <RatingBar
            logo={googlePlayLogo}
            ratings={googleRatings}
            stars={googleStars}
            url="https://play.google.com/store/apps/details?id=com.simplisafe.mobile&hl=en&gl=US"
          />
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(AppRating)
