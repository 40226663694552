import { Locale } from '@ecomm/utils'
import { overloadMaybe } from '@simplisafe/ewok'
import { localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export function formatPrice(locale: Locale, price: number) {
  return pipe(
    overloadMaybe(localizedDisplayPrice(locale)(price)),
    O.getOrElse(() => '')
  )
}
