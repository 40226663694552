/**
 * IMPORTANT NOTE: interceptFetchHeaders MUST be imported first, otherwise fetch-intercept
 * can break in unexpected ways and cause us to stop sending the X-Vid-Token header to the backend.
 * Long-term, we should stop using fetch-intercept, and the headers required for SS API requests
 * should just be handled by the actual functions that make the requests (in ss-ecomm-data, etc).
 * - csims
 */
import { interceptFetchHeaders } from '@ecomm/tracking'

// import '@ecomm/ss-react-components/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import './src/styles/fonts.css'
import './src/styles/global.css'

import { hydrateCustomAttributes } from '@ecomm/gatsby-plugins/utils'
import { pushToDataLayer } from '@ecomm/shared-window'
import { localeToLocalStorage } from '@ecomm/utils'

import { WrapPageElement } from './src/WrapPageElement'
import { ApolloProvider } from '@apollo/client/index.js'
import { client } from '@ecomm/shared-apollo'
import { ReactElement } from 'react'
import { WrapRootElement } from './src/WrapRootElement'

type WrapWithProviderProps = {
  readonly element: ReactElement
}

export function wrapRootElement<T extends WrapWithProviderProps>(t: T) {
  return (
    <ApolloProvider client={client}>
      <WrapRootElement {...t} />
    </ApolloProvider>
  )
}
export const wrapPageElement = WrapPageElement

// Registers fetch intercept.
// Called when the Gatsby browser runtime first starts: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
export const onClientEntry = () => {
  interceptFetchHeaders()
  // initialize GTM dataLayer
  pushToDataLayer({ site: 'fcp' })()
  // tag newrelic events with custom attributes
  hydrateCustomAttributes()
  // push locale to local storage
  // used when locale is needed by code that is outside of our react runtime
  localeToLocalStorage()
}
