//@ts-nocheck
import { Divider as DividerAtom } from '@ecomm/ss-react-components'
import { nullToUndefined } from '@simplisafe/ewok'
import React from 'react'

import { DividerFragment } from './query.types'

type Props = DividerFragment

/**
 * A wrapper for the Divider Atom to pass in data from Contentful.
 */
function Divider({ borderWidth, id, width, color }: Props) {
  return (
    <DividerAtom
      borderWidth={nullToUndefined(borderWidth)}
      color={color?.color}
      key={id}
      width={nullToUndefined(width)}
    />
  )
}

export default React.memo(Divider)
