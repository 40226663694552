import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { alarmMomentsSchema } from '@ecomm/shared-components'
import { appWidgetSchema, featureSectionSchema } from '@ecomm/shared-sections'
import { Z, z } from '@simplisafe/ewok'

import { layoutNoHeaderSchema } from '../../assemblies/Layout/layoutSchema'
import { colorVariantSchema } from '../../components/ColorSelector/schema'
import { itemsSchema } from '../../components/ConfidenceBar/schema'
import { productCardInternalSchema } from '../../components/ProductCard/schema'
import { twoColumnImageBlurbsSchema } from '../../components/TwoColumnImageBlurbs/schema'
import { twoColumnImagesSchema } from '../../components/TwoColumnImages/schema'

export const videoSchema = z.object({
  __typename: z.literal('ContentfulVideo'),
  entryTitle: z.string(),
  webmLink: z.string(),
  mp4Link: z.string(),
  posterImage: z.string().optional(),
  captions: z.string().nullish()
})

export const productSchema = z.object({
  contentful_id: z.string(),
  name: z.string(),
  slug: z.string(),
  isNew: z.boolean().nullish().default(false),
  sku: z.string(),
  namePlural: z.string(),
  maxQuantity: z.number(),
  description: contentfulRichTextSchema,
  longDescription: contentfulRichTextSchema.nullish(),
  features: contentfulRichTextSchema.nullish(),
  disclaimer: contentfulRichTextSchema.nullish(),
  imageWithBg: gatsbyImageSchema,
  imageCarousel: z.array(gatsbyImageSchema),
  imageCarouselColorVariant: z.array(gatsbyImageSchema).nullish(),
  lifestyleImage: gatsbyImageSchema.nullish(),
  installationInstructions: contentfulRichTextSchema.nullish(),
  installationImage: gatsbyImageSchema.nullish(),
  overviewVideo: videoSchema.nullish(),
  faq: z
    .object({
      primary: z
        .object({
          question: z.object({
            question: z.string()
          }),
          answer: contentfulRichTextSchema
        })
        .array()
    })
    .nullish(),
  variant: z.array(colorVariantSchema).optional()
})

const smallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: contentfulRichTextSchema
})

const imageLinkSchema = z.object({
  __typename: z.literal('ContentfulImageLink'),
  image: gatsbyImageSchema.optional(),
  title: z.string().default(''),
  url: z.string().default('')
})

const twoColumnSectionSchema = z.object({
  __typename: z.literal('ContentfulTwoColumnSection'),
  leftSide: videoSchema,
  rightSide: smallTextSchema
})

export const featuredProductPageSchema = z.object({
  confidenceBarHeadline: z.string(),
  confidenceBarItems: itemsSchema,
  id: z.string(),
  layout: layoutNoHeaderSchema,
  product: productSchema,
  productDetails: contentfulRichTextSchema.nullish(),
  productNameOverride: z.string().nullish(),
  productDescriptionOverride: contentfulRichTextSchema.nullish(),
  riskFreeCopy: z.string(),
  riskFreeImage: gatsbyImageSchema,
  riskFreeImageVariant: gatsbyImageSchema.nullish(),
  showcaseTitle: z.string(),
  showcaseVideo: z.union([videoSchema, imageLinkSchema]),
  alarmMoments: alarmMomentsSchema.nullish(),
  site: z.string().array(),
  title: z.string(),
  updatedAt: z.string(),
  meetTheProductTitle: z.string(),
  featureOverview: twoColumnImagesSchema,
  appWidget: appWidgetSchema.nullish(),
  imageBlurbs: twoColumnImageBlurbsSchema,
  installation: z.union([twoColumnSectionSchema, featureSectionSchema]),
  additionalProductsHeadline: z.string().nullish(),
  additionalProducts: z.array(productCardInternalSchema).nullish()
})

export type FeaturedProductPageSchema = Z.TypeOf<
  typeof featuredProductPageSchema
>
export type ProductSchema = Z.TypeOf<typeof productSchema>
