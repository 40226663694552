import React, { FC, ReactNode } from 'react'

type PageWrapperProps = {
  readonly children?: ReactNode
  readonly dataComponent?: string
}

// The "Skip to content" link is for accessibility,
// so a user can jump past the main navigation.
// It links to the 'main' element in /PageBody.
/** @deprecated Do not use ss-react-components*/
const PageWrapper: FC<PageWrapperProps> = ({
  children,
  dataComponent = PageWrapper.name
}: PageWrapperProps) => {
  return (
    <div
      className="min-h-screen overflow-x-hidden bg-white"
      data-component={dataComponent}
    >
      <a
        className="absolute -left-[9999px] -top-[9999px] z-50 block bg-white p-2 focus:left-0 focus:top-0"
        href="#content"
      >
        Skip to content
      </a>
      {children}
    </div>
  )
}

export default PageWrapper
