import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { TypeOf, z } from '@simplisafe/ewok'

const odmonFeatureFlagDraftSchema = z.object({
  id: z.string(),
  title: z.string(),
  json: z.object({
    id: z.string(),
    tag: z.string()
  })
})

const experimentSchema = experimentTemplateSchema.extend({
  variants: z.array(odmonFeatureFlagDraftSchema)
})

export const odmonFeatureFlagSchema = odmonFeatureFlagDraftSchema.extend({
  nt_experiences: z.array(experimentSchema).default([])
})

export type OdmonFeatureFlagDraftSchema = TypeOf<
  typeof odmonFeatureFlagDraftSchema
>
export type OdmonFeatureFlagSchema = TypeOf<typeof odmonFeatureFlagSchema>
