//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import unless from 'ramda/src/unless'
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  useMemo,
  useState
} from 'react'

import * as css from './SSSelect.module.scss'

export type selectObject = {
  readonly id: number | string
  readonly name: number | string
}

export type SSSelectProps = {
  readonly error?: boolean
  readonly id: string
  readonly label?: string
  readonly errorMsg?: string
  readonly placeholder?: string
  readonly required?: boolean
  readonly value?: string
  readonly onChange?: (event: ChangeEvent) => unknown
  readonly disabled?: boolean
  readonly className?: string
  readonly classNameLabel?: string
  readonly options?: readonly string[]
  readonly name?: string
  readonly showRequiredSymbol?: boolean | true
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const SSSelect = forwardRef<HTMLSelectElement, SSSelectProps>(
  (
    {
      disabled,
      error,
      errorMsg,
      id,
      label,
      onChange,
      placeholder,
      required,
      value,
      className,
      classNameLabel,
      options,
      name,
      showRequiredSymbol,
      dataComponent = 'SSSelect'
    }: SSSelectProps,
    ref
  ) => {
    const errorClass = css['error']

    const [touched, setTouched] = useState(false)
    const inputRequired = useMemo(() => {
      return touched && required
    }, [required, touched])

    const handleTouched: (event: FocusEvent) => unknown = () => {
      setTouched(true)
    }
    const selectClass = classNames(
      'border border-solid rounded-base block w-full max-w-[427px] bodyText first:mt-0 last:mb-0 text-base font-normal leading-[1.56] md:text-lg md:leading-[1.56]', //TODO: Remove bodyText after palette implementation',
      { [errorClass]: error },
      className
    )
    const propName = unless(isNil, always(id))(name)
    return (
      <>
        {label ? (
          <label
            className={classNames(
              'bodyText text-base font-normal leading-[1.56] first:mt-0 last:mb-0 md:text-lg md:leading-[1.56]',
              classNameLabel
            )}
            data-component={`${dataComponent}_label`}
            htmlFor={id}
          >
            {label}
            {required && showRequiredSymbol ? <span>*</span> : null}
          </label>
        ) : null}
        <select
          aria-required={required}
          className={selectClass}
          data-component={`${dataComponent}_select`}
          disabled={disabled}
          id={id}
          name={propName}
          onBlur={handleTouched}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          required={inputRequired}
          value={value}
        >
          {!isEmpty(placeholder) && !isNil(placeholder) && (
            <option value="">{placeholder}</option>
          )}
          {!isEmpty(options) && !isNil(options)
            ? options.map((item: string) => (
                <option key={`${item}`} value={item}>
                  {item}
                </option>
              ))
            : ''}
        </select>
        {errorMsg ? (
          <div
            className={classNames('mt-2.5 md:ml-2', css['errorText'])}
            data-component={`${dataComponent}_error`}
          >
            {errorMsg}
          </div>
        ) : null}
      </>
    )
  }
)
export default SSSelect
