//@ts-nocheck
import prop from 'ramda/src/prop'
import propOr from 'ramda/src/propOr'
import React, { FC, ReactNode } from 'react'

import * as css from './PatentTable.module.scss'

export type PatentTableCell = {
  readonly colspan: number
  readonly content: ReactNode
  readonly id?: string
}

export type PatentTableRow = {
  readonly cell: readonly PatentTableCell[]
  readonly id?: string
}

export type TableProps = {
  readonly row: readonly PatentTableRow[]
}

/** @deprecated Do not use ss-react-components*/
const PatentTable: FC<TableProps> = ({ row }: TableProps) => (
  <table className={css.patentTable}>
    <tbody>
      {row.map((item, rowIndex) => (
        <tr key={propOr(rowIndex, 'id', item)}>
          {prop('cell', item).map((cell, cellIndex) => (
            <th
              colSpan={prop('colspan', cell)}
              key={propOr(cellIndex, 'id', item)}
            >
              <div className={css.innerContent}>{prop('content', cell)}</div>
            </th>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

export default PatentTable
