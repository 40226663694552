import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

// once main merged/davids imaps ticket- we can call from the skus constants
const NIGHTIME_ODMON_SKU = 'SSODMONON__13798447'
const COMPLETE_ODMON_SKU = 'SSODMON247__13798553'

/**
 * Determines if either of the Outdoor Monitoring Plan SKUs are in cart.
 */
export const hasOutdoorMonitoringInCart = (
  lineItems: readonly LineItem[]
): boolean => lineItems.some(lineItem => isOutdoorMonitoringPlan(lineItem.sku))

export const isOutdoorMonitoringPlan = (sku: string): boolean => {
  return sku === NIGHTIME_ODMON_SKU || sku === COMPLETE_ODMON_SKU
}
