import { convertMonetMaybe, MaybeT } from '@simplisafe/ewok'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import * as O from 'fp-ts/lib/Option'

// @ts-expect-error -- something is off in Monet and it expects {}, which we don't want
type MaybeUnknown = MaybeT<unknown>

type ProductWithUnknown = Omit<
  Product,
  'discountedPrice' | 'discountedPriceWithServicePlan'
> & {
  readonly discountedPrice: MaybeUnknown
  readonly discountedPriceWithServicePlan: MaybeUnknown
}

export type ParsedProduct = Omit<
  Product,
  'discountedPrice' | 'discountedPriceWithServicePlan'
> & {
  readonly discountedPrice: O.Option<number>
  readonly discountedPriceWithServicePlan: O.Option<number>
}

export const parseMaybeNumber = (t: MaybeUnknown) =>
  convertMonetMaybe<number>(t.map(n => Number(n)))

export const parseProduct = (product: ProductWithUnknown): ParsedProduct => {
  return {
    ...product,
    discountedPrice: parseMaybeNumber(product.discountedPrice),
    discountedPriceWithServicePlan: parseMaybeNumber(
      product.discountedPriceWithServicePlan
    )
  }
}
