import { baseOdmonApiUrl } from '@ecomm/data-env-variables'
import { safeFetchJson } from '@jvlk/fp-ts-fetch'

export interface PlanSubmitResponse {
  readonly userId: string
  readonly email: string
  readonly planSelection: string
  readonly sku: string
  readonly oneTimeCode: string
  readonly date: string
  readonly order: string
}

const sendPostPurchase = (
  userId: string,
  email: string,
  planSku: 'SSODMON247' | 'SSODMONON'
) => {
  const body = JSON.stringify({
    user_id: userId,
    email,
    plan_sku: planSku
  })

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const method = 'POST'

  return safeFetchJson<PlanSubmitResponse>(
    `${baseOdmonApiUrl()}/participate/monitoring-plan/selection`,
    { body, headers, method }
  )
}

export { sendPostPurchase }
