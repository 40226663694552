import React, { useEffect, useState } from 'react'

import ColorBody, { ColorBodySlim } from './ColorBody'
import { ColorVariantSchema } from './schema'

type Props = {
  readonly data: readonly ColorVariantSchema[]
  readonly defaultSku: string
  readonly onChangeColor: (newSku: string) => void
  readonly placement: string
}

function ColorSelector({ data, defaultSku, onChangeColor }: Props) {
  const [sku, setSku] = useState('')
  const [color, setColor] = useState('')

  const defaultProduct = data
    .flatMap(product => product)
    .find(product => product.sku === defaultSku)

  useEffect(() => {
    setSku(defaultProduct?.sku || '')
    setColor(defaultProduct?.productColor || '')
    onChangeColor(defaultProduct?.sku || '')
  }, [data, setSku, setColor])

  const onClick = (data: ColorVariantSchema, isSelected: boolean) => {
    setSku(data.sku)
    setColor(data.productColor)
    !isSelected && onChangeColor(data.sku)
  }

  return (
    <div
      className="border-neutral-light-100 mb-3 inline-flex w-full items-center border-b-2 border-l-0 border-r-0 border-t-0 border-solid pb-2.5"
      data-component="ColorSelector"
    >
      <div className="mr-2 max-w-[60px]">
        <h4 className="mb-0 mt-1 font-bold empty:hidden">{'Color'}</h4>
        <p className="m-0">{color}</p>
      </div>
      {data.map((data, idx) => (
        <ColorBody
          data={data}
          isSelected={data.sku === sku}
          key={idx}
          onClick={() => onClick(data, data.sku === sku)}
          placement="ColorSelector"
        />
      ))}
    </div>
  )
}

export function ColorSelectorSlim({
  data,
  defaultSku,
  onChangeColor,
  placement
}: Props) {
  const [sku, setSku] = useState('')

  const defaultProduct = data
    .flatMap(product => product)
    .find(product => product.sku === defaultSku)

  useEffect(() => {
    setSku(defaultProduct?.sku || '')
    onChangeColor(defaultProduct?.sku || '')
  }, [data, setSku])

  const onClick = (data: ColorVariantSchema, isSelected: boolean) => {
    setSku(data.sku)
    !isSelected && onChangeColor(data.sku)
  }

  return (
    <div
      className="border-neutral-light-100 mb-3 inline-flex w-full items-center pb-2.5"
      data-component="ColorSelector"
    >
      {data.map((data, idx) => (
        <>
          <ColorBodySlim
            data={data}
            isSelected={data.sku === sku}
            key={idx}
            onClick={() => onClick(data, data.sku === sku)}
            placement={placement}
          />
          <p className="m-0">{data.productColor}</p>
        </>
      ))}
    </div>
  )
}

export default ColorSelector
