import React, { FC, ReactNode } from 'react'

import { Text } from '..'

export type SensorProductBannerProps = {
  /** A CTA button  */
  readonly button?: ReactNode
  /** A description of the product */
  readonly description: ReactNode
  /** Large header text for the banner */
  readonly headerText: string
  /** An image of the product on the left side of the banner */
  readonly image: ReactNode
  /** Out of stock messaging to appear below description */
  readonly outOfStockMessage?: ReactNode
  /** An optional second CTA button, intended to be a popup button displaying an add to cart modal */
  readonly popupButton?: ReactNode
  /** The name of the product to be displayed */
  readonly productName: string
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const SensorProductBanner: FC<SensorProductBannerProps> = ({
  button,
  description,
  headerText,
  image,
  outOfStockMessage,
  popupButton,
  productName,
  dataComponent = SensorProductBanner.name
}: SensorProductBannerProps) => {
  return (
    <Text
      className="inline-grid grid-cols-12 items-stretch gap-x-4 md:gap-x-6 lg:gap-x-8"
      data-component={dataComponent}
      useTailwind
    >
      <div className="rounded-base col-span-4 overflow-hidden md:col-span-3 lg:col-span-6 lg:row-span-2">
        {image}
      </div>
      <div className="col-span-8 flex flex-col justify-center md:col-span-9 lg:col-span-6 lg:justify-end">
        <p>{productName}</p>
        <h1 className="mb-6 mt-0">{headerText}</h1>
      </div>
      <div className="col-span-12 lg:col-span-6">
        {description}
        {outOfStockMessage ? (
          <small className="text-sale">{outOfStockMessage}</small>
        ) : null}
        <div className="mt-4 flex flex-wrap items-center gap-6">
          {button}
          {popupButton}
        </div>
      </div>
    </Text>
  )
}

export default SensorProductBanner
