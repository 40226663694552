import { Modal } from '@ecomm/checkout/components'
import { ShippingValidationAddressSchema } from '@ecomm/checkout/shipping-schema'
import {
  combineUserDataAndAddress,
  formatAddressEntered,
  getCheckoutBody
} from '@ecomm/checkout/utils'
import { useLocale } from '@ecomm/data-hooks'
import { useTrackingConfirmAddressShippingModalValidation } from '@ecomm/tracking'
import {
  selectCartLoading,
  selectCartShippingAddress
} from '@simplisafe/ss-ecomm-data/cart/select'
import { handleCheckoutSubmit } from '@simplisafe/ss-ecomm-data/checkout/checkout'
import { logError } from '@ecomm/error-handling'
import { navigate } from 'gatsby'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ShippingValidationModalPicker } from './ShippingValidationModalPicker'
import { ShippingValidationModalSubmission } from './ShippingValidationModalSubmission'

type Props = {
  readonly modal: ShippingValidationAddressSchema
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
}

export function ShippingModalValidation({
  modal: { data, redirectUrl },
  open,
  setOpen
}: Props) {
  // selectors
  const dispatch = useDispatch()
  const cartIsLoading = useSelector(selectCartLoading)
  const locale = useLocale()
  const shippingAddress = useSelector(selectCartShippingAddress)
  // states
  const [disableSubmit, setDisableSubmit] = useState(false)
  // data
  const staticShippingAddress = useMemo(
    () => shippingAddress,
    [shippingAddress]
  )
  // tracking
  const trackConfirmAddress = useTrackingConfirmAddressShippingModalValidation()

  const handleSubmit = () => {
    trackConfirmAddress('Confirm verified address')
    setDisableSubmit(true)

    const handleSubmitSuccess = () => {
      setDisableSubmit(false)
      navigate(redirectUrl)
    }
    const handleSubmitError = (err: Error) =>
      logError(
        Error(`something went wrong updating user address: ${err.message}`)
      )

    const handleUpdateAddress = () => {
      const addressData = combineUserDataAndAddress(data.userCheckoutData)(
        data.validatedAddress
      )(locale)
      const body = getCheckoutBody({
        billingAddress: addressData,
        setBillingAddress: !!data.config.setBillingAddress,
        shippingAddress: addressData,
        shippingMethod: data.shippingMethod,
        shouldValidate: false,
        lead: data.metadata.lead,
        leadOther: data.metadata.leadOther
      })
      dispatch(
        handleCheckoutSubmit(body)(handleSubmitError)(handleSubmitSuccess)
      )
    }

    return handleUpdateAddress()
  }

  const handleChangeAddressClick = () => {
    trackConfirmAddress('Change address')
    setOpen(false)
  }

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)}>
      <div
        className="flex w-full flex-col p-8 pb-2 md:pb-8"
        data-component="modal_content"
      >
        <div className="m-0 mb-6 flex flex-col gap-2 md:mb-0">
          <h3 className="m-0 text-2xl font-medium md:font-bold lg:text-4xl">
            Confirm your address
          </h3>
          <p className="text-neutral-black m-0 text-base lg:text-lg">
            To ensure delivery accuracy, we suggest the address displayed below.
          </p>
          <p
            className="m-0 w-full text-base lg:text-lg"
            data-component="user_entered_address"
          >
            <span className="font-bold"> You entered: </span>
            {formatAddressEntered(staticShippingAddress)}
          </p>
        </div>
        <div className="flex h-full w-full flex-col items-center gap-8 md:flex-row md:gap-10 lg:gap-24">
          <ShippingValidationModalPicker
            address={data.validatedAddress}
            title="Verified address:"
            userData={data.userCheckoutData}
          />
          <div className="flex h-full w-full flex-col md:mt-20">
            <ShippingValidationModalSubmission
              handleSubmit={handleSubmit}
              isLoading={cartIsLoading || disableSubmit}
            />
            <button
              className="text-btn-primary m-4 cursor-pointer border-none bg-white text-center text-base font-medium lg:text-lg"
              onClick={handleChangeAddressClick}
            >
              Change Address
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
