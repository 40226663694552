import {
  addDaysToHours,
  formatCountdownIntegerMobile
} from '@ecomm/promotions-utils'
import { renderMobileTime } from '@ecomm/promotions-utils'
import intervalToDuration from 'date-fns/fp/intervalToDuration'
import isBefore from 'date-fns/fp/isBefore'
import React, { useState } from 'react'
import { useInterval } from 'use-interval'

type CountdownProps = {
  /** The time to count down until. */
  readonly endTime: Date
}

export function Countdown({ endTime }: CountdownProps) {
  const [time, setTime] = useState(['00', '00', '00'])
  /** If the countdown ends we want to render "ENDS SOON" */
  const [expired, setExpired] = useState(false)
  const labels = ['HR', 'MIN', 'SEC']

  useInterval(
    () => {
      const currentTime = new Date()
      const { days, hours, minutes, seconds } = intervalToDuration({
        end: endTime,
        start: currentTime
      })

      const countdownHasExpired = isBefore(currentTime, endTime)

      !countdownHasExpired &&
        setTime([
          `${formatCountdownIntegerMobile(addDaysToHours(days, hours))}`,
          `${formatCountdownIntegerMobile(minutes)}`,
          `${formatCountdownIntegerMobile(seconds)}`
        ])
      countdownHasExpired && setExpired(true)
    },
    1000,
    true
  )

  return (
    <div data-component="MobileCountdown">
      {!expired && (
        <div className="min-w-[100px] max-w-[120px] text-center uppercase text-white">
          <div className="bg-neutral-black rounded-sm py-1.5 pt-1 font-bold">
            <span>ends in</span>
          </div>
          <div className="border-t-neutral-black mx-auto mb-0.5 h-0 w-0 border-x-[5px] border-b-0 border-t-[9px] border-solid border-x-transparent" />
          <div className="grid grid-cols-6 gap-0.5" role="timer">
            {time.map(renderMobileTime(labels))}
          </div>
        </div>
      )}
    </div>
  )
}
