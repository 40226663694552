import { ComparisonTableProps } from '@ecomm/scout'

export type CompetitiveComponentsProps = {
  readonly competitor?: 'ADT' | 'Ring'
}

export const getHeroBannerTitleByCompetitor = (
  competitor: CompetitiveComponentsProps['competitor'],
  title: string
) => {
  const splitter = 'vs.'
  if (!competitor) {
    return title
  }
  return `${title.split(splitter)[0]}${splitter} ${competitor}`
}

export const getComparisonTableDataByCompetitor = (
  competitor: CompetitiveComponentsProps['competitor'],
  data: ComparisonTableProps
) => {
  const simpliIndex = 0
  const competitorIndex = data.header.competitors.findIndex(
    c => c === competitor
  )

  if (competitorIndex === -1) {
    return data
  }

  // Add 1 to the competitor index because the first index (0) in 'values' and 'ratings' always represents 'SimpliSafe', offsetting the competitor's index by 1.
  const offsetCompetitorIndex = competitorIndex + 1

  return {
    ...data,
    header: {
      competitors: [data.header.competitors[competitorIndex]],
      titles: [data.header.titles[competitorIndex]]
    },
    body: data.body.map(group => {
      if ('rows' in group) {
        return {
          ...group,
          rows: group.rows.map(row => {
            if (row.values) {
              return {
                ...row,
                values: [
                  row.values[simpliIndex],
                  row.values[offsetCompetitorIndex]
                ]
              }
            } else if (row.ratings) {
              return {
                ...row,
                ratings: [
                  row.ratings[simpliIndex],
                  row.ratings[offsetCompetitorIndex]
                ]
              }
            } else {
              return row
            }
          })
        }
      } else {
        return group
      }
    })
  }
}
