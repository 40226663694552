import { useLocale } from '@ecomm/data-hooks'
import { contactIdeaCapture } from '@ecomm/data-leads'
import {
  FormQuerySchema,
  OnSubmit,
  SubmissionResultProps,
  validationSchema
} from '@ecomm/legal-data'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useState } from 'react'

import { FormField } from './lib/FormField'
import { FormTextArea } from './lib/FormTextArea'
import { FormTextInput } from './lib/FormTextInput'

export const SUBMISSION_SUCCESS_MESSAGE =
  'Your ideas have been submitted to publicideasub@simplisafe.com. Someone from our team will reach out soon.'
export const SUBMISSION_ERROR_MESSAGE =
  'There is an unexpected error on our service, please try again later.'

const inputTypeComponent: Record<
  string,
  React.InputHTMLAttributes<HTMLInputElement>['type']
> = {
  Text: 'text',
  Email: 'email',
  'Text Area': 'textarea',
  Checkbox: 'checkbox'
}

const messageByResultObject = {
  success: SUBMISSION_SUCCESS_MESSAGE,
  error: SUBMISSION_ERROR_MESSAGE
}

export function SubmitYourIdeasForm({ fields, button }: FormQuerySchema) {
  const [showInputs, setShowInputs] = useState(false)
  const [submissionResult, setSubmissionResult] =
    useState<SubmissionResultProps>({ result: 'pending' })
  const locale = useLocale()

  const onSubmit: OnSubmit = async formValues => {
    const payload = {
      locale,
      email: formValues.email,
      name: formValues.firstName + '_' + formValues.lastName,
      message: formValues.message
    }

    pipe(
      await contactIdeaCapture(payload)(),
      E.match(
        _ => {
          setSubmissionResult({
            result: 'error'
          })
        },
        _ => {
          setSubmissionResult({
            result: 'success'
          })
        }
      )
    )
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        checkboxField: false
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid }) => (
        <Form className="flex w-full flex-col" noValidate>
          <FormField
            className="mb-4 flex flex-row-reverse items-center justify-end md:mb-6 lg:mb-8"
            label="I have read these terms and agree that any submissions are governed by these terms"
            name="checkboxField"
          >
            <FormTextInput
              className="mr-3 h-4 w-4"
              disabled={submissionResult.result === 'success'}
              id="checkboxField"
              name="checkboxField"
              onChange={e => setShowInputs(e.target.checked)}
              type="checkbox"
            />
          </FormField>
          {showInputs && submissionResult.result !== 'success' ? (
            <>
              {fields.items.map((field, i) => (
                <FormField
                  className="mb-4 w-full md:mb-6 lg:mb-8"
                  key={`form-input-${i}`}
                  label={field.label}
                  name={field.name}
                >
                  {field.type === 'Text Area' ? (
                    <FormTextArea
                      className="w-full"
                      id={field.name}
                      name={field.name}
                      placeholder={field.placeholder}
                    />
                  ) : (
                    <FormTextInput
                      className="w-full"
                      id={field.name}
                      name={field.name}
                      placeholder={field.placeholder}
                      type={inputTypeComponent[field.type]}
                    />
                  )}
                </FormField>
              ))}
              <button
                className={classNames(
                  'btn block h-14 w-fit min-w-[190px] px-8',
                  {
                    'bg-neutral-medium-100 border-none text-white': !isValid,
                    'btn-solid-primary cursor-pointer': isValid
                  }
                )}
                disabled={!isValid}
                type="submit"
              >
                {button.text}
              </button>
            </>
          ) : null}
          {submissionResult.result !== 'pending' ? (
            <p
              className={classNames({
                'text-sale mt-4': submissionResult.result === 'error'
              })}
              data-testid="result-label"
            >
              {messageByResultObject[submissionResult.result]}
            </p>
          ) : null}
        </Form>
      )}
    </Formik>
  )
}
