import { ContentfulImage } from '@ecomm/contentful/components'
import { PromoImageSchema } from '@ecomm/contentful-schemas'
import React from 'react'

type HeroPromoStickerProps = {
  readonly image: PromoImageSchema
}

export function HeroPromoSticker({ image }: HeroPromoStickerProps) {
  return (
    <div
      className="absolute bottom-0 right-[10px] top-0 hidden max-w-[323px] items-center md:hidden lg:flex"
      data-component="HeroPromoSticker"
    >
      <ContentfulImage
        className="w-full object-contain"
        description={image.description}
        height={600}
        originalHeight={image.file?.details.image.height || 1}
        originalWidth={image.file?.details.image.width || 1}
        url={image.file?.url || ''}
        width={600}
      />
    </div>
  )
}
