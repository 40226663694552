import {
  useTrackingProductDecrease,
  useTrackingProductIncrease
} from '@ecomm/tracking'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/ReadonlyArray'
import * as R from 'fp-ts/lib/Record'
import { useSetAtom } from 'jotai'
import React, { useCallback } from 'react'

import {
  decrementItemQtyAtom,
  incrementItemQtyAtom
} from '../../../atoms/draftCart/itemQuantityAtom'
import { Product, useProductContext } from '../../../contexts/ProductContext'
import DraftCartPrice from '../DraftCartPrice'
import DraftCartQuantitySelector from '../DraftCartQuantitySelector'

type Props = {
  readonly sku: string
  readonly quantity: number
  readonly isAffirmMonthlyExperiment: boolean
}

function DraftCartLineListItem({
  sku,
  quantity,
  isAffirmMonthlyExperiment
}: Props) {
  const { getProduct } = useProductContext()
  const product: O.Option<Product> = O.fromNullable(getProduct(sku))
  const productName = O.toUndefined(product)?.name

  const increase = useSetAtom(incrementItemQtyAtom)
  const decrease = useSetAtom(decrementItemQtyAtom)

  const trackDecrease = useTrackingProductDecrease(productName)
  const trackIncrease = useTrackingProductIncrease(productName)

  const onDecrease = useCallback(() => {
    decrease(sku)
    trackDecrease()
  }, [sku, decrease, trackDecrease])

  const onIncrease = useCallback(() => {
    pipe(
      product,
      O.map(_product => _product.maxQuantity),
      O.map(_maxQuantity => increase(sku, _maxQuantity))
    )
    trackIncrease()
  }, [sku, increase, trackIncrease])

  const productVariant = <
    T extends {
      readonly variant:
        | readonly { readonly sku: string; readonly productColor: string }[]
        | null
    }
  >(
    _product: O.Option<T>
  ) =>
    pipe(
      _product,
      O.chain(R.lookup('variant')),
      O.chain(O.fromNullable),
      O.chain(A.findFirst(({ sku: variantSku }) => sku === variantSku))
    )

  return pipe(
    product,
    O.fold(
      () => null,
      _product => (
        <li className="my-2 flex justify-between" key={_product.sku}>
          <span className="flex items-center" data-component="product-variant">
            {_product.maxQuantity > 1 && (
              <DraftCartQuantitySelector
                maxQuantity={_product.maxQuantity}
                onDecrease={onDecrease}
                onIncrease={onIncrease}
                quantity={quantity}
              />
            )}
            {_product.maxQuantity === 1 && (
              <input
                checked={!!quantity}
                className="mx-8 h-5 w-5 cursor-pointer pr-3"
                onChange={onDecrease}
                type="checkbox"
              />
            )}{' '}
            {_product.name}
            {_product
              ? pipe(
                  productVariant(product),
                  O.fold(
                    () => null,
                    variant => ` - ${variant?.productColor}`
                  )
                )
              : null}
          </span>
          <span className="border-neutral-light-100 mx-3 mb-2 w-full flex-1 border-b border-l-0 border-r-0 border-t-0 border-solid" />
          <DraftCartPrice
            isAffirmMonthlyExperiment={isAffirmMonthlyExperiment}
            quantity={quantity}
            sku={sku}
          />
        </li>
      )
    )
  )
}

export default DraftCartLineListItem
