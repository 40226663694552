import React from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulButton } from '../../../graphql'
import ButtonComponent from '../ButtonComponent'
import Container from './Container'
import { HeroBannerFinancingSchema } from './schema'

export type Props = {
  readonly data: HeroBannerFinancingSchema & {
    readonly button1?: ContentfulButton
    readonly button2?: ContentfulButton
  }
}

export default function HeroBannerFinancingComponent({ data }: Props) {
  const {
    background,
    backgroundTablet,
    backgroundMobile,
    logo,
    heading,
    content,
    button1,
    button2
  } = data

  const url1 = button1?.url ?? null
  const url2 = button2?.url ?? null

  const { trackEvent } = useTracking()
  const trackOnClick = buttonName => () =>
    trackEvent({
      action: `${buttonName}_affirm_hero_click`,
      category: 'affirm_finance',
      event: `${buttonName}_affirm_hero_click`,
      label: ''
    })

  return (
    <Container
      background={background}
      backgroundMobile={backgroundMobile}
      backgroundTablet={backgroundTablet}
    >
      {logo?.file?.url && (
        <img alt="" className="w-24 lg:w-36" src={logo.file.url} />
      )}
      {heading && (
        <h2 className="h2 mt-4 text-4xl text-inherit lg:mt-8 lg:text-7xl">
          {heading}
        </h2>
      )}
      {content?.content && (
        <p className="max-w-xs text-inherit md:max-w-sm lg:max-w-xl">
          {content.content}
        </p>
      )}
      {button1 && (
        <a
          className="affirm-site-modal"
          href={url1}
          onClick={trackOnClick('button1')}
        >
          <ButtonComponent className="mr-md w-40 px-0 lg:w-60" data={button1} />
        </a>
      )}
      {button2 && (
        <a href={url2} onClick={trackOnClick('button2')}>
          <ButtonComponent
            className="hover:text-neutral-black w-[120px] text-white lg:w-[123px]"
            data={{ ...button2, url: null }}
          />
        </a>
      )}
    </Container>
  )
}
