import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { PriceProvider } from '@ecomm/data-price'
import { Header as HeaderRedesign } from '@ecomm/header-redesigned'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  ContentfulRichText,
  Footer,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { Locale, SEO } from '@ecomm/utils'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { match } from 'ts-pattern'

import CitiesGrid from '../components/CitiesGrid'
import CrimeLocationBanner from '../components/CrimeLocationBanner'
import CrimeTable from '../components/CrimeTable'
import PackageCarousel from '../components/PackageCarousel'
import { QuoteWizardEmbedded } from '../components/QuoteWizardEmbedded'
import {
  LocationCrimesTableSchema,
  SeoNodeSchema
} from '../config/crimeInTheUKResponseSchema'
import { useHeaderRedesignQuery } from '../HeaderRedesign/useHeaderRedesignQuery'
import { useCrimePageFragment } from '../hooks/useCrimePageFragment'
import type { CitiesGridSchema } from '../schemas/citiesGrid'
import type { CrimeBannerSchema } from '../schemas/crimeBanner'
import { LayoutReferenceSchema } from '../schemas/layoutReference'
import type { LinkSchema } from '../schemas/link'
import type { SyntheticPackageSchema } from '../schemas/package'
import type { SmallTextSchema } from '../schemas/smallText'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
  readonly locationCrimesTable: LocationCrimesTableSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

function getCrimeTableOptions(
  body: readonly (readonly string[])[],
  head: readonly string[]
): Options {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: _ => {
        return <CrimeTable body={body} head={head} />
      }
    }
  }
}

export default function CrimePageTemplate<
  T extends { readonly crimeInTheUkPage: U },
  U
>({
  data,
  pageContext: { locale, seoDetails, locationCrimesTable }
}: Props<T>) {
  const {
    canonicalLink,
    isNofollow,
    isNoindex,
    metaDescription,
    metaKeywords,
    metaTitle
  } = seoDetails
  const { layout } = useCrimePageFragment(data)
  const { components, footer } = layout
  const headerData = useHeaderRedesignQuery()

  return (
    <TrackingProvider metaTitle={''}>
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <PageToaster />
      <ApplyPromoCode />
      <PromoBannerWrapper type="standard" />
      <HeaderRedesign {...headerData} />
      <div className="prose relative z-0 w-full whitespace-pre-line md:prose-md lg:prose-lg">
        <div className="mx-auto mb-16 flex max-w-8xl flex-col gap-16 px-4 pt-8 md:px-8">
          <TrustpilotUKTopBanner className="-mb-8" />
          {components.map((component, index) =>
            match(component)
              .with(
                {
                  __typename: 'ContentfulCrimeLocationBannerV2'
                },
                (data: CrimeBannerSchema) => <CrimeLocationBanner {...data} />
              )
              .with(
                { __typename: 'ContentfulSmallText' },
                (data: SmallTextSchema) => (
                  <div className="text-center" key={index}>
                    <ContentfulRichText
                      optionsCustom={getCrimeTableOptions(
                        locationCrimesTable.body,
                        locationCrimesTable.head
                      )}
                      raw={data.text.raw}
                    />
                  </div>
                )
              )
              .with(
                { __typename: 'ContentfulLayoutReference' },
                (_: LayoutReferenceSchema) => <QuoteWizardEmbedded />
              )
              .with(
                { __typename: 'SyntheticPackages' },
                (data: SyntheticPackageSchema) => (
                  <PriceProvider
                    locale={locale}
                    skus={data.packages.map(p => p.product)}
                  >
                    <PackageCarousel {...data} />
                  </PriceProvider>
                )
              )
              .with(
                { __typename: 'ContentfulGrid' },
                (data: CitiesGridSchema) => <CitiesGrid {...data} />
              )
              .with({ __typename: 'ContentfulLink' }, (data: LinkSchema) => (
                <a
                  className="order-last mx-auto w-full btn btn-solid-primary md:w-48"
                  href={data.url}
                >
                  {data.linkText}
                </a>
              ))
              .otherwise(_ => null)
          )}
        </div>
      </div>
      <Footer data={footer} type="Full" />
    </TrackingProvider>
  )
}

export const query = graphql`
  query CrimeinTheUKLanding($id: String) {
    crimeInTheUkPage: contentfulGenericLandingPage(id: { eq: $id }) {
      ...crimeInTheUkFragment
    }
  }
`
