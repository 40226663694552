import { OptimizelyConfig } from '@optimizely/optimizely-sdk'
import { pipe } from 'fp-ts/lib/function'
import * as R from 'fp-ts/lib/Record'

/**
 * Parses an experiment map into an object with experiment IDs for keys and an array of all variations for the value.
 */
export const parseExperiments = (
  experimentsMap: OptimizelyConfig['experimentsMap']
) =>
  pipe(
    experimentsMap,
    R.map(({ variationsMap }) => R.keys(variationsMap))
  )
