import { GatsbyImage } from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import { CitiesGridSchema } from '../schemas/citiesGrid'

export default function CitiesGrid({ gridItems }: CitiesGridSchema) {
  return (
    <ul className="m-0 grid list-none grid-cols-2 gap-1.5 border-x-0 border-b-0 p-0 px-0 pb-0 md:grid-cols-5 lg:-mt-8 lg:border-t lg:border-solid lg:border-neutral-medium lg:pt-10">
      {gridItems.map(({ text, icon, url }, i) => (
        <li
          className="list-none overflow-hidden rounded-lg"
          key={`city-item${i}`}
        >
          <a className="group relative cursor-pointer no-underline" href={url}>
            {icon ? (
              <GatsbyImage className="h-40 lg:h-64" image={icon} />
            ) : null}
            <div
              className={classNames(
                'absolute bottom-0 flex h-12 w-full items-center justify-center text-lg font-medium',
                'bg-neutral-medium bg-gradient-to-l from-neutral-medium from-50% to-primary-100 to-50% bg-[length:200%] bg-right transition-all duration-300 ease-in-out',
                'group-hover:bg-left'
              )}
            >
              {text}
            </div>
          </a>
        </li>
      ))}
    </ul>
  )
}
