//@ts-nocheck
import { MaybeT } from '@simplisafe/ewok'
import classNames from 'classnames'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import prop from 'ramda/src/prop'
import React, { FC, Fragment, ReactNode } from 'react'
import { Helmet } from 'react-helmet'

import BGImage from '../BGImage'
import { BackgroundComponentType } from '../common'
import { ChevronRightPrimary, ChevronRightWhite } from '../icons'
import HeroSlideDescription from './HeroSlideDescription'

export type IContent = {
  readonly title: string
  /** The content of the component */
  readonly content?: ReactNode
}

export type AdditionalContent = {
  /** The content of the component */
  readonly content?: ReactNode
}

export type IDisplayMode =
  | 'bottom-left'
  | 'bottom-right'
  | 'bottom'
  | 'center'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'top'

export type PreloadLink = {
  readonly as: 'image'
  readonly href: string
  readonly id: string
  readonly rel: 'preload'
}

export type HeroSlideProps = {
  /** A Function Component that accepts any props and will replace the containing div as a background image */
  readonly BackgroundComponent?: BackgroundComponentType
  /** Props that are passed to the BackgroundComponent */
  readonly backgroundComponentProps?: Record<string, unknown>
  readonly content: IContent
  readonly displayMode?: IDisplayMode
  /** @deprecated */
  readonly srcImage?: string // TODO csims - clean up once frontend is no longer using this
  readonly handleClick?: (url: string) => unknown
  readonly id?: string
  readonly linkText?: string
  readonly linkUrl?: string
  readonly mediaType?: 'image' | 'video'
  readonly additionalContent?: AdditionalContent
  readonly textColor?: 'dark' | 'light'
  readonly paginationPosition?: string
  readonly onClick?: (linkItem: LinkItem) => void
  readonly children?: ReactNode
  readonly childrenPosition?: 'center' | 'left' | 'right'
  readonly backgroundColor?: 'gray' | 'neutralblack'
  readonly preload: MaybeT<PreloadLink>
  readonly className?: string
  readonly dataComponent?: string
}

export type LinkItem = {
  readonly id?: string
  readonly name?: string
  readonly subText?: string
  readonly url?: string
}

const renderListItems = (listItem: AdditionalContent) => (
  <div className="prose-h3:text-base prose-h3:font-medium prose-h3:m-0 prose-p:text-neutral-black prose-p:mt-0 prose-p:mx-0 prose-p:mb-2.5 relative min-h-[350px] w-full max-w-[240px] md:max-w-[51%] lg:mx-0 lg:my-auto lg:w-[300px]">
    {listItem.content}
  </div>
)

/** @deprecated Do not use ss-react-components*/
const HeroSlide: FC<HeroSlideProps> = ({
  BackgroundComponent,
  backgroundComponentProps = {},
  content,
  displayMode,
  srcImage,
  linkUrl,
  linkText,
  mediaType,
  additionalContent,
  textColor,
  paginationPosition,
  onClick,
  children,
  childrenPosition,
  backgroundColor,
  preload,
  className,
  dataComponent = HeroSlide.name
}: HeroSlideProps) => {
  const mode = displayMode !== undefined ? displayMode : 'left'
  const slideType = mediaType === 'image' ? 'imageSlide' : 'slide'
  const hasAdditionalContent =
    !isNil(additionalContent) && !isEmpty(additionalContent)
  const classes = classNames(
    //For both imageSlide and slide
    'flex min-h-[460px] relative w-full flex-col md:flex-row h-full max-md:justify-between md:min-h-[600px] prose md:prose-md lg:prose-lg',
    {
      'p-8 md:p-16 lg:p-32': slideType === 'slide',
      'flex-nowrap md:flex-wrap pt-8 px-8 pb-28 md:p-8 lg:px-16 ':
        slideType === 'imageSlide',
      '!bg-neutral-black': backgroundColor === 'neutralblack',
      'bg-neutral-light-50':
        backgroundColor === 'gray' || mediaType === 'image',
      'md:!bg-[0px]': paginationPosition === 'vertical',
      'max-md:block': hasAdditionalContent
    },
    className
  )
  const { title } = content
  const showicon = !isEmpty(linkText) && !isEmpty(linkUrl)
  const linkItem = {
    id: linkText,
    name: linkText,
    url: linkUrl
  }

  const getChevron = ifElse(
    equals('light'),
    () => <ChevronRightWhite className="ml-4 align-text-top" />,
    () => <ChevronRightPrimary className="ml-4 align-text-top" />
  )

  const childComponent = (
    <Fragment>
      <div
        className={classNames('md:min-h-[auto]', {
          'relative w-full md:w-1/2': slideType === 'imageSlide',
          'lg:w-[416px]': slideType === 'imageSlide' && mode === 'left',
          'prose-h2:!text-white prose-p:!text-white prose-a:!text-white text-white':
            textColor === 'light',
          'my-auto ml-0 mr-auto': mode === 'left',
          'm-auto': mode === 'center',
          'my-auto ml-auto mr-0': mode === 'right',
          'mx-auto mb-auto mt-0': mode === 'top',
          'mb-auto ml-0 mr-auto mt-0': mode === 'top-left',
          'mb-auto ml-auto mr-0 mt-0': mode === 'top-right',
          'mx-auto mb-0 mt-auto': mode === 'bottom',
          'mb-0 ml-0 mr-auto mt-auto': mode === 'bottom-left',
          'mb-0 ml-auto mr-0 mt-auto': mode === 'bottom-right'
        })}
      >
        <h2
          className={classNames('mb-6', {
            'md:text-white': textColor === 'light'
          })}
        >
          {title}
        </h2>
        <HeroSlideDescription content={content} />
        {linkUrl ? (
          <a
            className="text-primary-100 relative h-[25px] w-32 cursor-pointer pr-1 text-lg font-medium capitalize no-underline"
            href={linkUrl}
            onClick={() => onClick && onClick(linkItem)}
          >
            <span
              className={classNames(
                'border-primary-100 hover:border-primary-50 border-b-2 border-solid',
                { 'text-white hover:border-white': textColor === 'light' }
              )}
            >
              {linkText}
            </span>
            {showicon ? getChevron(textColor ?? '') : null}
          </a>
        ) : null}
      </div>
      {hasAdditionalContent && additionalContent
        ? renderListItems(additionalContent)
        : null}
      {!isNil(children) && (
        <div
          className={classNames({
            'p-2.5 max-md:w-full': childrenPosition === 'center',
            'bottom-0 w-full md:absolute md:bottom-auto md:left-[52%] md:right-[5%] md:top-1/2 md:w-auto md:-translate-y-1/2':
              childrenPosition === 'right'
          })}
        >
          {children}
        </div>
      )}
    </Fragment>
  )

  const preloadHelmet = preload.map(preloadLink => (
    <Helmet key={prop('id', preloadLink)}>
      <link {...preloadLink} />
    </Helmet>
  ))

  return BackgroundComponent ? (
    <BackgroundComponent
      {...backgroundComponentProps}
      className={classes}
      data-component={dataComponent}
    >
      {childComponent}
      {preloadHelmet}
    </BackgroundComponent>
  ) : srcImage ? (
    // TODO csims - clean up once frontend is no longer using this
    <BGImage
      className={classes}
      dataComponent={dataComponent}
      srcImage={srcImage}
    >
      {childComponent}
      {preloadHelmet}
    </BGImage>
  ) : (
    <div className={classes} data-component={dataComponent}>
      {childComponent}
      {preloadHelmet}
    </div>
  )
}

export default HeroSlide
