import type { Address } from '@commercetools/platform-sdk'
import {
  AddressValidationsSchema,
  UserCheckoutSchema
} from '@ecomm/checkout/shipping-schema'
import { MaybeT } from '@simplisafe/ewok'
import { CheckoutFormValues } from '@simplisafe/ss-ecomm-data/cart'
import type {
  AddressValidation,
  Correction
} from '@simplisafe/ss-ecomm-data/checkout/checkout'
import * as F from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { mergeDeep } from 'immutable'

const defaultPaymentPage = '/payment-page'
export const validatedAddressId = 'validated_address'

/**
 * Determines if the API found a correction of the address entered
 */
export const hasItFoundAddressSuggestion = (
  addressValidation: AddressValidation
) =>
  addressValidation.foundValidAddress && !!addressValidation.suggestions?.length

/**
 * Determines if the user locale is the proper to render the modal
 */
export const isCorrectShippingAddressLocale = (locale: string): boolean =>
  locale === 'en-US'

/**
 * Determines if the shipping address validation modal should render
 *
 * The modal should appear if:
 *
 *   1. The user is in the US.
 *   2. The API found a correction in the entered address.
 *   3. Corrections array is not empty
 *   4. Corrections array does not have an invalid correction (only FixedAbbreviations for now)
 */
export const shouldRenderShippingAddressModal =
  (addressValidation: AddressValidation) => (locale: string) =>
    isCorrectShippingAddressLocale(locale) &&
    addressValidation &&
    hasItFoundAddressSuggestion(addressValidation) &&
    hasItFoundAddressCorrections(addressValidation) &&
    !hasItFoundInvalidAddressCorrections(addressValidation)

/**
 * Determines if the we need to silently update the user address -> without opening the modal
 *
 * Should be true if:
 *
 *   1. The user is in the US.
 *   2. The API found a correction in the entered address.
 *   3. Corrections array is empty or only contains an invalid correction (only FixedAbbreviations for now)
 */
export const shouldSilentlyUpdate =
  (addressValidation: AddressValidation) => (locale: string) =>
    isCorrectShippingAddressLocale(locale) &&
    addressValidation &&
    hasItFoundAddressSuggestion(addressValidation) &&
    (!hasItFoundAddressCorrections(addressValidation) ||
      hasItFoundInvalidAddressCorrections(addressValidation))

/**
 * Get the redirect url from Contentful
 */
export const getCheckoutPaymentUrl = () => defaultPaymentPage

/**
 * Format postalCode with plus4Code if exists
 */
export const formatZipCode = (
  postalCode: string | undefined,
  plus4Code?: string
) => (plus4Code ? `${postalCode}-${plus4Code}` : postalCode)

/**
 * Map an AddressValidation object to convert in in a Address one
 */
type BaseAddress = Partial<AddressValidationsSchema> &
  Partial<CheckoutFormValues> &
  Partial<UserCheckoutSchema>
export const getFormattedUserAddress = <T extends BaseAddress>(
  values: T,
  locale: string
): Address => ({
  firstName: values.firstName,
  lastName: values.lastName,
  email: values?.email,
  phone: values?.phone,
  country: locale === 'en-US' ? 'US' : 'GB',
  city: values.city,
  state: locale === 'en-US' ? values.state : undefined,
  streetName: values.streetName || values.street,
  postalCode: formatZipCode(
    values.postalCode || values?.zipcode,
    values?.plus4Code
  ),
  additionalStreetInfo: values?.additionalStreetInfo || ''
})

const getFormattedBillingAddress = (
  address: Address,
  setBillingAddress: boolean
) => ({
  ...address,
  city: setBillingAddress ? address.city : '',
  state: setBillingAddress ? address.state : '',
  streetName: setBillingAddress ? address.streetName : '',
  postalCode: setBillingAddress ? address.postalCode : '',
  additionalStreetInfo: setBillingAddress ? address.additionalStreetInfo : ''
})

type CheckoutBody = {
  readonly billingAddress: Address
  readonly lead?: string
  readonly leadOther?: string
  readonly setBillingAddress: boolean
  readonly shippingAddress: Address
  readonly shippingMethod: string
  readonly shouldValidate: boolean
}
export const getCheckoutBody = (body: CheckoutBody) => ({
  billing: {
    address: getFormattedBillingAddress(
      body.billingAddress,
      body.setBillingAddress
    ),
    setBillingAddress: true
  },
  metaData: {
    leadOther: body.leadOther || '',
    leadSource: body.lead || ''
  },
  shipping: {
    address: body.shippingAddress,
    method: body.shippingMethod,
    validateAddress: body.shouldValidate
  }
})

/**
 * Get the first suggestion address. We receive an array of suggestions (if exists), but for now we have only one result.
 *
 * If the array is empty we return an object to make the spread operation.
 */
export const getFirstSuggestionAddress = (
  addressValidation: AddressValidation
) =>
  F.pipe(
    O.fromNullable(addressValidation.suggestions[0]),
    O.fold(
      () => null,
      suggestion => suggestion.address
    )
  )

/**
 * Determines if it has corrections to show up the modal
 */
export const hasItFoundAddressCorrections = (
  addressValidation: AddressValidation
) =>
  F.pipe(
    O.fromNullable(addressValidation.suggestions[0]),
    O.fold(
      () => false,
      suggestion => !!suggestion.corrections?.length
    )
  )

/**
 * Determines if it has invalid corrections to show the modal (otherwise don't)
 * So for now should take FixedAbbreviations as the only invalid correction
 */
const checkInvalidCorrections = (corrections: readonly Correction[]) =>
  F.pipe(
    corrections,
    O.fromPredicate(corrections => !!corrections.length),
    // @ts-expect-error
    O.map(corrections =>
      corrections.every(c => c === 'FixedAbbreviations')
        ? O.none
        : O.some(corrections)
    ),
    O.getOrElse(() => O.some([]))
  )

export const hasItFoundInvalidAddressCorrections = (
  addressValidation: AddressValidation | null | undefined
) =>
  F.pipe(
    O.fromNullable(addressValidation?.suggestions?.[0]),
    O.fold(
      () => false,
      suggestion =>
        O.isNone(checkInvalidCorrections(suggestion.corrections || []))
    )
  )

/**
 * Check is an address object is empty
 */
export const isAddressEmpty = (obj?: Address): boolean =>
  F.pipe(
    O.fromNullable(obj),
    O.chainNullableK(Object.keys),
    O.chain(keys => (keys.length > 0 ? O.some(keys) : O.none)),
    O.isNone
  )

/**
 * Build user personal data object
 */
export const getPersonalUserDataFromCheckout = (
  values?: Address
): UserCheckoutSchema => ({
  firstName: values?.firstName,
  lastName: values?.lastName,
  email: values?.email,
  phone: values?.phone
})

/**
 * Combine user personal data to the shipping address
 */
export const combineUserDataAndAddress =
  (userData: UserCheckoutSchema) =>
  (shippingAddress: AddressValidationsSchema) =>
  (locale: 'en-GB' | 'en-US') => {
    const selectedData = mergeDeep(userData, shippingAddress)
    return getFormattedUserAddress(selectedData, locale)
  }

/**
 * Format user address to a string
 */
export const formatAddressEntered = (
  shippingAddress: MaybeT<Address>
): string =>
  shippingAddress.cata(
    () => '',
    a =>
      `${a.firstName} ${a.lastName}, ${a.streetNumber} ${a.streetName}${
        a.additionalStreetInfo && ', ' + a.additionalStreetInfo
      }, ${a?.city}, ${a?.state} ${a?.postalCode}`.replace(/undefined/g, '')
  )
