import { odmonPreLaunchRenameLineItem } from '@ecomm/shared-hooks'
import { useOdmonExperience } from '@ecomm/shared-ninetailed'

import { OrderSummaryContent } from '../..'
import type {
  CartLineItemProps,
  PriceCalculationFieldProps
} from '../../content/types'

type Props = {
  readonly itemList: readonly CartLineItemProps[] | null
  readonly priceCalculationFields: readonly PriceCalculationFieldProps[]
}

export function OdmonPreLaunchOrderSummaryContent({
  itemList,
  priceCalculationFields
}: Props) {
  const odmonExperienceQuery = useOdmonExperience()

  return (
    <OrderSummaryContent
      itemList={
        itemList && odmonExperienceQuery.isVariant
          ? itemList.map(odmonPreLaunchRenameLineItem)
          : itemList
      }
      priceCalculationFields={priceCalculationFields}
    />
  )
}
