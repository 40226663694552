import { footerSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const layoutSchema = z.object({
  __typename: z.literal('ContentfulLayout'),
  contentful_id: z.string(),
  id: z.string(),
  internalTitle: z.string(),
  footer: footerSchema.optional(),
  promoBannerStyle: z.string(),
  ready: z.boolean().default(true)
})

export type LayoutFragment = TypeOf<typeof layoutSchema>
