import { trackLeadSubmit } from '@ecomm/cdp-tracking'
import type { LeadGenResponseProps } from '@ecomm/data-leads'
import { updateLeadData } from '@ecomm/shared-cookies'

import type { TrackEvent } from '../analytics'
import { handleBrazeTrackingEvent, updateBrazeLeadCustomData } from '../braze'
import { fbTrackLeadCreated } from '../facebook'
import type { OptimizelyEvent } from '../optimizely'
import {
  nonBounceSessionWasNotTracked,
  trackNonBounceVisit
} from '../trackNonBounceVisit'

/**
 *
 * Calls lead capture tracking events (braze, optimizely, GTM, facebook CAPI)
 * and sets a cookie with leads meta data ('leadData')
 */

export async function trackLeadCapture(
  leadGenResponse: LeadGenResponseProps,
  trackEvent: TrackEvent,
  optimizelyTrackSiteEvents: (data: OptimizelyEvent) => void,
  channel = ''
) {
  const firstSubmission = leadGenResponse.message?.includes('created')
  updateLeadData(leadGenResponse)
  nonBounceSessionWasNotTracked() === true && trackNonBounceVisit(trackEvent)
  await handleBrazeTrackingEvent(leadGenResponse, channel, firstSubmission)
  updateBrazeLeadCustomData()
  trackEvent({ event: 'submit' })
  trackEvent({ event: 'submitLead' })
  trackLeadSubmit(leadGenResponse.leadSource || '')
  optimizelyTrackSiteEvents({
    eventType: 'lead_captured_fs'
  })
  leadGenResponse.email && (await fbTrackLeadCreated(leadGenResponse.email))
  console.info(
    `In function trackLeadCapture called with ${JSON.stringify({
      leadId: leadGenResponse.leadId,
      externalId: leadGenResponse.externalId
    })}`
  )
  console.info('In function trackLeadCapture called handleBrazeTrackingEvent')
  console.info('In function trackLeadCapture called updateBrazeLeadCustomData')
}
