import { createMockGatsbyImage } from '@ecomm/contentful-schemas'
import { createMockRichText } from '@ecomm/utils'

import {
  FooterFragment,
  FooterMenusFragment,
  LeadGenFormFragment,
  LegalLinksFragment,
  SocialLinksFragment
} from './schema'

export const leadGenFormMock = async (): Promise<LeadGenFormFragment> => ({
  button: { text: 'Submit' },
  form: [
    {
      placeholder: 'Enter email',
      title: 'Email',
      type: 'Email'
    }
  ],
  note: {
    raw: await createMockRichText(
      'You may receive email offers from us in accordance with our privacy policy.'
    )
  },
  title: 'email'
})

export const copyrightTextMock = '© 2022 SimpliSafe, Inc.'

export const menusMock: FooterMenusFragment = [
  {
    links: [
      {
        text: 'Security Systems',
        url: '/home-security-shop'
      },
      {
        text: 'Outdoor Security Camera',
        url: '/outdoor-security-camera'
      },
      {
        text: 'Indoor Camera',
        url: '/simplicam-security-camera'
      },
      {
        text: 'Smart Lock',
        url: '/smart-lock'
      },
      {
        text: 'Video Doorbell Pro',
        url: '/video-doorbell-pro'
      },
      {
        text: 'Add to Your System',
        url: '/alarm-sensors'
      }
    ],
    title: 'Shop',
    titleUrl: '/home-security-shop'
  },
  {
    links: [
      {
        text: 'Careers',
        url: 'https://careers.simplisafe.com/'
      },
      {
        text: 'Press Kit',
        url: 'https://press.simplisafe.com/'
      },
      {
        text: 'Partner with Us',
        url: 'https://simplisafe.com/partner-with-us'
      }
    ],
    title: 'Company',
    titleUrl: ''
  },
  {
    links: [
      {
        text: 'Contact Us',
        url: 'https://simplisafe.com/contact-us'
      },
      {
        text: 'Help Center',
        url: 'https://support.simplisafe.com/hc/en-us'
      },
      {
        text: '888-910-1458',
        url: null
      }
    ],
    title: 'Support',
    titleUrl: ''
  }
]

export const menuLinkMock: FooterMenusFragment = [
  {
    links: [
      {
        text: 'Security Systems',
        url: 'https://simplisafe.com/home-security-shop'
      }
    ],
    title: 'Shop',
    titleUrl: null
  }
]

export const socialLinksMock: SocialLinksFragment = [
  {
    icon: createMockGatsbyImage(
      '//images.ctfassets.net/v6awd0kabi65/6mQNXJicH3fux0NFaL7IE9/a0a621d29b4c18c270db600e13f43464/footer_twitter-logo.png?w=800&q=50'
    ),
    text: 'Twitter',
    url: 'https://twitter.com/simplisafe'
  },
  {
    icon: createMockGatsbyImage(
      '//images.ctfassets.net/v6awd0kabi65/5EIPbWqI2Us8REZdGWfiF1/7dc7e3b359421bf112efa2cfa3ada3d5/footer_facebook-logo.png?w=800&q=50'
    ),
    text: 'Facebook',
    url: 'https://www.facebook.com/SimpliSafe'
  },
  {
    icon: createMockGatsbyImage(
      '//images.ctfassets.net/v6awd0kabi65/4IXB3UVo3SrhoRFXXhm4VY/c44e9d1aba0144ab3166490bfd7a000e/footer_instagram-logo.png?w=800&q=50'
    ),
    text: 'Instagram',
    url: 'https://www.instagram.com/simplisafe_uk/'
  }
]

export const legalLinksMock: LegalLinksFragment = [
  {
    text: 'Accessibility Statement',
    url: 'https://simplisafe.com/accessibility_statement'
  },
  {
    text: 'Legal',
    url: 'https://simplisafe.com/legal'
  },
  {
    text: 'Privacy',
    url: 'https://simplisafe.com/privacy',
    trackingEvent: 'link-click-privacy-footer'
  },
  {
    text: 'Blog',
    url: 'https://simplisafe.com/blog'
  }
]

export const response = async (): Promise<FooterFragment> => ({
  copyrightText: 'Control footer',
  leadGenForm: await leadGenFormMock(),
  legalLinks: legalLinksMock,
  menus: menusMock,
  socialLinks: socialLinksMock
})

export default response
