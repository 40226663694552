import { useLocale } from '@ecomm/data-hooks'
import {
  GatsbyImage,
  GatsbyImageSchema,
  GuidedSystemBuilder,
  GuidedSystemBuilderSchema
} from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import React from 'react'

export type QuizBannerProps = {
  readonly quoteWizard: GuidedSystemBuilderSchema
  readonly image: GatsbyImageSchema
}

export function QuizBanner({ quoteWizard, image }: QuizBannerProps) {
  const locale = useLocale()
  return (
    <div
      className="relative flex gap-10 overflow-hidden rounded-[20px] bg-[#9AC3DF] p-2 md:px-12 md:py-[34px] md:pb-0"
      data-component="QuizBanner"
    >
      <div className="absolute bottom-0 left-0 z-0 w-1/2">
        <GatsbyImage className="hidden w-full md:block" image={image} />
      </div>

      <div className="hidden md:block md:flex-1">
        <h2 className="mb-4 mt-4 text-5xl font-bold text-[#0F2648] lg:text-[56px] lg:leading-[60px]">
          Need help choosing?
        </h2>
        <p className="text-lg text-[#0F2648]">
          We know everyone’s security needs are different. Answer a few
          questions and we’ll recommend a system that suits you.
        </p>
      </div>

      <div className="w-full overflow-hidden rounded-[20px] md:mb-[34px] md:flex-1">
        {locale === 'en-US' ? (
          <Experience
            {...quoteWizard}
            component={GuidedSystemBuilder}
            experiences={mapExperiences(quoteWizard.nt_experiences)}
            key={quoteWizard.id}
          />
        ) : (
          <GuidedSystemBuilder {...quoteWizard} />
        )}
      </div>
    </div>
  )
}
