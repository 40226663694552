//@ts-nocheck
import React, { FC, ReactNode } from 'react'

import * as css from './PartnerTemplate.module.scss'

export type PartnerTemplateProps = {
  /** The main content area for the Partner page. Expected to be a combination of RichText and PhoneCTA. */
  readonly content: ReactNode
  /** The data-component attribute value for identifying this component when it renders. */
  readonly dataComponent?: string
  /** Displays on the side. Intended to display an image gallery of the package and included items. */
  readonly details: ReactNode
  /** Displays at the bottom of the component for any legal copy. */
  readonly disclaimer?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const PartnerTemplate: FC<PartnerTemplateProps> = ({
  content,
  dataComponent = PartnerTemplate.name,
  details,
  disclaimer
}: PartnerTemplateProps) => {
  return (
    <div className={css.template} data-component={dataComponent}>
      <div className={css.content}>{content}</div>
      <div className={css.details}>{details}</div>
      {disclaimer ? <div className={css.disclaimer}>{disclaimer}</div> : null}
    </div>
  )
}

export default PartnerTemplate
