import { document } from 'browser-monads-ts'
import { flow, pipe } from 'fp-ts/lib/function'
import * as IO from 'fp-ts/lib/IO'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/ReadonlyNonEmptyArray'
import * as S from 'fp-ts/lib/string'
import * as R from 'fp-ts/ReadonlyRecord'

/**
 * Parses the document cookie into an object.
 */
export const parseCookie: (s: string) => Record<string, string> = flow(
  S.split(';'),
  A.map(S.split('=')),
  A.reduce({}, (acc, item) =>
    pipe(
      O.Do,
      O.bind('t', () => O.fromNullable(item)),
      O.bind(
        'key',
        flow(({ t }) => O.fromNullable(t[0]), O.map(S.trim))
      ),
      O.bind(
        'val',
        flow(({ t }) => O.fromNullable(t[1]), O.map(S.trim))
      ),
      O.map(({ key, val }) => ({ ...acc, [key]: val })),
      O.getOrElse(() => acc)
    )
  )
)

/**
 *  Any of the following cookie attribute values can optionally follow the key-value pair, each preceded by a semicolon separator.
 */
export type CookieOptions = {
  readonly 'max-age'?: string
  readonly domain?: string
  readonly expires?: string
  readonly partitioned?: string
  readonly path?: string
  readonly samesite?: 'lax' | 'none' | 'strict'
  readonly secure?: boolean
}

const lookup = <T extends Readonly<Record<string, unknown>>>(
  key: string,
  obj: T
) => pipe(obj, R.lookup(key), O.chain(O.fromNullable))

/**
 * converts an object of key/value pairs into a string of cookie options.
 */
export const parseOptions = (options?: CookieOptions): string =>
  pipe(
    O.fromNullable(options),
    O.map(plainObj),
    O.map(Object.keys),
    O.bindTo('keys'),
    O.bind('obj', () => O.fromNullable(options)),
    O.map(({ keys, obj }) =>
      pipe(
        keys,
        t => t.filter(key => key !== 'secure'), // secure doesn't get a value like `secure=true`, it's just `; Secure`
        t =>
          t.map(key =>
            pipe(
              lookup(key, obj),
              O.map(val => `${key}=${val};`),
              O.getOrElse(() => '')
            )
          ),
        t => t.join('')
      )
    ),
    O.getOrElse(() => '')
  )

/**
 * Formats a key and value into a formatted cookie string.
 */
export const formatCookie =
  (key: string, options?: CookieOptions) =>
  (value: string): string => {
    return `${key}=${value};${parseOptions(options)}${
      options?.secure ? ' Secure' : ''
    }`
  }

const plainObj = (t: CookieOptions): Record<string, boolean | string> => t

/**
 * Sets a cookie value.
 */
export const setCookie = (cookie: string) => IO.of((document.cookie = cookie))
