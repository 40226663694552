import classNames from 'classnames'
import React, { ReactNode } from 'react'

export type HoverBorderProps = {
  readonly dataComponent?: string
  readonly children: readonly ReactNode[]
  readonly isSelected: boolean
}

/** @deprecated Do not use ss-react-components*/
const HoverBorder = ({
  dataComponent,
  children,
  isSelected
}: HoverBorderProps) => (
  <div
    className={classNames('rounded-base cursor-pointer border-2 border-solid', {
      'border-complementary-blue-100': isSelected,
      'border-transparent': !isSelected
    })}
    data-component={dataComponent}
  >
    {children}
  </div>
)

export default HoverBorder
