import {
  ctShippingMethodSchema,
  transformShippingMethod
} from '@simplisafe/eis-commercetools-shipping-methods'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'
import { z } from 'zod'

import { fetchApi } from './lib/fetchApi'

const responseSchema = z.object({
  results: z.array(ctShippingMethodSchema)
})

export const getShippingMethods = (
  cartId: string,
  shippingRateInput: number
) => {
  return pipe(
    fetchApi({
      endpoint: `/carts/v2/shipping-methods/matching-cart?cartId=${cartId}&expand=zoneRates[*].zone.id`,
      method: 'GET',
      headers: {}
    }),
    TE.chain(res => {
      const parsed = responseSchema.safeParse(res)
      return parsed.success
        ? TE.right(parsed.data.results)
        : TE.left(
            Error(
              `[commercetools]: shipping-method response is missing results: ${res}`
            )
          )
    }),
    TE.map(results =>
      results
        .map(r => transformShippingMethod(r, shippingRateInput))
        .sort((a, b) => a.price - b.price)
    )
  )
}
