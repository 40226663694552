import { FormField } from '@ecomm/checkout/components'
import { getReturningCustomerAddressByDeviceId } from '@ecomm/checkout/utils'
import { useTrackingCheckoutLoginClick } from '@ecomm/tracking'
import { MaybeT } from '@simplisafe/ewok'
import { UserCheckoutData } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { Dispatch, SetStateAction } from 'react'

export type LoginLinkProps = {
  readonly fetchUserCheckoutDataFailure: (e: Error) => void
  readonly fetchUserCheckoutDataSuccess: (
    userCheckoutData: MaybeT<UserCheckoutData>
  ) => void
  readonly loginSuccess: boolean
  readonly setIsFormLoading: Dispatch<SetStateAction<boolean>>
}

export function LoginLink({
  fetchUserCheckoutDataFailure,
  fetchUserCheckoutDataSuccess,
  loginSuccess,
  setIsFormLoading
}: LoginLinkProps) {
  const trackLoginClick = useTrackingCheckoutLoginClick()

  const onClickLogin = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setIsFormLoading(true)
    getReturningCustomerAddressByDeviceId(
      e,
      fetchUserCheckoutDataFailure,
      fetchUserCheckoutDataSuccess
    )
  }

  return (
    <FormField className={'w-full grow !grid-cols-1'} id="introduction">
      {loginSuccess ? (
        <h3>Order info will be sent to the email address:</h3>
      ) : (
        <div
          className={'prose-a:text-complementary-blue-150'}
          onClick={trackLoginClick}
        >
          <p>
            Enter a valid email address for this order or{' '}
            <a href="#" onClick={e => onClickLogin(e)}>
              login with an existing account
            </a>{' '}
            and return to checkout.
          </p>
        </div>
      )}
    </FormField>
  )
}
