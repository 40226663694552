import { featuresAlarmMonitoringTemplateSchema } from './schema'

export const useFeaturesAlarmPageFragment = <
  T extends { readonly contentfulMonitoringPage: U },
  U
>(
  query: T
) => {
  const featuresAlarmFragment = query.contentfulMonitoringPage

  return {
    ...featuresAlarmMonitoringTemplateSchema.parse(featuresAlarmFragment)
  }
}
