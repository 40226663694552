import React, { FC, ReactNode } from 'react'

import { Heading } from '..'

export type BuildMySystemItemContainerProps = {
  readonly title: string
  readonly children?: ReactNode
  readonly dataComponent?: string
}

/** @deprecated  Do not use ss-react-components*/
const BuildMySystemItemContainer: FC<BuildMySystemItemContainerProps> = ({
  children,
  title,
  dataComponent = BuildMySystemItemContainer.name
}: BuildMySystemItemContainerProps) => (
  <div
    className="mb-10 md:mb-14 md:mt-8 md:px-4 lg:mb-20 lg:px-16"
    data-component={dataComponent}
  >
    <Heading
      className="border-neutral-medium-100 hidden border-b border-l-0 border-r-0 border-t-0 border-solid md:block md:pb-7"
      element="h2"
      headingAspect="h3"
      margin="tiny"
      useTailwind
    >
      {title}
    </Heading>
    {children}
  </div>
)

export default BuildMySystemItemContainer
