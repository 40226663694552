import { useSitewidePromoEndDateDisplay } from '@ecomm/promotions-hooks'

import { RelativeEndTime } from '../RelativeEndTime'

export function EndDatePill() {
  const { endDate } = useSitewidePromoEndDateDisplay()

  return (
    <span
      className={
        'bg-neutral-black inline-block rounded px-2 text-center text-[14px] font-bold uppercase leading-[20px] text-white'
      }
      data-component="EndDatePill"
    >
      <RelativeEndTime displayEndsText endDate={new Date(endDate)} />
    </span>
  )
}
