import { Options } from '@contentful/rich-text-react-renderer'
import { MARKS } from '@contentful/rich-text-types'
import { usePriceContext } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  useCurrentPromoOverrideData,
  useDisplayMonitoringDiscount
} from '@ecomm/promotions-hooks'
import { overloadMaybe } from '@simplisafe/ewok'
import { selectCurrentPromoFlag } from '@simplisafe/ss-ecomm-data/redux/select'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import React from 'react'
import { useSelector } from 'react-redux'

import { ContentfulRichText } from '../ContentfulRichText'

export type OfferTagProps = {
  readonly sku: string
  readonly className?: string
  /**
   * only used for partner packages since they require relative discount information
   */
  readonly showPartnerPackageAbsoluteDiscountAsRelative?: boolean
  /**
   * Render an extra rich text after the discount text
   */
  readonly extraText?: string
}

export function OfferTag({
  sku,
  className = '',
  showPartnerPackageAbsoluteDiscountAsRelative = false, //only used for partner packages since they require relative discount information
  extraText = ''
}: OfferTagProps) {
  const {
    getDiscountTextWithServicePlan,
    getDiscountText,
    getRelativeDiscountTextWithServicePlan
  } = usePriceContext()
  const microCopy = useMicroCopy()
  const promoFlag = pipe(
    useSelector(selectCurrentPromoFlag),
    O.fromNullable,
    O.chain(overloadMaybe)
  )

  const displayMonitoringDiscount = useDisplayMonitoringDiscount()
  const { productFlag } = useCurrentPromoOverrideData()
  const hasOverrideFlagText = !!productFlag
  /**
   * getRelativeDiscountTextWithServicePlan only used for partner packages since they require relative discount information
   */
  const getDiscountTextAbsoluteOrRelative =
    showPartnerPackageAbsoluteDiscountAsRelative
      ? getRelativeDiscountTextWithServicePlan
      : getDiscountTextWithServicePlan

  const discountText = displayMonitoringDiscount
    ? getDiscountTextAbsoluteOrRelative(sku)
    : getDiscountText(sku)

  const tagBackgroundColor = pipe(
    promoFlag,
    O.chain(flag => overloadMaybe(flag.backgroundColor)),
    O.toNullable
  )
  const tagTextColor = pipe(
    promoFlag,
    O.chain(flag => overloadMaybe(flag.textColor)),
    O.toNullable
  )

  const style = {
    ...(tagBackgroundColor && { backgroundColor: tagBackgroundColor }),
    ...(tagTextColor && { color: tagTextColor })
  }

  const options: Options = {
    renderMark: {
      [MARKS.SUPERSCRIPT]: text => (
        <sup className="text-sm" style={style}>
          {text}
        </sup>
      )
    }
  }

  return pipe(
    discountText,
    O.fold(
      () => null,
      _discountText => (
        <span
          className={classNames(
            'flex min-h-[48px] items-center justify-center bg-white px-4 py-0 text-center md:min-w-[128px]',
            className
          )}
          style={style}
        >
          {hasOverrideFlagText
            ? productFlag
            : `${_discountText} ${microCopy.off}`}
          {extraText ? (
            <div className="prose-p:ml-1 prose-p:mt-3">
              <ContentfulRichText optionsCustom={options} raw={extraText} />
            </div>
          ) : null}
        </span>
      )
    )
  )
}
