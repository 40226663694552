import { fetchApi } from '@ecomm/data-simplisafe-api'
import {
  expandCustomerGroup,
  expandDiscountCode,
  expandProductType
} from '@simplisafe/ss-ecomm-data/secrets/commercetools'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

import { isCartResponse } from './lib'

/**
 * Calls /carts/v2/carts/${cartId}:applyDiscountCodes route
 * Applies discountCodes to the cart, supports bulk discount codes
 * @param cartId id of cart to update
 * @param discountCodes discount codes to apply to cart
 * @returns
 */
export function applyDiscountCodesRequest(
  cartId: string,
  discountCodes: ReadonlyArray<string>
) {
  return pipe(
    TE.Do,
    TE.chain(() => {
      return fetchApi({
        endpoint: `/carts/v2/carts/${cartId}:applyDiscountCodes?${expandProductType}&${expandDiscountCode}&${expandCustomerGroup}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ discountCodes })
      })
    }),
    TE.chain(res => (isCartResponse(res) ? TE.right(res) : TE.left(res))),
    TE.mapLeft(res => Error(`Error applying discount codes to cart ${res}`))
  )
}
