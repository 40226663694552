import {
  AI,
  Alert,
  Ambulance,
  Cancel,
  CheckMark,
  Clock,
  Close,
  FaceMatching,
  FalseAlarm,
  HeadsetPerson,
  Help,
  IconProps,
  Microphone,
  PersonRunning,
  Phone,
  PhoneRinging,
  PoliceCar,
  Siren,
  Spotlight,
  Video,
  Warning,
  Wifi
} from '@ecomm/shared-icons'
import classNames from 'classnames'
import React from 'react'

import { ContentfulRichText } from '../ContentfulRichText'
import { IconAndTextSchema } from './schema'

export const iconsTranslation: Record<string, React.FC<IconProps>> = {
  AI,
  Alert,
  Ambulance,
  Cancel,
  Check: CheckMark,
  CheckMark,
  Clock,
  Close,
  FaceMatching,
  FalseAlarm,
  HeadsetPerson,
  Help,
  Microphone,
  PersonRunning,
  Phone,
  PhoneRinging,
  PoliceCar,
  Siren,
  Spotlight,
  Video,
  Warning,
  Wifi,
  X: Cancel
  // We can add here more icons as we need them
}

export function IconAndText({ className, text, icon }: IconAndTextSchema) {
  const Icon = iconsTranslation[icon]
  return Icon ? (
    <div
      className={classNames(
        'prose-p:m-0 my-3 inline-flex w-full items-center gap-4',
        className
      )}
      data-component={IconAndText.name}
    >
      {Icon ? (
        <Icon className="h-6 w-6 shrink-0" data-component="Icon" />
      ) : null}
      <ContentfulRichText raw={text.raw} />
    </div>
  ) : null
}
