import { ContentfulRichText } from '@ecomm/shared-components'
import { Modal } from '@ecomm/ss-react-components'
import React from 'react'

import ProductCard from '../ProductCard'
import { AccessoriesModalSchema } from './schema'

type Props = {
  readonly modal: AccessoriesModalSchema
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
}

function AccessoriesModal({ modal, open, setOpen }: Props) {
  return (
    <div className="mt-4 flex justify-center">
      <span
        className="m-0 cursor-pointer text-center text-lg underline"
        onClick={e => {
          e.preventDefault()
          !open && setOpen(true)
        }}
      >
        {modal.modalTrigger.text}
      </span>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
        <div className="bg-neutral-50 px-4 pb-6 pt-6 md:px-10 md:pb-20 md:pt-14">
          <div className="mb-10">
            <h3 className="h2" data-component="modal_headline">
              {modal.headline}
            </h3>
            <ContentfulRichText raw={modal.description.raw} />
          </div>
          <div className="flex flex-col gap-2 md:flex-row">
            {modal.products.map((p, idx) => (
              <ProductCard
                key={`${idx}-${p.sku}`}
                {...p}
                isAffirmExperience={false}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AccessoriesModal
