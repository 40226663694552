import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const trackingEventSchema = z
  .enum([
    'link-click-protectors-footer',
    'link-click-manual-footer',
    'link-click-privacy-promise-footer',
    'link-click-privacy-footer',
    'link-click-meet-the-system-footer',
    'link-click-reviews-footer',
    'link-click-locations-footer'
  ])
  .nullish()

export const footerMenuSchema = z.object({
  links: z
    .array(
      z.object({
        text: z.string().default(''),
        url: z.string().nullable(),
        trackingEvent: trackingEventSchema
      })
    )
    .default([]),
  title: z.string(),
  titleUrl: z.string().nullable().default('')
})

export const footerMenusSchema = z.array(footerMenuSchema).default([])

export const socialLinkSchema = z.object({
  icon: gatsbyImageSchema,
  text: z.string(),
  url: z.string()
})

export const socialLinksSchema = z.array(socialLinkSchema).default([])

export const legalLinkSchema = z.object({
  text: z.string(),
  url: z.string(),
  trackingEvent: trackingEventSchema
})

export const simpleLinkSchema = z
  .object({
    text: z.string(),
    url: z.string()
  })
  .nullish()

export const legalLinksSchema = z.array(legalLinkSchema).default([])

export const leadGenFormSchema = z.object({
  button: z.object({ text: z.string().default('') }),
  form: z
    .array(
      z.object({
        placeholder: z.string().default(''),
        title: z.string().default(''),
        type: z.string().default('')
      })
    )
    .default([]),
  note: contentfulRichTextSchema.optional(),
  title: z.string().default('')
})

export const footerSchema = z.object({
  copyrightText: z.string().default(''),
  leadGenForm: leadGenFormSchema.optional(),
  legalLinks: legalLinksSchema,
  menus: footerMenusSchema,
  socialLinks: socialLinksSchema,
  disclaimerLink: simpleLinkSchema,
  type: z.enum(['Full', 'Slim']).optional()
})

export const countrySchema = z.object({
  linkIcon: gatsbyImageSchema,
  linkSubtext: z.string('USA'),
  linkText: z.string(),
  linkUrl: z.string()
})

export const countriesSchema = z.array(countrySchema).default([])

export type FooterFragment = TypeOf<typeof footerSchema>
export type LeadGenFormFragment = TypeOf<typeof leadGenFormSchema>

export type LegalLinksFragment = TypeOf<typeof legalLinksSchema>
export type LegalLinkFragment = TypeOf<typeof legalLinkSchema>

export type SocialLinksFragment = TypeOf<typeof socialLinksSchema>
export type SocialLinkFragment = TypeOf<typeof socialLinkSchema>

export type FooterMenusFragment = TypeOf<typeof footerMenusSchema>
export type FooterMenuFragment = TypeOf<typeof footerMenuSchema>

export type CountriesFragment = TypeOf<typeof countriesSchema>
export type CountryFragment = TypeOf<typeof countrySchema>
