import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { Modal } from '@ecomm/ss-react-components'
import { LookingForUsSiteModal } from '@ecomm/ss-react-components'
import { localStorage } from '@simplisafe/ewok'
import React, { useState } from 'react'

import { locale } from '../../commercetools/utils'
import { SmallTextSectionRedirect } from '../../templates/DefaultPage'
import ButtonComponent from '../ButtonComponent'
import ContentfulRichText from '../ContentfulRichText'
import HeadingComponent from '../HeadingComponent'
const { get, set } = localStorage

const geoRedirectKey = 'geoRedirectSuggested'

type CountryRedirectModal = {
  readonly data: SmallTextSectionRedirect
  readonly language?: string
}

function CountryRedirectModal({
  data,
  language = locale
}: CountryRedirectModal) {
  // Safari returns 'en' for 'en-us'
  const getBrowserLanguage = (browserLanguage: string) =>
    browserLanguage === 'en' ? 'en-us' : browserLanguage
  const showRedirectModal =
    typeof navigator !== 'undefined' &&
    language.toLowerCase() !==
      getBrowserLanguage(navigator.language.toLowerCase()) &&
    !get(geoRedirectKey)

  const [isRedirectModalVisible, setRedirectModalVisibility] =
    useState(showRedirectModal)
  /*
    redirect modal needs to show only once to the user
    if a user is set to see the redirect modal, save a flag in localstorage,
    so that the modal doesn't show again when a user goes back to the site
   */
  const handleClose = () => {
    setRedirectModalVisibility(false)
    set(geoRedirectKey, true)
  }

  const richTextOptions: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const type: string | undefined = node.data.target?.internal?.type
        return type === 'ContentfulHeading' ? (
          <HeadingComponent data={node.data.target} />
        ) : type === 'ContentfulButton' ? (
          <ButtonComponent
            className="mt-2 !flex !w-fit items-baseline"
            data={node.data.target}
          />
        ) : null
      },
      // Remove empty trailing paragraphs https://github.com/contentful/rich-text/issues/101#issuecomment-966339506
      [BLOCKS.PARAGRAPH]: (_, children) =>
        children?.toString().trim() !== '' ? <p>{children}</p> : null
    }
  }

  const modalContent = (
    <ContentfulRichText
      optionsCustom={richTextOptions}
      raw={data?.description?.raw}
      references={data?.description?.references}
    />
  )

  return isRedirectModalVisible ? (
    <Modal
      appElementId="___gatsby"
      dataComponent="CountryRedirectModal"
      isOpen={isRedirectModalVisible}
      onRequestClose={handleClose}
    >
      {/* TODO rename modal to remove "US" reference to be more generic */}
      <LookingForUsSiteModal content={modalContent} />
    </Modal>
  ) : null
}

export default CountryRedirectModal
