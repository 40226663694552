import { useMicroCopy } from '@ecomm/micro-copy'
import classNames from 'classnames'
import React from 'react'

import { ShopProductSchema } from '../../templates/Shop/shopPageSchema'
import ShopAccessoryCard from '../ShopAccessoryCard'

export type ShopAccesoryCardListProps = {
  readonly products: readonly ShopProductSchema[]
  readonly className?: string
}
export default function ShopAccesoryCardList({
  products,
  className = ''
}: ShopAccesoryCardListProps) {
  const microcopy = useMicroCopy()

  return (
    <section
      className={classNames('flex flex-col items-center', className)}
      data-component="ShopAccesoryCardList"
    >
      <h2 className="text-center">
        {microcopy['shop-cameras-and-accessories']}
      </h2>
      <ul className="m-0 grid list-none grid-cols-12 gap-4 p-0 md:gap-8 lg:gap-16">
        {products.map(accessory => (
          <ShopAccessoryCard
            key={`Shop-product-card-${accessory.name}`}
            {...accessory}
          />
        ))}
      </ul>
    </section>
  )
}
