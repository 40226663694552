import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { trackClickBuildASystemCta } from '@ecomm/cdp-tracking'
import { ContentfulRichText } from '@ecomm/shared-components'
import { SSButton } from '@ecomm/ss-react-components'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { ReactElement, ReactNode, useCallback, useState } from 'react'

import { Output as ComponentProps } from '../../hooks/useLocalizedData'
import QuantitySelector from '../QuantitySelector'
import { schema } from './schema'

const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="text-[16px] font-light md:text-[18px] md:leading-[24px]">
        {children}
      </p>
    )
  }
}

type RiskFreeProps = ComponentProps<typeof schema>

export function RiskFree({
  data,
  atcButton = () => <div></div>,
  price = ''
}: {
  readonly data: RiskFreeProps
  readonly atcButton?: (qty: number) => ReactElement
  readonly price?: ReactNode
}) {
  const [quantity, setQuantity] = useState(1)

  const handleChange = useCallback(
    (increase: boolean) => {
      increase ? setQuantity(quantity + 1) : setQuantity(quantity - 1)
    },
    [quantity]
  )

  return (
    <div
      className="lg:align-stretch flex w-full flex-col lg:flex-row"
      data-component="RiskFreeComponent"
    >
      <div className="rounded-t-base bg-neutral-light-50 lg:rounded-l-base h-2/4 w-full lg:h-auto lg:w-2/5 lg:rounded-tr-none">
        <GatsbyImage
          alt={data.image.description || ''}
          className="rounded-tl-base rounded-tr-base lg:rounded-bl-base lg:rounded-tl-base w-full object-contain object-center md:h-auto lg:h-full lg:rounded-tr-none"
          image={data.image.gatsbyImageData}
        />
      </div>
      <div className="md:flex-start md:align-start rounded-b-base bg-neutral-light-50 lg:rounded-e-base flex h-2/4 flex-col p-6 md:justify-center lg:h-auto lg:w-3/5 lg:rounded-bl-none lg:p-12">
        <h2 className="m-0 text-2xl font-medium md:text-5xl">
          {data.headline}
        </h2>
        <ContentfulRichText
          optionsCustom={descriptionCustomOptions}
          raw={data.body.raw}
        />
        <div
          className="justify-left mb-4 flex flex-row items-center"
          data-component="QuantitySelectorWrapper"
        >
          <span className="mr-4 text-xl md:text-2xl" data-component="Price">
            {price}
          </span>
          <QuantitySelector
            maxQuantity={4}
            name={''}
            onDecrease={() => handleChange(false)}
            onIncrease={() => handleChange(true)}
            quantity={quantity}
          />
        </div>
        <div className="flex flex-col md:mt-4 md:h-14 md:flex-row">
          {atcButton && atcButton(quantity)}
          <Link to={data.buttonLink}>
            <SSButton
              className="mb-4 w-full md:w-64"
              color="primaryOutline"
              href={data.buttonLink}
              onClick={trackClickBuildASystemCta}
            >
              {data.buttonText}
            </SSButton>
          </Link>
        </div>
      </div>
    </div>
  )
}
