import { RadioItem } from '@ecomm/checkout/components'
import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { ShippingOption } from '@simplisafe/ss-ecomm-data/cart'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface Props {
  readonly userAddress: Partial<CheckoutFormValuesType>
  readonly shippingOptions: readonly ShippingOption[]
  readonly setSelectedShipping: (shippingName: string) => void
}

function UsShippingOptions({
  userAddress,
  shippingOptions,
  setSelectedShipping
}: Props) {
  const [hasBatteries, setHasBatteries] = useState(false)
  const cart: RemoteData<ImmutableCart> = useSelector(selectCart)

  const airShippingStates = ['AK', 'GU', 'HI', 'PR', 'VI']
  const batteryDisclaimer =
    'If you are shipping to AK, GU, HI, PR or VI and have an Outdoor Camera Battery in cart, you must first remove it due to shipping restrictions.'

  const shouldRenderBatteryWarning =
    airShippingStates.includes(userAddress.state || '') && hasBatteries

  useEffect(() => {
    const batterySkus = ['SSCAM-BAT1']

    cart.forEach(elements => {
      const itemList = elements.lineItems
      setHasBatteries(
        itemList.some(product => batterySkus.includes(product.sku))
      )
    })
  }, [cart])

  return (
    <div data-component="UsShippingOptionDisclaimer">
      <div className="flex flex-col">
        {shouldRenderBatteryWarning ? (
          <>
            <h3 className="text-xs font-bold leading-tight">Please Note</h3>
            <p className="text-base font-light md:text-lg">
              {batteryDisclaimer}
            </p>
          </>
        ) : (
          shippingOptions.map((option, i) => (
            <div
              key={option.id}
              onClick={() => setSelectedShipping(option.name)}
            >
              <RadioItem
                checked={i === 0}
                description={option.localizedShippingPrice}
                key={option.id}
                label={option.name.replace('business', 'bus.')}
                name="shippingOption"
                sublabel={option.description || ''}
                value={option.id}
              />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default UsShippingOptions
