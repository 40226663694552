import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { useLocale } from '@ecomm/data-hooks'
import { Link } from '@ecomm/framework'
import { GatsbyImage } from '@ecomm/shared-components'
import classNames from 'classnames'
import { useTracking } from 'react-tracking'

import {
  getPackageMenuRightItems,
  getPackagesMenuLeftItems,
  getPackagesMenuMiddleItems
} from './data'
import type { HeaderRedesignSchema } from './schema'

type PackagesDropdownMenuProps = HeaderRedesignSchema & {
  readonly onToggleDropdown: (close?: boolean) => void
  readonly isOdmonVariant: boolean
}

const includeImage = <T extends { readonly imageId: string }>(
  item: T,
  images: HeaderRedesignSchema['images']
) => ({
  ...item,
  image: images.nodes.find(img => img.contentful_id === item.imageId)
})

export function PackagesDropdownMenu({
  images,
  onToggleDropdown,
  isOdmonVariant
}: PackagesDropdownMenuProps) {
  const locale = useLocale()
  const { trackEvent } = useTracking()
  const packages = getPackagesMenuMiddleItems(locale, isOdmonVariant).map(
    item => includeImage(item, images)
  )
  const rightSide = includeImage(
    getPackageMenuRightItems(locale, isOdmonVariant),
    images
  )
  const trackClick = (label: string) => {
    trackEvent({
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation',
      label: label
    })
    trackNavigationClick({
      action: 'click',
      navElement: label
    })
  }
  const rightSideImage = rightSide.image && (
    <GatsbyImage
      className="mx-auto h-[77px] w-[77px] rounded-xl md:h-[114px] md:w-[114px]"
      image={{
        gatsbyImageData: rightSide.image.gatsbyImageData,
        title: rightSide.image.title
      }}
    />
  )
  const rightSideInfo = (
    <>
      <span className="block text-base font-bold min-[1126px]:text-2xl">
        {rightSide.title}
      </span>
      <span className="block text-sm min-[1126px]:text-base">
        {rightSide.description}
      </span>
    </>
  )
  return (
    <div
      className="left-0 top-0 z-[1] flex w-full flex-wrap justify-start gap-3 bg-white px-0 min-[1126px]:absolute min-[1126px]:mt-[78px] min-[1126px]:h-52 min-[1126px]:flex-nowrap min-[1126px]:justify-center min-[1126px]:py-4 min-[1126px]:shadow-md min-[1380px]:gap-8"
      data-testid="packages-dropdown-menu"
    >
      <div className="w-full px-4 min-[1126px]:w-auto min-[1126px]:px-0">
        {getPackagesMenuLeftItems(locale).map((item, i) => (
          <div
            className="first-of-type:mt-4 last-of-type:mb-3 min-[1126px]:!mb-6 first-of-type:min-[1126px]:mt-2 [&:not(:last-of-type)]:mb-8"
            key={i}
          >
            {item.caption ? (
              <small className="block">{item.caption}</small>
            ) : null}
            <Link
              className={classNames(
                'text-neutral-black block w-full text-base no-underline hover:underline min-[1126px]:w-auto min-[1126px]:font-medium'
              )}
              onClick={() => {
                onToggleDropdown(true)
                trackClick(item.text)
              }}
              to={item.url}
            >
              {item.text}
            </Link>
          </div>
        ))}
      </div>
      <div className="hidden h-full w-[1px] min-[1126px]:block"></div>
      <div className="flex flex-wrap gap-3 px-4 min-[1126px]:mt-2 min-[1126px]:flex-nowrap min-[1126px]:gap-6 min-[1126px]:px-0 min-[1380px]:gap-11">
        {packages.map((item, i) => (
          <Link
            className="text-neutral-black focus:outline-complementary-blue-100 flex w-full items-center border-0 border-t border-solid border-neutral-300 no-underline last-of-type:border-b last-of-type:pb-3 hover:underline focus:outline-2 focus:outline-offset-8 min-[1126px]:block min-[1126px]:w-auto min-[1126px]:rounded-md min-[1126px]:border-t-0 last-of-type:min-[1126px]:border-b-0 last-of-type:min-[1126px]:pb-0"
            key={i}
            onClick={() => {
              onToggleDropdown(true)
              trackClick(item.title)
            }}
            to={item.url}
          >
            <div className="w-1/2 min-[1126px]:w-auto">
              <span className="block text-base font-bold min-[1126px]:text-sm min-[1261px]:text-base">
                {item.title}
              </span>
              <small className="mt-1 block text-xs min-[1126px]:text-[10px] min-[1261px]:text-xs">
                {item.packageName}
              </small>
            </div>
            {item.image ? (
              <div className="mb-1 mt-4 flex w-1/2 justify-center min-[1126px]:mb-auto min-[1126px]:mt-2 min-[1126px]:w-auto">
                <GatsbyImage
                  className="h-[75px] w-[110px] rounded-xl md:h-[96px] md:w-[142px] min-[1126px]:mx-auto min-[1261px]:h-[117px] min-[1261px]:w-[175px]"
                  image={{
                    gatsbyImageData: item.image.gatsbyImageData,
                    title: ''
                  }}
                />
              </div>
            ) : null}
          </Link>
        ))}
      </div>
      <div className="hidden h-full w-[1px] border-0 border-r border-solid border-neutral-300 min-[1126px]:block"></div>
      <div className="flex items-center border-0 border-b border-solid border-neutral-300 px-4 pb-3 pt-1 min-[1126px]:border-b-0 min-[1126px]:px-0 min-[1126px]:py-0">
        <div className="hidden min-[1126px]:flex">
          {rightSideImage}
          <div className="ml-4 flex w-2/3 flex-wrap gap-1 min-[1126px]:w-60 min-[1270px]:ml-9">
            {rightSideInfo}
            <Link
              className="text-complementary-blue-100 block text-sm hover:no-underline min-[1126px]:text-base"
              data-testid="rightside-link"
              onClick={() => {
                onToggleDropdown(true)
                trackClick(rightSide.title)
              }}
              to={rightSide.url}
            >
              {rightSide.link}
            </Link>
          </div>
        </div>
        <div className="flex min-[1126px]:hidden">
          <Link
            className="text-neutral-black flex items-center no-underline"
            data-testid="rightside-link"
            onClick={() => {
              onToggleDropdown(true)
              trackClick(rightSide.title)
            }}
            to={rightSide.url}
          >
            {rightSideImage}
            <div className="ml-4 flex w-2/3 flex-wrap gap-1 min-[1126px]:w-60 min-[1270px]:ml-9">
              {rightSideInfo}
              <span className="text-complementary-blue-100 block text-sm underline hover:no-underline min-[1126px]:text-base">
                {rightSide.link}
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
