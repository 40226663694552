import { mapExperiences } from '@ecomm/shared-ninetailed'
import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import React from 'react'

import { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import { ProductCardFragment } from '../ProductCard/schema'
import ProductCardsList from './ProductCardsList'

interface Props {
  readonly items: readonly ProductCardFragment[]
  readonly isCurrent: boolean
}

function AffirmProductCardListWrapper({ items, isCurrent }: Props) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <ProductCardsList
      isAffirmExperience={isAffirmExperience}
      isCurrent={isCurrent}
      items={items}
    />
  )
}

export default AffirmProductCardListWrapper
