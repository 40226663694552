import {
  useFetchPartnerBanner,
  usePromoBannerState
} from '@ecomm/promotions-hooks'
import { useMemo } from 'react'

import { NinetailedFeatureFlagSchema } from '../lib/experiments/ninetailedSchema'
import { PartnerBanner } from '../Partners'
import { SitewidePromoBanner } from '../SitewidePromoBanner'
import { PromoBannerPageType } from '../types'
import { LeadFormContext } from './leadFormContext'

type PromoBannerWrapperProps = {
  readonly type: PromoBannerPageType
  readonly experimentData?: NinetailedFeatureFlagSchema
}

export function PromoBannerWrapper({
  type,
  experimentData
}: PromoBannerWrapperProps) {
  useFetchPartnerBanner()

  const { hasBanner, isPartnerBanner } = usePromoBannerState()

  return useMemo(() => {
    return hasBanner ? (
      isPartnerBanner ? (
        <PartnerBanner showCta={type !== 'pdp-plp'} type={type} />
      ) : (
        <LeadFormContext.Provider value={experimentData}>
          <SitewidePromoBanner type={type} />
        </LeadFormContext.Provider>
      )
    ) : null
  }, [hasBanner, isPartnerBanner, type, experimentData])
}
