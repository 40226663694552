import { Options } from '@contentful/rich-text-react-renderer'
import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import { FeatureSectionMedia } from './FeatureSectionMedia'
import { FeatureSectionSchema, themes, types } from './schema'

export const getCustomRenderOptions = (defaultOptions?: Options): Options => ({
  ...defaultOptions,
  renderText: (text: string) =>
    text.startsWith('*') ? <span className="text-sm">{text}</span> : text
})

export type FeatureSectionProps = Omit<
  FeatureSectionSchema & {
    readonly richTextOptions?: Options
    readonly className?: string
    readonly columnClassName?: string
    readonly mediaClassName?: string
  },
  '__typename'
>

export function FeatureSection({
  body,
  className = '',
  mediaClassName = '',
  columnClassName,
  media,
  richTextOptions = {},
  theme,
  type = 'Left Side Media'
}: FeatureSectionProps) {
  const isBgImage = type.includes('Background Media')

  return (
    <div
      className={classNames(
        'grid grid-cols-2 items-center gap-4 md:gap-8',
        className,
        {
          'relative h-[350px] p-4 md:h-[37.5rem] md:p-8 lg:p-16': isBgImage,
          'bg-neutral-light-100 px-4 py-6 md:px-8 md:py-10':
            theme === themes.gray
        }
      )}
    >
      {/* Possible background image */}
      {isBgImage && media ? (
        <FeatureSectionMedia
          background
          media={media}
          mediaClassName={mediaClassName}
        />
      ) : null}
      {/* Text */}
      <div
        className={classNames(
          'prose-h3:mb-4 order-2 col-span-2 md:col-span-1',
          {
            //Text will be in the second column by default and in mobile. if the type is right media or background with text on the left, it will get order-1
            'md:order-1': type === types.rightMedia || type === types.bgLeft,
            'justify-center md:col-span-2': type === types.bgCenter,
            'prose-headings:text-white prose-p:text-white z-[1]': isBgImage,
            'prose-headings:text-neutral-black prose-p:text-neutral-black':
              theme === themes.default,
            'prose-headings:text-gray-900 prose-p:text-gray-900':
              theme === themes.gray,
            'prose-headings:text-primary-100 prose-p:text-primary-100':
              theme === themes.primary
          },
          columnClassName
        )}
      >
        <ContentfulRichText
          optionsCustom={getCustomRenderOptions(richTextOptions)}
          raw={body.raw}
          references={body.references}
        />
      </div>
      {/* Asset */}
      {!isBgImage && (
        <div
          className={classNames(
            'order-1 col-span-2 md:col-span-1',
            {
              //Asset will be in the first column by default and in mobile, then from tablet on, the type will indicate the position
              'md:order-2': type === types.rightMedia,
              hidden: type === types.bgCenter
            },
            columnClassName
          )}
        >
          {media ? <FeatureSectionMedia media={media} /> : null}
        </div>
      )}
    </div>
  )
}
