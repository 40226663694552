import {
  FAST_PROTECT_THREE_MONTHS_OFFER,
  INTERACTIVE_MONITORING,
  ODMON_247_MONITORING,
  ODMON_OVERNIGHT_MONITORING,
  PRO_PREMIUM_MONITORING
} from '@ecomm/data-constants'
import { pipe } from 'fp-ts/lib/function'
import { getOrElse, map } from 'fp-ts/lib/Option'

import { useCartLineItems } from './useCartLineItems'

export const premiumSkus = [
  INTERACTIVE_MONITORING,
  PRO_PREMIUM_MONITORING,
  FAST_PROTECT_THREE_MONTHS_OFFER,
  ODMON_OVERNIGHT_MONITORING,
  ODMON_247_MONITORING
]

export const useHasPremiumMonitoringPlan = () => {
  const lineItems = useCartLineItems()

  return pipe(
    lineItems,
    map(l => l.some(i => premiumSkus.includes(i.sku))),
    getOrElse(() => false)
  )
}
