import { useMediaQuery } from '@ecomm/ss-react-components'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { getSystemBannerData, SystemBanner } from '../SystemBanner'

export function SecuredSystems() {
  const isTabletAndUp = useMediaQuery('TabletAndUp')
  const staticQuery = useStaticQuery(graphql`
    fragment SecuredSystemsQWBaseFragment on ContentfulQuoteWizard {
      __typename
      id: contentful_id
      jebbitUrl
      type
      buttonText
    }

    fragment SecuredSystemsQWExperienceFragment on ContentfulNinetailedExperience {
      id: contentful_id
      name: nt_name
      type: nt_type
      audience: nt_audience {
        id: contentful_id
        name: nt_name
      }
      config: nt_config {
        components {
          baseline {
            id
          }
          variants {
            id
            hidden
          }
        }
        traffic
        distribution
      }
      variants: nt_variants {
        ... on ContentfulQuoteWizard {
          ...SecuredSystemsQWBaseFragment
        }
      }
    }
    query SecuredSystemsQuery {
      quoteWizard: contentfulQuoteWizard(
        contentful_id: { eq: "6wRdF53o74w2MhOhBIkzip" }
      ) {
        ...SecuredSystemsQWBaseFragment
        nt_experiences {
          ...SecuredSystemsQWExperienceFragment
        }
      }
      tabletAndUpSystemBannerAsset: contentfulAsset(
        contentful_id: { eq: "7dWLidlgDj6bpThb3JX3Xp" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      mobileSystemBannerAsset: contentfulAsset(
        contentful_id: { eq: "2bq7s0L6Q0KL28pZRYUBVf" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      systemBanner: contentfulJson(
        contentful_id: { eq: "5BDx1vdUPVCD4JGzsZr0Ac" }
      ) {
        ...json
      }
    }
  `)

  const systemBannerData = getSystemBannerData({
    copy: staticQuery.systemBanner,
    image: isTabletAndUp
      ? staticQuery.tabletAndUpSystemBannerAsset
      : staticQuery.mobileSystemBannerAsset,
    quoteWizard: staticQuery.quoteWizard
  })

  return <SystemBanner data={systemBannerData} />
}
