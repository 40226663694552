import { useSelector } from 'react-redux'
import { selectIsActivePromotion } from '@simplisafe/ss-ecomm-data/redux/select'

/**
 * Returns false if current sitewide promo is Evergreen. Otherwise returns true
 * @example
 * const isActivePromotion = useIsActivePromotion() // => boolean
 */

export const useIsActivePromotion = () => {
  const isActivePromotion = useSelector(selectIsActivePromotion)
  return isActivePromotion
}
