import { useLocale } from '@ecomm/data-hooks'
import {
  monthlyToLocalizedDailyPrice,
  usePriceContext
} from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import { ContentfulRichText, IconAndTextSchema } from '@ecomm/shared-components'
import { CheckSolid, XSolidIcon } from '@ecomm/shared-icons'
import { isNotNil } from '@simplisafe/ewok'
import classNames from 'classnames'
import { match } from 'fp-ts/lib/Option'
import React from 'react'

import FeatureModal from '../FeatureModal'
import IconTextGrid from '../IconTextGrid'
import { MonitoringPlanCardSchema } from './schema'

export default function MonitoringPlanCard(data: MonitoringPlanCardSchema) {
  const {
    recommended,
    name,
    sku,
    features,
    additionalFeatures,
    cta,
    disclaimer,
    description
  } = data

  const locale = useLocale()
  const microcopy = useMicroCopy()
  const { getPrice } = usePriceContext()
  const priceText = match(
    () => null,
    (price: number) => monthlyToLocalizedDailyPrice(locale, price)
  )(getPrice(sku))

  const additionalFeaturesWithIcons: readonly IconAndTextSchema[] =
    additionalFeatures
      .map(feature =>
        feature.__typename === 'ContentfulIconAndText' ? feature : null
      )
      .filter(isNotNil)

  return (
    <div className="flex w-full flex-col" data-testid="MonitoringPlanCard">
      <div className="flex flex-col md:flex-row">
        <div className="border-neutral-medium-100 flex flex-grow basis-[55%] flex-col gap-3 rounded-t-2xl border border-solid px-4 py-5 md:rounded-l-2xl md:rounded-tr-none md:pb-6 md:pt-8 lg:pl-16 lg:pr-14">
          <span
            className={classNames(
              'bg-primary-100 mr-auto px-[10px] py-[7px] text-xs',
              { invisible: !recommended }
            )}
          >
            {microcopy['recommended']}
          </span>
          <h3 className="my-0 text-xl font-bold leading-normal">{name}</h3>
          <ul className="my-0 mb-2 flex flex-col gap-1 pl-2">
            {features?.map((feature, idx) => (
              <li
                className="prose-p:text-base prose-p:mb-0 flex list-none items-center gap-1"
                key={idx}
              >
                {feature.icon === 'CheckMark' ? (
                  <CheckSolid className="text-complementary-green-100 h-6 w-6 shrink-0" />
                ) : (
                  <XSolidIcon className="text-complementary-red-100 h-6 w-6 shrink-0" />
                )}
                <ContentfulRichText {...feature.helpText} />
                {feature.modal ? <FeatureModal modal={feature.modal} /> : null}
              </li>
            ))}
          </ul>
          <div className="mb-6 flex flex-grow flex-col">
            <div className="prose-p:text-base">
              <ContentfulRichText {...description} />
            </div>
            <div className="mb-1 flex items-center gap-2">
              {cta ? (
                <button className="btn btn-outlined btn-outlined-primary">
                  {cta}
                </button>
              ) : null}
              <span
                className={classNames('h3', {
                  'text-complementary-red-100': recommended
                })}
              >
                {priceText}
              </span>
              <span className="text-xs">per day</span>
            </div>
            <p className="text-base">{microcopy['no-contract']}</p>
          </div>
        </div>
        <IconTextGrid items={additionalFeaturesWithIcons} />
      </div>
      {disclaimer ? (
        <span className="pt-5 text-center text-xs">{disclaimer}</span>
      ) : null}
    </div>
  )
}
