import { useLocale } from '@ecomm/data-hooks'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { Modal } from '@ecomm/ss-react-components'
import { useTrackGuidedSystemBuilderOpenEvent } from '@ecomm/tracking'
import React, { useEffect } from 'react'

import { GuidedSystemBuilder } from '../GuidedSystemBuilder'
import { GuidedSystemBuilderSchema } from '../GuidedSystemBuilder/schema'

interface Props {
  readonly show?: boolean
  readonly onRequestClose?: () => void
  readonly data: GuidedSystemBuilderSchema
}

export function QuoteWizardModal({
  show = false,
  onRequestClose,
  data
}: Props) {
  const trackGSBStart = useTrackGuidedSystemBuilderOpenEvent(data.type)
  const locale = useLocale()
  useEffect(() => {
    show && trackGSBStart && trackGSBStart()
  }, [show, trackGSBStart])

  return (
    <Modal
      isOpen={show}
      onRequestClose={onRequestClose}
      style={{ content: { padding: '32px' } }}
    >
      <div className="w-full md:w-[500px]">
        {locale === 'en-US' ? (
          <Experience
            {...data}
            component={GuidedSystemBuilder}
            experiences={mapExperiences(data.nt_experiences)}
            key={data.id}
          />
        ) : (
          <GuidedSystemBuilder {...data} />
        )}
      </div>
    </Modal>
  )
}
