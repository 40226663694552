import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'
import { useEnv } from '@ecomm/utils'
import classNames from 'classnames'
import React, { useState } from 'react'

import ProductModal from '../ProductModal'
import type { SystemHeroItemFragment } from './schema'

function SystemHeroItem({
  name,
  image,
  description,
  productModal
}: SystemHeroItemFragment) {
  const [isModalOpen, setModalIsOpen] = useState(false)
  const { locale } = useEnv()

  return (
    <div
      className={classNames(
        'rounded-base grid grid-cols-[80px_1fr] items-center gap-6 py-2 md:p-10',
        {
          'md:grid-cols-1 md:items-baseline md:px-4 md:py-10 lg:grid-cols-[60px_1fr] lg:items-center lg:px-8 lg:py-10':
            locale === 'en-GB'
        }
      )}
    >
      {image ? (
        <div className="px-4 md:px-0">
          <GatsbyImage image={image} />
        </div>
      ) : null}
      <div>
        <h3 className="mb-2 text-base font-bold md:text-lg">{name}</h3>
        {description ? <ContentfulRichText raw={description.raw} /> : null}
        {productModal ? (
          <div className="mt-4 flex">
            <span
              className="m-0 cursor-pointer text-center text-lg underline"
              onClick={e => {
                e.preventDefault()
                !isModalOpen && setModalIsOpen(true)
              }}
            >
              See details
            </span>
            <ProductModal
              isNew={false}
              modal={productModal}
              open={isModalOpen}
              setOpen={setModalIsOpen}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SystemHeroItem
