import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { ImageSchema } from './schema'

export function ImageGalleryCurrent({
  current
}: {
  readonly current: ImageSchema
}) {
  return (
    <div
      className="col-span-12 flex md:col-span-10 lg:col-span-10"
      data-component="ImageGalleryCurrent"
    >
      <GatsbyImage
        alt={current.description || ''}
        className="w-full rounded-lg object-contain object-center lg:max-h-[420px]"
        image={current.gatsbyImageData}
      />
    </div>
  )
}
