import { gql } from '@apollo/client/index.js'

export const VIDEO_BUTTON_ASSET_QUERY = gql`
  query videoButtonAssetQuery($preview: Boolean! $locale: String!){
    videoButtonAsset: asset(
      id:"485NQEr4JUie3FE2IIlZ7B",
      preview : $preview,
      locale : $locale,
    ) {
      url
      originalHeight : height
      originalWidth : width
      description
      title
  }
}

`
