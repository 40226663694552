import {
  parseSchemaWithVariations,
  parseVariants,
  validateString,
  VariationFragment,
  variationSchema
} from '@ecomm/utils'
import { TypeOf, z } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useEffect, useMemo, useState } from 'react'
import {
  DeepNonNullable,
  DeepNullable,
  DeepPartial,
  DeepRequired
} from 'ts-essentials'
import { ZodTypeAny } from 'zod'

import { useOptimizelyActivate } from './useOptimizelyActivate'

/** Infers the type of the fragment based on the Zod schema with everything being nullable and optional. */
type Fragment<U extends ZodTypeAny> = DeepPartial<DeepNullable<TypeOf<U>>>

/**
 * An easy way to parse a fragment with a Zod schema and automatically activate any available optimizely experiments.
 */
export function useFragmentWithVariations<T extends ZodTypeAny, U>(
  fragment: U | null | undefined,
  baseSchema: T
) {
  const schemaWithVariatons = z.intersection(
    baseSchema,
    variationSchema(baseSchema)
  )

  type S = TypeOf<typeof schemaWithVariatons>

  const parsedFragmentWithVariations: DeepNonNullable<
    DeepRequired<VariationFragment<T>>
  > &
    S = useMemo(
    () =>
      parseSchemaWithVariations<Fragment<typeof baseSchema>, S>(
        // @ts-expect-error
        fragment,
        schemaWithVariatons
      ),
    [schemaWithVariatons]
  )

  const experimentKey: string = validateString(
    parsedFragmentWithVariations.variations.experimentKey
  )

  /** This can be used later to know if we need a loading state for SSR */
  const activeExperiment: boolean =
    parsedFragmentWithVariations.variations.variations.length > 0

  const [variation] = useOptimizelyActivate(experimentKey)

  /**
   * If ready is false we need to show a loading skeleton to avoid a flicker when react switches from SSR to client content.
   * Ready is true if there is not an active experiment, we can just render the content.
   * Ready is false if there is an active experiment, it will be set to true after React has hyrdyated and rendered for the first time.
   */
  const [ready, setReady] = useState(!activeExperiment)

  useEffect(() => {
    setReady(true)
  }, [variation])

  const parsedFragment = useMemo<TypeOf<typeof baseSchema>>(() => {
    const fragmentThatMatchesVariation = pipe(
      variation,
      O.fold(
        () => parsedFragmentWithVariations,
        variation => parseVariants(variation, parsedFragmentWithVariations)
      )
    )

    return fragmentThatMatchesVariation
  }, [parsedFragmentWithVariations, variation])

  return {
    ...schemaWithVariatons.parse(parsedFragment),
    ready
  }
}
