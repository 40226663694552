import { graphql, useStaticQuery } from 'gatsby'

export const useCompetitiveComponentsQuery = () => {
  return useStaticQuery(graphql`
    query CompetitiveComponentsQuery {
      confidenceBar: contentfulJson(
        contentful_id: { eq: "4MPjXZsXki3lB8IIfHPOau" }
      ) {
        content {
          internal {
            content
          }
        }
      }
      guaranteeAsset: contentfulAsset(
        contentful_id: { eq: "5FRJNkZAaZL4mHk6vuMNVb" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
      }
      guaranteeCopy: contentfulJson(
        contentful_id: { eq: "5bXaWtcoGF04HVmWb5Kuoe" }
      ) {
        ...json
      }
      userReviewsData: contentfulJson(
        contentful_id: { eq: "5S5vhfcSNQqBcrZZhwVXWO" }
      ) {
        ...json
      }
      quoteWizard: contentfulQuoteWizard(
        contentful_id: { eq: "6wRdF53o74w2MhOhBIkzip" }
      ) {
        __typename
        id: contentful_id
        contentful_id
        jebbitUrl
        type
      }
      comparisonTable: contentfulJson(
        contentful_id: { eq: "7Cwz11tB8GasKfZOpDzpSX" }
      ) {
        ...json
      }
      tabletAndUpSystemBannerAsset: contentfulAsset(
        contentful_id: { eq: "7dWLidlgDj6bpThb3JX3Xp" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      mobileSystemBannerAsset: contentfulAsset(
        contentful_id: { eq: "1JupDkm1XxkXugGUJLqwjn" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      systemBanner: contentfulJson(
        contentful_id: { eq: "1djXQe21lhPRvWbz2dE4JX" }
      ) {
        ...json
      }
      textSection: contentfulJson(
        contentful_id: { eq: "4uTNOt8lFINM7A1AvWw3eH" }
      ) {
        ...json
      }
      featureSectionAsset: contentfulAsset(
        contentful_id: { eq: "2iEddqw4heR3jDmoQDuY2S" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      featureSection: contentfulJson(
        contentful_id: { eq: "2BAR4YlsGooJbWxopPhg8X" }
      ) {
        ...json
      }
      optionsComparison: contentfulJson(
        contentful_id: { eq: "7ugz4V6Josv2LmM2LBHXux" }
      ) {
        ...json
      }
      optionsComparisonDIYAsset: contentfulAsset(
        contentful_id: { eq: "2C2OHoztyEyOFxOxzweR3x" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      optionsComparisonProInstallAsset: contentfulAsset(
        contentful_id: { eq: "1md4MHLECa3FREbwDzgIbk" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      mobileOptionsComparisonProInstallAsset: contentfulAsset(
        contentful_id: { eq: "2S0uocpKjAAZPEbSWMZwte" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      heroBannerAsset: contentfulAsset(
        contentful_id: { eq: "6JsB67aqNgW59O4IpM8uQR" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      mobileheroBannerAsset: contentfulAsset(
        contentful_id: { eq: "7ikgorpiBwz37621g0olfV" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      heroBanner: contentfulJson(
        contentful_id: { eq: "4AJCgSO0gVffYOsrZ5CaU" }
      ) {
        ...json
      }
    }
  `)
}
