import { ninetailedFeatureFlagSchema } from '@ecomm/promotions-components'
import { graphql, useStaticQuery } from 'gatsby'

export const usePromoBannerExperimentQuery = () =>
  ninetailedFeatureFlagSchema.parse(
    useStaticQuery(graphql`
      query HLPromoBannerExperiment {
        promoBannerExperiment: contentfulNinetailedFeatureFlag(
          contentful_id: { eq: "1GaRfPYfJQJuediSrCar1r" }
        ) {
          ...homeLandingFeatureFlagBase
          nt_experiences {
            ...homeLandingFeatureFlagExperience
          }
        }
      }
    `).promoBannerExperiment
  )
