import { graphql, useStaticQuery } from 'gatsby'

import { odmonFeatureFlagSchema } from './schema'

export const useOdmonFeatureFlagQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    fragment odmonFeatureFlagFrag on ContentfulNinetailedFeatureFlag {
      id: contentful_id
      title
      json {
        id
        tag
      }
    }

    fragment odmonFeatureFlagExp on ContentfulNinetailedExperience {
      id: contentful_id
      name: nt_name
      type: nt_type
      audience: nt_audience {
        id: contentful_id
        name: nt_name
      }
      config: nt_config {
        components {
          baseline {
            id
          }
          variants {
            id
            hidden
          }
        }
        traffic
        distribution
      }
      variants: nt_variants {
        ... on ContentfulNinetailedFeatureFlag {
          ...odmonFeatureFlagFrag
        }
      }
    }

    query OdmonFeatureFlagQuery {
      contentfulNinetailedFeatureFlag(
        contentful_id: { eq: "2uosOt6dmR3EsTLOD8se75" }
      ) {
        ...odmonFeatureFlagFrag
        nt_experiences {
          ...odmonFeatureFlagExp
        }
      }
    }
  `)

  return odmonFeatureFlagSchema.parse(
    staticQuery.contentfulNinetailedFeatureFlag
  )
}
