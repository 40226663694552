import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track click checkout progress bar to navigate to previous checkout step.
 */
export function trackCheckoutProgressSteps(clickText: string) {
  getRudderstack(r =>
    r.track('checkout_progress_steps', {
      click_text: clickText.toLowerCase()
    })
  )
}
