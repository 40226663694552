import { getCookie } from '@ecomm/shared-cookies'
import { getRudderstack } from '@ecomm/shared-window'
import { window } from 'browser-monads-ts'

export type WizardType =
  | 'embedded'
  | 'floating'
  | 'guarantee'
  | 'hero'
  | 'monitoring_component'
  | 'nav'
  | 'popup'

/**
 * Track quote wizard events.
 */
export function trackQuoteWizard({
  qw_type,
  qw_action
}: {
  readonly qw_type: WizardType
  readonly qw_action: 'complete' | 'open' | 'start'
}) {
  getRudderstack(r => r.track('quote_wizard', { qw_type, qw_action }))
}

export function listenToJebbitPostMessages() {
  const childOrigin = 'https://simplisafe.jebbit.com'
  const childOriginNew = 'https://quiz.simplisafe.com'
  const pollInterval = 400

  const postCallback = function (event: any) {
    if (event.origin !== childOrigin && event.origin !== childOriginNew) {
      return
    }

    if (
      event.data === 'childReady' &&
      event.source &&
      event.source.postMessage
    ) {
      // Send event that parent is ready
      try {
        event.source.postMessage('parentReady', event.origin)
      } catch (e) {
        console.error(e)
      }

      const poll = setInterval(() => {
        // Only proceed if GA loaded
        const gaClientId = getCookie('_ga')

        if (window.gaGlobal && gaClientId) {
          // Send message back to frame with Client ID
          try {
            event.source.postMessage(
              {
                event: 'clientId',
                clientId: gaClientId
              },
              event.origin
            )
          } catch (e) {
            console.error(e)
          } finally {
            clearInterval(poll)
          }
        }
      }, pollInterval)
    }

    if (event.data.event === 'start' || event.data.event === 'complete') {
      trackQuoteWizard({
        qw_type: event.data.wizardType,
        qw_action: event.data.event
      })
    }
  }

  window.addEventListener('message', postCallback)
}
