//@ts-nocheck
import React, { FC } from 'react'

import * as css from './PhoneCTA.module.scss'

export type PhoneCTAProps = {
  /** The data-component attribute value for identifying this component when it renders. */
  readonly dataComponent?: string
  /** The phone number for users to call so a rep can complete their order. */
  readonly phone: string
  /** The contact text. Example: "Contact us today to redeem your free SimpliSafe Security System". */
  readonly text: string
}

/** @deprecated Do not use ss-react-components*/
const PhoneCTA: FC<PhoneCTAProps> = ({
  dataComponent = PhoneCTA.name,
  phone,
  text
}: PhoneCTAProps) => {
  return (
    <div className={css.cta} data-component={dataComponent}>
      <h2 className={css.text}>{text}</h2>
      <p className={css.phone}>{phone}</p>
    </div>
  )
}

export default PhoneCTA
