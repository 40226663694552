import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { TypeOf } from 'zod'

import { alarmCategoriesSchema } from './schema'

type IllustrationProps = TypeOf<typeof alarmCategoriesSchema>['image']

export function Illustration({ image }: { readonly image: IllustrationProps }) {
  return (
    <div
      className="relative mt-2 w-full max-w-3xl text-center"
      data-component="Illustration"
    >
      {image && (
        <GatsbyImage
          alt={image.description || image.title}
          className="w-full object-contain"
          image={image.gatsbyImageData}
        />
      )}
    </div>
  )
}
