import { Link } from 'gatsby'
import React from 'react'
import { TypeOf } from 'zod'

import { ctaSchema } from './schema'
import { useOnLiveGuardCtaClick } from './useOnLiveGuardCtaClick'

type AlarmMomentsCtaProps = TypeOf<typeof ctaSchema>

export function AlarmMomentsCta({ text, link }: AlarmMomentsCtaProps) {
  const trackOnClick = useOnLiveGuardCtaClick()

  const handleOnClick = () => {
    trackOnClick()
  }

  return (
    <Link
      className={`rounded-base border-btn-primary text-btn-primary hover:bg-btn-primary mt-4 w-full cursor-pointer border border-solid px-4 py-3 text-center no-underline transition-colors duration-200 ease-in-out hover:text-white md:mt-6 md:w-fit`}
      onClick={handleOnClick}
      to={link}
    >
      {text}
    </Link>
  )
}
