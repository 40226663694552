// This component is a simplified variant of the original `AddToCartButton` located at `apps/shop/src/components/AddToCartButton`.
// It was specifically created to add monitoring plans to the cart. This version is decoupled from dependencies and specific logic that are not necessary here.
//
// TODO: Evaluate the possibility of merging this component with the original `AddToCartButton` in a shared location to improve code reuse and maintenance.

import { LoadingSpinner } from '@ecomm/checkout-icons'
import { logError } from '@ecomm/error-handling'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import * as O from 'fp-ts/lib/Option'
import { navigate } from 'gatsby'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import AddToCartError from './AddToCartError'

type AddPlanToCartButtonProps = {
  readonly buttonText: string
  readonly sku: string
  readonly quantity?: number
  readonly className?: string
  readonly onClick?: () => void
}

type AddToCartErrorType = 'recoverable' | 'unrecoverable' | null

type AddPlanToCartButtonState = {
  readonly showSpinner: boolean
  readonly addToCartError: AddToCartErrorType
}

export function AddPlanToCartButtonProduct({
  buttonText,
  sku,
  quantity = 1,
  className = '',
  onClick = () => null
}: AddPlanToCartButtonProps) {
  const dispatch = useDispatch()

  const [state, setState] = useState<AddPlanToCartButtonState>({
    showSpinner: true,
    addToCartError: null
  })

  const addToCart = useCallback(() => {
    const handleSuccess = () => {
      onClick()
      setState({ ...state, showSpinner: false })
      navigate('/cart')
    }

    const handleFailure = () => {
      setState({ ...state, showSpinner: false, addToCartError: 'recoverable' })
    }

    O.fold(
      () => {
        setState({
          ...state,
          showSpinner: false,
          addToCartError: 'unrecoverable'
        })
        logError(Error('Cannot add to cart: received null/empty sku'))
      },
      (_sku: string) => {
        const product = {
          quantity,
          sku: _sku
        }

        dispatch(
          IOAddToCart({ products: [product] }, handleFailure, handleSuccess)
        )
      }
    )(O.fromNullable(sku))
  }, [quantity])

  useEffect(() => {
    sku && setState({ ...state, showSpinner: false })
  }, [sku])

  return (
    <>
      <button
        className={`btn btn-solid-primary self-center ${className}`}
        onClick={addToCart}
      >
        {state.showSpinner ? (
          <LoadingSpinner fillColor="white" size={24} />
        ) : (
          buttonText
        )}
      </button>
      {state.addToCartError ? (
        <div className="m-auto ml-2 max-w-[200px]">
          <AddToCartError
            errorType={state.addToCartError!}
            textAlign="center"
          />
        </div>
      ) : null}
    </>
  )
}
