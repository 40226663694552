import { graphql, useStaticQuery } from 'gatsby'

import plpPageSchema from './plpPageSchema'

export const useOdmonPLPQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query OdmonPlpTemplate {
      contentfulPackageListingPage(contentful_id: { eq: "2NZwUx14bynQ8WlEppNqv5" }) {
        ...plpPage
        ...plpPageVariations
      }
    }
  `)

  return plpPageSchema.parse(staticQuery.contentfulPackageListingPage)
}
