import { isRouteErrorResponse } from '@remix-run/react'
import { match, P } from 'ts-pattern'
import { z } from 'zod'

const ServerError = z
  .object({
    message: z.string(),
    cause: z.string().optional()
  })
  .passthrough()

export const parseRouteError = (x: unknown) =>
  match(x)
    .when(isRouteErrorResponse, err => {
      try {
        const res = ServerError.safeParse(JSON.parse(err.data))
        return res.success === true
          ? Error(res.data.message, { cause: res.data.cause })
          : Error(JSON.stringify(x))
      } catch (err) {
        return Error(JSON.stringify(err))
      }
    })
    .with(P.instanceOf(Error), err => err)
    .otherwise(() => Error(JSON.stringify(x)))
