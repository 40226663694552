import { deployEnv, locale } from '@ecomm/data-env-variables'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

/** Available CT locales */
type Locale = 'en-GB' | 'en-US'

type CurrencyCode = 'GBP' | 'USD'

type CountryName = 'United Kingdom' | 'United States'

/**
 * Represents an ISO 3166 Alpha-3 country code.
 *
 * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
 */
type Alpha3CountryCode = 'GBR' | 'USA'

type LocaleInfo = {
  readonly [key in Locale]: {
    readonly alpha3Code: Alpha3CountryCode
    readonly country: string
    readonly currency: CurrencyCode
    readonly domain: string
    readonly countryName: CountryName
    readonly priceChannel: string
    readonly site: string
    readonly strikeThroughKey: string
    readonly strikeThroughKeyWithServicePlan: string
    readonly plaStrikeThroughKey?: string
  }
}

/** Price channels for each locale and environment */
const priceChannels = {
  UK: {
    development: '1bfff3c2-3c0d-4ad2-9a02-1917f2918c9b',
    prd: '78389942-d07e-49ea-b6db-d9880399b866',
    qa: '1bfff3c2-3c0d-4ad2-9a02-1917f2918c9b',
    stg: '99b7e451-fd77-4355-842a-d5c4f5a72ca9'
  },
  US: {
    development: '769db2fd-1900-455c-826a-062b72e16936',
    prd: 'ac57a074-b417-40c1-b592-6e51b9f60cfc',
    qa: '769db2fd-1900-455c-826a-062b72e16936',
    stg: 'eeb5e09e-5f2a-4062-b69c-1c1dfd105b5a'
  }
}

/**
 * Country, currency, domain and price channel for each locale
 */
const localesInfo: TE.TaskEither<Error, LocaleInfo> = pipe(
  deployEnv,
  TE.map(deploy => ({
    'en-GB': {
      alpha3Code: 'GBR',
      country: 'GB',
      countryName: 'United Kingdom',
      currency: 'GBP',
      domain: 'uk',
      plaStrikeThroughKey: 'plaDiscountGroup-UK',
      priceChannel: priceChannels.UK[deploy],
      site: 'simplisafe.co.uk',
      strikeThroughKey: 'discountGroup-UK',
      strikeThroughKeyWithServicePlan: 'discountGroupService-UK'
    },
    'en-US': {
      alpha3Code: 'USA',
      country: 'US',
      countryName: 'United States',
      currency: 'USD',
      domain: 'us',
      plaStrikeThroughKey: 'plaDiscountGroup-US',
      priceChannel: priceChannels.US[deploy],
      site: 'simplisafe.com',
      strikeThroughKey: 'discountGroup-US',
      strikeThroughKeyWithServicePlan: 'discountGroupService-US'
    }
  }))
)

/**
 * To get the locale info for the configured environment locale variable.
 */
export const localeInfo = pipe(
  TE.Do,
  TE.bind('locale', () => locale),
  TE.bind('info', () => localesInfo),
  TE.map(({ locale, info }) => info[locale])
)
