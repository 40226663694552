import { optimizelyClientAtom } from '@ecomm/optimizely-utils'
import { pipe } from 'fp-ts/lib/function'
import * as TO from 'fp-ts/TaskOption'
import { useAtom } from 'jotai'

/**
 * Returns an Optimizely client that is ready.
 *
 * @private
 */
export const useReadyClient = () => {
  const [client] = useAtom(optimizelyClientAtom)

  return pipe(
    TO.fromOption(client),
    TO.chain(c => {
      return pipe(
        TO.tryCatch(() => c.onReady()),
        TO.chain(status => (status.success ? TO.of(status) : TO.none)),
        TO.chain(() => TO.fromOption(client))
      )
    })
  )
}
