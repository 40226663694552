import { useMicroCopy } from '@ecomm/micro-copy'
import { useEnv } from '@ecomm/utils'
import { Modal } from '@ecomm/ss-react-components'
import { localStorage } from '@simplisafe/ewok'
import React, { useState } from 'react'
const { get, set } = localStorage

const geoRedirectKey = 'geoRedirectSuggested'

export function CountryRedirectModal() {
  const { locale } = useEnv()
  const getBrowserLanguage = (browserLanguage: string) =>
    browserLanguage.toLowerCase() === 'en' ? 'en-US' : browserLanguage

  const showRedirectModal =
    typeof navigator !== 'undefined' &&
    locale !== getBrowserLanguage(navigator.language) &&
    !get(geoRedirectKey)
  const [isRedirectModalVisible, setRedirectModalVisibility] =
    useState(showRedirectModal)

  const handleClose = () => {
    setRedirectModalVisibility(false)
    set(geoRedirectKey, true)
  }

  const microcopy = useMicroCopy()

  return (
    <Modal isOpen={isRedirectModalVisible} onRequestClose={handleClose}>
      <div className="prose md:prose-md flex w-full flex-col items-center p-8 px-4 py-8 text-center md:max-w-sm md:px-8 md:py-16 lg:max-w-lg lg:p-16">
        <h2 className="mb-4">{microcopy['country-redirect-title']}</h2>
        <p>{microcopy['country-redirect-text']}</p>
        <a
          className="btn btn-solid-primary mt-4 block w-fit min-w-[190px] md:mt-6"
          href={microcopy['country-redirect-link']}
        >
          {microcopy['country-redirect-button-text']}
        </a>
      </div>
    </Modal>
  )
}
