import React from 'react'

import { getMappedComponent } from '../../utils/getMappedComponent'
import { ComponentTypes, ContentCollectionProps } from './schema'

export const mapPageComponents = (components: readonly ComponentTypes[]) =>
  components.map((componentData, index) =>
    getMappedComponent(componentData, index)
  )

export default function ContentCollection({ content }: ContentCollectionProps) {
  return <>{mapPageComponents(content)}</>
}
