import { ODMON_VALIDATION_ERROR_CODE } from '@ecomm/data-constants'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

import { validateToken, ValidationResponse } from './lib/odmonClient'

const validationError = Error(ODMON_VALIDATION_ERROR_CODE)

/**
 * Hit API for token validity and shunt a false Right to a Left
 * -- from <Error | Response, boolean> to <Error, true> -- such
 * that the result TaskEither is Left side invalid, Right side
 * valid.
 */
export const isTokenValid = (token: string): TE.TaskEither<Error, true> =>
  pipe(
    validateToken(token),
    TE.chain<Error | Response, ValidationResponse, true>(response =>
      response.valid ? TE.right(true) : TE.left(validationError)
    ),
    TE.mapLeft<Error | Response, Error>(() => validationError)
  )
