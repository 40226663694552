import { ninetailedFeatureFlagSchema } from '@ecomm/promotions-components'
import { graphql, useStaticQuery } from 'gatsby'

export const usePromoBannerExperimentQuery = () =>
  ninetailedFeatureFlagSchema.parse(
    useStaticQuery(graphql`
      query ShopPromoBannerExperiment {
        promoBannerExperiment: contentfulNinetailedFeatureFlag(
          contentful_id: { eq: "1GaRfPYfJQJuediSrCar1r" }
        ) {
          ...shopFeatureFlagBase
          nt_experiences {
            ...shopFeatureFlagExperience
          }
        }
      }
    `).promoBannerExperiment
  )
