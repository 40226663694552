// TODO This should be replaced by gatsby-background-image in the front-end and moved into storybookComponents

import React, { FC, ReactNode } from 'react'

import useMediaQuery from '../hooks/useMediaQuery'

export type ImageProps = {
  readonly srcImage: string
  readonly children: ReactNode
  readonly className?: string
}

const ImageContainer: FC<ImageProps> = ({ srcImage, className, children }) => (
  <div
    className={`${className} min-w-full bg-cover`}
    style={{
      background: `url(${srcImage}) center center no-repeat`
    }}
  >
    {children}
  </div>
)

export type BGImageProps = {
  readonly srcImage: string
  readonly srcImageTablet?: string
  readonly srcImageDesktop?: string
  readonly children?: ReactNode
  readonly className?: string
  readonly dataComponent?: string
}

export const BGImage: FC<BGImageProps> = ({
  srcImage,
  srcImageDesktop,
  srcImageTablet,
  children,
  className,
  dataComponent = BGImage.name
}: BGImageProps) => {
  const isTabletUp = useMediaQuery('TabletAndUp')
  const isDesktopUp = useMediaQuery('DesktopAndUp')
  const img = isDesktopUp
    ? srcImageDesktop
    : isTabletUp
      ? srcImageTablet
      : srcImage

  return (
    <ImageContainer
      className={className}
      data-component={dataComponent}
      srcImage={img || srcImage}
    >
      {children}
    </ImageContainer>
  )
}

export default BGImage
