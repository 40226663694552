import classNames from 'classnames'
import React from 'react'

import { ColorVariantSchema } from '../schema'

export type Props = {
  readonly data: ColorVariantSchema
  readonly isSelected: boolean
  readonly onClick: () => void
  readonly placement: string
}

const bgColorOptions = (color: string, isSelected: boolean) => {
  return classNames(
    'p-0.5 mt-0 inline-block h-12 w-12 cursor-pointer appearance-none rounded-full bg-clip-content',
    { 'bg-neutral-light-50': color === 'White' },
    { 'bg-neutral-black': color === 'Obsidian' },
    { 'shadow-[0_0_0_3px_var(--complementary-blue-100)]': isSelected }
  )
}

function ColorBody({ data, isSelected, onClick }: Props) {
  const labelId = data.sku
  const color = data.productColor

  return (
    <div
      className={classNames(
        'breakLine ssProse md:order-0 prose-p:!m-0 prose-p:text-sm md:prose-p:text-base mt-4 flex items-center pl-1.5 text-left first:mt-0 md:mt-0'
      )}
      data-component="ColorBody"
      onClick={onClick}
    >
      <input
        aria-label={`Color: ${color}`}
        aria-labelledby={labelId}
        checked={isSelected}
        className={classNames(bgColorOptions(color, isSelected))}
        data-component={labelId}
        name={color}
        readOnly={true}
        type="radio"
        value={labelId}
      />
      <label className={classNames('screenReaderText')} id={labelId}>
        {color}
      </label>
    </div>
  )
}

export function ColorBodySlim({ data, isSelected, onClick, placement }: Props) {
  const labelId = data.sku
  const color = data.productColor

  return (
    <div
      className={classNames(
        'breakLine ssProse md:order-0 prose-p:!m-0 prose-p:text-sm md:prose-p:text-base mt-4 flex items-center pl-1.5 text-left first:mt-0 md:mt-0'
      )}
      data-component="ColorBody"
      onClick={onClick}
    >
      <input
        aria-label={`Color: ${color}`}
        aria-labelledby={`${placement}-${labelId}`}
        checked={isSelected}
        className={classNames(
          bgColorOptions(color, isSelected),
          'border-neutral-light-100 mr-2 h-[18px] w-[18px] border-[1px] border-solid',
          {
            'shadow-[0_0_0_2px_var(--complementary-blue-100)]': isSelected
          }
        )}
        data-component={`${placement}-${labelId}`}
        name={color}
        readOnly={true}
        type="radio"
        value={labelId}
      />
      <label
        className={classNames('screenReaderText')}
        id={`${placement}-${labelId}`}
      >
        {color}
      </label>
    </div>
  )
}

export default ColorBody
