import classNames from 'classnames'
import prop from 'ramda/src/prop'
import React, { ComponentType, FC, ReactNode } from 'react'

import Square from '../Square'

export type OverlayBannerProps = {
  /** A Function Component that accepts any props and will replace the containing div as a background image */
  readonly BackgroundComponent?: ComponentType<{
    readonly [x: string]: unknown
  }>
  /** Props that are passed to the BackgroundComponent */
  readonly backgroundComponentProps?: Record<string, unknown>
  readonly title: string
  // description content from richtext contentul
  /** The content of the component */
  readonly content?: ReactNode
  // additional content from richtext contentul
  /** Additional content of the component */
  readonly additional?: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const OverlayBanner: FC<OverlayBannerProps> = ({
  BackgroundComponent,
  backgroundComponentProps = {},
  title,
  content: contentProp,
  additional,
  dataComponent = OverlayBanner.name
}: OverlayBannerProps) => {
  const content = (
    <>
      <Square
        className={classNames(
          'mb-[186px] !bg-transparent bg-gradient-to-t from-transparent to-black px-9 pt-6 md:mb-0 md:ml-16 md:flex md:w-[240px] md:items-center md:justify-center md:!bg-black md:px-8 md:py-16 md:opacity-80',
          'prose-h3:m-0 prose-p:mb-[5px] prose-hr:my-2.5 prose-hr:mx-auto prose-hr:md:my-6 prose-hr:lg:my-8'
        )}
      >
        {additional ? (
          <div className="whitespace-pre-line text-center">{additional}</div>
        ) : null}
      </Square>
      <div
        className={classNames(
          'mx-auto my-0 w-full max-w-[420px] px-6 pb-8 text-center md:my-auto md:ml-auto md:mr-16 md:max-w-[280px] md:p-0 md:text-left lg:max-w-[310px]',
          'prose-a:bg-transparent prose-a:block prose-a:mt-2.5 prose-a:py-3 prose-a:w-full md:prose-a:mt-5',
          '[&>*_button]:mt-2.5 [&>*_button]:block [&>*_button]:w-full [&>*_button]:bg-transparent [&>*_button]:py-3 md:[&>*_button]:mt-5'
        )}
      >
        <h2 className="text-white">{title}</h2>
        {contentProp}
      </div>
    </>
  )

  // Merge supplied className if exists with this component's 'overlay' className.
  const backgroundProps = {
    ...backgroundComponentProps,
    ...{
      className: classNames(
        'rounded-base overflow-hidden bg-[center_65%] bg-no-repeat bg-cover',
        {
          [`${prop('className', backgroundComponentProps)}`]:
            prop('className', backgroundComponentProps) !== undefined
        }
      )
    }
  }

  return BackgroundComponent ? (
    <BackgroundComponent {...backgroundProps} data-component={dataComponent}>
      <div
        className={classNames(
          'prose md:prose-md lg:prose-lg prose-h3:text-white prose-p:text-white prose-p:my-[5px] relative z-[1] w-full md:flex md:min-h-[600px]'
        )}
      >
        {content}
      </div>
    </BackgroundComponent>
  ) : (
    <div {...backgroundProps} data-component={dataComponent}>
      <div
        className={classNames(
          'prose md:prose-md lg:prose-lg prose-h3:text-white prose-p:text-white prose-p:my-[5px] w-full md:flex md:min-h-[600px]'
        )}
      >
        {content}
      </div>
    </div>
  )
}

export default OverlayBanner
