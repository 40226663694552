import React, { ReactNode, useState } from 'react'

import { Accordion, AccordionProps } from './Accordion'

export type AccordionGroupProps = {
  readonly images: readonly ReactNode[]
  readonly accordions: readonly AccordionProps[]
  readonly dataComponent?: string
}

/**
 * @deprecated Only used in legacy; use `import { Accordion } from '@ecomm/shared-components` instead
 */
export function AccordionGroup({
  images,
  accordions,
  dataComponent = 'AccordionGroup'
}: AccordionGroupProps) {
  const [selected, setSelected] = useState<string>(accordions[0].id)
  const currentId = accordions.findIndex(accordion => accordion.id === selected)

  return (
    <div
      className={
        'flex w-full flex-col-reverse rounded-3xl bg-[#f1f1f2] lg:flex-row'
      }
      data-component={dataComponent}
    >
      <div className={'items-center justify-center p-2 md:p-6 lg:w-1/2'}>
        {accordions.map((accordion: AccordionProps) => (
          <div
            className={'m-1 bg-white first:rounded-t-3xl last:rounded-b-3xl'}
            key={accordion.id}
            onClick={() => setSelected(accordion.id)}
          >
            <Accordion
              key={accordion.id}
              {...{
                ...accordion,
                isExpanded: selected === accordion.id
              }}
            />
          </div>
        ))}
      </div>
      <div className="block h-full w-full grow overflow-hidden lg:h-[504px] lg:w-1/2">
        <div
          className={
            'h-full w-full overflow-hidden rounded-t-3xl lg:rounded-l-none lg:rounded-r-3xl'
          }
        >
          {images[currentId]}
        </div>
      </div>
    </div>
  )
}
