import { useLocale } from '@ecomm/data-hooks'
import { OfferTag, OfferTagProps } from '@ecomm/ss-react-components'
import { safeProp } from '@simplisafe/monda'
import classNames from 'classnames'
import React, { ReactElement, ReactNode } from 'react'
import * as uuid from 'short-uuid'

import { AffirmMonthlyDivider } from '../..'
import { AffirmMonthlyOption } from '../AffirmMonthlyOption'
import { AffirmClient } from '../types/affirm'

export type PriceDetails = {
  readonly price?: ReactElement
  readonly pieces?: number
}

type position = 'bottom' | 'left' | 'right' | 'top'
type disclaimerPosition = 'above' | 'below'

export type ItemProduct = {
  readonly productName: string
}

export type CardItemBannerProps = {
  readonly additionalInfo?: ReactNode
  readonly affirmClient?: AffirmClient | null
  readonly affirmPromoMessage?: ReactNode
  readonly disclaimerText?: string
  /** position of the disclaimer text relative to the price */
  readonly disclaimerPosition?: disclaimerPosition
  readonly priceDetails?: PriceDetails
  /** child node - gatsby-image from frontend */
  readonly itemImage: ReactNode
  readonly imagePosition: position | 'background'
  readonly textPosition: position
  /** The content of the component */
  readonly productDescriptionContent?: ReactNode
  /** The content of the component */
  readonly productHighlightsContent?: ReactNode
  readonly title: string
  /** Button to take the user to the product page */
  readonly productButton: ReactElement
  readonly popup?: (popUpData: Record<string, unknown>) => Element | ReactNode
  readonly dataComponent?: string
  readonly offerTag?: OfferTagProps
  readonly productSensors?: ReadonlyArray<ItemProduct>
  readonly showSensorList?: boolean
  readonly numberRegularPrice?: number
  readonly numberDiscountedPrice?: number
}

const renderSensorList = (productSensors: ReadonlyArray<ItemProduct>) => {
  return (
    <ul className="columns-2">
      {productSensors.map((includedItem, idx) => (
        <span key={'IncludedItem' + idx}>
          <p className="my-[5px] font-normal">{includedItem.productName}</p>
        </span>
      ))}
    </ul>
  )
}
const renderPieces = (priceDetails: PriceDetails | undefined, title: string) =>
  priceDetails
    ? safeProp('pieces', priceDetails).map(pieces => (
        <span
          className={
            'bg-neutral-light-50 absolute bottom-0 right-0 z-10 px-7 py-3 font-bold capitalize md:bottom-8 md:text-lg'
          }
          key={`${title}-pieces-${pieces}`}
        >{`${pieces} pieces`}</span>
      ))
    : null

const renderPrice = (priceDetails: PriceDetails | undefined, title: string) =>
  priceDetails
    ? safeProp('price', priceDetails).cata(
        () => null,
        price => (
          <div className="text-2xl font-light" key={`${title}-price-${price}`}>
            {price}
          </div>
        )
      )
    : null

const renderBodyText = (
  price: ReactNode,
  disclaimerText: string | undefined,
  disclaimerPosition: disclaimerPosition,
  additionalInfo: ReactNode
) => {
  const key = uuid.generate()
  const disclaimer = disclaimerText && (
    <p className="m-0 text-xs font-normal" key={`disclaimer-text-${key}`}>
      {disclaimerText}
    </p>
  )
  const additional = additionalInfo && (
    <div
      className="text-complementary-red-100 m-0 text-base	font-normal"
      key={`additional-info-wrapper-${key}`}
    >
      {additionalInfo}
    </div>
  )
  const renderingOrder =
    disclaimerPosition === 'below'
      ? [price, additional, disclaimer]
      : [disclaimer, price, additional]
  return price && <div>{renderingOrder}</div>
}

export function CardItemBanner({
  affirmClient = null,
  itemImage = null,
  priceDetails,
  productDescriptionContent = null,
  additionalInfo = null,
  disclaimerText,
  disclaimerPosition = 'below',
  showSensorList = false,
  productSensors = [],
  productHighlightsContent,
  title,
  dataComponent = CardItemBanner.name,
  offerTag,
  productButton = <div data-component="productButton" />,
  numberDiscountedPrice,
  numberRegularPrice
}: CardItemBannerProps) {
  const isUs = useLocale() === 'en-US'
  const key = uuid.generate()
  const price =
    isUs && numberRegularPrice ? (
      <AffirmMonthlyOption
        affirmClient={affirmClient}
        discountedPrice={numberDiscountedPrice}
        isPlp
        key={key}
        stacked
        subTotalPrice={numberRegularPrice}
      />
    ) : (
      renderPrice(priceDetails, title)
    )
  return (
    <div
      className="max-w-8xl bg-neutral-light-50 from-neutral-light-50 mx-auto flex flex-col overflow-hidden rounded-2xl bg-gradient-to-b from-0% via-white via-75% to-black/30 md:flex-row"
      data-component={dataComponent}
    >
      <div className="relative w-full flex-1 md:order-1 ">
        {offerTag ? (
          <OfferTag
            className="z-10 h-12 w-auto font-medium md:absolute md:top-8 md:min-w-[15.5rem] md:text-lg lg:px-4"
            {...offerTag}
          />
        ) : null}
        {itemImage}
        {renderPieces(priceDetails, title)}
      </div>
      <div className="bg-neutral-light-50 flex flex-1 items-center">
        <div
          className="flex flex-1 flex-col px-4 py-8 md:p-16"
          id="package-info"
        >
          <h2 className="h2">{title}</h2>
          {productDescriptionContent}
          <div className="">
            {productSensors.length > 0 && showSensorList ? (
              <div className="py-4 [&>ul]:p-0">
                {renderSensorList(productSensors)}
              </div>
            ) : (
              productHighlightsContent
            )}
          </div>

          {isUs ? <AffirmMonthlyDivider className="my-2" stacked /> : null}
          <div
            className={classNames(
              'mt-auto grid grid-flow-row items-center gap-4 lg:auto-cols-[1-fr] lg:grid-flow-col',
              {
                'pt-10': !isUs,
                'pt-0 lg:pt-0': isUs
              }
            )}
          >
            {renderBodyText(
              price,
              disclaimerText,
              disclaimerPosition,
              additionalInfo
            )}
            <div
              className={classNames('', {
                'mt-0 w-full self-end lg:w-auto': isUs
              })}
            >
              {React.cloneElement(productButton, {
                className: classNames('', {
                  'w-full font-bold md:w-full': isUs
                })
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
