import { groupBy } from '@simplisafe/ewok'

import { getPreviousDates } from './dates'

export const policeDataUrl = 'https://data.police.uk/api'

export type PoliceDataValue = {
  readonly category: string
  readonly context?: string
  readonly id: number
  readonly location?: { readonly latitude: string; readonly longitude: string }
}

export type CrimeDataType = {
  readonly key: string
  readonly color: string
  readonly count: number
  readonly title: string
}

export const mapBoxToken =
  'pk.eyJ1IjoicG9ubmFyYXNhbiIsImEiOiJja2p5YnNqeGcwN2EwMndwZDUzcmV1YXF4In0.DIEeanGhFnkvRPWnD5_iww'
export const mapBoxUrl = 'https://api.mapbox.com/geocoding/v5'

export const crimeDataArray: readonly CrimeDataType[] = [
  {
    color: 'var(--complementary-red-100)',
    count: 0,
    key: 'burglary',
    title: 'BURGLARY'
  },
  {
    color: 'var(--primary-100)',
    count: 0,
    key: 'criminal-damage-arson',
    title: 'CRIMINAL DAMAGE AND ARSON'
  },
  {
    color: 'var(--neutral-medium-100)',
    count: 0,
    key: 'anti-social-behaviour',
    title: 'ANTI-SOCIAL BEHAVIOUR'
  },
  {
    color: 'var(--primary-50)',
    count: 0,
    key: 'bicycle-theft',
    title: 'BICYCLE THEFT'
  },
  {
    color: 'var(--complementary-tan)',
    count: 0,
    key: 'other-theft',
    title: 'OTHER THEFT'
  }
]

export async function getYearlyPoliceDataForLocation(
  lat: number,
  lng: number
): Promise<readonly (readonly PoliceDataValue[])[]> {
  const dates = getPreviousDates(12, 2)
  const left = dates.slice(0, dates.length / 2)
  const right = dates.slice(dates.length / 2, dates.length)

  return Promise.all([
    await Promise.all(
      left.map(async (date: string) => {
        const res = await fetch(
          `${policeDataUrl}/crimes-street/all-crime?lat=${lat}&lng=${lng}&date=${date}`
        )
        return res.json()
      })
    ),
    await Promise.all(
      right.map(async (date: string) => {
        const res = await fetch(
          `${policeDataUrl}/crimes-street/all-crime?lat=${lat}&lng=${lng}&date=${date}`
        )
        return res.json()
      })
    )
  ])
}

export async function getSearhTermLocation(location: string) {
  const res = await fetch(
    `${mapBoxUrl}/mapbox.places/${location}.json?access_token=${mapBoxToken}`
  )
  return res.json()
}

export function groupCrimeData(
  data: readonly PoliceDataValue[]
): readonly CrimeDataType[] {
  const groupData = groupBy(data, 'category')
  return crimeDataArray.map(cData => {
    return {
      ...cData,
      count: groupData[cData.key]?.length ?? 0
    }
  })
}
