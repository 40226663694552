import { PriceProvider } from '@ecomm/data-price'
import { Breadcrumbs, Header } from '@ecomm/header-redesigned'
import { LiveChat } from '@ecomm/live-chat'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  FAQSection,
  Footer,
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { Locale, SEO } from '@ecomm/utils'
import { graphql, PageProps } from 'gatsby'
import { useEffect } from 'react'

import InstallationInstructions from '../../components/SensorPageComponents/InstallationInstructions'
import OverviewVideoSection from '../../components/SensorPageComponents/OverviewVideoSection'
import SensorFeatures from '../../components/SensorPageComponents/SensorFeatures'
import SensorHero from '../../components/SensorPageComponents/SensorHero'
import { SeoNodeSchema } from '../../config/seoNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useResetDraftCart } from '../../hooks/draftCart/useResetDraftCart'
import { useHeaderRedesignQuery } from '../../hooks/HeaderRedesign/useHeaderRedesignQuery'
import { useEcommerceProduct } from './hooks'
import { SensorPageSchema } from './sensorPageSchema'
import { useSensorPageFragment } from './useSensorPageFragment'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
  readonly slug: string
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

function ProductLandingTemplate({
  data,
  pageContext: { locale, seoDetails }
}: Props<{ readonly contentfulProductLandingPage: SensorPageSchema }>) {
  const { layout, product } = useSensorPageFragment(data)
  const {
    sku,
    overviewVideo,
    installationImage,
    installationInstructions,
    features,
    lifestyleImage,
    faq,
    slug,
    name
  } = product

  const {
    canonicalLink,
    isNofollow,
    isNoindex,
    metaDescription,
    metaKeywords,
    metaTitle
  } = seoDetails
  const { footer, promoBannerStyle, liveChat } = layout
  const promoType = toPromoBannerStyleValue(promoBannerStyle) ?? 'none'
  const headerData = useHeaderRedesignQuery()
  const { isOnStock, isSellable, type } = useEcommerceProduct(sku)
  const microcopy = useMicroCopy()

  // Force wipe Draft Cart state when leaving Sensor page.
  // If we don't wipe draft cart, the sensor that was added to cart get pre-filled on BMS which can lead to invalid children such as wifi-extenders.
  // TODO: This functionality can be removed when Jotai Providers are
  // template scoped: https://simplisafe.atlassian.net/browse/ECP-8723
  const wipeDraftCart = useResetDraftCart()
  useEffect(() => {
    return () => {
      wipeDraftCart()
    }
  }, [])

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle="shop">
      <PageToaster />
      <ApplyPromoCode />
      <PriceProvider locale={locale} skus={[sku]}>
        <SEO
          canonicalLink={canonicalLink ?? ''}
          isNofollow={isNofollow}
          isNoindex={isNoindex}
          lang={locale}
          metaDescription={metaDescription.metaDescription}
          metaKeywords={metaKeywords ?? []}
          metaTitle={metaTitle}
        />
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={promoType}
        />
        <Header {...headerData} />
        <Breadcrumbs
          steps={[
            {
              label: name,
              slug
            }
          ]}
          template="Legacy/PLP"
          type="Expand My System"
        />
        <main className="prose max-w-8xl md:prose-md lg:prose-lg mx-auto grid grid-cols-1 gap-6 whitespace-pre-line p-4 md:gap-8 md:p-8 lg:gap-12">
          {locale === 'en-GB' && <TrustpilotUKTopBanner />}
          <SensorHero
            isOnStock={isOnStock}
            isSellable={isSellable}
            product={product}
            type={type}
          />
          {overviewVideo ? (
            <OverviewVideoSection
              mp4VideoLink={overviewVideo.mp4Link}
              title={overviewVideo.entryTitle}
              webmVideoLink={overviewVideo.webmLink}
            />
          ) : null}
          <SensorFeatures description={features} image={lifestyleImage} />
          {locale === 'en-GB' ? (
            <InstallationInstructions
              installationImage={installationImage}
              installationInstructions={installationInstructions}
            />
          ) : null}
          {faq ? <FAQSection faq={faq} title={microcopy['faq-title']} /> : null}
        </main>
        {footer ? <Footer data={footer} type="Full" /> : null}
        {liveChat ? <LiveChat /> : null}
      </PriceProvider>
    </TrackingProvider>
  )
}

export default ProductLandingTemplate
export const productLandingTemplateQuery = graphql`
  #graphql
  query ProductLandingTemplate($id: String) {
    contentfulProductLandingPage(id: { eq: $id }) {
      ...productLandingPage
    }
  }
`
