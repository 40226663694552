import { useFragmentWithVariations } from '@ecomm/optimizely-hooks'

import { reviewsLayoutSchema, reviewsPageContentSchema } from './schema'

export const useReviewsPageFragment = <
  T extends { readonly contentfulGenericLandingPage: U },
  U
>(
  query: T
) => {
  const reviewsFragment = query.contentfulGenericLandingPage

  const reviewsPage = useFragmentWithVariations(
    reviewsFragment,
    reviewsPageContentSchema
  )

  //defaults showPopupQuoteWizard to true if no value is provided in CTFL
  const reviewsPageWithPopupQW =
    reviewsPage.showPopupQuoteWizard === null
      ? { ...reviewsPage, showPopupQuoteWizard: true }
      : reviewsPage

  const layoutFragment = reviewsPageWithPopupQW.layout

  const layout = useFragmentWithVariations(layoutFragment, reviewsLayoutSchema)

  return {
    ...reviewsPageContentSchema.parse(reviewsPageWithPopupQW),
    layout: reviewsLayoutSchema.parse(layout)
  }
}
