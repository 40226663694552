import { BLOCKS } from '@contentful/rich-text-types'
import type { BlogArticle } from '@ecomm/blog-data'
import {
  type CreateOptions,
  ApolloRichText,
  ContentfulImage,
  renderBlocksEmbeddedEntry
} from '@ecomm/contentful/components'
import { Link } from '@ecomm/framework'
import { useMicroCopy } from '@ecomm/micro-copy'
import { YoutubeVideo, youtubeVideoSchema } from '@ecomm/shared-components'
import { format, parseISO } from 'date-fns'

import {
  type FacebookPostProps,
  FacebookCommentsBox
} from './FacebookCommentsBox'

const options: CreateOptions = links => {
  return {
    [BLOCKS.EMBEDDED_ENTRY]: node =>
      renderBlocksEmbeddedEntry(
        links,
        node,
        youtubeVideoSchema,
        ({ title, link }) => <YoutubeVideo link={link} title={title} />
      )
  }
}

type Props = BlogArticle & {
  readonly facebookProps: FacebookPostProps
}

export function Article({
  title,
  content,
  publishDate,
  image,
  facebookProps
}: Props) {
  const formattedDate = publishDate
    ? format(parseISO(publishDate), 'MMMM do, yyyy')
    : ''
  const microCopy = useMicroCopy()

  return (
    <div className="mx-auto max-w-4xl px-4 py-4 md:px-8 md:pb-16 md:pt-8">
      <Link to="/blog">
        <h1 className="text-heading-2-size mb-4 md:mb-8">{title}</h1>
      </Link>
      <p className="mb-8 max-w-none text-left">
        {microCopy['blog-posted-date'](formattedDate)}
      </p>
      <div className="my-4">
        {image ? (
          <ContentfulImage
            className="rounded-base"
            height={554}
            loading="high"
            quality={90}
            width={832}
            {...image}
          />
        ) : null}
      </div>
      <div className="last:prose-p:mb-0 col-span-12 md:col-span-6 md:flex md:flex-col">
        {content?.json ? (
          <ApolloRichText
            json={content.json}
            links={content?.links}
            options={options}
          />
        ) : null}
      </div>
      <FacebookCommentsBox {...facebookProps} />
    </div>
  )
}
