import { getExpertReviews } from '@ecomm/lander'
import { QuizBanner } from '@ecomm/lander'
import { parseContentfulJson } from '@ecomm/micro-copy'
import {
  ConfidenceBar,
  ConfidenceBarProps,
  ExpertSection,
  ExpertsReviews,
  ExpertsReviewsProps,
  FAQs,
  GuaranteeSection,
  parseJSONDataFromContentful
} from '@ecomm/scout'
import { useMoversPageFAQsData } from '@ecomm/scout/src/components/FAQs/useMoversPageFAQsData'
import { UserReviews, UserReviewsProps } from '@ecomm/shared-components'
import { useEnv } from '@ecomm/utils'
import React from 'react'

import { useMoversComponentsQuery } from './useMoversComponentsQuery'

export function MoversComponents() {
  const staticQuery = useMoversComponentsQuery()
  const { locale } = useEnv()
  const faqsData = useMoversPageFAQsData()
  const confidenceBarData: ConfidenceBarProps = parseJSONDataFromContentful(
    staticQuery,
    'confidenceBar'
  )

  const expertReviewsData: ExpertsReviewsProps = parseJSONDataFromContentful(
    staticQuery,
    'expertReviews'
  )
  const expertReviews = getExpertReviews(
    staticQuery.expertReviewsAssets.nodes,
    locale,
    expertReviewsData.reviews
  )

  const guaranteeSectionCopy = parseContentfulJson(staticQuery, 'guaranteeCopy')
  const usersReviews: UserReviewsProps = parseJSONDataFromContentful(
    staticQuery,
    'usersReviews'
  )
  const expertsSection = {
    image: staticQuery.expertSectionAsset,
    title: 'Need help? Our experts are here.'
  }

  return (
    <>
      <ConfidenceBar data={confidenceBarData} />
      <ExpertsReviews reviews={expertReviews} />
      <QuizBanner
        image={staticQuery.quoteWizardAsset}
        quoteWizard={staticQuery.quoteWizard}
      />
      <FAQs data={faqsData} />
      <UserReviews {...usersReviews} />
      <GuaranteeSection
        copy={guaranteeSectionCopy}
        image={staticQuery.guaranteeAsset}
        quoteWizard={staticQuery.quoteWizard}
      />
      <ExpertSection data={expertsSection} />
    </>
  )
}
