import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import React from 'react'

import { OdmonPreLaunchAddToCartButtonPackage } from '../../experiments/ODMONPrelaunch/OdmonPreLaunchAddToCartButtonPackage'
import AddToCartButton from '../AddToCartButton'
import { PackageProduct, PackageType } from '../Package/schema'

export type Props = {
  readonly className?: string
  readonly packageName: string
  readonly packageProducts: readonly PackageProduct[]
  readonly packageType: PackageType
  readonly sku: string
  readonly error: boolean
  readonly setError: React.Dispatch<React.SetStateAction<boolean>>
}

export function PersonalizePackageAddOnsButton({
  className = '',
  error,
  packageName,
  packageProducts,
  packageType,
  setError,
  sku
}: Props) {
  const microCopy = useMicroCopy()
  const locale = useLocale()

  return (
    <div
      className="flex flex-col items-end"
      data-component="PersonalizePackageAddOnsButton"
    >
      {error ? (
        <p className="text-sale text-sm">{microCopy['package-atc-error']}</p>
      ) : null}
      {locale === 'en-US' ? (
        <OdmonPreLaunchAddToCartButtonPackage
          className={className}
          packageName={packageName}
          packageProducts={packageProducts}
          packageSku={sku}
          packageType={packageType}
          setError={setError}
        />
      ) : (
        <AddToCartButton.Package
          className={className}
          packageName={packageName}
          packageProducts={packageProducts}
          packageSku={sku}
          packageType={packageType}
          setError={setError}
        />
      )}
    </div>
  )
}
