import { proSetupQueryParam } from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { ContentfulRichText } from '@ecomm/shared-components'
import { Modal } from '@ecomm/ss-react-components'
import { Info } from '@ecomm/ss-react-components'
import {
  useTrackingPackageDetailTooltip,
  useTrackingPackageProSetup
} from '@ecomm/tracking'
import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'

import { proSetupAtom } from '../../atoms/package/monitoringProSetup'
import { ProductCardFragment } from '../../components/ProductCard/schema'

type ProSetupProps = {
  readonly proSetup: ProductCardFragment
  readonly label?: string
  readonly showPricingInfo?: boolean
}

export function ProSetup({
  proSetup,
  label = 'Add Pro Setup',
  showPricingInfo = true
}: ProSetupProps) {
  const isUs = useLocale() === 'en-US'
  const [proSetupState, setProSetup] = useAtom(proSetupAtom)
  const [isOpenLearnMoreModal, setIsOpenLearnMoreModal] = useState(false)
  const trackProSetupModalOpen =
    useTrackingPackageDetailTooltip('pro setup help')
  const trackProSetup = useTrackingPackageProSetup()

  const hasProSetup = O.isSome(proSetupState)

  const { sku, priceText, name, description } = proSetup

  const onProSetupModalOpenClose = useCallback(
    (open: boolean) => {
      setIsOpenLearnMoreModal(open)
      open && trackProSetupModalOpen()
    },
    [setIsOpenLearnMoreModal, trackProSetupModalOpen]
  )

  const toggleSelection = useCallback(
    () =>
      pipe(
        proSetupState,
        O.fold(
          () => {
            trackProSetup(true)
            return setProSetup(O.some(sku))
          },
          () => {
            trackProSetup(false)
            return setProSetup(O.none)
          }
        )
      ),
    [proSetupState, sku, setProSetup, trackProSetup]
  )

  const location = useLocation()
  useEffect(() => {
    const preCheckProSetup =
      new URLSearchParams(location.search).get(proSetupQueryParam) !== null
    preCheckProSetup && setProSetup(O.some(sku))
    preCheckProSetup && trackProSetup(true)
  }, [location, sku, setProSetup])

  return (
    <div data-component="ProSetup">
      <div className="m-0 mb-2 mt-2 flex items-center">
        <input
          aria-label="prosetup"
          checked={hasProSetup}
          className={classNames('mr-2 cursor-pointer', {
            'h-4 w-5': !isUs,
            'h-6 w-7': isUs
          })}
          id="prosetup"
          onChange={toggleSelection}
          type="checkbox"
        />

        {priceText ? (
          <label
            className={classNames({
              'text-xs': !isUs,
              'text-lg text-[#0F2544]': isUs
            })}
            data-component="MonitoringToggle_title"
            htmlFor="prosetup"
          >
            {label} {showPricingInfo ? priceText.toLocaleLowerCase() : null}
          </label>
        ) : null}
        {showPricingInfo ? (
          !isUs ? (
            <Info
              aria-label="info"
              className="icon"
              dataComponent="info"
              forceButtonMode
              onClick={() => onProSetupModalOpenClose(true)}
            />
          ) : (
            <span
              className="ml-1 cursor-pointer text-lg text-[#0F2544] underline"
              onClick={() => onProSetupModalOpenClose(true)}
            >
              Learn More
            </span>
          )
        ) : null}
        <Modal
          isOpen={isOpenLearnMoreModal}
          onRequestClose={() => onProSetupModalOpenClose(false)}
        >
          <div className="p-8" data-component="modal_content">
            <div
              className="flex flex-col text-center"
              data-component="content_headline"
            >
              <h3 className="h3">{name}</h3>
            </div>
            <div className="paragraph" data-component="content_description">
              <ContentfulRichText raw={description.raw} />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}
