import { useLocale } from '@ecomm/data-hooks'
import * as F from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useMemo } from 'react'
import { TypeOf, z } from 'zod'

export enum Locale {
  EnGB = 'en-GB',
  EnUS = 'en-US'
}

type Schema = {
  readonly [Locale.EnGB]: z.ZodObject<z.ZodRawShape>
  readonly [Locale.EnUS]: z.ZodObject<z.ZodRawShape>
}

type Data = {
  readonly [Locale.EnGB]?: { readonly [k: string]: unknown }
  readonly [Locale.EnUS]: { readonly [k: string]: unknown }
}

export type Output<S extends Schema> =
  | TypeOf<S[Locale.EnGB]>
  | TypeOf<S[Locale.EnUS]>

const defaultToUSData = (data: Data) =>
  F.pipe(
    data[Locale.EnGB],
    O.fromNullable,
    O.fold(
      () => ({ ...data, [Locale.EnGB]: data[Locale.EnUS] }),
      () => data
    )
  )

export const useLocalizedData = <S extends Schema>(
  schema: S,
  data: Data
): Output<S> => {
  const locale = useLocale()
  const enumLocale = locale === Locale.EnUS ? Locale.EnUS : Locale.EnGB

  return useMemo(
    () =>
      F.pipe(
        z.discriminatedUnion('locale', [
          z.object({
            locale: z.literal(Locale.EnGB),
            [Locale.EnGB]: schema[Locale.EnGB]
          }),
          z.object({
            locale: z.literal(Locale.EnUS),
            [Locale.EnUS]: schema[Locale.EnUS]
          })
        ]),
        discriminatedUnion =>
          discriminatedUnion.parse({
            locale: enumLocale,
            ...defaultToUSData(data)
          }),
        parsedData =>
          parsedData.locale === Locale.EnUS
            ? parsedData[Locale.EnUS]
            : parsedData[Locale.EnGB]
      ),
    [enumLocale]
  )
}
