import { Block, Inline } from '@contentful/rich-text-types'
import {
  buttonSchema,
  IconAndText,
  iconAndTextSchema
} from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'
import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import { match } from 'ts-pattern'
import { literal } from 'zod'

export const possibleEmbeddedSchema = z.union([buttonSchema, iconAndTextSchema])

type PossibleEmbeddedComponent = TypeOf<typeof possibleEmbeddedSchema>

const getMappedComponent = (component: PossibleEmbeddedComponent) =>
  match(component)
    .with(
      {
        __typename: literal('ContentfulButton').value
      },
      (data: TypeOf<typeof buttonSchema>) => (
        <Link
          className={classNames(
            'btn mx-auto mt-4 block w-fit min-w-[190px]  md:mt-6',
            {
              'btn-outlined btn-outlined-secondary':
                data.type === 'Secondary Outline',
              'btn-outlined btn-outlined-tertiary':
                data.type === 'Tertiary Outline',
              'btn-solid-primary': data.type === 'Primary'
            }
          )}
          to={data.url}
        >
          {data.buttonText}
        </Link>
      )
    )
    .with({ __typename: literal('ContentfulIconAndText').value }, data => (
      <IconAndText {...data} />
    ))
    .otherwise(() => null)

export default function ColoredSectionEmbeddedEntry({
  node
}: {
  readonly node: Block | Inline
}) {
  const target = node.data['target']
  return getMappedComponent(target)
}
