import { graphql, useStaticQuery } from 'gatsby'

export const useMoversComponentsQuery = () => {
  return useStaticQuery(graphql`
    fragment landerQWBaseFragment on ContentfulQuoteWizard {
      __typename
      id: contentful_id
      jebbitUrl
      type
      buttonText
    }

    fragment landerQWExperienceFragment on ContentfulNinetailedExperience {
      id: contentful_id
      name: nt_name
      type: nt_type
      audience: nt_audience {
        id: contentful_id
        name: nt_name
      }
      config: nt_config {
        components {
          baseline {
            id
          }
          variants {
            id
            hidden
          }
        }
        traffic
        distribution
      }
      variants: nt_variants {
        ... on ContentfulQuoteWizard {
          ...landerQWBaseFragment
        }
      }
    }
    query MoversComponentsQuery {
      confidenceBar: contentfulJson(
        contentful_id: { eq: "5lTjX2gRqFH0isBGwGGXLH" }
      ) {
        content {
          internal {
            content
          }
        }
      }
      expertReviews: contentfulJson(
        contentful_id: { eq: "2143Uo0jib8jsfRyWv2DDQ" }
      ) {
        ...json
      }
      expertReviewsAssets: allContentfulAsset(
        filter: {
          contentful_id: {
            in: ["5X5OsfxAW6Fc2Zj3HZDlHC", "7eH4KhA049wM5ZTOOmWyWd"]
          }
        }
      ) {
        nodes {
          contentful_id
          gatsbyImageData(width: 320)
          description
        }
      }
      expertSectionAsset: contentfulAsset(
        contentful_id: { eq: "4l8DenaxSNBl1LH3mmDLMv" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
      }
      guaranteeAsset: contentfulAsset(
        contentful_id: { eq: "5FRJNkZAaZL4mHk6vuMNVb" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        description
      }
      guaranteeCopy: contentfulJson(
        contentful_id: { eq: "5bXaWtcoGF04HVmWb5Kuoe" }
      ) {
        ...json
      }
      quoteWizard: contentfulQuoteWizard(
        contentful_id: { eq: "6wRdF53o74w2MhOhBIkzip" }
      ) {
        ...landerQWBaseFragment
        nt_experiences {
          ...landerQWExperienceFragment
        }
      }
      quoteWizardAsset: contentfulAsset(
        contentful_id: { eq: "4mI9KaGvxh51fi1GYOSaVr" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        description
      }
      usersReviews: contentfulJson(
        contentful_id: { eq: "7dCqcYGzfl0wH061G6DB0I" }
      ) {
        content {
          internal {
            content
          }
        }
      }
    }
  `)
}
