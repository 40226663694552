import { usePriceContext } from '@ecomm/data-price'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export type Response<P> = {
  readonly regularPrice: P
  readonly extrasPrice: P
  readonly discountedPrice: O.Option<P>
  readonly discountAmount: O.Option<P>
}

/**
 * This is a similar duplicate of apps/shop/src/components/Package/hooks/usePackagePrice.tsx
 * only to be used for partner pages
 */
export const usePackagePrice = (
  sku: string,
  months = 1,
  /**
   * Apply monitoring discount regardless of selected plan
   * @default false
   */
  displayMonitoringDiscount = false
): Response<number> => {
  const draftCartTotal = 0

  const { getPrice, getDiscountedPrice, getDiscountedPriceWithServicePlan } =
    usePriceContext()

  // Prices for the package sku
  const packageOriginalPrice = O.getOrElse(() => 0)(getPrice(sku, months))

  const packageDiscountedPrice = displayMonitoringDiscount
    ? getDiscountedPriceWithServicePlan(sku, months)
    : getDiscountedPrice(sku, months)

  // Package price + non-discounted products in draft cart
  const totalDiscountedPrice = pipe(
    packageDiscountedPrice,
    O.map(subtotal => subtotal + draftCartTotal)
  )

  // Amount saved from the promotion
  const discountAmount = pipe(
    totalDiscountedPrice,
    O.map(total => total - (packageOriginalPrice + draftCartTotal))
  )

  return {
    regularPrice: packageOriginalPrice,
    extrasPrice: draftCartTotal,
    discountedPrice: totalDiscountedPrice,
    discountAmount: discountAmount
  }
}
