//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import { IContent } from '.'

export type HeroSlideDescriptionProps = {
  readonly content: IContent
  readonly className?: string
}

const HeroSlideDescription: FC<HeroSlideDescriptionProps> = ({
  content,
  className = ''
}: HeroSlideDescriptionProps) => {
  const { content: heroContent } = content

  return !!heroContent ? (
    <div className={classNames('my-4', className)}>{heroContent}</div>
  ) : null
}

export default HeroSlideDescription
