import {
  leadGenCapture,
  LeadGenCaptureProps,
  LeadGenResponseProps
} from '@ecomm/data-leads'
import { useLeadCaptureEmailSubmitted } from '@ecomm/promotions-hooks'
import {
  COOKIE_LEAD_DATA,
  getLeadData,
  useCookieChange
} from '@ecomm/shared-cookies'
import {
  ReferrerUrlContext,
  useOptimizelyTrackSiteEvents
} from '@ecomm/tracking'
import { trackLeadCapture } from '@ecomm/tracking'
import { selectCurrentPromoCode } from '@simplisafe/ss-ecomm-data/redux/select'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as R from 'fp-ts/lib/Record'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { toOffersData } from './helpers'
import { LeadGenFormFragment } from './schema'

export function useOfferForm(formData: LeadGenFormFragment) {
  const { trackEvent } = useTracking()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const currentPromoCode = useSelector(selectCurrentPromoCode)

  const [isSuccess, setIsSuccess] = useState(false)
  const [defaultEmail, setDefaultEmail] = useState<string>(
    pipe(
      getLeadData(),
      O.fromNullable,
      O.chain(R.lookup('email')),
      O.getOrElse(() => '')
    )
  )

  const { setIsSubmitted } = useLeadCaptureEmailSubmitted()

  const channel = useContext(ReferrerUrlContext).channel

  const handleSuccess = (value: LeadGenResponseProps) => {
    setIsSuccess(true)
    trackLeadCapture(value, trackEvent, optimizelyTrackSiteEvents, channel)
    console.info(
      `In function useOfferForm called trackLeadCapture with ${JSON.stringify({
        leadId: value.leadId,
        externalId: value.externalId
      })}`
    )
    // When a lead is captured from any source, hide PromoBanner lead form ECP-10763
    setIsSubmitted(true)
  }

  const handleError = () => {
    setIsSuccess(false)
    optimizelyTrackSiteEvents({ eventType: 'website_error' })
  }

  const handleEmailSubmit = (email: string) => {
    const leadGenParams: LeadGenCaptureProps = {
      email,
      promoCode: currentPromoCode.getOrElse('NO_CODE'),
      source: 'footer',
      sourceType: 'footer'
    }

    trackEvent({ event: 'submit' })
    leadGenCapture(leadGenParams)()
      .then(res => {
        return pipe(
          res,
          E.match(_ => {
            handleError()
            return {}
          }, handleSuccess)
        )
      })
      .catch(_ => handleError())
  }

  useCookieChange(COOKIE_LEAD_DATA, data =>
    setDefaultEmail(
      pipe(
        JSON.parse(data),
        R.lookup('email'),
        O.getOrElse(() => '')
      )
    )
  )

  const offers = toOffersData(
    formData,
    handleEmailSubmit,
    isSuccess,
    defaultEmail
  )

  return { offers }
}
