import { useMicroCopy } from '@ecomm/micro-copy'
import {
  useCurrentPromoBannerData,
  usePartnerPromoBannerData
} from '@ecomm/promotions-hooks'
import { isExpired, isMoreThan7DaysAway } from '@ecomm/promotions-utils'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { PromoBannerBaseTemplate } from '../../PromoBannerBaseTemplate'
import { RelativeEndTime } from '../RelativeEndTime'

const getEndsTextTag = (text: string) => (
  <span
    style={{
      color: 'var(--promo-secondary-text)',
      textTransform: 'uppercase'
    }}
  >
    {text}
  </span>
)

export function CartBanner() {
  const { endTime, displayEndTimeCalloutOverride, endTimeCalloutOverride } =
    useCurrentPromoBannerData()
  const { hasPartnerBanner, partnerName } = usePartnerPromoBannerData()
  const isPartnerPromo = hasPartnerBanner || !!partnerName
  const microCopy = useMicroCopy()
  const endsSoonText = microCopy.soon

  const displayEndsSoonText = (date: Date) =>
    isExpired(date) || isMoreThan7DaysAway(date) || isPartnerPromo
      ? O.none
      : O.some(date)

  const endsTextMarkup = pipe(
    O.of(displayEndTimeCalloutOverride ? endTimeCalloutOverride : endTime),
    O.map(dateString => new Date(dateString)),
    O.chain(displayEndsSoonText),
    O.match(
      () => getEndsTextTag(endsSoonText),
      dateInstance => (
        <>
          <span>by</span> <RelativeEndTime endDate={dateInstance} />
        </>
      )
    )
  )

  return (
    <PromoBannerBaseTemplate dataComponent="CartBanner" position="top">
      <div className="text-center" data-component="CartBannerText">
        <strong className="text-lg">
          Checkout {endsTextMarkup} for this offer
        </strong>
      </div>
    </PromoBannerBaseTemplate>
  )
}
