import { contentfulImageSchema } from '@ecomm/contentful/components'
import { documentSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

export const blogPreviewSchema = z.lazy(() =>
  z.object({
    image: contentfulImageSchema.nullable(),
    previewContent: z.object({ json: documentSchema.nullable() }).nullable(),
    publishDate: z.string().optional(),
    slug: z.string(),
    title: z.string()
  })
)

export type BlogPostPreview = TypeOf<typeof blogPreviewSchema>

export const blogListSchema = blogPreviewSchema.array().nonempty()
