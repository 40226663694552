import { parseContentfulJson } from '@ecomm/micro-copy'
import { GatsbyImage } from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/ss-react-components'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { privacyHeroBannerImageSchema } from './schema'

export function PrivacyHeroBanner() {
  const isTabletAndUp = useMediaQuery('TabletAndUp')
  const data = privacyHeroBannerImageSchema.parse(
    useStaticQuery(graphql`
      query PrivacyHeroBanner {
        tabletAndUpImage: contentfulAsset(
          contentful_id: { eq: "7DKEYjIYpM4hStGSFMI9iA" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
        mobileImage: contentfulAsset(
          contentful_id: { eq: "2YgujJALullkAb3pSpYgfg" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
        copy: contentfulJson(contentful_id: { eq: "5az93epwKKvbPq2qcyh3Pk" }) {
          ...json
        }
      }
    `)
  )

  const copy = parseContentfulJson(data, 'copy')

  return (
    <div data-component="PrivacyHeroBanner">
      <div className="relative mt-3 lg:m-auto lg:mt-0">
        <h1 className="absolute bottom-10 left-1/2 z-10 m-0 w-4/5 -translate-x-1/2 transform text-center text-4xl text-white md:w-3/5 lg:bottom-16 lg:text-6xl ">
          {copy.get('title')}
        </h1>
        {isTabletAndUp && data.tabletAndUpImage ? (
          <GatsbyImage
            className="min-h-[320px] lg:rounded-2xl"
            image={data.tabletAndUpImage}
          />
        ) : null}
        {!isTabletAndUp && data.mobileImage ? (
          <GatsbyImage
            className="min-h-[320px] lg:rounded-2xl"
            image={data.mobileImage}
          />
        ) : null}
      </div>
    </div>
  )
}
