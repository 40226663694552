import React from 'react'

function SkipToContentLink() {
  return (
    <a
      className="absolute left-[-9999px] z-50 bg-white p-2 focus:left-0 focus:top-0"
      data-component="SkipToContentLink"
      href="#content"
    >
      Skip to content
    </a>
  )
}

export default SkipToContentLink
