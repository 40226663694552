import { fetchApi } from '@ecomm/data-simplisafe-api'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

export type MemberData = {
  readonly memberNumber?: string | null
  readonly firstName?: string
  readonly lastName?: string
  readonly partnerName: string
}

export type MemberDataResponse = {
  readonly partnerId: number
  readonly orderId: number
  readonly systemPurchased: number
  readonly customerUUID: number
  readonly customerData: string
  readonly submissionDate: number
  readonly email: string
  readonly processed: number
  readonly returnedSystem: number
  readonly processedReturn: number
  readonly memberNumber: string
}

const isPartnerData = (res: unknown): res is MemberDataResponse => {
  polyfillHasOwn()
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'email') &&
    Object.hasOwn(res, 'customerData') &&
    Object.hasOwn(res, 'partnerId')
  )
}

const formatMemberData = (data: MemberData) => {
  return {
    memberNumber: data.memberNumber,
    membershipData: {
      firstName: data.firstName,
      lastName: data.lastName,
      memberNumber: data.memberNumber
    },
    partnerName: data.partnerName
  }
}

export const sendMemberData = (data: MemberData) => {
  return pipe(
    fetchApi({
      endpoint: `/partnerships/v1/partners/customer`,
      method: 'POST',
      headers: {},
      body: JSON.stringify(formatMemberData(data))
    }),
    TE.chain(res => (isPartnerData(res) ? TE.right(res) : TE.left(res))),
    TE.mapLeft(res => Error(`Error sending member data: ${res}`))
  )
}
