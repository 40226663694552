import { type Document, BLOCKS, INLINES } from '@contentful/rich-text-types'
import type { IfEquals } from '@ecomm/utils'
import type { TypeOf } from '@simplisafe/ewok'
import { z } from '@simplisafe/ewok'
import type { DeepNullable, DeepPartial } from 'ts-essentials'
import type { ZodType, ZodTypeDef } from 'zod'

const nodeSchema = z.object({
  data: z.record(z.string(), z.any()),
  nodeType: z.string()
})

const markSchema = z.object({ type: z.string() })

const textSchema = nodeSchema.extend({
  marks: z.array(markSchema),
  nodeType: z.literal('text'),
  value: z.string()
})

const inlinesEnum = z.nativeEnum(INLINES)

// @ts-expect-error - ts can't handle inferring recursive types
const inlineSchema = z.lazy(() =>
  nodeSchema.extend({
    content: z.array(z.union([textSchema, inlineSchema])),
    nodeType: inlinesEnum
  })
)

const blocksEnum = z.nativeEnum(BLOCKS)

// @ts-expect-error - ts can't handle inferring recursive types
const blockSchema = z.lazy(() =>
  nodeSchema.extend({
    content: z.array(z.union([blockSchema, inlineSchema, textSchema])),
    nodeType: blocksEnum
  })
)

const topLevelBlockEnum = z.enum([
  BLOCKS.PARAGRAPH,
  BLOCKS.HEADING_1,
  BLOCKS.HEADING_2,
  BLOCKS.HEADING_3,
  BLOCKS.HEADING_4,
  BLOCKS.HEADING_5,
  BLOCKS.HEADING_6,
  BLOCKS.OL_LIST,
  BLOCKS.UL_LIST,
  BLOCKS.HR,
  BLOCKS.QUOTE,
  BLOCKS.EMBEDDED_ENTRY,
  BLOCKS.EMBEDDED_ASSET,
  BLOCKS.TABLE
])

// this was supposed to extend blockSchema but it introduces type ambiguity since blockSchema is lazy
const topLevelBlockSchema = nodeSchema.extend({
  content: z.array(z.union([blockSchema, inlineSchema, textSchema])),
  nodeType: topLevelBlockEnum
})

export const documentSchema: ZodType<Document, ZodTypeDef, unknown> =
  nodeSchema.extend({
    content: z.array(topLevelBlockSchema),
    nodeType: z.literal(BLOCKS.DOCUMENT)
  })

export type Json = TypeOf<typeof documentSchema>

export type RichTextFragment = IfEquals<TypeOf<typeof documentSchema>, Document>

export const parseRichText = (
  richText: DeepNullable<DeepPartial<Document>>
): RichTextFragment => documentSchema.parse(richText)

const sysSchema = z.object({
  id: z.string()
})

const entryschema = z.array(
  z
    .object({ __typename: z.string(), sys: sysSchema })
    .passthrough()
    .transform(({ sys, ...rest }) => ({ id: sys.id, ...rest }))
)

const assetSchema = z.array(
  z
    .object({
      __typename: z.string(),
      sys: sysSchema,
      url: z.string(),
      originalWidth: z.number(),
      originalHeight: z.number(),
      description: z.string()
    })
    .transform(({ sys, ...rest }) => ({ id: sys.id, ...rest }))
)

const linksSchema = z
  .object({
    entries: z
      .object({
        hyperlink: entryschema,
        inline: entryschema,
        block: entryschema
      })
      .nullable()
      .optional(),
    assets: z
      .object({
        hyperlink: assetSchema,
        inline: assetSchema,
        block: assetSchema
      })
      .nullable()
      .optional()
  })
  .default({})

export type Links = TypeOf<typeof linksSchema>

export const apolloRichTextSchema = z
  .object({
    json: documentSchema.nullable(),
    links: linksSchema
  })
  .nullable()
