import type { IconTypes } from '@ecomm/shared-icons'
import { SimpliSafeLogoDark } from '@ecomm/shared-icons'
import * as Icons from '@ecomm/shared-icons'
import classNames from 'classnames'
import React from 'react'

type HeaderProps = {
  readonly competitors?: readonly {
    readonly title: string
    readonly icon?: IconTypes
  }[]
  readonly hasExpandableRows: boolean
}

function renderIcon(icon: IconTypes, title: string) {
  const Icon = Icons[icon]

  return (
    <Icon
      className="h-12 w-12 md:h-16 md:w-16 lg:h-20 lg:w-20"
      data-component="CompetitorIcon"
      titleA11y={title}
    />
  )
}

export function Header({ competitors = [], hasExpandableRows }: HeaderProps) {
  return (
    <thead>
      <tr
        className={classNames(
          'flex gap-1',
          hasExpandableRows ? 'pb-0' : 'pb-3 lg:pb-5'
        )}
      >
        <th
          className={classNames(
            'flex items-center lg:basis-2/3',
            hasExpandableRows ? 'basis-3/5' : 'basis-1/2'
          )}
        >
          <h2
            className={classNames(
              'mb-0 text-start lg:mb-5 lg:block lg:leading-none',
              hasExpandableRows
                ? 'text-2xl lg:text-5xl'
                : 'lg:text-heading-3-size hidden max-lg:hidden'
            )}
          >
            The SimpliSafe<span className="align-super text-lg">®</span>{' '}
            difference
          </h2>
        </th>
        <th
          className={classNames(
            'flex justify-center lg:basis-1/6',
            hasExpandableRows
              ? 'basis-1/5 items-end md:items-center'
              : 'basis-1/4 items-center'
          )}
        >
          <span className="sr-only">SimpliSafe</span>
          <SimpliSafeLogoDark
            className="mx-auto w-full max-w-[7rem] md:w-28 md:max-w-none lg:w-36"
            height={hasExpandableRows ? 24 : 40}
            titleA11y="SimpliSafe"
          />
        </th>
        {competitors.map((competitor, index) => (
          <th
            className={classNames(
              'flex justify-center lg:basis-1/6',
              hasExpandableRows
                ? 'basis-1/5 items-end p-2 lg:items-center lg:p-0'
                : 'basis-1/4 items-center'
            )}
            key={index}
          >
            {competitor.icon ? (
              renderIcon(competitor.icon, competitor.title)
            ) : (
              <h3
                className={classNames(
                  'mb-0 text-center font-bold',
                  hasExpandableRows
                    ? 'text-[10px] md:text-lg md:leading-6'
                    : 'md:text-body-size text-xs'
                )}
              >
                {competitor.title}
              </h3>
            )}
          </th>
        ))}
      </tr>
    </thead>
  )
}
