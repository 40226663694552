import { layoutSchema } from '../schemas/layoutSchema'
import { LegalPageSchema } from '../schemas/legalPage'

export const useLegalPageFragment = (query: LegalPageSchema) => {
  const { contentfulLayout } = query

  return {
    layout: layoutSchema.parse(contentfulLayout)
  }
}
