import {
  clearOdmonBetaExperience,
  isOdmonBetaExperience
} from '@ecomm/checkout/utils'
import { submitPlan } from '@ecomm/data-odmon'
import { getUserEmail, getUserId } from '@ecomm/shared-cookies'
import { TrackMetricProps } from '@ecomm/tracking'
import { get as sessionStorageGet } from '@ecomm/utils'
import { CreateOrderV1Response as CreateOrderResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as T from 'fp-ts/lib/Task'
import * as TE from 'fp-ts/lib/TaskEither'

import {
  getOrderId,
  logErrorWithOrderInfo,
  navigateLocal
} from './utils/common'
import {
  checkPreActivationEligibilityStatus,
  validateWebAppToken
} from './utils/preactivation'

/**
 * Split an order response to either standard or pre-activation
 */
export const handlePostPayment = (
  orderResponse: CreateOrderResponse,
  onPreactivationReady: (_webAppToken: string) => void,
  trackMetricEvent: TrackMetricProps
): T.Task<void> => {
  return pipe(
    E.of(orderResponse),
    E.chain(checkPreActivationEligibilityStatus),
    E.chain(validateWebAppToken),
    E.match(
      // not PA eligible
      preactivationError => {
        logErrorWithOrderInfo(preactivationError)
        return handleNonPreactivationPostPayment(
          orderResponse,
          trackMetricEvent
        )
      },
      // PA eligible
      orderResponse => {
        const handlePreactivationPostPayment = () => {
          onPreactivationReady(orderResponse.webappToken)
          forwardPreactivationFlowToWebApp(
            orderResponse.hasPlan,
            getOrderId(orderResponse),
            trackMetricEvent
          )
        }

        return T.of(handlePreactivationPostPayment())
      }
    )
  )
}

/**
 * Non-pre-activation -- split to either standard or ODMON beta handling
 */
const handleNonPreactivationPostPayment = (
  orderResponse: CreateOrderResponse,
  trackMetricEvent: TrackMetricProps
) => {
  return pipe(
    isOdmonBetaExperience(),
    O.guard,
    O.match(
      () =>
        handleStandardPostPayment(getOrderId(orderResponse), trackMetricEvent),
      () => handleOdmonBetaPostPayment(trackMetricEvent)
    )
  )
}

/**
 * Regular order -- track and redirect to payment confirmation.
 * Delay 1s to give the user time to read any info on-screen.
 */
const handleStandardPostPayment = (
  orderId: string,
  trackMetricEvent: TrackMetricProps
): T.Task<void> => {
  const trackAndNavigate = () => {
    trackMetricEvent('forwarded-to-payment-confirmation')
    navigateLocal('/payment-confirmation?orderId=' + orderId, 3000)
  }

  return T.of(trackAndNavigate())
}

/**
 * ODMON Beta order -- submit plan choice to ODMON API, track, and redirect
 * to ODMON Beta payment confirmation.
 * Delay 1s to give the user time to read any info on-screen.
 */
const handleOdmonBetaPostPayment = (
  trackMetricEvent: TrackMetricProps
): T.Task<void> => {
  const userId = getUserId()
  const email = getUserEmail()
  const odmonPlan = sessionStorageGet('odmon-plan')
  const odmonPlanCode = odmonPlan === '24 hour' ? 'SSODMON247' : 'SSODMONON'

  const trackAndNavigate = () => {
    const destinationUrl = `/outdoor-monitoring-early-access/payment-confirmation`

    clearOdmonBetaExperience()
    trackMetricEvent('forwarded-to-odmon')
    navigateLocal(destinationUrl, 3000)
  }

  return pipe(
    submitPlan(userId, email, odmonPlanCode),
    // if failed, log it, but fwd along either way
    TE.match(
      () => {
        trackMetricEvent('odmon-api-fail')
        trackAndNavigate()
      },
      () => trackAndNavigate()
    )
  )
}

/**
 * Pre-activation order -- track and redirect to webapp.
 * Delay 1s to give the user time to read any info on-screen.
 */
const forwardPreactivationFlowToWebApp = (
  hasPlan: boolean,
  orderId: string,
  trackMetricEvent: TrackMetricProps
) => {
  const webAppUrl = getWebAppUrl()
  const hasPlanUrl = `${webAppUrl}/#/collect-monitoring?funnel`
  const noPlanUrl = `${webAppUrl}/#/collect-monitoring?funnel&orderId=${orderId}`

  trackMetricEvent('forwarded-to-preactivation', { orderId: orderId })
  navigateLocal(hasPlan ? hasPlanUrl : noPlanUrl, 3000)
}

// exported for testing
export const getWebAppUrl = () => {
  return process.env['WEBAPP_URL']
    ? process.env['WEBAPP_URL']
    : 'https://webapp.simplisafe.com'
}
