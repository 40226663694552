import React from 'react'

function AdditionalAlarmSensorsDisclaimer() {
  return (
    <div className="m-auto p-4" data-component="AlarmSensorsDisclaimer">
      <h3 className="h3">Additional Alarm Sensors</h3>
      <p className="paragraph whitespace-pre-line">
        Add extra cameras and sensors on to your security system at anytime.{' '}
        <b>Free delivery on all orders over £50.</b>
      </p>
    </div>
  )
}

export default AdditionalAlarmSensorsDisclaimer
