import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackPlanWarningModalContinue() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      event: 'plan-warning-modal-continue'
    })
  }, [trackEvent])
}
