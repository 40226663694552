import { z } from '@simplisafe/ewok'

const idAsString = z.string()
const idAsObject = z.object({ id: z.string() })

const variantSchema = z.object({
  id: z.string(),
  hidden: z.boolean()
})

const componentSchema = z.object({
  baseline: z.object({
    id: z.string(),
    title: z.string().nullish() // absent in new apollo response
  }),
  variants: z.array(variantSchema)
})

const audienceSchema = z.object({
  id: idAsString.or(idAsObject), // required to be compatible with new apollo response
  name: z.string()
})

/**
 * Note: When using this schema you must extend it with a `variants` field
 * that includes the an array of the schema you're trying to A/B test.
 *
 * @example
 * const fooSchema = experimentTemplateSchema.extend({
 *   variants: z.array(fooSc)
 * })
 */
export const experimentTemplateSchema = z.object({
  id: idAsString.or(idAsObject), // required to be compatible with new apollo response
  name: z.string(),
  type: z.string(),
  audience: audienceSchema.nullish(),
  config: z.object({
    components: z.array(componentSchema),
    traffic: z.number(),
    distribution: z.array(z.number())
  })
})
