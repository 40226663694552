import React from 'react'

import DraftCartPrice from '../DraftCartPrice'
type Props = {
  readonly name?: string | null
  readonly sku: string
  readonly quantity: number
  readonly forFree: boolean
  readonly isAffirmMonthlyExperiment?: boolean
}

function DraftCartStaticItem({
  name,
  sku,
  quantity,
  forFree,
  isAffirmMonthlyExperiment = false
}: Props) {
  /**
   * If quantity is 0, then it won't be included in the name
   * If forFree is true, then PriceContext won't be used
   */

  const displayName = quantity > 0 ? `${quantity} ${name}` : name

  return (
    <li className="my-2 flex justify-between" key={sku}>
      <span className="flex items-center">{displayName}</span>
      <span className="border-neutral-light-100 mx-3 mb-2 w-full flex-1 border-b border-l-0 border-r-0 border-t-0 border-solid" />
      <DraftCartPrice
        forFree={forFree}
        isAffirmMonthlyExperiment={isAffirmMonthlyExperiment}
        quantity={quantity}
        sku={sku}
      />
    </li>
  )
}

export default DraftCartStaticItem
