import { layoutSchema } from '../../assemblies/Layout/layoutSchema'
import { BlogListPageSchema } from './schema'

export const useBlogPageFragment = (query: BlogListPageSchema) => {
  const { contentfulLayout } = query

  return {
    layout: layoutSchema.parse(contentfulLayout)
  }
}
