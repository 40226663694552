import { useAffirmAPRExperimentLegacy } from '@ecomm/shared-hooks'
import React from 'react'

import { ContentfulCartDetails } from '../../../graphql'
import { CartPageContext } from '../../templates/CartPage'
import CartDetailsComponent from '.'

type Props = {
  readonly data: Partial<ContentfulCartDetails>
  readonly pageContext: CartPageContext
}
function CartDetailAffirmWrapper({ data, pageContext }: Props) {
  const isAffirmExperiment = useAffirmAPRExperimentLegacy()

  return (
    <CartDetailsComponent
      data={data}
      isAffirmExperiment={isAffirmExperiment}
      pageContext={pageContext}
    />
  )
}

export default CartDetailAffirmWrapper
