import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { Link } from '@ecomm/framework'
import {
  GuidedSystemBuilderSchema,
  QuoteWizardModal
} from '@ecomm/shared-components'
import classNames from 'classnames'
import { match, none, Option } from 'fp-ts/lib/Option'
import { ReactNode, useState } from 'react'
import { useTracking } from 'react-tracking'
import { match as tsPatternMatch } from 'ts-pattern'

type NavLinkProps = {
  readonly text: string
  readonly icon?: ReactNode
  readonly url?: string
  readonly mobileIcon?: ReactNode
  readonly openQuiz?: boolean
  readonly mobileOnly?: boolean
  readonly className?: string
  readonly displayInSideNav?: boolean
  readonly toggleSideNav?: Option<(track?: boolean) => void>
  readonly quoteWizard: GuidedSystemBuilderSchema
}

export function NavLink({
  text,
  icon = null,
  mobileIcon = null,
  url = '',
  openQuiz = false,
  mobileOnly = false,
  className = '',
  displayInSideNav = false,
  toggleSideNav = none,
  quoteWizard
}: NavLinkProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const openQuoteWizardModal = () => {
    setModalOpen(true)
  }

  const { trackEvent } = useTracking()
  const handleClick = () => {
    trackEvent({
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation',
      label: text
    })
    trackNavigationClick({
      action: 'click',
      navElement: text
    })
    match(
      () => null,
      (toggleSideNav: () => void) => toggleSideNav()
    )(toggleSideNav)
    openQuiz && openQuoteWizardModal()
  }

  const css = classNames(
    'relative items-center border-0 border-b border-solid border-b-transparent transition-[border-bottom-color] duration-300 min-[1126px]:h-auto min-[1126px]:text-white cursor-pointer',
    icon
      ? 'grid grid-cols-1 justify-items-center gap-0 p-0 text-[10px] text-white hover:border-b-primary-100 min-[1126px]:text-[13px]'
      : 'inline-flex gap-3 font-bold min-[1126px]:mb-[5px] min-[1126px]:border-b-2 min-[1126px]:font-medium min-[1126px]:hover:border-b-primary-100',
    mobileIcon &&
      'h-16 w-full gap-3 !border-b-neutral-300 px-4 text-lg text-neutral-black min-[1126px]:p-0',
    !icon && !mobileIcon && mobileOnly
      ? 'underline text-white font-normal text-sm min-[1126px]:no-underline min-[1126px]:text-lg min-[1126px]:font-medium'
      : 'no-underline',
    mobileOnly
      ? 'min-[1126px]:hidden'
      : displayInSideNav && 'hidden min-[1126px]:grid',
    className
  )

  const isExternal = /((http|https):\/\/|tel:).*/.test(url)

  const content = (
    <>
      {icon}
      {mobileIcon ? <div className="mt-2 w-6">{mobileIcon}</div> : null}
      <div className={'mt-0 flex min-[1126px]:mt-1'}>
        <span>{text}</span>
      </div>
    </>
  )

  function renderItem() {
    return tsPatternMatch(url)
      .with('', () => (
        <button className={classNames('btn-reset', css)} onClick={handleClick}>
          {content}
        </button>
      ))
      .otherwise(() => (
        <Link
          activeClassName="min-[1126px]:border-primary-100 min-[1126px]:border-b-2 min-[1126px]:mt-0.5 min-[1126px]:!border-b-[5px] min-[1126px]:pt-[23px] min-[1126px]:pb-[21px]"
          className={css}
          onClick={handleClick}
          to={url}
        >
          {content}
        </Link>
      ))
  }

  return (
    <>
      {isExternal ? (
        <a className={css} href={url} onClick={handleClick}>
          {content}
        </a>
      ) : (
        renderItem()
      )}
      {openQuiz ? (
        <QuoteWizardModal
          data={{ ...quoteWizard }}
          onRequestClose={() => setModalOpen(false)}
          show={modalOpen}
        />
      ) : null}
    </>
  )
}
