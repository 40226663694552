import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { usePageContext } from '@ecomm/data-page-context'
import { getCartDiscountCode } from '@ecomm/data-cart'
import { logError } from '@ecomm/error-handling'
import { ContentfulRichText } from '@ecomm/shared-components'
import { getPurchasedCartId } from '@ecomm/shared-cookies'
import { useScript } from '@ecomm/shared-hooks'
import { SSButton } from '@ecomm/ss-react-components'
import { TrackingData } from '@ecomm/tracking'
import { useLocation } from '@reach/router'
import { Just, Maybe, MaybeT } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import {
  commercetoolsGetCart,
  CTCartToRecord,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { CurrencyCode } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { getLiveChatScriptUrl } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { fork } from 'fluture'
import { Link } from 'gatsby'
import React, { ReactNode, useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulPaymentConfirmationContainer } from '../../../checkout.graphql'
import liveChatOrderTracking, { OrderTracking } from './liveChatOrderTracking'
//todo: import useLivechat from @ecomm/live-chat
// Right now it throws this error: error "window" is not available during Server-Side Rendering. Enable "DEV_SSR" to debug this during "gatsby develop".
import { useLiveChatClientExists } from './useLiveChatClientExists'

type PaymentConfirmationContainerComponentProps = {
  readonly id?: string
  readonly data: Partial<ContentfulPaymentConfirmationContainer>
}

export type TrackEvent = (
  data: Partial<Record<string, unknown> | TrackingData>
) => void

/**
 * TODO: This piece of tracking code is specifically for ECP-3110, where the UK just wants to
 * double check perceived discrepencies in GA orders
 *
 * I expect that once any discrepencies are found or identified, this piece of code can
 * be removed as it adds no further value.
 */
const trackSystemPurchase = (trackEvent: TrackEvent) =>
  trackEvent({ event: 'purchaseSystemConfirmation' })

export const toOrderTrackingObject = (
  orderId: string,
  cartTotal: MaybeT<number>,
  currency: CurrencyCode,
  lineItems: readonly LineItem[]
): OrderTracking => {
  return {
    cartTotal: cartTotal,
    currency: currency,
    lineItems: lineItems,
    orderId: orderId
  }
}

const QS_PARAM_ORDER_ID = 'orderId'

export function PaymentConfirmationContainerComponent({
  data
}: PaymentConfirmationContainerComponentProps) {
  const { currencyCode } = usePageContext()

  const [orderTracking, setOrderTracking] = useState<OrderTracking>({
    cartTotal: Just(0),
    currency: currencyCode,
    lineItems: [],
    orderId: ''
  })
  const { Track, trackEvent } = useTracking()
  const location = useLocation()
  const orderId =
    new URLSearchParams(location.search).get(QS_PARAM_ORDER_ID) || ''

  useEffect(() => {
    !orderId && logError(Error('Missing orderId on payment confirmation page'))
  }, [orderId])

  // Normally, we'd want to get cartId from redux instead of directly from local storage. This component is an
  // exception because redux clears the cart out of state and removes cartId from local storage once the cart
  // is ordered, which it is by the time the user gets to this page. Here, we're grabbing the cart id out of
  // local storage before redux has a chance to clear it, and then making a separate cart request (but not
  // storing it) so that we can track the purchased line items.

  const cartId = getPurchasedCartId()

  useEffect(() => {
    Maybe.fromNull(cartId).forEach(_cartId => {
      // TODO turn this into a specialized function in ecomm-data instead of doing fetching & parsing here
      commercetoolsGetCart(_cartId).pipe(
        fork((e: Error) => {
          logError(e)
        })(ctCart => {
          ctCart
            .filter(ctCart => ctCart.cartState === 'Ordered')
            .forEach(ctCart => {
              const cart = CTCartToRecord(ctCart)

              const shippingAddress = safeProp('shippingAddress', ctCart)
              const email = shippingAddress.chain(safeProp('email'))
              const firstName = shippingAddress.chain(safeProp('firstName'))
              const lastName = shippingAddress.chain(safeProp('lastName'))

              const transactionTotal = safeProp('totalPrice', cart)

              const systemInOrder = cart.isThereAnySecurity

              systemInOrder && trackSystemPurchase(trackEvent)

              setOrderTracking(
                toOrderTrackingObject(
                  orderId,
                  transactionTotal,
                  currencyCode,
                  cart.lineItems
                )
              )

              trackEvent({
                event: 'paymentConfirmation',
                transactionCoupon: getCartDiscountCode(cart),
                transactionId: orderId,
                transactionTotal: transactionTotal.getOrElse(0),
                userData: {
                  email: email.getOrElse(''),
                  firstName: firstName.getOrElse(''),
                  lastName: lastName.getOrElse('')
                }
              })
            })
        })
      )
    })
  }, [cartId, orderId, trackEvent, currencyCode])

  const status = useScript(
    getLiveChatScriptUrl('610bd23d07ea1e23ca931dd8_app_979960')
  )
  const clientExists = useLiveChatClientExists(status)

  useEffect(() => {
    clientExists && liveChatOrderTracking(orderTracking)
  }, [clientExists, orderTracking])

  const options: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: () => <span>{orderId}</span>,
      [BLOCKS.PARAGRAPH]: (_: unknown, children: ReactNode) => (
        <p className="paragraph whitespace-pre-line text-center">{children}</p>
      ),
      [BLOCKS.HEADING_3]: (_: unknown, text: ReactNode) => (
        <h3 className="h3 text-center">{text}</h3>
      ),
      // TODO: not hardcode to and buttontText values
      // TODO: It used to work with the old ContentfulRichText
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) =>
        data?.target && (
          <div className="flex justify-center">
            <Link to={data.target?.url || '/'}>
              <SSButton color="primary">
                {data.target?.buttonText || 'Back to homepage'}
              </SSButton>
            </Link>
          </div>
        )
    }
  }

  return (
    <Track>
      <ContentfulRichText
        optionsCustom={options}
        raw={data.successMessage?.description?.raw}
        // @ts-expect-error
        references={data.successMessage?.description?.references}
      />
    </Track>
  )
}
