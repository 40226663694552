import {
  addDaysToHours,
  formatNumber,
  renderLabel,
  renderTime
} from '@ecomm/promotions-utils'
import intervalToDuration from 'date-fns/fp/intervalToDuration'
import isBefore from 'date-fns/fp/isBefore'
import React, { useState } from 'react'
import { useInterval } from 'use-interval'

type CountdownProps = {
  /** The time to count down until. */
  readonly endTime: Date
}

export function Countdown({ endTime }: CountdownProps) {
  const [time, setTime] = useState(['00', '00', '00'])
  /** If the countdown ends we want to render "ENDS SOON" */
  const [expired, setExpired] = useState(false)
  const labels = ['HR', 'MIN', 'SEC']

  useInterval(
    () => {
      const currentTime = new Date()
      const { days, hours, minutes, seconds } = intervalToDuration({
        end: endTime,
        start: currentTime
      })

      const countdownHasExpired = isBefore(currentTime, endTime)

      !countdownHasExpired &&
        setTime([
          `${formatNumber(addDaysToHours(days, hours))}`,
          `${formatNumber(minutes)}`,
          `${formatNumber(seconds)}`
        ])
      countdownHasExpired && setExpired(true)
    },
    1000,
    true
  )

  return (
    <div data-testid="DesktopCountdown">
      {!expired && (
        <div className="max-w-xs text-center">
          <div className="grid grid-cols-3 gap-2" role="timer">
            {time.map(renderTime(true))}
            {labels.map(renderLabel)}
          </div>
        </div>
      )}
    </div>
  )
}
