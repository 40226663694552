import {
  AddressValidationsSchema,
  UserCheckoutSchema
} from '@ecomm/checkout/shipping-schema'

type Props = {
  readonly title: string
  readonly address: AddressValidationsSchema | null
  readonly userData: UserCheckoutSchema | null
}

export function ShippingValidationModalPicker({
  title,
  address,
  userData
}: Props) {
  return (
    <div className="w-full">
      <p className="mb-2 mt-0 text-base font-bold md:mt-4 lg:text-lg">
        {title}
      </p>
      <div className="min-h-36 border-btn-primary flex w-full flex-col rounded-lg border-4 border-solid px-3 py-4 md:w-80 lg:w-96">
        <span className="mb-1 text-base font-medium md:font-bold lg:text-lg">
          {userData?.firstName} {userData?.lastName}
        </span>
        <span className="text-neutral-black text-base font-medium md:font-bold lg:text-lg">
          {address?.street}
        </span>
        {address?.additionalStreetInfo && (
          <span className="text-neutral-black text-base font-medium md:font-bold lg:text-lg">
            {address.additionalStreetInfo}
          </span>
        )}
        <span className="text-neutral-black text-base font-medium md:font-bold lg:text-lg">
          {address?.city}, {address?.state} {address?.zipcode}
          {address?.plus4Code ? `-${address.plus4Code}` : ''}
        </span>
      </div>
    </div>
  )
}
