//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { SiteColor } from '../types/colors'
import * as css from './ButtonWithIcon.module.scss'

export enum Status {
  ACTIVE = 'active',
  DEFAULT = 'default',
  DISABLED = 'disabled'
}

export type ButtonWithIconProps = {
  readonly id?: string
  /** data-component attribute value */
  readonly dataComponent?: string
  /** The icon that is displayed with text. */
  readonly icon: ReactNode
  readonly text: string
  readonly className?: string

  readonly buttonColor?: SiteColor
  readonly textColor?: SiteColor
  readonly borderColor?: SiteColor
  /** Active border color when on url */
  readonly activeBorderColor?: SiteColor

  readonly status?: Status
  readonly onClick?: () => void
}

/** @deprecated Do not use ss-react-components*/
const IconButton: FC<ButtonWithIconProps> = ({
  id,
  icon,
  onClick,
  text,
  className = '',
  dataComponent = IconButton.name,
  buttonColor = 'neutralWhite',
  textColor = 'neutralBlack',
  borderColor = 'neutralMediumGray',
  activeBorderColor = 'brandPrimary',
  status = Status.DEFAULT
}: ButtonWithIconProps) => {
  const buttonBorderColor =
    status === Status.ACTIVE ? activeBorderColor : borderColor

  const colorClasses = {
    [`${buttonColor}BackgroundColor`]: !!buttonColor,
    [`${textColor}TextColor`]: !!textColor,
    [`${buttonBorderColor}BorderColor`]: !!buttonBorderColor
  }

  const classes = classNames(
    css.buttonContent,
    colorClasses,
    css.buttonWithIcon,
    className
  )

  return (
    <button
      className={classes}
      data-component={dataComponent}
      id={id}
      key={id}
      onClick={onClick}
      style={{ opacity: status === Status.DISABLED ? 0.5 : 1 }}
    >
      {icon}
      <p className={css.buttonText}>{text}</p>
    </button>
  )
}

export default IconButton
