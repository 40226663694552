import { ChevronIcon } from '@ecomm/checkout-icons'
import { useTrackingSummaryDetails } from '@ecomm/tracking'
import classNames from 'classnames'
import { ReactNode, useState } from 'react'

type Props = {
  readonly children: ReactNode
  readonly hasDiscount: boolean
  readonly itemCount: number
}

export function OrderSummaryAccordion({
  children,
  hasDiscount,
  itemCount
}: Props) {
  const trackingSummaryDetails = useTrackingSummaryDetails()
  const [expanded, setExpanded] = useState(false)
  const itemSummary = `${itemCount} ${itemCount > 1 ? 'items' : 'item'}`

  const handleExpand = () => {
    const newState = !expanded ? 'expand' : 'collapse'
    trackingSummaryDetails(newState)
    setExpanded(!expanded)
  }

  return (
    <div className="mt-0" data-component="OrderSummaryAccordion">
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={handleExpand}
        role="button"
      >
        <h4 className="text-neutral-black m-0 mb-1 text-base font-bold md:mb-0 md:text-lg ">
          Review cart
        </h4>
        <ChevronIcon className={classNames({ 'rotate-180': expanded })} />
      </div>
      {!expanded && (
        <div
          className="text-neutral-black text-left text-sm"
          data-component="RowSummary"
        >
          {hasDiscount ? `${itemSummary}, coupon code applied` : itemSummary}
        </div>
      )}
      <div
        className={classNames(
          'mb-0 transition-[height] duration-1000 ease-out',
          { 'h-0': !expanded, 'mb-[-1rem] h-4': expanded }
        )}
      ></div>
      <div
        aria-hidden={!expanded}
        aria-labelledby="OrderSummaryAccordionHeading"
        className={classNames({ hidden: !expanded })}
        data-component="OrderSummaryAccordionHeading"
        role="region"
      >
        {children}
      </div>
    </div>
  )
}
