import { mapExperiences } from '@ecomm/shared-ninetailed'
import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import React from 'react'

import { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import { PackageFragment } from '../Package/schema'
import { PackageListItem } from '.'

type Props = {
  readonly item: PackageFragment
  readonly itemCount?: number
}

function AffirmPackageListItemWrapper({ item, itemCount }: Props) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <PackageListItem
      isAffirmExperience={isAffirmExperience}
      item={item}
      itemCount={itemCount}
    />
  )
}

export default AffirmPackageListItemWrapper
