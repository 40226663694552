import { footerSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

import { contentfulImageQuerySchema } from '../../schemas/contentfulImageQuery'

export const parseManualSchema = <
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>(
  data: T
): ManualPageSchema => {
  const { footer, content } = manualPageSchema.parse(data)

  return {
    footer,
    content
  }
}

const imageLinkSchema = z.object({
  contentful_id: z.string(),
  title: z.string(),
  url: z.string(),
  image: contentfulImageQuerySchema
})

export const manualPageSchema = z.object({
  footer: footerSchema.nullish(),
  content: z.object({
    contentful_id: z.string(),
    content: z
      .object({
        headline: z.string(),
        gridItems: imageLinkSchema.array()
      })
      .array()
  })
})

export type ManualPageSchema = TypeOf<typeof manualPageSchema>
export type ManualGridSchema = readonly TypeOf<typeof imageLinkSchema>[]
