import { ShippingInfo } from '@commercetools/platform-sdk'
import { MaybeT, overloadMaybe } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const getShipppingLabel = (
  shippingInfoPrice: number,
  shippingInfo: MaybeT<ShippingInfo>
) =>
  shippingInfoPrice > 0
    ? pipe(
        overloadMaybe(shippingInfo),
        O.chain(info => O.fromNullable(`${prop('shippingMethodName', info)}:`))
      )
    : O.some('Free Shipping:')
