import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'
import type { IconTypes } from '@ecomm/shared-icons'
import * as Icons from '@ecomm/shared-icons'
import { useMediaQuery } from '@ecomm/ss-react-components'
import { Link } from 'gatsby'
import React from 'react'

type Feature = {
  readonly icon: IconTypes
  readonly text: string
  readonly mobileText?: string
}

type Option = {
  readonly title: string
  readonly mobileTitle?: string
  readonly features: readonly Feature[]
}

export type OptionsComparisonDataProps = {
  readonly title: string
  readonly mobileTitle?: string
  readonly options: readonly Option[]
  readonly button: {
    readonly text: string
    readonly url: string
  }
}

export type OptionsComparisonProps = {
  readonly data: OptionsComparisonDataProps
  readonly images: readonly GatsbyImageSchema[]
}

function FeatureRow({ feature }: { readonly feature: Feature }) {
  const isTabletAndUp = useMediaQuery('TabletAndUp')
  const Icon = Icons[feature.icon]

  return (
    <li className="flex max-h-[15px] list-none items-center before:mr-[6px] before:align-middle before:content-['·'] md:mt-4 md:max-h-none md:gap-4 md:before:mr-0 md:before:content-none lg:mt-6">
      {isTabletAndUp ? (
        <Icon className="text-complementary-blue-100 h-8 w-8 flex-none" />
      ) : null}
      <span className="grow text-xs md:text-base md:leading-[22px] lg:text-lg">
        {isTabletAndUp ? feature.text : feature.mobileText}
      </span>
    </li>
  )
}

export function OptionsComparison({ data, images }: OptionsComparisonProps) {
  const { title, mobileTitle, options, button } = data
  const isTabletAndUp = useMediaQuery('TabletAndUp')

  return (
    <div>
      <h2 className="mb-6 text-center text-[28px] font-bold leading-[34px] md:mx-auto md:max-w-xl lg:mb-10 lg:max-w-6xl lg:text-[55px] lg:leading-none">
        {isTabletAndUp ? title : mobileTitle}
      </h2>

      <div className="flex gap-x-3 md:gap-x-6 lg:gap-x-10">
        {options.map((option, index) => (
          <div className="basis-1/2" key={index}>
            <GatsbyImage
              className="min-h-[150px] rounded-[20px] md:min-h-[275px]"
              image={images[index]}
            />
            <h3 className="mb-0 mt-4 text-[22px] font-bold leading-[30px] md:text-2xl lg:mt-6 lg:text-4xl">
              {isTabletAndUp ? option.title : option.mobileTitle}
            </h3>
            <ul className="mt-1 pl-[6px] md:mt-0">
              {option.features.map((feature, index) => (
                <FeatureRow feature={feature} key={index} />
              ))}
            </ul>
          </div>
        ))}
      </div>

      <Link className="md:block md:text-center" to={button.url}>
        <button
          className="rounded-base border-complementary-blue-100 text-complementary-blue-100 hover:bg-complementary-blue-100 my-6 w-full cursor-pointer border-2 border-solid bg-transparent px-4 py-3 text-center text-base leading-normal no-underline transition-colors duration-200 ease-in-out hover:text-white md:w-96 md:px-6 lg:w-auto lg:text-lg lg:even:ml-6 lg:even:mt-0"
          type="button"
        >
          {button.text}
        </button>
      </Link>
    </div>
  )
}
