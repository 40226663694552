import classNames from 'classnames'
import React from 'react'

import { GatsbyImage } from '../GatsbyImage'
import { ImageWithCaptionSchema } from './schema'

export type ImageWithCaptionProps = {
  readonly data: ImageWithCaptionSchema
}

export function ImageWithCaption({ data }: ImageWithCaptionProps) {
  return (
    <div>
      {data.media ? (
        <GatsbyImage image={data.media} imgStyle={{ width: '100%' }} />
      ) : null}
      {data.caption ? (
        <div
          className={classNames(
            'absolute left-0 flex h-14 w-full items-center justify-center bg-neutral-200/80 text-lg font-medium tracking-tight lg:h-20 lg:text-3xl',
            !data.position || data.position === 'bottom' ? 'bottom-0' : 'top-0'
          )}
        >
          {data.caption}
        </div>
      ) : null}
    </div>
  )
}
