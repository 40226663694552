import { voidFn } from '@simplisafe/ewok'
import equals from 'fast-deep-equal'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { CookieSetOptions } from 'universal-cookie'

import { COOKIE_DRUPAL_UID, COOKIE_USER_TOKEN } from './constants'
import { setCookie } from './cookies'
import { getUserId, getUserToken } from './getCookies'

type YodaAuth = {
  readonly token: string
  readonly userId: string
}

const cookiesOption: CookieSetOptions = {
  path: '/',
  sameSite: 'strict',
  secure: true
}

const baseYodaAtom = atom<YodaAuth | null>(null)

export const yodaAtom = atom(
  get => {
    const jotai = get(baseYodaAtom)
    const token = getUserToken()
    const userId = getUserId()
    /**
     * If auth data exists in Jotai, return it.
     * Otherwise, read token and userId from cookies and return them if they exist.
     */
    return !!jotai ? jotai : token && userId ? { token, userId } : null
  },
  (get, set, update: YodaAuth) => {
    const prev = get(baseYodaAtom)
    const next = update
    const fn = () => {
      // Persist any changes to the Jotai atom to cookies.
      setCookie(COOKIE_USER_TOKEN, next.token, cookiesOption)
      setCookie(COOKIE_DRUPAL_UID, next.userId, cookiesOption)
      set(baseYodaAtom, next)
    }
    equals(prev, next) ? voidFn() : fn()
  }
)

export const useYoda = () => useAtom(yodaAtom)
export const useYodaValue = () => useAtomValue(yodaAtom)
export const useSetYoda = () => useSetAtom(yodaAtom)
