import { Header } from '@ecomm/header-redesigned'
import { Footer, TrustpilotUKTopBanner } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { graphql, PageProps } from 'gatsby'

import ReferAFriendPlaceholder from '../../components/ReferAFriendPlaceholder'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import type { SeoNodeSchema } from '../../schemas/seo'
import { parseReferAFriendSchema } from './schema'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly placeholderType: string
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function ReferAFriendTemplate<
  T extends { readonly footer: U },
  U
>({ data, pageContext: { locale, seoDetails, placeholderType } }: Props<T>) {
  const { footer } = parseReferAFriendSchema(data)
  const headerData = useHeaderRedesignQuery()

  return (
    <TrackingProvider metaTitle={'refer-a-friend'}>
      <SEO
        canonicalLink={seoDetails.canonicalLink ?? ''}
        isNofollow={seoDetails.isNofollow}
        isNoindex={seoDetails.isNoindex}
        lang={locale}
        metaDescription={seoDetails.metaDescription?.metaDescription ?? ''}
        metaKeywords={seoDetails.metaKeywords ?? []}
        metaTitle={seoDetails.metaTitle}
      />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <Header {...headerData} />
        <div className="max-w-8xl mx-auto flex flex-col gap-4 px-4 py-6 md:gap-8 md:px-8 md:py-10 lg:py-12">
          {locale === 'en-GB' ? <TrustpilotUKTopBanner /> : null}
          <ReferAFriendPlaceholder type={placeholderType} />
        </div>
        {footer ? <Footer data={footer} type={footer.type} /> : null}
      </div>
    </TrackingProvider>
  )
}

export const query = graphql`
  #graphql
  query ReferAFriend($footerId: String) {
    footer: contentfulFooter(contentful_id: { eq: $footerId }) {
      ...footerFragment
    }
  }
`
