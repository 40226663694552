import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@ecomm/shared-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { UserStorySchema } from '../schema'

type UserStoryProps = {
  readonly userStory: UserStorySchema
}

const descriptionCustomOptions = (author: string): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) =>
      children && (
        <p className="mb-2 text-sm font-light leading-relaxed text-white md:my-3">
          {children}
          <span className="mx-2 italic">{author}</span>
        </p>
      )
  }
})

export function UserStory({ userStory }: UserStoryProps) {
  const backgroundImage = userStory?.backgroundImage?.gatsbyImageData && (
    <GatsbyImage
      alt={userStory.backgroundImage.description || ''}
      className="h-full object-cover"
      image={userStory.backgroundImage.gatsbyImageData}
    />
  )

  const deviceImage = userStory?.deviceImage?.gatsbyImageData && (
    <div className="absolute bottom-[-10px] left-8 h-auto w-[45%] md:top-8">
      <GatsbyImage
        alt={userStory.deviceImage.description || ''}
        image={userStory.deviceImage.gatsbyImageData}
      />
    </div>
  )

  return (
    <div
      className="relative h-full overflow-hidden rounded-lg leading-5"
      data-component="UserStory"
      style={{ backgroundColor: userStory.color }}
    >
      <div className="relative w-full md:h-auto">
        {backgroundImage}
        {deviceImage}
      </div>
      <div className="p-5 text-white">
        <h4 className="mb-2 text-2xl font-medium md:mt-5">{userStory.title}</h4>
        <ContentfulRichText
          optionsCustom={descriptionCustomOptions(userStory.author)}
          raw={userStory.description?.raw}
        />
        <h5 className="m-0 text-lg text-white md:mb-3 md:mt-4">
          {userStory.listTitle}
        </h5>
        <ul className="mb-4 mt-2 list-disc pl-6 text-sm font-light md:mb-12 md:mt-4 md:pl-8 md:pr-7">
          {userStory.list.map((item, idx) => (
            <li className="mb-2 md:mb-3" key={idx}>
              {item}
            </li>
          ))}
        </ul>
        {userStory.note && (
          <span className="mx-6 mb-2 block text-xs italic md:absolute md:bottom-4 md:mr-14 md:pl-2">
            {userStory.note}
          </span>
        )}
      </div>
    </div>
  )
}
