import { createMockRichText } from '@ecomm/utils'

import { MonitoringPlanCardSchema } from './schema'

export const getMonitoringPlanCardMock =
  async (): Promise<MonitoringPlanCardSchema> => ({
    __typename: 'ContentfulMonitoringPlan',
    description: {
      raw: await createMockRichText('Description')
    },
    features: [
      {
        __typename: 'ContentfulMonitoringFeature',
        contentful_id: '12345',
        helpText: {
          raw: await createMockRichText('Feature 1 description')
        },
        icon: 'CheckMark',
        modal: {
          description: {
            raw: await createMockRichText('Feature 1 modal description')
          },
          headlineText: 'Feature 1 modal title',
          __typename: 'ContentfulModal'
        }
      },
      {
        __typename: 'ContentfulMonitoringFeature',
        contentful_id: '123456',
        helpText: {
          raw: await createMockRichText('Feature 2 description')
        },
        icon: 'XSolidIcon'
      }
    ],
    additionalFeatures: [
      {
        __typename: 'ContentfulIconAndText',
        contentful_id: '1234',
        icon: 'PoliceCar',
        text: {
          raw: await createMockRichText('Additional Feature 1')
        },
        title: ''
      },
      {
        __typename: 'ContentfulImageAndText',
        contentful_id: '5678',
        image: {
          url: '/image-test',
          description: 'Test Image',
          originalHeight: 100,
          originalWidth: 100
        },
        text: {
          raw: await createMockRichText('Additional Feature 2')
        }
      }
    ],
    disclaimer: 'some disclaimer',
    recommended: true,
    cta: 'CTA BUTTON',
    name: 'Standard Monitoring',
    sku: '100'
  })
