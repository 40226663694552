import { OutdoorMonitoringCartBuilder } from '@ecomm/checkout/odmon-components'
import { TrackingProvider } from '@ecomm/tracking'

export default function OutdoorMonitoringInterstitialTemplate() {
  return (
    <TrackingProvider metaTitle="Outdoor Montitoring">
      <OutdoorMonitoringCartBuilder />
    </TrackingProvider>
  )
}
