import { SimpleButton, Text as Typography } from '@ecomm/ss-react-components'
import classNames from 'classnames'
import React, { useState } from 'react'

import {
  FastProtectMonitoringCard,
  SelfMonitoringCard
} from '../MonitoringCard/MonitoringCard'
import { ScoutPlanComparison } from '../MonitoringComparisonTable/MonitoringComparisonTable'
import { StandardMonitoringBox } from '../StandardMonitoringBox'
import { ExpandableMonitoringProps } from './types'
import { useOnComparePlans } from './useOnComparePlans'
import { useOnShowOtherPlans } from './useOnShowOtherPlans'

export function ExpandableMonitoring({
  headline,
  professionalMonitoring,
  selfMonitoring,
  showPlanText,
  showComparisonText,
  comparisonTable
}: ExpandableMonitoringProps) {
  const [isExpandCard, setIsExpandCard] = useState(false)
  const [isExpandTable, setIsExpandTable] = useState(false)
  const onComparePlans = useOnComparePlans()
  const onShowOtherPlans = useOnShowOtherPlans()

  const handleShowOtherPlans = () => {
    onShowOtherPlans()
    setIsExpandCard(true)
  }

  const handleComparePlans = () => {
    onComparePlans()
    setIsExpandTable(true)
  }

  return (
    <>
      {headline ? (
        <Typography className="mb-9" useTailwind>
          <h2 className="h2 text-center">{headline}</h2>
        </Typography>
      ) : null}
      {professionalMonitoring}
      {!isExpandCard && (
        <div className="w-full py-8 text-center">
          <SimpleButton
            aria-label="Expand Monitoring Card"
            className={classNames(
              'w-52 shrink-0 self-center text-base md:w-64 md:text-lg lg:self-auto'
            )}
            onClick={handleShowOtherPlans}
            variant="outlined"
          >
            {showPlanText}
          </SimpleButton>
        </div>
      )}
      {isExpandCard ? (
        <div className="py-4">
          {selfMonitoring}
          {!isExpandTable && (
            <div className="w-full py-8 text-center">
              <SimpleButton
                aria-label="Expand Monitoring Card"
                className={classNames(
                  'w-52 shrink-0 self-center text-base md:w-64 md:text-lg lg:self-auto'
                )}
                onClick={handleComparePlans}
                variant="outlined"
              >
                {showComparisonText}
              </SimpleButton>
            </div>
          )}
        </div>
      ) : null}
      {isExpandTable ? <div>{comparisonTable}</div> : null}
    </>
  )
}

/**
 * @deprecated this component has been moved to monitoring/components' library and will be deleted soon
 */
export function ScoutExpandableMonitoring({
  headline = '',
  professionalMonitoringAtcButton = null,
  selfMonitoringAtcButton = null,
  standardMonitoringAtcButton = null
}: {
  readonly headline?: string
  readonly professionalMonitoringAtcButton?: React.ReactNode
  readonly selfMonitoringAtcButton?: React.ReactNode
  readonly standardMonitoringAtcButton?: React.ReactNode
}) {
  return (
    <ExpandableMonitoring
      comparisonTable={
        <>
          <ScoutPlanComparison
            professionalMonitoringAtcButton={professionalMonitoringAtcButton}
            selfMonitoringAtcButton={selfMonitoringAtcButton}
          />
          <StandardMonitoringBox
            atcButton={standardMonitoringAtcButton}
            price="73¢/day"
          />
        </>
      }
      headline={headline}
      professionalMonitoring={
        <FastProtectMonitoringCard
          atcButton={professionalMonitoringAtcButton}
        />
      }
      selfMonitoring={
        <SelfMonitoringCard atcButton={selfMonitoringAtcButton} />
      }
      showComparisonText="Compare plans"
      showPlanText="Show other plans"
    />
  )
}
