import { TypeOf, z } from '@simplisafe/ewok'

export const monitoringSchema = z.object({
  contentfulJson: z.object({
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  })
})

export type MonitoringSchema = TypeOf<typeof monitoringSchema>
