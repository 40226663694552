const enUs = {
  'affirm-buy-now-title': 'Buy now, pay later with',
  'a11y-close-section': (title: string) => `close section ${title}`,
  'a11y-open-section': (title: string) => `open section ${title}`,
  'add-sensors-to-customize': 'Add sensors to customize your package',
  'add-to-cart': 'Add to cart',
  'aria-decrease-quantity': 'Click here to decrease the quantity',
  'aria-increase-quantity': 'Click here to increase the quantity',
  'aria-remove-item': 'Remove Item',
  'blog-hero-title': 'The SimpliSafe home security blog',
  'blog-posted-date': (date: string) => `Posted ${date} by SimpliSafe`,
  'build-your-own-system': 'Build your own system',
  'build-your-system': 'Build Your System',
  first: 'first.',
  'cart-updated': 'Cart updated',
  'claim-offer': 'Claim offer',
  'claim-this-deal': 'Claim this deal',
  color: 'color',
  'dynamic-pkg-title': (count: number) => `${count}-Piece Security System`,
  'enter-email-label': 'Enter your email',
  'enter-email-placeholder': 'enter your email',
  'floating-badge-available': 'Available on site today',
  'floating-badge-pro-premium': 'All systems with Pro Premium monitoring',
  'floor-plan-title': 'in your home',
  'free-item-automatically-added': (item: string) =>
    `One free ${item} will be automatically added to your new system`,
  'free-shipping': '+ Free Shipping',
  'free-text': 'Free',
  'hide-details': 'hide details',
  'how-many-do-you-need': 'How many do you need?',
  'included-with-your-system': 'Included with your system',
  'experiment-service-plan-name': 'Fast Protect™',
  'monitor-header-save': 'and save',
  'monitoring-toggle-title': (discountText: string, planName: string) =>
    `Save ${discountText} with ${[planName]}`,
  'monitoring-name': 'Fast Protect™ Monitoring',
  monitoring: 'Monitoring',
  'need-more-sensors': 'Need more sensors?',
  next: 'next',
  new: 'NEW',
  'not-the-right-fit': 'Not the right fit?',
  off: 'OFF',
  'order-expected-date': (date: string) =>
    `Order now for an expected ${date} ship date`,
  'out-of-stock': 'Out of stock',
  'package-atc-error':
    'Something went wrong. Please reload the page and try again.',
  'package-item-ooo': (date: string) =>
    `Low stock: may ship separately in ${date}`,
  'personalize-your-package': 'Personalize your package',
  'product-details': 'Product details',
  'reset-sections': 'Reset Selections',
  'return-disclaimer':
    'Don’t worry. It’s easy to add more later, or return ones you don’t need.',
  'required-with-system': 'Required with your system',
  'see-details': 'See details',
  'see-promo-details': 'see promo details',
  simplisafe: 'SimpliSafe',
  'shop-now': 'Shop now',
  soon: 'soon',
  tomorrow: 'tomorrow',
  'tomorrow-abbreviation': 'tmrw',
  'total-label': 'Total',
  'what-is-included': "What's included",
  equipment: 'Equipment',
  'your-custom-system': 'Your custom security system',
  'country-redirect-title': 'Are you in the UK?',
  'country-redirect-text':
    "You're on the US site, which serves our customers in United States. Switch to the UK site below.",
  'country-redirect-button-text': 'Go to the UK site',
  'country-redirect-link': 'https://simplisafe.co.uk',
  '60-day-money-back':
    '60 Day Money-Back Guarantee // Free Shipping // Free Returns',
  'meet-the-camera': 'Meet the camera',
  'meet-the-system': 'Meet the System',
  'added-to-your-cart': 'Added to Your Cart',
  'continue-shopping': 'Continue shopping',
  checkout: 'Checkout',
  'pay-as-low-as': 'Pay as low as',
  'pay-in-full': 'Or pay in full',
  'pay-over-time': 'Pay over time with',
  'due-today': 'due today',
  'sub-total': 'Subtotal',
  'the-right-security-system': 'The right security system',
  'take-a-short-quiz': 'Take a short quiz to get a recommendation.',
  'take-our-quiz-label': 'Take our quiz',
  'affirm-buy-now-description':
    'Select Affirm at checkout. You’ll pay at the monthly installment that works best for you. It’s easy to sign up. And there’s no late fees or surprises.',
  'cart-camera-max-quantity-error': (maxQuantity: number) =>
    `Only ${maxQuantity} cameras of this type per customer. Please update your cart to continue.`,
  'cart-below-content-title': 'More ways to mount and charge your camera',
  'cart-below-content-title-variation':
    'More ways to mount or charge your camera',
  'cart-below-content-subtitle':
    'Everything you need to keep your outdoor camera mounted and charged 24/7.',
  'cart-below-content-subtitle-variation':
    'Everything you need to keep your cameras ready for action',
  'cart-newest-security-system-warning':
    'Items in your cart require our newest security system with a Base Station and Keypad to work. New to SimpliSafe®?',
  'affirm-checkout-description':
    'Enter a few pieces of information for a real-time decision. Once your order is completed, you’ll be taken back to our website to finish setting up your account.',
  'check-out-with': 'Check out with',
  'redirect-to-affirm-purchase':
    'You will be redirected to Affirm to complete your SimpliSafe purchase.',
  'live-guard-protection': 'Live guard protection',
  'affirm-only-available-orders-over-150':
    'Affirm is only available for orders over $150.',
  'if-the-issue-persists':
    'If the issue persists, please call 888-910-1458 to complete your order.',
  'by-submitting-order-agree-simplisafe': `By submitting this order, you agree to SimpliSafe's`,
  'terms-of-sale': 'Terms of Sale',
  'terms-of-service': 'Terms of Service',
  and: 'and',
  'privacy-policy': 'Privacy Policy',
  loading: 'Loading...',
  'bms-phone-number-label':
    'Need help? Talk to a security specialist: 1-800-304-3601',
  redeem: 'Redeem',
  'us-phone-number': '1-800-304-3601',
  'payment-number': '888-753-5219',
  'postal-code': 'ZIP Code',
  'shop-cameras-and-accessories': 'Shop cameras and accessories',
  'make-sure-your-base-station': 'Make sure your Base Station looks like this.',
  'build-your-security-system': 'Build your security system',
  'buy-extra-sensors': 'Buy extra sensors',
  'see-packages': 'See packages',
  'buy-accessory': 'Buy Accessory',
  'faq-title': 'Frequently Asked Questions',
  'faq-title-short': 'FAQs',
  features: 'Features',
  'add-to-cart-error-recoverable':
    'Something went wrong. Please reload the page and try again.',
  'add-to-cart-error-unrecoverable':
    'Something went wrong. Please call 888-910-1458 for further assistance.',
  'professional-monitoring': 'First month of monitoring is free',
  'shipping-truck': 'Free shipping with system order',
  shield: '60 day return policy',
  'shopping-carty-empty': 'Your shopping cart is currently empty.',
  recommended: 'RECOMMENDED',
  'compare-plans': 'Compare plans',
  'show-other-plans': 'Show other plans',
  'no-contract': 'No contract. Cancel anytime.',
  'cart-error-state-heading': 'Something isn’t right. Try reloading the page.',
  'cart-error-state-subheading':
    'If the issue persists, please call 888-910-1458 to complete your order.',
  'cart-updating-state-heading': 'Loading your cart...',
  'system-updated': 'System updated',
  'please-enter-attribution-info': 'Please enter your attribution info',
  'secure-checkout': 'Secure checkout',
  'continue-to-secure-checkout': 'Continue to secure checkout',
  'contact-email': 'customer-support@simplisafe.com',
  'contact-number': '1-888-910-1215',
  'fast-protect-daily-price': '$1.06',
  'ss2-monitoring-toggle': (discountText: string) =>
    `Get ${discountText} off today`,
  'checkout-tax': 'Estimated tax:',
  'checkout-total': 'Total:'
}

export default enUs
