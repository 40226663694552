import { PackageFragment } from '@ecomm/partners-hooks'
import {
  ConfidenceBar,
  ConfidenceBarProps,
  parseJSONDataFromContentful
} from '@ecomm/scout'
import { graphql, useStaticQuery } from 'gatsby'

import { BuildMySystemBanner } from '../BuildMySystemBanner'
import { MonitoringWrapper } from '../Monitoring'
import { PackageListItem } from '../PackageListItem'

type Props = {
  readonly packagesData: readonly PackageFragment[]
  readonly showPartnerPackageAbsoluteDiscountAsRelative?: boolean
}

export default function StrategicReferral({
  packagesData,
  showPartnerPackageAbsoluteDiscountAsRelative
}: Props) {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query StrategicReferralQuery {
      confidenceBar: contentfulJson(
        contentful_id: { eq: "2yJuhWpaW0nmKtDSEIEkxM" }
      ) {
        ...json
      }
    }
  `)

  const confidenceBarData: ConfidenceBarProps = parseJSONDataFromContentful(
    staticQuery,
    'confidenceBar'
  )

  return (
    <div>
      <div className="p-5 lg:p-8">
        <ConfidenceBar data={confidenceBarData} />
      </div>

      <p id="packages"></p>
      {/* this id is to auto-scroll the user down from the hero banner shop now button*/}
      <div className="max-w-8xl mx-auto flex flex-col gap-6 p-4 md:gap-8 md:p-8">
        {packagesData.map((partnerPackage, index) => (
          <>
            <PackageListItem
              item={partnerPackage}
              showPartnerPackageAbsoluteDiscountAsRelative={
                showPartnerPackageAbsoluteDiscountAsRelative
              }
            />
            {index === 0 && <MonitoringWrapper />}
          </>
        ))}
      </div>
      <BuildMySystemBanner />
    </div>
  )
}
