import React, { useRef, useState } from 'react'

import Review, { ReviewProps } from './Review'

type CarouselProps = {
  readonly reviews: readonly ReviewProps[]
}

export default function Carousel({ reviews }: CarouselProps) {
  const carouselRef = useRef<HTMLDivElement>(null)
  const [activeSlide, setActiveSlide] = useState(0)

  /* istanbul ignore next */
  const scrollToSlide = (index: number) => {
    const scrollToIndex = (index: number) => {
      const scrollWidth = carouselRef?.current?.scrollWidth || 0
      const slideWidth = scrollWidth / reviews.length
      carouselRef?.current?.scrollTo({
        left: slideWidth * index,
        behavior: 'smooth'
      })
    }
    setActiveSlide(index)

    carouselRef.current && scrollToIndex(index)
  }

  /* istanbul ignore next */
  const handleScroll = () => {
    const updateActiveSlide = () => {
      const scrollWidth = carouselRef?.current?.scrollWidth || 0
      const slideWidth = scrollWidth / reviews.length
      const newIndex = Math.round(
        (carouselRef?.current?.scrollLeft || 0) / slideWidth
      )

      setActiveSlide(newIndex)
    }

    carouselRef.current && updateActiveSlide()
  }

  return (
    <div>
      <div
        className="mx-auto flex w-full snap-x snap-mandatory overflow-scroll pb-0"
        data-component="carousel"
        onScroll={handleScroll}
        ref={carouselRef}
      >
        {reviews.map((review, index) => (
          <div
            className="flex w-full snap-center flex-col justify-center pb-2 pl-1 pr-4 md:px-8 md:py-10"
            key={index}
          >
            <Review {...review} />
          </div>
        ))}
      </div>

      <div className="my-4 flex flex-row justify-center gap-4">
        {reviews.map((_, index) => (
          <div
            className={`h-2 w-2 cursor-pointer rounded-full ${
              index === activeSlide
                ? 'bg-neutral-black'
                : 'bg-neutral-light-100'
            }`}
            data-component="dot"
            key={index}
            onClick={() => scrollToSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  )
}
