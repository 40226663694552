import { SSButton } from '@ecomm/ss-react-components'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

export type CartWidgetItemProps = {
  readonly alignContentCenter?: boolean
  readonly buttonText?: string
  readonly dataComponentName?: string
  readonly description?: ReactNode
  readonly heading?: ReactNode
  readonly icon?: ReactNode
  readonly onClick?: () => void
  readonly subHeading?: ReactNode
  readonly shouldTrackClicks?: boolean
}

function CartWidgetItem({
  alignContentCenter,
  buttonText,
  dataComponentName,
  description,
  heading,
  icon,
  onClick,
  subHeading,
  shouldTrackClicks
}: CartWidgetItemProps) {
  return (
    <div
      className={classNames(
        'neutralLight50BackgroundColor rounded-lg px-4 py-4',
        { alignCenter: alignContentCenter }
      )}
      data-component={dataComponentName}
      onClick={shouldTrackClicks && onClick ? onClick : () => null}
    >
      {icon && icon}
      {heading && heading}
      {subHeading && subHeading}
      {description && description}

      {buttonText && <SSButton onClick={onClick}>{buttonText}</SSButton>}
    </div>
  )
}

export default CartWidgetItem
