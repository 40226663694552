import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

import Bullet from './Bullet'
import { CarouselSectionFragment } from './schema'

export type CarouselSectionProps = {
  readonly data: CarouselSectionFragment
}

export default function CarouselSection({ data }: CarouselSectionProps) {
  const items = data.items || []
  const hasItems = items.length > 0

  const [position, setPosition] = useState(0)
  const maxItems: number = items.length - 1

  const moveTo = useCallback((newPosition: number) => {
    setPosition(newPosition)
  }, [])

  const next = useCallback(() => {
    const newPosition: number = maxItems < position + 1 ? 0 : position + 1
    setPosition(newPosition)
  }, [position, maxItems])

  useEffect(() => {
    const intervalId = setInterval(() => {
      next()
    }, 4000)

    return () => clearInterval(intervalId)
  }, [next, position])

  return hasItems ? (
    <div className="rounded-base bg-neutral-light-50 relative h-[600px]">
      {items.map((item, index) => {
        const isVisible = position === index
        return (
          <div
            className={classNames(
              'absolute h-full w-full transition-opacity duration-500',
              {
                'opacity-0': !isVisible,
                'opacity-100': isVisible
              }
            )}
            data-component={isVisible ? 'Selected' : 'Hidden'}
            key={index}
          >
            <div className="hidden md:block">
              <GatsbyImage
                className="rounded-base prose-img:rounded-base !absolute h-full w-full"
                image={item.image}
              />
            </div>
            <div className="block md:hidden">
              <GatsbyImage
                className="rounded-base prose-img:rounded-base !absolute h-full w-full"
                image={item.mobileImage}
              />
            </div>
            <div className="relative flex h-full flex-col items-start justify-start px-8 py-8 md:flex-row md:justify-between md:px-16  md:pt-[9rem]">
              <div className="md:w-[26rem]">
                <h2 className="h2" data-component="Title">
                  {data.title}
                </h2>
                <p className="paragraph" data-component="Headline">
                  {item.headline}
                </p>
              </div>
              <div className="w-[18.75rem]">
                <div className="paragraph mt-4 md:mt-0">
                  <ContentfulRichText raw={item.description.raw} />
                </div>
              </div>
            </div>
            <ul
              className="absolute bottom-[33px] m-0 flex w-full items-center justify-center p-0 md:hidden"
              data-component={'Bullets/Mobile'}
            >
              {items.map((item, index) => (
                <Bullet
                  index={index}
                  key={index}
                  moveTo={moveTo}
                  position={position}
                />
              ))}
            </ul>
            <ul
              className="absolute bottom-0 top-0 hidden flex-col items-center justify-center md:right-[33px] md:flex"
              data-component={'Bullets/Desktop'}
            >
              {items.map((item, index) => (
                <Bullet
                  index={index}
                  key={index}
                  moveTo={moveTo}
                  position={position}
                />
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  ) : null
}
