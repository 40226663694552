import classNames from 'classnames'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import React, { FC, ReactNode } from 'react'

import { Heading } from '..'
import CartLineItem, { CartLineItemProps } from '../CartLineItem'
import MiniCartOptions, { MiniCartOptionsProps } from '../MiniCartOptions'
import SSButton, { SSButtonProps } from '../SSButton'

export type CartSummaryprops = {
  readonly addSensorsText?: string
  readonly badgeImage: ReactNode
  /** TO DO: replace badge text as ReactNode when refactoring RichText usage */
  /** The content of the badge */
  readonly badgeContent?: ReactNode
  /** A location after the button to add disclaimer text, shipping information, or save cart widgets. */
  readonly buttonAfter?: ReactNode
  /** Displays inline to the left of the button. Used for error messages. */
  readonly buttonMessage?: ReactNode
  readonly buttonOnClick: () => void
  readonly buttonProps: SSButtonProps
  readonly cartLineItem: readonly CartLineItemProps[]
  readonly cartMessage?: ReactNode
  readonly dataComponent?: string
  /** @deprecated Use buttonAfter instead. */
  readonly freeShippingText?: string
  readonly isDisabled?: boolean
  readonly miniCartOptions: MiniCartOptionsProps
  /** Out of stock message displayed when the product is out of stock */
  readonly outOfStockMsg?: ReactNode
  readonly price?: ReactNode
  readonly title: string
  /** The content of the component */
  readonly totalOrderContent?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const CartSummary: FC<CartSummaryprops> = ({
  addSensorsText,
  title,
  buttonAfter,
  buttonMessage,
  cartMessage,
  miniCartOptions,
  freeShippingText,
  cartLineItem,
  price,
  badgeContent,
  badgeImage,
  buttonOnClick,
  isDisabled = false,
  dataComponent = CartSummary.name,
  buttonProps,
  outOfStockMsg,
  totalOrderContent
}: CartSummaryprops) => {
  const cartItemList = cartLineItem.map(
    ({
      itemName,
      price,
      quantitySelect,
      isFreeItem,
      quantity,
      quantityItem
    }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const keyValue = ifElse(
        isEmpty,
        always('empty'),
        always(itemName)
      )(itemName)

      return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <span key={keyValue}>
          <CartLineItem
            isFreeItem={isFreeItem}
            itemName={itemName}
            price={price}
            quantity={quantity}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            quantityItem={quantityItem}
            quantitySelect={quantitySelect}
            theme="customLineItem"
          />
        </span>
      )
    }
  )

  return (
    <div
      className="rounded-base bg-neutral-light-50 grid grid-cols-12 px-4 py-5 md:gap-5 md:p-8 lg:p-16"
      data-component={dataComponent}
    >
      <div className="md:text-body-size hidden px-4 text-center text-xs md:col-span-4 md:block lg:px-6">
        {badgeImage ? (
          <div className="mb-4 inline-block h-32 w-32 -rotate-12 lg:mb-8 lg:h-60 lg:w-60">
            {badgeImage}
          </div>
        ) : null}
        {badgeContent}
      </div>
      <div className="relative col-span-12 md:col-span-8 lg:col-span-7 lg:col-start-6">
        <div className="block md:mb-12">
          <Heading
            className={classNames('prose-h2:font-normal', {
              'hidden md:block': !cartMessage
            })}
            element="h2"
            headingAspect="h4"
            margin="extraSmall"
            useTailwind
          >
            {title}
          </Heading>
          {cartMessage ? (
            <div className="py-4 md:pb-16 md:pt-8">{cartMessage}</div>
          ) : null}
          <div className={classNames({ 'hidden md:block': !cartMessage })}>
            {cartItemList}
          </div>
        </div>
        <MiniCartOptions {...miniCartOptions} totalPrice={price} />
        <div className="my-2 flex items-center justify-end md:my-4">
          {totalOrderContent}
          {price ? (
            <Heading
              className="prose-h4:m-0 my-0 font-normal"
              element="h4"
              useTailwind
            >
              {price}
            </Heading>
          ) : null}
        </div>
        <div className="flex items-center justify-end text-right md:mb-4">
          {buttonMessage ? (
            <div className="mr-4 md:mr-8">{buttonMessage}</div>
          ) : null}
          <SSButton
            className="max-w-fit md:!w-60 md:max-w-none"
            {...buttonProps}
            disabled={isDisabled}
            onClick={buttonOnClick}
          />
        </div>
        {outOfStockMsg ? (
          <div className="outOfStockText mt-2.5 whitespace-pre-line text-right text-xs">
            {outOfStockMsg}
          </div>
        ) : null}
        {freeShippingText ? (
          <p className="my-2 inline-block w-full text-right md:hidden">
            {freeShippingText}
          </p>
        ) : null}
        {buttonAfter}
        {addSensorsText && isDisabled ? (
          <p
            className={classNames('whitespace-pre-line text-right text-xs', {
              'mt-1': !!buttonAfter,
              'mt-4': !buttonAfter
            })}
          >
            {addSensorsText}
          </p>
        ) : null}
      </div>
    </div>
  )
}

export default CartSummary
