import { overloadedOption } from '@ecomm/shared-fp-ts-utils'
import { MaybeT, overloadOption } from '@simplisafe/ewok'
import {
  EvergreenPromotion,
  PromoBanner
} from '@simplisafe/ss-ecomm-data/promotions/lib'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import type { GqlEvergreen, GqlEverGreenPromoFlag } from './types'

function parsePromoBanner(
  t: NonNullable<GqlEvergreen['promoBanner']>
): MaybeT<PromoBanner> & O.Option<PromoBanner> {
  return overloadedOption<PromoBanner>({
    backgroundColor: overloadedOption(t.backgroundColor),
    buttonBackgroundColor: overloadedOption(t.buttonBackgroundColor),
    buttonTextColor: overloadedOption(t.buttonTextColor),
    disclaimerText: overloadedOption(t.disclaimerText),
    displayEndTimeCalloutOverride: t.displayEndTimeCalloutOverride,
    discountSecondaryText: overloadedOption(t.discountSecondaryText),
    endTimeCalloutOverride: overloadedOption(t.endTimeCalloutOverride),
    experimentId: overloadedOption(t.experimentId),
    freeGiftItemPrimaryText: overloadedOption(t.freeGiftItemPrimaryText),
    freeGiftItemSecondaryText: overloadedOption(t.freeGiftItemSecondaryText),
    giftlineItemText: overloadedOption(t.giftLineItemText),
    hasCountdown: t.hasCountdown ?? false,
    hasEmailInput: t.hasEmailInput ?? false,
    hasEndDateCallout: t.hasEndDateCallout ?? false,
    hasFreeGiftItem: t.hasFreeGiftItem ?? false,
    hasSaleName: t.hasSaleName || false,
    primaryTextColor: overloadedOption(t.primaryTextColor),
    saleName: overloadedOption(t.saleName),
    saleNameLabel: overloadedOption(t.saleNameLabel),
    secondaryTextColor: overloadedOption(t.secondaryTextColor)
  })
}

function parsePromoFlag(t: GqlEverGreenPromoFlag) {
  return {
    backgroundColor: overloadedOption(t.backgroundColor),
    textColor: overloadedOption(t.textColor)
  }
}

export function parseEvergreen(t: GqlEvergreen | undefined) {
  return pipe(
    O.fromNullable(t),
    O.map(
      (p): EvergreenPromotion => ({
        endTime: overloadedOption(p.endTime),
        promoBanner: pipe(
          p.promoBanner,
          O.fromNullable,
          O.chain(parsePromoBanner),
          overloadOption
        ),
        promoCode: overloadedOption(p.promoCode),
        promoCodeWithMonitoring: overloadedOption(p.promoCodeWithMonitoring),
        lastFetched: overloadedOption(p.lastFetched),
        lastModified: overloadedOption(p.lastModified),
        displayMonitoringDiscount: p.displayMonitoringDiscount || false,
        promoFlag: pipe(
          p.promoFlag,
          O.fromNullable,
          O.map(parsePromoFlag),
          overloadOption
        ),
        // @ts-expect-error - not sure when this image was added, but it wasn't added to graphql so it doesn't exist in the source plugin
        image: overloadedOption(O.none)
      })
    ),
    overloadOption
  )
}
