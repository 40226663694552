import classNames from 'classnames'
import React from 'react'

import { AffirmInformationLogoIcon } from '../../../AffirmInformationLogoIcon'
import { AffirmOptionType } from '../../../types/affirm'

type Props = {
  readonly affirmOption: AffirmOptionType
  readonly payAsLowAsMicroCopy: string
  readonly payOverTimeMicroCopy: string
  readonly onClick: () => void
  readonly priceInCents: number
  readonly isPlp?: boolean
  readonly useSystemTermMonths?: boolean
}

export function MonthlyOptionHeader({
  affirmOption,
  onClick,
  payOverTimeMicroCopy,
  payAsLowAsMicroCopy,
  priceInCents,
  isPlp = false,
  useSystemTermMonths = true
}: Props) {
  return affirmOption === 'qualified-monthly' ? (
    <p
      className={classNames('m-0 pt-1 text-sm font-bold leading-none', {
        'self-center': !isPlp,
        'mb-0': isPlp
      })}
    >
      {payAsLowAsMicroCopy}
    </p>
  ) : (
    <p className="m-0 self-start text-left text-sm font-bold leading-none">
      {payOverTimeMicroCopy}
      {
        <AffirmInformationLogoIcon
          height="16px"
          onClick={onClick}
          priceInCents={priceInCents}
          useSystemTermMonths={useSystemTermMonths}
          width="43px"
        />
      }
    </p>
  )
}
