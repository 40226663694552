import {
  buttonSchema,
  floorPlanCarouselSchema,
  footerSchema,
  heroBannerSchema
} from '@ecomm/shared-components'
import {
  anchorSectionSchema,
  coloredSectionSchema,
  duplexSectionSchema,
  featureSectionSchema,
  gridSchema,
  reviewSectionSchema,
  twoColumnTextSchema
} from '@ecomm/shared-sections'
import { addVariationSchema } from '@ecomm/utils'
import { TypeOf, z } from '@simplisafe/ewok'

import { layoutSchema } from '../../assemblies/Layout/layoutSchema'
import { contentCollectionSchema } from '../../components/ContentCollection/schema'
import { trustpilotSchema } from '../../components/HomeLandingTrustpilotBanner/schema'
import { layoutReferenceSchema } from '../../components/LayoutReference/schema'
import { smallTextSchema } from '../../components/SmallText/schema'

const layoutBodySchema = z.union([
  contentCollectionSchema,
  addVariationSchema(heroBannerSchema),
  anchorSectionSchema,
  featureSectionSchema,
  gridSchema,
  floorPlanCarouselSchema,
  trustpilotSchema,
  coloredSectionSchema,
  buttonSchema,
  layoutReferenceSchema,
  twoColumnTextSchema,
  duplexSectionSchema,
  smallTextSchema,
  reviewSectionSchema
])

export const homeLandingLayoutSchema = layoutSchema.extend({
  components: z.array(layoutBodySchema).optional(),
  promoBannerStyle: z.string(),
  footer: footerSchema.optional()
})

export const genericHomeLandingTemplateSchema = z.object({
  contentful_id: z.string(),
  slug: z.string(),
  breadcrumbTitle: z.string().nullish(),
  layout: homeLandingLayoutSchema,
  showPopupQuoteWizard: z.boolean().nullable().optional()
})

export type GenericHomeLandingTemplateSchema = TypeOf<
  typeof genericHomeLandingTemplateSchema
>
