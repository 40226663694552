import { mapExperiences } from '@ecomm/shared-ninetailed'
import { useIsODMONPreLaunchVariant } from '@ecomm/shared-hooks'

import AddToCartButton from '../../components/AddToCartButton'
import { Props as AddToCartButtonPackageProps } from '../../components/AddToCartButton/AddToCartButtonPackage'
import { useOdmonFeatureFlagQuery } from './FeatureFlagQuery'
import { OdmonFeatureFlagSchema } from './schema'

export function OdmonPreLaunchAddToCartButtonPackage(
  props: Omit<AddToCartButtonPackageProps, 'isOdmonPreLaunchVariant'>
) {
  const odmonFeatureFlagQueryData = useOdmonFeatureFlagQuery()
  const mappedExperiences = mapExperiences<OdmonFeatureFlagSchema>(
    odmonFeatureFlagQueryData.nt_experiences
  )
  const isOdmonPreLaunchVariant = useIsODMONPreLaunchVariant(mappedExperiences)

  return (
    <AddToCartButton.Package
      {...props}
      isOdmonPreLaunchVariant={isOdmonPreLaunchVariant}
    />
  )
}
