import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { UAParser } from 'ua-parser-js'

const bannerHideClass = 'onetrust-banner-hide'
const backdropHideClass = 'onetrust-backdrop-hide'

/**
 * Hides OneTrust banner when the user is not visiting the homepage on a desktop device.
 */
export function HideOneTrustBanner() {
  const [isOneTrustBannerHidden, setIsOneTrustBannerHidden] = useState(true)

  useEffect(() => {
    const showOneTrustBanner = () => {
      document.body.classList.remove(bannerHideClass, backdropHideClass)
      setIsOneTrustBannerHidden(false)
    }

    // If UAParser detects a device `type`, the device is non-desktop
    const isDesktop = !new UAParser().getDevice().type
    const showOneTrust = window.location.pathname === '/' && isDesktop

    showOneTrust && showOneTrustBanner()

    const onClick = (e: MouseEvent) => {
      const oneTrustPrivacyCenterOpenLink = document.querySelector(
        '.ot-sdk-show-settings'
      )
      e.target === oneTrustPrivacyCenterOpenLink &&
        document.body.classList.remove(backdropHideClass)

      /* Modal backdrop is shared w/ banner so it needs to be hidden again when the
      OneTrust Privacy Center is closed. If the user updates their privacy center
      settings it will dismiss the banner so the backdrop doesn't need to be hidden in that case */
      const oneTrustPrivacyCenterCloseButton = document.querySelector(
        '#close-pc-btn-handler'
      )
      e.target === oneTrustPrivacyCenterCloseButton &&
        isOneTrustBannerHidden &&
        document.body.classList.add(backdropHideClass)
    }

    const onKeydown = (e: KeyboardEvent) => {
      e.key === 'Escape' &&
        isOneTrustBannerHidden &&
        document.body.classList.add(backdropHideClass)
    }

    // Set global event listeners since OneTrust may not be initialized yet
    window.addEventListener('click', onClick, { capture: true })
    window.addEventListener('keydown', onKeydown)
    return () => {
      window.removeEventListener('click', onClick, { capture: true })
      window.removeEventListener('keydown', onKeydown)
    }
  }, [isOneTrustBannerHidden])

  return (
    // Hide banner and backdrop by default to prevent flickering
    <Helmet
      bodyAttributes={{
        class: `${bannerHideClass} ${backdropHideClass}`
      }}
    />
  )
}
