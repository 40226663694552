import classNames from 'classnames'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import z from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { FormTextInput } from '../FormTextInput'
import { useOfferForm } from './hooks'
import { LeadGenFormFragment } from './schema'

const EMAIL_ERROR = 'Please enter a valid email address.'
const emailSchema = toFormikValidationSchema(
  z.object({
    footerEmail: z.string({ required_error: EMAIL_ERROR }).email(EMAIL_ERROR)
  })
)

type OffersProps = {
  readonly className?: string
  readonly leadForm: LeadGenFormFragment
}

function Offers({ className = '', leadForm }: OffersProps) {
  const { offers } = useOfferForm(leadForm)

  const {
    onEmailSubmit,
    defaultEmail,
    success,
    buttonText,
    errorMsg,
    inputLabel,
    placeHolder,
    ctaContent
  } = offers

  return !success?.message ? (
    <Formik
      initialValues={{ footerEmail: defaultEmail }}
      onSubmit={el => onEmailSubmit(el.footerEmail ?? '')}
      validationSchema={emailSchema}
    >
      {({ isValid }) => (
        <Form className={className} noValidate>
          <div className="flex w-full flex-col">
            <label
              className="text-body-size mb-2 font-medium"
              htmlFor="footerEmail"
            >
              {inputLabel}
            </label>
            <div className="flex w-full gap-4">
              <FormTextInput
                className={classNames({
                  'border-complementary-red-100 border-2': !isValid
                })}
                fullWidth
                hasError={!isValid}
                id="footerEmail"
                name="footerEmail"
                placeholder={placeHolder}
                type="email"
              />
              <button
                className="rounded-base text-body-size hover:text-neutral-black disabled:bg-neutral-light-100 inline-flex h-11 cursor-pointer items-center justify-center border-2 border-solid border-white bg-transparent px-6 py-3 text-center text-white transition-all hover:bg-white disabled:text-white md:px-8"
                disabled={!isValid}
                type="submit"
              >
                {buttonText}
              </button>
            </div>
            <ErrorMessage
              className="text-complementary-red-100"
              component="span"
              name="footerEmail"
              render={() => errorMsg ?? ''}
            />
            <span className="prose-p:my-1 prose-p:text-sm prose-p:text-white prose-a:text-sm prose-a:text-white prose-a:hover:no-underline">
              {ctaContent}
            </span>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <p className="text-md h-14 font-medium text-white md:text-lg lg:my-0">
      {success.message}
    </p>
  )
}

export default Offers
