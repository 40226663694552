import { footerSchema } from '@ecomm/shared-components'
import { TypeOf } from '@simplisafe/ewok'

export const checkoutFooterData: TypeOf<typeof footerSchema> = {
  copyrightText: '© 2022 SimpliSafe, Inc.',
  legalLinks: [
    {
      text: 'Legal',
      url: '/legal',
      trackingEvent: null
    },
    {
      text: 'Privacy Policy',
      url: '/privacy-policy',
      trackingEvent: 'link-click-privacy-footer'
    },
    {
      text: 'Help Center',
      url: 'https://support.simplisafe.com/hc/en-us',
      trackingEvent: null
    }
  ],
  menus: [],
  socialLinks: []
}
