// extracted by mini-css-extract-plugin
export var addtoCart = "TestimonialBanner-module--addtoCart--a494a";
export var btnSize = "TestimonialBanner-module--btnSize--2c085";
export var button = "TestimonialBanner-module--button--de457";
export var halfOpacity = "TestimonialBanner-module--halfOpacity--2f964";
export var highlight = "TestimonialBanner-module--highlight--3fb56";
export var large = "TestimonialBanner-module--large--6b793";
export var largeSidePadding = "TestimonialBanner-module--largeSidePadding--6ad22";
export var medium = "TestimonialBanner-module--medium--9bf8b";
export var mediumSidePadding = "TestimonialBanner-module--mediumSidePadding--50a6a";
export var responsive = "TestimonialBanner-module--responsive--a10b2";
export var small = "TestimonialBanner-module--small--8f6c3";
export var testimonialsActive = "TestimonialBanner-module--testimonialsActive--bf9cd";
export var testimonialsSection = "TestimonialBanner-module--testimonialsSection--97123";
export var wrapper = "TestimonialBanner-module--wrapper--dbc36";