import { FormSection } from '@ecomm/checkout/components'
import { useTrackPurchaseComplete } from '@ecomm/checkout/payment-hooks'
import { hasOutdoorMonitoringInCart } from '@ecomm/checkout/utils'
import { getBillingAddress } from '@ecomm/data-cart'
import { AFFIRM_MIN_PRICE } from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import { useSendMemberData } from '@ecomm/partners-hooks'
import { AffirmInformationLogoIcon, AffirmLogo } from '@ecomm/shared-components'
import { CCAmex, CCDiscover, CCMaster, CCVisa } from '@ecomm/shared-icons'
import { Text } from '@ecomm/ss-react-components'
import { TrackingData, useTrackingAffirmInformationIcon } from '@ecomm/tracking'
import { convertMonetMaybe, prop, propOr } from '@simplisafe/ewok'
import {
  selectCart,
  selectLocale
} from '@simplisafe/ss-ecomm-data/redux/select'
import { window } from 'browser-monads-ts'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { AffirmCheckoutContent } from '../AffirmCheckoutContent/AffirmCheckoutContent'
import InputRadio from '../InputRadio'
import { PaymentErrorMessage } from '../PaymentErrorMessage/PaymentErrorMessage'
import { PaymentFormBanner } from '../PaymentFormBanner/PaymentFormBanner'
import { ShippingCard } from '../ShippingCard/ShippingCard'
import { TermsOfSale } from '../TermsOfSale/TermsOfSale'
import { ZuoraPaymentForm } from '../ZuoraPaymentForm/ZuoraPaymentForm'
import { ZuoraSubmitButton } from '../ZuoraSubmitButton/ZuoraSubmitButton'
import { PaymentFormWrapperProps } from './types'
import {
  getSavedPaymentMethodsRadioOptions,
  PAYMENT_OPTION_AFFIRM,
  PAYMENT_OPTION_CARD,
  PAYMENT_OPTION_SAVED_CARD,
  paymentMethodIconClasses,
  paymentOptionMapper
} from './utils'

export function PaymentFormWrapper({
  // FIXME move functions that directly access window to `@ecomm/shared-window`
  // @ts-ignore
  affirmClient = window.affirm,
  data,
  errorMessage,
  handleSelectedSavedCard,
  handleSubmitAffirmOrder,
  handleZuoraFormRender,
  isZuoraPaymentSubmitted,
  paymentState,
  refreshZuoraForm,
  safeTechSdkUrl,
  savedPaymentMethods,
  selectedPaymentOption,
  selectPaymentOption,
  setIsZuoraPaymentSubmitted,
  submitZuoraPaymentForm,
  // FIXME move functions that directly access window to `@ecomm/shared-window`
  // @ts-ignore
  zuoraClient = window.Z,
  zuoraPaymentMethod
}: PaymentFormWrapperProps) {
  const microCopy = useMicroCopy()
  const cart = useSelector(selectCart)
  const locale = useSelector(selectLocale)
  const { Track, trackEvent } = useTracking()
  const trackIconClickEvent = useTrackingAffirmInformationIcon('content')
  const [isAffirmOrderSubmitted, setIsAffirmOrderSubmitted] = useState(false)
  const isUs = useLocale() === 'en-US'

  const hasSavedPaymentMethods = savedPaymentMethods.length > 0

  const trackEventPaymentForm = useCallback(
    (args: TrackingData) => {
      trackEvent({ appSection: 'paymentForm', ...args })
    },
    [trackEvent]
  )

  const discountedPrice = cart.map(_cart => _cart.totalPrice * 100)

  const isAffirmEnabled = !!(affirmClient && prop('affirmEnabled', data))

  const shippingAddress = convertMonetMaybe(
    cart.toMaybe().chain(_cart => _cart.shippingAddress)
  )
  const shippingInfo = convertMonetMaybe(
    cart.toMaybe().chain(_cart => _cart.shippingInfo)
  )

  useEffect(() => {
    affirmClient &&
      affirmClient.ui.ready(() => {
        affirmClient && affirmClient.ui.refresh()
      })
  }, [affirmClient, discountedPrice])

  useEffect(() => {
    if (savedPaymentMethods.length > 0) {
      selectPaymentOption(PAYMENT_OPTION_SAVED_CARD)
      trackEventPaymentForm({
        event: 'payment-option-toggle',
        selectedPaymentOption: PAYMENT_OPTION_SAVED_CARD
      })
    }
  }, [savedPaymentMethods, selectPaymentOption, trackEventPaymentForm])

  useTrackPurchaseComplete(
    paymentState,
    selectedPaymentOption,
    trackEventPaymentForm
  )

  const _handleSubmitAffirmOrder = () =>
    handleSubmitAffirmOrder(setIsAffirmOrderSubmitted)

  const onPaymentOptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const paymentOption = paymentOptionMapper[e.target.value]

      paymentOption === 'new card' && refreshZuoraForm()
      paymentOption === 'saved card' && handleSelectedSavedCard(e.target.id)

      selectPaymentOption(paymentOption)
      trackEventPaymentForm({
        event: 'payment-option-toggle',
        selectedPaymentOption: paymentOption
      })
    },
    [
      handleSelectedSavedCard,
      refreshZuoraForm,
      selectPaymentOption,
      trackEventPaymentForm
    ]
  )

  const isCartAffirmEligible = cart
    .map(_cart => _cart.totalPrice >= AFFIRM_MIN_PRICE)
    .orJust(false)

  const paymentMethodRadioButtonText = (
    <>
      {hasSavedPaymentMethods
        ? data.creditCardCheckoutOptionTitleWithSavedCc
        : data.creditCardCheckoutOptionTitle}
    </>
  )

  const paymentMethodRadioButtonDescription = (
    <>
      <CCVisa className={paymentMethodIconClasses} />
      <CCMaster className={paymentMethodIconClasses} />
      <CCAmex className={paymentMethodIconClasses} />
      <CCDiscover className={paymentMethodIconClasses} />
    </>
  )

  const cartHasSystem = cart
    .map(_cart => _cart.isThereAnySecurity)
    .orJust(false)

  const cardVerificationNoteContent = prop('cardVerificationNote', data)

  const creditCardCheckoutContent = (
    <ZuoraPaymentForm
      billingAddress={convertMonetMaybe(getBillingAddress(cart))}
      cardVerificationNote={O.fromNullable(cardVerificationNoteContent)}
      errorMessage={errorMessage}
      isSubmitted={isZuoraPaymentSubmitted}
      locale={locale}
      onPaymentFormRender={handleZuoraFormRender}
      paymentMethod={O.fromNullable(zuoraPaymentMethod)}
      paymentState={paymentState}
      safeTechCollectorSdkUrl={pipe(
        O.fromNullable(safeTechSdkUrl),
        O.getOrElse(() => '')
      )}
      setIsSubmitted={setIsZuoraPaymentSubmitted}
      zuoraClient={pipe(O.fromNullable(zuoraClient))}
    />
  )

  const affirmUnavailableMessage = (
    <div className="rounded-base noneBackgroundColor defaultTextColor overflow-hidden p-4">
      <p>{microCopy['affirm-only-available-orders-over-150']}</p>
    </div>
  )

  const affirmCheckoutContent = (
    <AffirmCheckoutContent
      affirmClient={affirmClient}
      onOrderSubmit={_handleSubmitAffirmOrder}
    />
  )

  const affirmRadioButtonLabel = (
    <Text data-testid="affirm-radio-button" fontWeight="medium">
      {propOr('', 'affirmCheckoutOptionTitle', data)}{' '}
      {!isUs && <AffirmLogo height="22px" width="58px" />}
      {isUs && affirmClient ? (
        <AffirmInformationLogoIcon
          height="22px"
          iconSize="25px"
          onClick={trackIconClickEvent}
          priceInCents={discountedPrice.orJust(0)}
          useSystemTermMonths={cartHasSystem}
          width="58px"
        />
      ) : null}
    </Text>
  )

  const affirmRadioOption = {
    content: affirmCheckoutContent,
    disabled: !isCartAffirmEligible,
    disabledContent: affirmUnavailableMessage,
    text: affirmRadioButtonLabel,
    value: PAYMENT_OPTION_AFFIRM,
    className:
      'mb-4 pb-4 border border-solid border-t-0 border-x-0 border-[var(--neutral-light-100)] items-center'
  }

  const newCreditCardOption = {
    content: creditCardCheckoutContent,
    defaultChecked: hasSavedPaymentMethods ? false : true,
    text: paymentMethodRadioButtonText,
    value: PAYMENT_OPTION_CARD,
    className: 'mb-5 items-start md:items-center',
    ...(hasSavedPaymentMethods
      ? {}
      : { description: paymentMethodRadioButtonDescription })
  }

  const RadioComponent = InputRadio

  const radioOptions = [
    affirmRadioOption,
    ...(hasSavedPaymentMethods
      ? getSavedPaymentMethodsRadioOptions(savedPaymentMethods)
      : []),
    newCreditCardOption
  ]

  // ODMON Pre-Launch: if the user has ODMON in their cart, redirect to shipping
  hasOutdoorMonitoringInCart(
    cart
      .map(cart => cart.lineItems)
      .toMaybe()
      .getOrElse([])
  )
    ? navigate('/cart/checkout')
    : null

  useSendMemberData()

  return (
    <Track>
      <div className="payment-iframe-container" data-component="PaymentForm">
        <PaymentFormBanner paymentState={paymentState} />
        <div
          className={classNames('overflow-hidden', {
            'invisible h-0': ['loading', 'ready'].indexOf(paymentState) < 0
          })}
        >
          <ShippingCard
            shippingAddress={shippingAddress}
            shippingInfo={shippingInfo}
          />
          <PaymentErrorMessage />
          <FormSection title={'Payment options'}>
            <div className={'rounded-base bg-neutral-light-50 p-4 pb-4 md:p-8'}>
              {isAffirmEnabled || hasSavedPaymentMethods ? (
                <RadioComponent
                  dataComponent="payment-options"
                  id="payment-options"
                  name="payment-options"
                  onChange={onPaymentOptionChange}
                  options={radioOptions}
                />
              ) : (
                creditCardCheckoutContent
              )}
            </div>
          </FormSection>
          <div className="mt-12 hidden justify-between lg:flex">
            <div className="flex-2">
              <TermsOfSale />
            </div>
            <div className="flex flex-1 justify-end">
              <ZuoraSubmitButton
                className="ml-14 w-72"
                disabled={
                  isZuoraPaymentSubmitted ||
                  (selectedPaymentOption !== PAYMENT_OPTION_CARD &&
                    selectedPaymentOption !== PAYMENT_OPTION_SAVED_CARD) ||
                  isAffirmOrderSubmitted
                }
                onClick={submitZuoraPaymentForm}
                trackingLabel="below"
              />
            </div>
          </div>
        </div>
      </div>
    </Track>
  )
}
