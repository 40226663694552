import { FormSection } from '@ecomm/checkout/components'
import {
  useLoadCheckoutShippingOptions,
  useSelectCheckoutShippingOption
} from '@ecomm/checkout/shipping-hooks'
import {
  CheckoutFormValuesType,
  isSiteUS
} from '@ecomm/checkout/shipping-schema'
import { LoadingSpinner } from '@ecomm/checkout-icons'
import { useLocale } from '@ecomm/data-hooks'
import { MaybeT } from '@simplisafe/ewok'
import { ShippingOption } from '@simplisafe/ss-ecomm-data/cart'
import { selectShippingOptions } from '@simplisafe/ss-ecomm-data/cart/select'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import UkShippingOptions from './UkShippingOptions'
import UsShippingOptions from './UsShippingOptions'

type SetStateFn = React.Dispatch<React.SetStateAction<boolean>>

export type ShippingOptionsProps = {
  readonly isFormLoading?: boolean
  readonly setIsFormLoading: SetStateFn
  readonly userAddress: Partial<CheckoutFormValuesType>
  readonly setSelectedShippingMethod: React.Dispatch<
    React.SetStateAction<string>
  >
}

export function ShippingOptions({
  setIsFormLoading,
  userAddress,
  setSelectedShippingMethod
}: ShippingOptionsProps) {
  const locale = useLocale()
  const cart = useSelector(selectCart)

  const { shippingOptions, status, fetchOptions } =
    useLoadCheckoutShippingOptions()

  const maybeShippingOptions: MaybeT<readonly ShippingOption[]> = useSelector(
    selectShippingOptions(shippingOptions)
  )
  const formattedShippingOptions = maybeShippingOptions.getOrElse([])

  useSelectCheckoutShippingOption({
    formattedShippingOptions,
    setIsFormLoading: setIsFormLoading
  })

  useEffect(() => {
    status !== 'success' && fetchOptions(true)
  }, [cart])

  const isUserAddressSet =
    userAddress.postalCode &&
    userAddress.streetName &&
    userAddress.country &&
    userAddress.city

  return (
    <div data-component="ShippingOption">
      <FormSection title="Shipping options">
        {!isUserAddressSet ? (
          <div className="rounded-base bg-neutral-light-50 w-full p-4">
            Enter your address for shipping options
          </div>
        ) : (
          <div className="rounded-base bg-neutral-light-50 flex w-full flex-col gap-4 p-4">
            <h3 className="m-0 text-lg font-bold">
              Shipping options for {userAddress.streetName}, {userAddress.city},{' '}
              {userAddress.state} {userAddress.postalCode}
            </h3>
            <div className="w-full">
              {isSiteUS(locale) ? (
                <UsShippingOptions
                  setSelectedShipping={setSelectedShippingMethod}
                  shippingOptions={formattedShippingOptions}
                  userAddress={userAddress}
                />
              ) : (
                <UkShippingOptions
                  setSelectedShipping={setSelectedShippingMethod}
                  shippingOptions={formattedShippingOptions}
                />
              )}
            </div>
            {status === 'loading' && (
              <div className="flex w-full justify-center">
                <LoadingSpinner size={96} />
              </div>
            )}
          </div>
        )}
      </FormSection>
    </div>
  )
}
