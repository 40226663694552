import {
  FormCheckbox,
  FormField,
  FormSelect,
  FormTextInput
} from '@ecomm/checkout/components'
import { isSiteUK } from '@ecomm/checkout/utils'
import { Locale } from '@ecomm/utils'

export type CustomerServiceProps = {
  readonly foundInfoThrough: readonly string[]
  readonly locale: Locale
}

export function CustomerService({
  foundInfoThrough,
  locale
}: CustomerServiceProps) {
  const shuffledOptions: readonly string[] = new Array(
    ...(foundInfoThrough || [])
  ).sort(() => Math.random() - 0.5)

  return (
    <div
      className="rounded-base my-6 flex w-full flex-col gap-1 border border-solid border-neutral-200 p-6"
      data-component="CheckoutFormCustomerService"
    >
      <div className="flex flex-col">
        <span className="font-bold ">Where did you hear about SimpliSafe?</span>
        <span className="mb-1 mt-[6px] text-[#514F4C]">(Optional)</span>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <FormField
          className="w-full grow"
          id="foundInfoThrough"
          label="I found SimpliSafe through"
        >
          <FormSelect
            name="foundInfoThrough"
            options={shuffledOptions}
            placeholder="Please select one..."
          />
        </FormField>
        <div className="flex w-full grow flex-col">
          <FormField
            className="w-full grow"
            id="additionalFoundInfoThrough"
            label="Additional details"
          >
            <FormTextInput
              id="additionalFoundInfoThrough"
              maxLength={64}
              name="additionalFoundInfoThrough"
              placeholder="Please provide any details"
            />
          </FormField>
          <div className="text-sm text-[#514F4C]">
            e.g. tv show you were watching or magazine name
          </div>
        </div>
      </div>
      {locale && isSiteUK(locale) ? (
        <div className="mt-4">
          <FormCheckbox
            label="Tick this box to receive the latest products and offers by post from select third party partners"
            name="epsilonAbacusOptIn"
          />
        </div>
      ) : null}
    </div>
  )
}
