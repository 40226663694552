import { Locale } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'

export const getLineItemComponents = (cart: ImmutableCart, locale: Locale) =>
  (cart.lineItems || []).map(item => ({
    name: item.name[locale],
    product_id: item.sku,
    quantity: item.quantity,
    item_list_id: item.custom?.fields?.package_parent_id,
    category: item.productType
  }))
