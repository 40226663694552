import { Address } from '@commercetools/platform-sdk'
import { trackOrderCompleted } from '@ecomm/cdp-tracking'
import {
  getCartDiscountCode,
  getCartDiscountValue,
  getShippingInfoPrice
} from '@ecomm/data-cart'
import { logError } from '@ecomm/error-handling'
import { set as sessionStorageSet } from '@ecomm/utils'
import { MaybeT } from '@simplisafe/ewok'
import { overloadMaybe, prop } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { awinRequest } from '@simplisafe/ss-ecomm-data/thirdparty/awin'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import path from 'ramda/src/path'
import propOr from 'ramda/src/propOr'
import Cookies from 'universal-cookie'

import { TrackEvent } from '../analytics'
import { formatPhoneNumber } from '../braze/lib/formatPhoneNumber'
import {
  brazeTrackPurchaseComplete,
  getCommerceDataFromLineItems,
  getOnTechUrl,
  TrackingData
} from '../index'
import { getProductsDataForOrderTracking } from '../utils'

const EVENT_CATEGORY_PURCHASE_SYSTEM = 'Purchase System'
const EVENT_CATEGORY_PURCHASE = 'Purchase'

const GTM_TRANSACTION_INFO_ACTION = 'transaction'
const GTM_TRANSACTION_INFO_CATEGORY = 'eec'
const GTM_TRANSACTION_INFO_EVENT = 'eec.transaction'
const GTM_TRANSACTION_INFO_LABEL = 'transactions'
const AWIN_COOKIE = 'awin_awc'

const getOrderPhoneNumber = (shippingAddress: MaybeT<Address>) =>
  shippingAddress.cata(
    () => '',
    (data: Address) =>
      // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
      formatPhoneNumber(propOr<string, string>('', 'phone', data))
  )

export const trackPaymentConfirmation = (
  trackEvent: TrackEvent,
  cart: ImmutableCart,
  systemInOrder: boolean,
  orderId: string,
  cartTotal: number,
  email: string,
  selectedPaymentOption: TrackingData['selectedPaymentOption'],
  shippingAddress: MaybeT<Address>,
  locale: string
) => {
  const ecommerceData = getCommerceDataFromLineItems('purchase')(
    cart.get('lineItems')
  )
  const orderPhoneNumber = getOrderPhoneNumber(shippingAddress)
  const cookies = new Cookies()

  // using this for mention-me.js file
  shippingAddress.cata(
    () => null,
    (data: Address) => {
      sessionStorageSet(
        'userData',
        JSON.stringify({
          email,
          firstName: data.firstName,
          lastName: data.lastName,
          transactionTotal: Number(cartTotal)
        })
      )
    }
  )

  trackEvent({
    ecommerce: getCommerceDataFromLineItems('purchase')(cart.get('lineItems')),
    event: systemInOrder ? 'purchaseSystem' : 'purchase',
    // todo add eventCategory param to TrackingData
    eventCategory: systemInOrder
      ? EVENT_CATEGORY_PURCHASE_SYSTEM
      : EVENT_CATEGORY_PURCHASE,
    // todo add orderTotal param to TrackingData
    orderTotal: cart.subTotal,
    selectedPaymentOption: selectedPaymentOption,
    // todo add transactionCoupon param to TrackingData
    transactionCoupon: getCartDiscountCode(cart),
    // todo add transactionDiscount param to TrackingData
    transactionDiscount: pipe(
      // @ts-expect-error Argument of type 'false | Option<string>' is not assignable to parameter of type 'Option<string>'.
      getCartDiscountValue(cart, locale),
      O.getOrElse(() => '')
    ),
    transactionId: orderId,
    transactionTotal: Number(cartTotal),
    userData: shippingAddress.cata(
      () => null,
      (data: Address) => ({
        email,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        firstName: propOr<string, string>('', 'firstName', data),
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        lastName: propOr<string, string>('', 'lastName', data),
        phoneNumber: orderPhoneNumber
      })
    )
  })

  brazeTrackPurchaseComplete({
    // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
    currencyCode: propOr<string, string>(
      'en-US',
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      'currencyCode',
      ecommerceData
    ),
    orderId,
    phoneNumber: orderPhoneNumber,
    products: path(['purchase', 'products'], ecommerceData) || [],
    systemInOrder,
    onTechUrl: getOnTechUrl(
      locale,
      orderId,
      overloadMaybe(shippingAddress),
      cart
    )
  })

  const awinCookie: string = cookies.get<string>(AWIN_COOKIE)
  locale === 'en-GB' &&
    awinCookie &&
    awinRequest({
      amount: Number(cartTotal),
      clickSource: awinCookie,
      orderRef: orderId,
      voucher: getCartDiscountCode(cart)
    })(err => logError(err))(() => null)
}

export function trackTransactionEvent(
  trackEvent: TrackEvent,
  cart: ImmutableCart,
  selectedPaymentOption: TrackingData['selectedPaymentOption'],
  cartTotal: number,
  orderId: string
) {
  const ecommerceData = getCommerceDataFromLineItems('purchase')(
    cart.get('lineItems')
  )
  const taxedPrice = pipe(
    overloadMaybe(prop('taxedPrice', cart)),
    O.getOrElse(() => 0)
  )

  trackEvent({
    ecommerce: ecommerceData,
    // todo add eec.transaction to TrackingEvent union type
    event: GTM_TRANSACTION_INFO_EVENT,
    // todo add eventAction param to TrackingData
    eventAction: GTM_TRANSACTION_INFO_ACTION,
    // todo add eventCategory param to TrackingData
    eventCategory: GTM_TRANSACTION_INFO_CATEGORY,
    // todo add eventLabel param to TrackingData
    eventLabel: GTM_TRANSACTION_INFO_LABEL,
    selectedPaymentOption: selectedPaymentOption
  })
  trackOrderCompleted({
    currency: ecommerceData.currencyCode,
    total: cartTotal,
    coupon: getCartDiscountCode(cart),
    products: getProductsDataForOrderTracking(ecommerceData),
    order_id: orderId,
    tax: Math.round((taxedPrice + Number.EPSILON) * 100) / 100,
    shipping: getShippingInfoPrice(cart),
    discount: cart.totalCartDiscount,
    subtotal: cart.subTotal
  })
}
