import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy/src/lib/jsonSchema'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { z } from '@simplisafe/ewok'
import { graphql, useStaticQuery } from 'gatsby'

const shoppageQuery = graphql`
  query ShopPageQuery {
    cameraBannerAsset: contentfulAsset(
      contentful_id: { eq: "5mbs4LEkQgjIZpoFklL2ur" }
    ) {
      gatsbyImageData(placeholder: BLURRED)
    }
    guaranteeAsset: contentfulAsset(
      contentful_id: { eq: "5FRJNkZAaZL4mHk6vuMNVb" }
    ) {
      gatsbyImageData(placeholder: BLURRED)
      description
    }
    quoteWizard: contentfulQuoteWizard(
      contentful_id: { eq: "3GT1zG3PE5djEurbN7NQmm" }
    ) {
      __typename
    id: contentful_id
    jebbitUrl
    type
    buttonText
    }
    guaranteeCopy: contentfulJson(
      contentful_id: { eq: "5bXaWtcoGF04HVmWb5Kuoe" }
    ) {
      ...json
    }
    comparisonTable: contentfulJson(
      contentful_id: { eq: "1zTyewofJ6lKWySS451GBg" }
    ) {
      ...json
    }
  }
`

const shopPageBelowTheFoldSchema = z.object({
  cameraBannerAsset: gatsbyImageSchema,
  guaranteeAsset: gatsbyImageSchema,
  quoteWizard: guidedSystemBuilderSchema,
  guaranteeCopy: jsonSchema.extend({ contentful_id: z.string() }),
  comparisonTable: jsonSchema
})

const useShopPageQuery = () => {
  return shopPageBelowTheFoldSchema.parse(useStaticQuery(shoppageQuery))
}

export { useShopPageQuery }
