import { cartAddDiscountCodes } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { voidFn } from '@simplisafe/ewok'
import { selectCurrentPromoVariationId } from '@simplisafe/ss-ecomm-data/redux/select'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useSetCart } from './useCart'

/**
 * Adds discount codes to cart. Supports bulk discount codes.
 * Does not remove existing discount codes from cart.
 */
export const useCartAddDiscounts = () => {
  const cartId = getCartId()
  const setCart = useSetCart()
  const variationId = useSelector(selectCurrentPromoVariationId).orUndefined()

  return useCallback(
    (
      discountCodes: readonly string[],
      onFailure: () => void,
      onSuccess: () => void
    ) => {
      const fn = async (id: string) => {
        const cart = await cartAddDiscountCodes(
          id,
          discountCodes,
          variationId
        )()
        pipe(
          cart,
          E.fold(
            () => onFailure(),
            t => {
              setCart(['update_cart', t])
              onSuccess()
            }
          )
        )
      }
      pipe(
        O.fromNullable(cartId),
        O.fold(() => voidFn(), fn)
      )
    },
    [cartId, setCart, variationId]
  )
}
