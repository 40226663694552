import React, { HTMLAttributes, ReactElement } from 'react'

import { GatsbyImage } from '../GatsbyImage'
import { SocialLinkFragment, SocialLinksFragment } from './schema'

type SocialMediaLinksProps = HTMLAttributes<HTMLUListElement> & {
  readonly links: SocialLinksFragment
}

type SocialMediaLinkProps = {
  readonly className?: string
  readonly link: SocialLinkFragment
}

export function SocialMediaLink({
  link,
  className = ''
}: SocialMediaLinkProps): ReactElement {
  return (
    <li className={`mr-3 md:ml-0 md:mr-4 ${className} h-4 w-4`}>
      <a href={link.url} key={link.url}>
        <GatsbyImage className="h-4 w-4" image={link.icon} />
      </a>
    </li>
  )
}

export function SocialMediaLinks({
  links,
  className = '',
  style
}: SocialMediaLinksProps) {
  return (
    <ul
      className={`m-0 flex list-none py-2 md:mt-0 ${className}`}
      style={style}
    >
      {links.map((link, index) => (
        <SocialMediaLink
          className="hover:opacity-50"
          key={`social_media_link${index}`}
          link={link}
        />
      ))}
    </ul>
  )
}
