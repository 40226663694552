import { OrderSummaryContainer } from '@ecomm/checkout/order-summary'
import { ShippingForm } from '@ecomm/checkout/shipping-components'
import { ShippingFormFragment } from '@ecomm/checkout/shipping-schema'
import { useState } from 'react'

import { PlanWarning } from '../PlanWarning'

export type CheckoutPageWrapperProps = {
  readonly formData: ShippingFormFragment
}

function CheckoutPageWrapper({ formData }: CheckoutPageWrapperProps) {
  const pageTitle = 'Shipping'

  const [showPlanWarning, setShowPlanWarning] = useState(false)

  const warningClose = () => setShowPlanWarning(false)

  return (
    <div
      className="flex flex-col lg:flex-row lg:justify-between lg:gap-8"
      data-component="CheckoutPageWrapper"
    >
      <div className="w-full grow">
        <section className={'mx-auto mt-3 w-full'}>
          <div>
            <h1 className="my-2 text-4xl font-medium">{pageTitle}</h1>
            <span className="text-[#514F4C]">*Required fields</span>
          </div>
        </section>
        <div className="mt-4 flex flex-col justify-between lg:flex-row">
          <section className={'mr-2 w-full'}>
            <ShippingForm
              data={formData}
              setShowPlanWarning={setShowPlanWarning}
            />
          </section>
          <div className="lg:sticky lg:top-0 lg:h-full lg:w-1/2 lg:pl-20">
            <div className="mt-6 text-lg lg:mt-8">
              <h2
                className={`flex w-full shrink-0 items-center whitespace-nowrap text-xl font-medium md:text-2xl`}
              >
                Order summary
              </h2>
            </div>
            <PlanWarning isOpen={showPlanWarning} onClose={warningClose} />
            <div className="my-2.5 md:my-5">
              <OrderSummaryContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutPageWrapper
