import { ContentfulRichText } from '@ecomm/shared-components'
import { Text } from '@ecomm/ss-react-components'
import React from 'react'

type TestimonialProps = {
  readonly testimonial: { readonly raw: string }
}

function Testimonial({ testimonial }: TestimonialProps) {
  return (
    <div className="mx-auto my-0 flex min-h-[21.25rem] max-w-[62.5rem] items-center justify-center py-0 text-center align-middle md:min-h-[18.75rem] md:px-[6.25rem] lg:px-[9.375rem]">
      <Text className="text-center" useTailwind>
        <ContentfulRichText raw={testimonial.raw} />
      </Text>
    </div>
  )
}

export default Testimonial
