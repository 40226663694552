import { TypeOf, z } from '@simplisafe/ewok'

import { productCardSchema } from '../ProductCard/schema'

export const sensorGroupSchema = z.object({
  __typename: z.literal('ContentfulBmsGroup'),
  contentful_id: z.string(),
  id: z.string(),
  image: z
    .object({
      __typename: z.literal('ContentfulAsset'),
      file: z.object({ url: z.string() }),
      id: z.string()
    })
    .optional()
    .nullable(),
  items: z.array(productCardSchema),
  ready: z.boolean().default(true),
  title: z.string()
})

export type SensorGroupFragment = TypeOf<typeof sensorGroupSchema>
