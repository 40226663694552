import { voidFn } from '@simplisafe/ewok'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { match } from 'ts-pattern'

import ArrowControls from './ArrowControls'
import CarouselItem from './CarouselItem'
import carouselReducer from './carouselReducer'
import type { CarouselProps } from './CarouselTypes'
import { CarouselActionType } from './CarouselTypes'
import DotControls from './DotControls'

export default function Carousel({
  children,
  autoPlay,
  autoPlaySpeed,
  infinite,
  dotControls,
  arrowControls,
  activeSlide
}: CarouselProps) {
  const initialState = useMemo(
    () => ({
      currentIdx: activeSlide || 0,
      enableAutoPlay: autoPlay || false
    }),
    [activeSlide, autoPlay]
  )

  const [state, dispatch] = useReducer(carouselReducer, initialState)
  const { currentIdx, enableAutoPlay } = state

  const length = React.Children.count(children)
  const disableNext = currentIdx === length - 1 && !infinite
  const disablePrevious = currentIdx === 0 && !infinite

  const handleSetIdx = useCallback((idx: number) => {
    dispatch({
      payload: idx,
      type: CarouselActionType.SetIdx
    })
  }, [])

  const handleNextSlide = useCallback(() => {
    const currentSlideIsTheLast = currentIdx === length - 1
    match(infinite)
      .when(
        i => i === true,
        () => {
          const nextIdx = currentSlideIsTheLast ? 0 : currentIdx + 1
          handleSetIdx(nextIdx)
        }
      )
      .when(
        i => !i && !currentSlideIsTheLast,
        () => {
          dispatch({ type: CarouselActionType.Next })
        }
      )
      .otherwise(() => {
        dispatch({
          payload: false,
          type: CarouselActionType.SetAutoPlay
        })
      })
  }, [currentIdx, handleSetIdx, infinite, length])

  const handlePreviousSlide = useCallback(() => {
    const isFirstSlide = currentIdx === 0
    const fn = infinite
      ? () => {
          const nextIdx = isFirstSlide ? length - 1 : currentIdx - 1
          handleSetIdx(nextIdx)
        }
      : () => {
          dispatch({ type: CarouselActionType.Previous })
        }
    fn()
  }, [currentIdx, handleSetIdx, infinite, length])

  useEffect(
    function handleAutoplay() {
      const fn = enableAutoPlay
        ? () => {
            const interval = setInterval(() => {
              handleNextSlide()
            }, autoPlaySpeed)

            return () => clearInterval(interval)
          }
        : voidFn
      fn()
    },
    [autoPlaySpeed, enableAutoPlay, handleNextSlide]
  )

  useEffect(
    function handleSetActiveSlide() {
      const fn =
        activeSlide || activeSlide === 0
          ? () => {
              dispatch({
                payload: activeSlide,
                type: CarouselActionType.SetActiveSlide
              })
            }
          : () => {
              dispatch({
                payload: initialState.enableAutoPlay,
                type: CarouselActionType.SetAutoPlay
              })
            }
      fn()
    },
    [activeSlide, handleSetIdx, initialState]
  )

  return (
    <div className="relative aspect-square h-full w-full">
      {React.Children.map(children, (child, idx) => {
        return (
          <CarouselItem currentIdx={currentIdx} idx={idx}>
            {child}
          </CarouselItem>
        )
      })}
      {dotControls ? (
        <DotControls
          currentIdx={currentIdx}
          handleSetIdx={handleSetIdx}
          length={length}
        />
      ) : null}
      {arrowControls ? (
        <ArrowControls
          disableNext={disableNext}
          disablePrevious={disablePrevious}
          onNextClick={handleNextSlide}
          onPreviousClick={handlePreviousSlide}
          onSwipeLeft={handleNextSlide}
          onSwipeRight={handlePreviousSlide}
        />
      ) : null}
    </div>
  )
}
