import { useFragmentWithVariations } from '@ecomm/optimizely-hooks'
import { heroBannerSchema } from '@ecomm/shared-components'
import { TypeOf } from '@simplisafe/ewok'

import {
  genericHomeLandingTemplateSchema,
  homeLandingLayoutSchema
} from './schema'

function applyVariationsToLayoutComponents(
  layout: TypeOf<typeof homeLandingLayoutSchema>
) {
  return layout.components?.map(component =>
    component.__typename === 'ContentfulHeroBanner'
      ? useFragmentWithVariations(component, heroBannerSchema)
      : component
  )
}

export const useHomeLandingPageFragment = <
  T extends { readonly contentfulGenericLandingPage: U },
  U
>(
  query: T
) => {
  const homeLandingFragment = query.contentfulGenericLandingPage

  const homeLandingPage = useFragmentWithVariations(
    homeLandingFragment,
    genericHomeLandingTemplateSchema
  )

  //defaults showPopupQuoteWizard to true if no value is provided in CTFL
  const homeLandingPageWithPopupQW =
    homeLandingPage.showPopupQuoteWizard === null
      ? { ...homeLandingPage, showPopupQuoteWizard: true }
      : homeLandingPage

  const layoutFragment = {
    ...homeLandingPageWithPopupQW.layout,
    components: applyVariationsToLayoutComponents(
      homeLandingPageWithPopupQW.layout
    )
  }

  const layout = useFragmentWithVariations(
    layoutFragment,
    homeLandingLayoutSchema
  )

  return {
    ...genericHomeLandingTemplateSchema.parse(homeLandingPageWithPopupQW),
    layout: homeLandingLayoutSchema.parse(layout)
  }
}
