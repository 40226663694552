import {
  AFFIRM_PROMO_ID,
  AFFIRM_PROMO_ID_36_INSTALLMENTS,
  AFFIRM_PROMO_NO_SYSTEM
} from '@ecomm/data-constants'
import { getAffirm } from '@ecomm/shared-window'
import classNames from 'classnames'
import React, { useEffect } from 'react'

import { AffirmOptionType } from '../../../types/affirm'

type Props = {
  readonly discountedPrice?: number
  readonly totalPrice: number
  readonly isPlp?: boolean
  readonly priceInCents?: number
  readonly affirmOption: AffirmOptionType
  readonly useSystemTermMonths?: boolean
  readonly isAffirmMonthlyExperiment?: boolean
}

export function MonthlyOptionPrice({
  discountedPrice = 0,
  totalPrice,
  isPlp = false,
  affirmOption,
  useSystemTermMonths = true,
  isAffirmMonthlyExperiment = false
}: Props) {
  const getMonthlyPromoId = (isMonthlyExperiment: boolean) =>
    isMonthlyExperiment ? AFFIRM_PROMO_ID_36_INSTALLMENTS : AFFIRM_PROMO_ID

  const promoId = useSystemTermMonths
    ? getMonthlyPromoId(isAffirmMonthlyExperiment)
    : AFFIRM_PROMO_NO_SYSTEM

  useEffect(() => {
    getAffirm(affirm => {
      affirm.ui.ready(() => {
        // TODO: affirm + optimizely have a race condition so we need to wait until we know if it is an experiment or not until we run affirm refresh script
        setTimeout(() => {
          affirm.ui.refresh()
        }, 3000)
      })
    })
  }, [isAffirmMonthlyExperiment])

  const toPriceInCents = (price: number) => Math.round(price * 100)

  return (
    <div
      className={classNames(
        'pointer-events-none flex flex-row gap-1 font-bold',
        {
          'text-lg': !isPlp,
          '!-mt-0 text-base': isPlp
        }
      )}
    >
      <div
        className="affirm-as-low-as monthlyDiscount"
        data-amount={toPriceInCents(discountedPrice)}
        data-component="AffirmMonthlyDiscount"
        data-page-type="marketplace"
        data-promo-id={promoId}
      ></div>
      <div
        className={`affirm-as-low-as text-neutral-black ${
          affirmOption === 'qualified-monthly' &&
          discountedPrice !== 0 &&
          discountedPrice !== null
            ? 'line-through'
            : ''
        }`}
        data-amount={toPriceInCents(totalPrice)}
        data-component="AffirmMonthlyPrice"
        data-page-type="marketplace"
        data-promo-id={promoId}
      ></div>
    </div>
  )
}
