import { COOKIE_CHAT_OPENED, setCookie } from '@ecomm/shared-cookies'
import { getSprChat } from '@ecomm/shared-window'
import { ScriptStatus } from '@ecomm/shared-hooks'
import { useLocation } from '@reach/router'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTracking } from 'react-tracking'
import { match } from 'ts-pattern'

import { SprChatOnEventParameter } from '../sprChatTypes'
import { useLiveChatClientExists } from '../useLiveChatClientExists'

export const useLiveChatTracking = (status: ScriptStatus) => {
  const location = useLocation()

  const { trackEvent } = useTracking()

  const { pathname } = location

  // State to track if an agent was assigned in the chat. Determines which close event fires.
  const [liveChatAgentAssigned, setLiveChatAgentAssigned] =
    useState<boolean>(false)
  // Keep track of the current state value for usage within useCallback.
  const stateRef = useRef(false)
  stateRef.current = liveChatAgentAssigned

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleAgentAssigned = useCallback(() => {
    setLiveChatAgentAssigned(true)
    trackEvent({
      appSection: 'liveChat',
      event: 'agentEvent',
      pagePath: pathname
    })
  }, [pathname, trackEvent])

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleConversationWindowClosedPostEngagement = useCallback(() => {
    trackEvent({
      appSection: 'liveChat',
      event: 'closeWidgetPostEngagement',
      pagePath: pathname
    })
  }, [pathname, trackEvent])

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleConversationWindowClosedWithoutEngagement = useCallback(() => {
    trackEvent({
      appSection: 'liveChat',
      event: 'closeWidgetWithoutEngaging',
      pagePath: pathname
    })
  }, [pathname, trackEvent])

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleConversationWindowClosed = useCallback(() => {
    stateRef.current
      ? handleConversationWindowClosedPostEngagement()
      : handleConversationWindowClosedWithoutEngagement()
  }, [
    handleConversationWindowClosedPostEngagement,
    handleConversationWindowClosedWithoutEngagement,
    stateRef
  ])

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleConversationWindowOpened = useCallback(() => {
    trackEvent({
      appSection: 'liveChat',
      event: 'openEvent',
      pagePath: pathname
    })
    setCookie(COOKIE_CHAT_OPENED, 'true', cookiesOption)
  }, [pathname, trackEvent])

  const handleTriggerVisible = useCallback(() => {
    trackEvent({
      appSection: 'liveChat',
      event: 'impression',
      pagePath: pathname
    })
  }, [pathname, trackEvent])

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleEvent = useCallback(
    (event: SprChatOnEventParameter) => {
      const { eventType } = event

      match<string, () => void>(eventType)
        .with('AGENT_ASSIGNED', () => handleAgentAssigned)
        .with(
          'CONVERSATION_WINDOW_CLOSED',
          () => handleConversationWindowClosed
        )
        .with(
          'CONVERSATION_WINDOW_OPENED',
          () => handleConversationWindowOpened
        )
    },
    [
      handleAgentAssigned,
      handleConversationWindowClosed,
      handleConversationWindowOpened
    ]
  )

  const clientExists = useLiveChatClientExists(status)

  useEffect(() => {
    getSprChat(chat => {
      const runSideEffect = () => {
        handleTriggerVisible()
        chat('onEvent', handleEvent)
      }

      clientExists && runSideEffect()
    })
    return () => getSprChat(chat => chat('offEvent', handleEvent))
  }, [clientExists, handleEvent, handleTriggerVisible])
}
