import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { prop } from '@simplisafe/ewok'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import { SSButtonProps } from '@ecomm/ss-react-components'
import { localStorage } from '@simplisafe/ewok'
import { Maybe, None } from 'monet'
import applySpec from 'ramda/src/applySpec'
import compose from 'ramda/src/compose'
import concat from 'ramda/src/concat'
import converge from 'ramda/src/converge'
import defaultTo from 'ramda/src/defaultTo'
import find from 'ramda/src/find'
import has from 'ramda/src/has'
import head from 'ramda/src/head'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import join from 'ramda/src/join'
import juxt from 'ramda/src/juxt'
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import tail from 'ramda/src/tail'
import toLower from 'ramda/src/toLower'
import toUpper from 'ramda/src/toUpper'
import when from 'ramda/src/when'
import { ReactNode } from 'react'
import { v4 } from 'uuid'

const { get: getLocalStorage, set: setLocalStorage } = localStorage

export const toFirstCharLower = compose(
  join(''),
  juxt([compose(toLower, head), tail])
)

/**
 * @example
 * capitalizeFirst('apply deal') // => 'Apply deal'
 */
export const capitalizeFirst = converge(
  // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
  concat(),
  [compose(toUpper, head), tail]
)

type ToButton = {
  readonly text?: ReactNode
  readonly buttonText?: ReactNode
}

export const toCamelCase = pipe(
  defaultTo(''),
  toFirstCharLower,
  split(' '),
  join('')
)

export const toButton: (a: ToButton) => SSButtonProps =
  applySpec<SSButtonProps>({
    buttonColor: prop('buttonColor'),
    children: ifElse(has('text'), prop('text'), prop('buttonText')),
    color: pipe(prop('type'), toCamelCase),
    href: prop('url'),
    minWidth: prop('buttonSize'),
    textColor: prop('textColor')
  })

export const leadingSlashIt = (s: string) => `/${unleadingSlashIt(s)}`
export const unleadingSlashIt = (s: string) => s.replace(/^\/+/, '')
export const untrailingSlashIt = (s: string) => s.replace(/\/+$/, '')

/**
 * When a non-required field is unset in Contentful, its value is returned as null. However, this
 * can end up overriding default values for non-required properties on the underlying components.
 * This function returns undefined when passed null, otherwise it returns the given argument.
 */
export function nullToUndefined<T>(field: T | null | undefined): T | undefined {
  return field === null ? undefined : field
}

/**
 * Returns the first Just in an array of Maybes.
 * If there are no values it returns None'
 *
 * @example
 * findFirstJust([ None(), Just(10), Just(42) ]) // => Just(10)
 */
// this can't be read only since it conflicts with Ramda's type expectation for find

export function findFirstJust<T>(a: readonly Maybe<T>[]) {
  return find<Maybe<T>>((x: Maybe<T>) => x.isSome(), a) || None()
}

/**
 * Returns a camelCase string from any string with spaces
 *
 * @param str string
 *
 * @example
 * strToCamelCase("sOme Weird CasE sTring") => "someWeirdCaseString"
 */
export const strToCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

/**  Remove first letter underscore from object keys */
export const jsonObjectRemoveUnderscore = (dataObject: any) => {
  Object.keys(dataObject).forEach(key => {
    const formattedKey = key.replace(/^_/, '')
    /* eslint-disable-next-line functional/immutable-data -- legacy code, legacy code */
    dataObject[formattedKey] = dataObject[key]
    const deleteOldKey: boolean = formattedKey !== key
    // eslint-disable-next-line functional/immutable-data -- legacy code
    deleteOldKey && delete dataObject[key]
  })
  return dataObject
}

export const setDeviceId = () => {
  when(isNil, () => setLocalStorage('deviceId', v4()))(
    getLocalStorage('deviceId')
  )
}

export const getLocalStorageCartIdAsString = () => {
  const localStorageCartId: string | null =
    getLocalStorage(LOCAL_STORAGE_CARTID)
  return localStorageCartId === null ? '' : localStorageCartId
}
// Replaces "{{months_of_free_service}}" in `linkText` with value passed in `monthsOfFreeService`
export const configureServicePlanTextPlaceholders = (
  linkText: string,
  monthsOfFreeService: string
): string => {
  const regexMonthOfService = /(?:{{months_of_free_service}})/g
  return linkText.replace(regexMonthOfService, monthsOfFreeService)
}

// Partner shop url
export const rewritePartnerShopUrl = (url: string) => {
  const partnerShopUrl = getValueFromPartnerCookie('customShopUrl')
  return url === '/home-security-shop-packages' && partnerShopUrl
    ? partnerShopUrl
    : url
}

/**
 * capitalizes first letter of each word
 *
 * @param str string
 *
 * @example
 * strToCapitalize("one two three") => "One Two Three"
 */
export const strToCapitalize = (str: string) =>
  str
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
