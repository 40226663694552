import { trackAddLineItemToCartEvent } from '@ecomm/tracking/src/addToCart'
import { TrackEvent } from '@ecomm/tracking/src/analytics'
import { trackRemoveCartEvent } from '@ecomm/tracking/src/removeFromCart'
import { getLocale } from '@ecomm/utils'
import { isUndefined, overloadMaybe, path, prop } from '@simplisafe/ewok'
import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
//EXPORT THE TYPE FROM SS-REACT-COMPONENTS
import { QuantityChangerProps } from '@ecomm/ss-react-components'
import { map } from 'fp-ts/lib/Array'
import { flow, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as S from 'fp-ts/lib/string'
import applySpec from 'ramda/src/applySpec'

import { getNameWithUSFallback, toSubItem } from './toSubItem'
const locale = getLocale()

// TODO need to figure out how to re-write the applyspec in this file.

export const toTextQuantity = (quantity: number) =>
  S.Monoid.concat(quantity.toString(), 'x')

export const toQuantity = (
  onQuantityChange: (lineItemId: string, quantity: number) => void
) =>
  applySpec<QuantityChangerProps>({
    defaultValue: prop('quantity'),
    onChange: (item: LineItem) => (quantity: number) =>
      onQuantityChange(item.lineItemId, quantity)
  })

export const toItem =
  (
    trackEvent: TrackEvent,
    lineItems: readonly LineItem[],
    onQuantityChange?: (lineItemId: string, quantity: number) => void,
    onRemoveProduct?: (lineItem: LineItem) => void
  ) =>
  (item: LineItem) => {
    const trackQuantityChange = (newQuantity: number, oldQuantity: number) => {
      const netQuantityChange = Math.abs(newQuantity - oldQuantity)
      oldQuantity < newQuantity &&
        trackAddLineItemToCartEvent(
          item,
          trackEvent,
          lineItems,
          netQuantityChange
        )
      oldQuantity > newQuantity &&
        trackRemoveCartEvent(item, trackEvent, lineItems, netQuantityChange)
    }

    const lineItemProp = {
      isBms: path(['custom', 'fields', 'product_is_bms'], item) ?? false,
      isFreeItem: prop('isGift', item),
      itemName: getNameWithUSFallback(item),
      lineItemDisplayName: path(
        ['custom', 'fields', 'lineItemDisplayName'],
        item
      ),
      onClickRemove: pipe(
        O.fromNullable(onRemoveProduct),
        O.chain(onRemoveProduct => O.fromNullable(onRemoveProduct)),
        O.fold(
          () => null,
          _onRemoveProduct => _onRemoveProduct(item)
        )
      ),

      packageParentId: path(['custom', 'fields', 'package_parent_id'], item),
      price: pipe(
        overloadMaybe(localizedDisplayPrice(locale)(item.price)),
        O.chain(displayPrice => O.fromNullable(displayPrice)),
        O.getOrElse(() => '')
      ),
      sku: item.sku,
      subItems: pipe(
        O.fromNullable(item.child),
        O.fold(
          () => [],
          // @ts-expect-error
          children => flow(() => children, map(toSubItem))()
        )
      ),

      trackQuantityChange: trackQuantityChange
    }

    //Quantity text will be shown when there is no quantity callback action related to quantity update
    return isUndefined(onQuantityChange) ||
      prop('isGift', item) ||
      item.productType === 'service' ||
      item.sku === 'SSPSH-ON' ||
      item.sku === 'SSPSH' ||
      item.sku === 'SSPSH-UK'
      ? {
          ...lineItemProp,
          quantityText: toTextQuantity(item.quantity)
        }
      : {
          ...lineItemProp,
          quantity: toQuantity(onQuantityChange)
        }
  }
