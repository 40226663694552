//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { SiteColor } from '../types/colors'
import * as css from './CardKit.module.scss'

export type CardKitProps = {
  /** Amount of items that are part of the Kit */
  readonly tagText?: string
  readonly tagBackgroundColor?: SiteColor
  readonly tagTextColor?: SiteColor
  readonly tagPosition?: 'left' | 'right'
  /** Kit Image */
  readonly image: ReactNode
  /** Kit Title */
  readonly title: string
  /** Shop URL */
  readonly url: string
  /** Component with fully formatted price string, including currency symbol and any text like 'each'*/
  readonly price?: ReactNode
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const CardKit: FC<CardKitProps> = ({
  tagText = '',
  tagTextColor = 'neutralWhite',
  tagBackgroundColor = 'neutralDarkGray',
  tagPosition = 'left',
  image,
  title,
  price,
  url,
  dataComponent
}: CardKitProps) => {
  const tagClass = tagPosition === 'left' ? css.leftImageTag : css.rightImageTag

  return (
    <div className={css.cardKit} data-component={dataComponent}>
      <div className={css.image}>
        <a href={url}>{image}</a>
        {tagText !== '' && (
          <div
            className={classNames(tagClass, css.imageTag, {
              [`${tagBackgroundColor}BackgroundColor`]: !!tagBackgroundColor,
              [`${tagTextColor}TextColor`]: !!tagTextColor
            })}
          >
            {tagText}
          </div>
        )}
      </div>
      <div className={css.content}>
        <div className={classNames('h5', css.title)}>{title}</div>
        {price ? <div className={css.price}>{price}</div> : null}
      </div>
    </div>
  )
}

export default CardKit
