import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Node } from '@contentful/rich-text-types'
import { mutableArray } from '@ecomm/utils'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText
} from 'gatsby-source-contentful/rich-text'
import React from 'react'

import { GatsbyImage } from '../GatsbyImage'
import { isContentfulRichTextGatsbyReference } from './util'

export type RichTextProps = {
  /** From Contentful */
  readonly raw?: string | null
  readonly references?: ReadonlyArray<
    Partial<ContentfulRichTextGatsbyReference> | null | undefined
  >
  readonly optionsCustom?: Options
}

export function safeguardType(
  t: RichTextProps['references']
): readonly ContentfulRichTextGatsbyReference[] {
  if (!t) {
    return []
  } else {
    const validReferences = t.filter(isContentfulRichTextGatsbyReference)
    return Array.of(...validReferences)
  }
}

export function getDefaultOptions(): Options {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: function <T extends Node>({ data }: T) {
        const { gatsbyImageData } = data['target'] || {}

        return gatsbyImageData ? <GatsbyImage image={gatsbyImageData} /> : null
      }
    }
  }
}

export function ContentfulRichText({
  raw = null,
  references = [],
  optionsCustom = getDefaultOptions()
}: RichTextProps) {
  return raw ? (
    <>
      {renderRichText(
        {
          raw,
          references: mutableArray(safeguardType(references))
        },
        optionsCustom
      )}
    </>
  ) : null
}
