import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, NodeData } from '@contentful/rich-text-types'
import { ButtonFragment, ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import { TwoColumnTextSchema } from './schema'

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }: NodeData) => {
      const { url, buttonText }: ButtonFragment = data.target

      //For now it renders links
      return (
        <a
          className="btn btn-solid-primary block w-full md:max-w-[190px]"
          href={url}
        >
          {buttonText}
        </a>
      )
    }
  }
}

type TwoColumnTextSection = TwoColumnTextSchema & {
  readonly className?: string
}

export function TwoColumnTextSection({
  leftSide,
  rightSide,
  columnBorders,
  headline,
  className
}: TwoColumnTextSection) {
  const sharedColumnClasses = classNames('p-4 md:p-6 lg:p-8', {
    'border border-solid border-neutral-light-100 rounded-base': columnBorders
  })

  return (
    <section
      className={classNames(
        'flex flex-col gap-6 md:gap-8 lg:gap-12',
        className
      )}
      data-component="TwoColumnTextSection"
    >
      {headline ? <h2 className="mb-0">{headline}</h2> : null}
      <div className="flex flex-col items-center gap-6 md:flex-row md:gap-8 lg:gap-12">
        <div className={sharedColumnClasses}>
          <ContentfulRichText {...leftSide} optionsCustom={options} />
        </div>
        <div className={sharedColumnClasses}>
          <ContentfulRichText {...rightSide} optionsCustom={options} />
        </div>
      </div>
    </section>
  )
}
