import { ContentfulRichText } from '@ecomm/shared-components'
import React from 'react'
import { TypeOf } from 'zod'

import { alarmCategoriesSchema } from './schema'

type LegalDisclaimerProps = TypeOf<
  typeof alarmCategoriesSchema
>['disclaimerText']

export function LegalDisclaimer({
  text
}: {
  readonly text: LegalDisclaimerProps
}) {
  return (
    <div
      className="prose-p:m-0 prose-p:text-xs prose-p:font-light prose-p:leading-relaxed prose-p:text-neutral-dark mt-3 text-center"
      data-component="LegalDisclaimer"
    >
      {text && <ContentfulRichText raw={text} />}
    </div>
  )
}
