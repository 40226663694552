import { locale as localeEnv } from '@ecomm/data-env-variables'
import { fetchApi } from '@ecomm/data-simplisafe-api'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

export type LeadGenCaptureProps = {
  readonly email: string
  readonly promoCode?: string
  readonly source?: string
  readonly sourceType?: string
  readonly leadSource?: string
  readonly leadVersion?: Record<string, unknown>
  readonly leadType?: string
}

export type LeadGenResponseProps = {
  readonly email?: string
  readonly externalId?: string
  readonly leadId?: string
  readonly leadSource?: string
  readonly leadStatus?: string
  readonly message?: string
  readonly return_code?: string
}

const isLeadGenResponse = (res: unknown): res is LeadGenResponseProps => {
  polyfillHasOwn()
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'message') &&
    Object.hasOwn(res, 'email')
  )
}

export function leadGenCapture(data: LeadGenCaptureProps) {
  return pipe(
    TE.Do,
    TE.bind('locale', () => localeEnv),
    TE.chain(({ locale }) => {
      const leadGenBody = {
        email: data.email,
        lead_promo_offer: data.promoCode,
        lead_source_details: data.leadSource ?? 'embedded',
        lead_source_type: data.sourceType ?? 'promo',
        lead_source_version: data.leadVersion ?? '{}',
        lead_type: data.leadType ?? 'partner',
        locale: locale,
        source: data.source || 'null'
      }

      return fetchApi({
        method: 'POST',
        endpoint: '/leads/v1/lead/capture',
        headers: {},
        body: JSON.stringify(leadGenBody)
      })
    }),
    TE.chain(res =>
      isLeadGenResponse(res)
        ? TE.right(res)
        : TE.left(Error('There was an error trying to send the data'))
    )
  )
}
