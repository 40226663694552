//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import gridCss from '../scss/grid/grid.module.scss'
import * as css from './TabNavigation.module.scss'

export type TabNavigationProps = {
  readonly tabs: readonly string[]
  readonly onClick: (tab: string) => void
  readonly dataComponent?: string
}

// TODO Refactor to use children
const renderTabList = (
  tabs: readonly string[],
  onClick: (tab: string) => void
) =>
  tabs.map((tab: string) => {
    return (
      <li key={tab}>
        <a key={tab} onClick={() => onClick(tab)}>
          {tab}
        </a>
      </li>
    )
  })

/** @deprecated Do not use ss-react-components*/
const TabNavigation: FC<TabNavigationProps> = ({
  tabs,
  onClick,
  dataComponent = TabNavigation.name
}: TabNavigationProps) => {
  return (
    <div className={classNames(css.filter)} data-component={dataComponent}>
      <div className={gridCss.row}>
        <div className={classNames(gridCss['col-sm-12'], gridCss['col-md-12'])}>
          <ul className={css['filterTab']}>{renderTabList(tabs, onClick)}</ul>
        </div>
      </div>
    </div>
  )
}

export default TabNavigation
