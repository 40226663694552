import { CCAmex, CCDiscover, CCMaster, CCVisa } from '@ecomm/shared-icons'
import { TrackingData } from '@ecomm/tracking'
import { ReactNode } from 'react'

import { PaymentMethod } from './types'

/** Value for the Affirm checkout radio button. This value is sent to tracking endpoints. */
export const PAYMENT_OPTION_AFFIRM = 'affirm'
/** Value for the credit card checkout radio button. This value is sent to tracking endpoints. */
export const PAYMENT_OPTION_CARD = 'new card'
/** Value for the saved card checkout radio button. This value is sent to tracking endpoints. */
export const PAYMENT_OPTION_SAVED_CARD = 'saved card'
/** Maps the value for selected payment option from a string to an accepted TrackingData value */
export const paymentOptionMapper: Record<
  string,
  TrackingData['selectedPaymentOption']
> = {
  [PAYMENT_OPTION_AFFIRM]: PAYMENT_OPTION_AFFIRM,
  [PAYMENT_OPTION_CARD]: PAYMENT_OPTION_CARD,
  [PAYMENT_OPTION_SAVED_CARD]: PAYMENT_OPTION_SAVED_CARD
}

export const paymentMethodIconClasses = 'w-[27px] h-[18px] mr-2 self-center'

const paymentMethodIconMapper: Record<string, ReactNode> = {
  visa: <CCVisa className={paymentMethodIconClasses} />,
  mastercard: <CCMaster className={paymentMethodIconClasses} />,
  americanexpress: <CCAmex className={paymentMethodIconClasses} />,
  discover: <CCDiscover className={paymentMethodIconClasses} />
}

const savedPaymentMethodRadioOptionDescription = (
  paymentMethod: PaymentMethod
) => {
  const { address } = paymentMethod
  return (
    <div className="ml-9 md:ml-0">
      <span className="block">
        {address.firstName} {address.lastName}
      </span>
      <span className="mt-1 block">{address.street1}</span>
      <span className="mt-1 block">
        {address.city}, {address.state} {address.zip}
      </span>
    </div>
  )
}

const savedPaymentMethodRadioOptionText = (paymentMethod: PaymentMethod) => {
  const { lastFour, ccType } = paymentMethod
  return (
    <>
      {paymentMethodIconMapper[ccType.split(' ').join('').toLowerCase()]}
      <span>
        {ccType} **** {lastFour}
      </span>
    </>
  )
}

export const getSavedPaymentMethodsRadioOptions = (
  paymentMethods: readonly PaymentMethod[]
) =>
  paymentMethods.map((paymentMethod, index) => {
    const { paymentProfileId } = paymentMethod

    return {
      id: paymentProfileId,
      text: savedPaymentMethodRadioOptionText(paymentMethod),
      description: savedPaymentMethodRadioOptionDescription(paymentMethod),
      value: PAYMENT_OPTION_SAVED_CARD,
      className:
        'mb-4 pb-4 border border-solid border-t-0 border-x-0 border-[var(--neutral-light-100)] items-start md:items-center',
      defaultChecked: index === 0 ? true : false
    }
  })
