import { Text } from '@ecomm/ss-react-components'
import React, { ReactNode } from 'react'

export type FeatureListItemProps = {
  readonly children?: ReactNode
  readonly isFeatured: boolean
}

const bgUrlCheckmark =
  'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC43MjEzIDEwLjE4ODVMMTAuMjg2NiAyMC41ODEzTDMuNTg5MDUgMTMuODU2Nkw1LjkwNzg4IDExLjU0NzFMMTAuMjk1MSAxNS45NTIyTDE4LjQxMTggNy44Njk3MUwyMC43MjEzIDEwLjE4ODVaIiBmaWxsPSIjMDA1NjVDIi8+Cjwvc3ZnPgo=)'
const bgUrlCross =
  'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxOCAyMSIgc3Ryb2tlPSIjQzg0QTNCIiBzdHJva2Utd2lkdGg9IjMiLz4KPHBhdGggZD0iTTE4IDlMNiAyMSIgc3Ryb2tlPSIjQzg0QTNCIiBzdHJva2Utd2lkdGg9IjMiLz4KPC9zdmc+Cg==)'

export function FeatureListItem({
  children = null,
  isFeatured
}: FeatureListItemProps) {
  return (
    <Text
      className="mt-1 flex gap-2 first:mt-0"
      dataComponent="FeatureListItem"
      useTailwind={true}
    >
      <div
        className="w-6 flex-none"
        data-component={`FeatureListItem_${isFeatured ? 'Checkmark' : 'Cross'}`}
        style={{
          backgroundImage: isFeatured ? bgUrlCheckmark : bgUrlCross,
          backgroundRepeat: 'no-repeat'
        }}
      ></div>
      {children && (
        <div data-component="FeatureListItem_Content">
          <p className="pt-1 text-sm leading-[1.3] last:m-0 md:pt-0 md:text-base md:leading-normal">
            {children}
          </p>
        </div>
      )}
    </Text>
  )
}
