import { CancelIcon } from '@ecomm/checkout-icons'
import { useTrackPlanWarningBannerClose } from '@ecomm/tracking'

interface Props {
  readonly isOpen: boolean
  readonly onClose: () => void
}

export function PlanWarning({ isOpen, onClose }: Props) {
  const trackClose = useTrackPlanWarningBannerClose()

  const handleClose = () => {
    trackClose()
    onClose()
  }

  return isOpen ? (
    <div
      className="border-1 mx-auto mb-8 flex flex-row items-start rounded-base border-solid border-[#3D2700] bg-[#FFF8EB] p-4"
      data-component="plan-warning"
    >
      <span className="grow text-left text-sm text-[#3D2700]">
        Your free month has updated to our most popular 24/7 monitoring plan.
      </span>
      <div
        className="cursor-pointer"
        data-component="warning-close-button"
        onClick={handleClose}
        role="button"
      >
        <span className="hidden">close</span>
        <CancelIcon className="text-[#3D2700]" />
      </div>
    </div>
  ) : (
    <></>
  )
}
