import { localStorage } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const getCartId = () => {
  const cartId = localStorage.get('cartId')

  // Attempt to JSON.parse the cartId in order to be interoperable with usage of https://www.npmjs.com/package/local-storage in ss-ecomm-data,
  // which uses JSON.stringify when saving cartId to localStorage.
  return pipe(
    O.fromNullable(cartId),
    O.chain(cartId => O.tryCatch(() => <string>JSON.parse(cartId))),
    O.getOrElse(() => cartId)
  )
}

// JSON.stringify the cartId in order to be interoperable with usage of https://www.npmjs.com/package/local-storage in ss-ecomm-data,
// which uses JSON.stringify when saving cartId to localStorage.
export const setCartId = (cartId: string) =>
  localStorage.set('cartId', JSON.stringify(cartId))

export const removeCartId = () => localStorage.remove('cartId')
