import React from 'react'

import { EndDatePill } from '../../EndDatePill'
import { LeadCapture as LeadCaptureForm } from '../../LeadCaptureForm'
import { Countdown } from '../Countdown'
import { MiddleLayout } from '../MiddleLayout'

type LayoutWithCountdownProps = {
  readonly endTime: Date
  readonly saleName?: string
}

export function LayoutWithCountdown({
  endTime,
  saleName = ''
}: LayoutWithCountdownProps) {
  return (
    <div className="grid w-full max-w-[1300px] grid-flow-col grid-cols-12">
      <div
        className="col-span-3 flex flex-col items-center justify-center"
        data-component="LayoutCountdown"
      >
        <div className="mb-2 flex justify-center text-sm uppercase">
          {saleName && (
            <span className="px-2 py-0.5 pt-0" data-component="SaleName">
              {saleName}
            </span>
          )}
          <div className="mx-2">
            <EndDatePill />
          </div>
        </div>
        <Countdown endTime={endTime} />
      </div>
      <div
        className="col-span-5 flex flex-col items-center justify-center"
        data-component="LayoutMiddle"
      >
        <MiddleLayout />
      </div>
      <div
        className="col-span-4 flex flex-row items-center justify-end"
        data-component="LeadCapture"
      >
        <LeadCaptureForm />
      </div>
    </div>
  )
}
