import { ODMON_OVERNIGHT_MONITORING } from '@ecomm/data-constants'
import {
  useTrackingAddMonitoring,
  useTrackingRemoveMonitoring
} from '@ecomm/tracking'

import useMonitoringPlan from '../../../hooks/IMAPS/useMonitoringPlan'
import { ChooseMonitoringBox } from '../ChooseMonitoringBox'
import { OptInOrOutProps } from './types'
import { useDataToRender } from './useDataToRender'

export function OptInOrOut({
  amountSavedWithServicePlan,
  discountWithServicePlan,
  priceToggles
}: OptInOrOutProps) {
  const trackRemoveMonitoring = useTrackingRemoveMonitoring()
  const trackAddMonitoring = useTrackingAddMonitoring()

  const { initialPlan, hasMonitoring, onSelectMonitoring } = useMonitoringPlan({
    priceToggles
  })

  const isOdmonPreLaunch = !!priceToggles.find(
    pt => pt.sku === ODMON_OVERNIGHT_MONITORING
  )

  const { removeBtnText, claimBtnText, OptedInContent, OptedOutContent } =
    useDataToRender({
      odmon: isOdmonPreLaunch
    })

  return (
    <ChooseMonitoringBox
      buttonText={hasMonitoring ? removeBtnText : claimBtnText}
      content={
        hasMonitoring ? (
          <OptedInContent discount={discountWithServicePlan} />
        ) : (
          <OptedOutContent discount={amountSavedWithServicePlan} />
        )
      }
      hasMonitoring={hasMonitoring}
      key={hasMonitoring ? 'withMonitoring' : 'withoutMonitoring'}
      onButtonClick={() => {
        onSelectMonitoring(hasMonitoring ? '' : initialPlan)
        hasMonitoring ? trackRemoveMonitoring() : trackAddMonitoring()
      }}
    />
  )
}
