import { ErrorResponse, fetchApi } from '@ecomm/data-simplisafe-api'
import { Locale } from '@ecomm/utils'
import { pipe } from 'fp-ts/lib/function'
import * as T from 'fp-ts/lib/Task'
import * as TE from 'fp-ts/lib/TaskEither'

import { response } from './schema'
import { FacebookEventData, FacebookResponse } from './types'

function isResponse(res: unknown): res is FacebookResponse {
  return response.safeParse(res).success
}

const buildRequestBody = (data: FacebookEventData) => {
  return {
    data: [
      {
        action_source: data.actionSource,
        custom_data:
          data.customData === undefined
            ? undefined
            : {
                content_ids: data.customData.contentIds,
                content_name: data.customData.contentName,
                content_type: data.customData.contentType,
                contents: data.customData.contents,
                currency: data.customData.currency,
                num_items: data.customData.numItems,
                order_id: data.customData.orderId,
                value: data.customData.value
              },
        event_id: data.eventId,
        event_name: data.eventName,
        event_source_url: data.eventSourceUrl,
        event_time: data.eventTime,
        opt_out: data.optOut,
        user_data: {
          client_ip_address: data.userData.clientIpAddress,
          client_user_agent: data.userData.clientUserAgent,
          country: data.userData.country,
          ct: data.userData.ct,
          db: data.userData.db,
          em: data.userData.em,
          external_id: data.userData.externalId,
          fbp: data.userData.fbp,
          fn: data.userData.fn,
          ge: data.userData.ge,
          leadId: data.userData.leadId,
          ln: data.userData.ln,
          ph: data.userData.ph,
          st: data.userData.st,
          zp: data.userData.zp
        }
      }
    ],
    test_event_code: data.testCode
  }
}

export const handleFacebookEvent = (
  data: FacebookEventData,
  locale: Locale,
  handleError: (err: Error | ErrorResponse) => void,
  handleSuccess: (res: FacebookResponse) => void
) => {
  const endpoint = `/proxy/v1/facebook/api/v13/conversions/${
    locale === 'en-GB' ? 'uk' : 'us'
  }`

  return pipe(
    fetchApi({
      body: JSON.stringify(buildRequestBody(data)),
      endpoint: endpoint,
      headers: {},
      method: 'POST'
    }),
    TE.chain(res =>
      isResponse(res)
        ? TE.right(res)
        : TE.left(new Error('Response does not match schema'))
    ),
    TE.fold(
      e => T.of(handleError(e)),
      r => T.of(handleSuccess(r))
    )
  )()
}
