import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import { ContentfulRichText } from '@ecomm/shared-components'
import { useTrackOdmonComparePlanClick } from '@ecomm/tracking'
import React, { useState } from 'react'

import ComparisonTable, { type TableProps, Themes } from '../ComparisonTable'
import MonitoringPlanModal from '../MonitoringPlanModal'
import type { MonitoringPlanModalSchema } from '../MonitoringPlanModal/schema'
import OdmonMonitoringPlanCard from '../OdmonMonitoringPlanCard'
import { mapRows } from '../utils/table'
import type { OdmonExpandableMonitoringSchema } from './schema'

const options: Options = {
  renderNode: {
    [BLOCKS.TABLE]: node => {
      const { content } = node
      const tableData: TableProps = {
        headRows: mapRows(content, BLOCKS.TABLE_HEADER_CELL, false),
        bodyRows: mapRows(content, BLOCKS.TABLE_CELL, false),
        theme: Themes.planComparison
      }
      return <ComparisonTable {...tableData} />
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      const {
        data: { target }
      } = node
      const data: MonitoringPlanModalSchema = target

      return <MonitoringPlanModal {...data} withAddToCartButton={true} />
    }
  }
}

export function OdmonExpandableMonitoring(
  data: OdmonExpandableMonitoringSchema
) {
  const {
    headline,
    subheadline,
    monitoringCards,
    comparisonTable,
    comparisonDisclaimer,
    showAddToCartButton
  } = data
  const trackComparePlan = useTrackOdmonComparePlanClick()
  const [comparisonTableVisible, setComparisonTableVisible] = useState(false)
  const microcopy = useMicroCopy()
  const locale = useLocale()

  return (
    <div className="prose md:prose-md lg:prose-lg flex w-full flex-col items-center gap-6 md:gap-4">
      {headline ? (
        <h2 className="mb-0 text-center text-3xl md:max-w-[960px] md:text-6xl">
          {headline}
        </h2>
      ) : null}
      {subheadline ? (
        <p className="max-w-[860px] text-center text-base">{subheadline}</p>
      ) : null}

      <div className="flex flex-col gap-4 lg:flex-row">
        {monitoringCards.map((monitoringCard, index) => (
          <OdmonMonitoringPlanCard
            badge={monitoringCard.disclaimer}
            cta={monitoringCard.cta}
            description={monitoringCard.additionalFeatures[0].text}
            key={index}
            showAddToCartButton={showAddToCartButton}
            sku={monitoringCard.sku}
            subtitle={monitoringCard.description}
            title={monitoringCard.name}
          />
        ))}
      </div>

      {comparisonTableVisible ? (
        <ContentfulRichText {...comparisonTable} optionsCustom={options} />
      ) : (
        <button
          className="mx-auto cursor-pointer border-none bg-transparent px-0 py-0 text-lg text-blue-600 underline hover:text-blue-800"
          onClick={() => {
            setComparisonTableVisible(true)
            trackComparePlan()
          }}
        >
          {microcopy['compare-plans']}
        </button>
      )}

      {comparisonDisclaimer && comparisonTableVisible && locale !== 'en-GB' ? (
        <div className="border-neutral-light-100 rounded-base border border-solid p-6 md:p-8">
          <ContentfulRichText
            {...comparisonDisclaimer}
            optionsCustom={options}
          />
        </div>
      ) : null}
    </div>
  )
}
