import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy/src/lib/jsonSchema'
import { z } from 'zod'

export const refurbishedBannerSchema = z.object({
  copy: jsonSchema,
  modalData: z.object({
    headline: z.string(),
    description: contentfulRichTextSchema
  })
})
