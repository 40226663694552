import { NativeVideo } from '@ecomm/shared-components'
import { useOdmonExperience } from '@ecomm/shared-ninetailed'
import type { TypeOf } from '@simplisafe/ewok'

import type { videoSchema } from '../ContentMapper/schema'

// video data should be correctly passed from monitoring page ctfl entry
// keeping the heading/subtitle hardcoded for now as it was before. Could change if preferred.

type VideoSection = TypeOf<typeof videoSchema>
export default function VideoSection(data: VideoSection) {
  const isODMONLaunchVariant = useOdmonExperience().isVariant
  const heading = isODMONLaunchVariant
    ? 'How we help prevent crime'
    : 'See how we keep you safe'
  const subtitle = isODMONLaunchVariant
    ? 'Today, most traditional home security systems will trigger an alarm when an intruder is breaking into your home, but by then it may be too late. Now, you can feel safer than ever with Active Guard Outdoor Protection — our AI-powered Outdoor Camera works with live SimpliSafe® agents to proactively deter intruders outside your home.'
    : 'Only SimpliSafe® agents can see and speak to intruders via an indoor camera during an alarm'

  return (
    <div className="flex flex-col items-center">
      <h2 className="mb-4 md:mb-6 text-center">{heading}</h2>
      <p className="mx-2 mb-2 text-center md:mx-4 md:mb-4 lg:mb-6">
        {subtitle}
      </p>
      <NativeVideo
        autoPlay={false}
        captionsSrcUrl={data.captions}
        className="h-auto"
        containerClassName="mx-auto max-w-5xl"
        data-component="monitoring-video"
        poster={data.posterImage ?? ''}
        preload="none"
        videoSrcUrlMp4={data.mp4Link}
        videoSrcUrlWebm={data.webmLink}
      />
    </div>
  )
}
