import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import { MaybeT } from '@simplisafe/ewok'
import { IOUpdateCart } from '@simplisafe/ss-ecomm-data/cart'
import {
  buildShippingAddressUpdateAction,
  commercetoolsGetShippingMethodsV2,
  ShippingMethod,
  ShippingMethodPagedQueryResponse
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { fork } from 'fluture'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { validatePostalCode } from '../validations/validatePostalCode'

type Status = 'failed' | 'loading' | 'pending' | 'success'

export function useLoadCheckoutShippingOptions() {
  const locale = useLocale()
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
  const [status, setStatus] = useState<Status>('pending')
  const [shippingOptions, setShippingOptions] = useState<
    readonly ShippingMethod[]
  >([])

  const { values, isSubmitting, touched } =
    useFormikContext<CheckoutFormValuesType>()

  const validPostalCode = validatePostalCode(values.postalCode, locale)
  const postalAndStateDefined = !!(
    values.postalCode &&
    (values.state || locale === 'en-GB')
  )

  useEffect(() => {
    fetchOptions(false)
  }, [values.state, values.postalCode])

  const handleShippingOptionsSuccess = (cartId: string) => () => {
    commercetoolsGetShippingMethodsV2(cartId).pipe(
      fork((e: Error) => {
        logError(e)
        setStatus('failed')
      })((shipResp: MaybeT<ShippingMethodPagedQueryResponse>) => {
        const shippingData = shipResp.getOrElse({
          count: 0,
          results: []
        })
        setShippingOptions(shippingData.results)
        setStatus('success')
      })
    )
  }

  const handleShippingOptionsError = () => {
    setStatus('failed')
    logError(Error('something went wrong when requesting shipping options'))
  }

  const fetchOptions = (forceFetch: boolean) => {
    const shouldFetch =
      (touched.postalCode || status !== 'success') &&
      (forceFetch || status !== 'loading') &&
      !isSubmitting

    if (postalAndStateDefined && validPostalCode && shouldFetch) {
      setStatus('loading')
      setShippingOptions([])

      const shippingAddressUpdateAction = buildShippingAddressUpdateAction({
        country: locale === 'en-US' ? 'US' : 'GB',
        postalCode: values.postalCode,
        state: values.state || undefined,
        streetName: values.streetName,
        city: values.city
      })

      cart.forEach(_cart => {
        dispatch(
          IOUpdateCart(
            [shippingAddressUpdateAction],
            handleShippingOptionsError,
            handleShippingOptionsSuccess(_cart.id)
          )
        )
      })
    }
  }

  return {
    status,
    shippingOptions,
    fetchOptions
  }
}
