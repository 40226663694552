import { overloadedOption } from '@ecomm/shared-fp-ts-utils'
import { Map as IMap } from 'immutable'

import { parseDate, parseVariant, Product } from './internal'
import type { GqlProduct } from './types'

export function parseProducts(t: {
  readonly allProduct: {
    readonly edges: readonly { readonly node: GqlProduct }[]
  }
}): IMap<string, Product> {
  return t.allProduct.edges.reduce((acc, { node }) => {
    const sku = node.masterSku
    return acc.set(sku, {
      '@@type': 'product',
      id: node.id,
      discountedPrice: overloadedOption(node.discountedPrice),
      discountedPriceWithServicePlan: overloadedOption(
        node.discountedPriceWithServicePlan
      ),
      isCartable: node.isCartable,
      isIndeterminatePrice: node.isIndeterminatePrice,
      isOnStock: node.isOnStock,
      isSellable: node.isSellable,
      isViewable: node.isViewable,
      hardwareVersion: node.hardwareVersion,
      masterSku: node.masterSku,
      name: {
        'en-US': node.name.en_US,
        'en-GB': node.name.en_GB
      },
      nodeId: node.nodeId,
      price: node.price,
      productType: node.productType,
      productTypeId: node.productTypeId,
      restockDate: node.restockDate,
      sku: node.sku,
      variants: node.variants?.map(parseVariant) ?? [],
      lastFetched: parseDate(node.lastFetched)
    })
  }, IMap<string, Product>())
}
