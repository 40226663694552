import {
  toIconPositionValue,
  toIconSizeValue,
  toIconVerticalAlignmentValue,
  toTextAlignmentValue
} from '@ecomm/shared-components'
import { prop } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import { IconWithText, Info, Text } from '@ecomm/ss-react-components'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import React, { ReactNode } from 'react'

import { ContentfulIconWithText } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'
import ModalComponent from '../ModalComponent'

type IconWithTextContainerProps = {
  readonly data: ContentfulIconWithText
}
type ImageData = ContentfulIconWithText['icon'] | null

export default function IconWithTextComponent({
  data
}: IconWithTextContainerProps) {
  const icon = prop('icon', data)

  const iconPosition = propOr('Left', 'iconPosition', data)
  const iconSize = propOr('medium', 'iconSize', data)
  const verticalAlignment = propOr('middle', 'iconVerticalAlignment', data)
  const textAlignment = propOr('Left', 'textAlignment', data)
  const modalContent = prop('modalContent', data)

  const disclaimer = data?.disclaimerText

  const toImg = (imgData: ImageData): ReactNode =>
    Maybe.fromNull(imgData)
      .chain(safeProp('url'))
      .cata(
        () => null,
        (fileUrl: string) => (
          <img alt={prop('title', imgData)} key={fileUrl} src={fileUrl} />
        )
      )

  const { raw, references } = data?.text
  const toText = raw && (
    <ContentfulRichText
      key={prop('id', data)}
      raw={raw}
      references={references}
    />
  )

  return (
    <IconWithText
      icon={toImg(icon)}
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      iconPosition={toIconPositionValue(iconPosition)}
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      iconSize={toIconSizeValue(iconSize)}
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      iconVerticalAlignment={toIconVerticalAlignmentValue(verticalAlignment)}
      text={
        <div style={{ display: 'flex' }}>
          <div>
            {toText}
            {disclaimer ? (
              <Text
                display="inline-flex"
                fontWeight="medium"
                textColor="neutralDarkGray"
                textSize="xs"
              >
                {disclaimer}
              </Text>
            ) : null}
          </div>
          {modalContent ? (
            <ModalComponent
              clickTarget={
                <Info forceButtonMode={true} titleA11y="show more info" />
              }
              data={modalContent}
            />
          ) : null}
        </div>
      }
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      textAlignment={toTextAlignmentValue(textAlignment)}
    />
  )
}
