import { SimpleButton, Text } from '@ecomm/ss-react-components'
import classNames from 'classnames'
import React from 'react'

import { FloatingOpenModalButtonProps } from '../types'

export function OpenModalButton({
  buttonText,
  onClick,
  disabled,
  hideOnDisable
}: FloatingOpenModalButtonProps) {
  return (
    <SimpleButton
      className={classNames('w-auto px-2 shadow-md md:px-5', {
        'border-neutral-light-50 bg-neutral-light-50 hover:border-neutral-light-100 hover:bg-neutral-light-100':
          !disabled,
        'border-neutral-medium bg-neutral-medium': disabled,
        hidden: hideOnDisable && disabled
      })}
      disabled={disabled}
      onClick={onClick}
      size="medium"
      variant="solid"
    >
      <Text className={classNames('', { 'text-white': disabled })} useTailwind>
        {buttonText}
      </Text>
    </SimpleButton>
  )
}
