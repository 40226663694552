import { useAtom } from 'jotai'
import React from 'react'

import { freeGiftItemAtom } from '../../../atoms/draftCart/freeGiftAtom'
import { monitoringAtom } from '../../../atoms/draftCart/monitoringPlanAtom'
import { MonitoringPlan } from '../../DraftCart/types'
import DraftCartStaticItem from '../DraftCartStaticItem'
import { KeyValueFragment, ProductFragment } from '../types'

type Props = {
  readonly baseProducts: readonly ProductFragment[]
  readonly otherItems: readonly KeyValueFragment[]
  readonly isAffirmMonthlyExperiment: boolean
}

function DraftCartStaticList({
  baseProducts,
  otherItems,
  isAffirmMonthlyExperiment = false
}: Props) {
  const [monitoring] = useAtom(monitoringAtom)
  const [freeGiftItems] = useAtom(freeGiftItemAtom)

  const giftItem =
    monitoring.type === MonitoringPlan.interactive ||
    monitoring.type === MonitoringPlan.odmonOvernight ||
    monitoring.type === MonitoringPlan.odmon247
      ? freeGiftItems.withMonitoring
      : freeGiftItems.withoutMonitoring

  const baseProductCount = baseProducts.reduce<Record<string, number>>(
    (counts, product) => ({
      ...counts,
      [product.sku]: counts[product.sku] || 1
    }),
    {}
  )

  return (
    <ul className="m-0 list-none p-0">
      {Object.entries(baseProductCount).map(([sku, quantity]) => (
        <DraftCartStaticItem
          forFree={false}
          isAffirmMonthlyExperiment={isAffirmMonthlyExperiment}
          key={sku}
          name={baseProducts.find(p => p.sku === sku)?.name}
          quantity={quantity}
          sku={sku}
        />
      ))}
      {giftItem
        ? giftItem.map(item => (
            <DraftCartStaticItem
              forFree={true}
              key={item.sku}
              name={item.title}
              quantity={item.quantity || 1}
              sku={item.sku || ''}
            />
          ))
        : null}
      {otherItems.map(({ key }) => (
        <DraftCartStaticItem
          forFree={true}
          key={key}
          name={key}
          quantity={0}
          sku={key}
        />
      ))}
    </ul>
  )
}

export default DraftCartStaticList
