import { TypeOf, z } from '@simplisafe/ewok'

export const layoutReferenceSchema = z.object({
  referenceId: z
    .enum([
      '404',
      'CompetitiveComponents',
      'CompetitiveComponentsADT',
      'CompetitiveComponentsRing',
      'DeepDive',
      'MoversComponents',
      'NeighbourhoodWatch',
      'PrivacyHeroBanner',
      'SecuredSystems',
      'SubHeroText',
      'QuoteWizard',
      'QuizRecommendedSystem',
      'PartnerWithUs'
    ])
    .optional(),
  __typename: z.literal('ContentfulLayoutReference')
})

export type LayoutReferenceProps = TypeOf<typeof layoutReferenceSchema>
