import {
  leadGenCapture,
  LeadGenCaptureProps,
  LeadGenResponseProps
} from '@ecomm/data-leads'
import { useLeadCaptureEmailSubmitted } from '@ecomm/promotions-hooks'
import { ContentfulRichText } from '@ecomm/shared-components'
import {
  COOKIE_LEAD_DATA,
  getLeadData,
  useCookieChange
} from '@ecomm/shared-cookies'
import {
  Footer,
  FooterLinkProps,
  FooterProps,
  Links,
  SocialLinks
} from '@ecomm/ss-react-components'
import { ItemIconDropDown } from '@ecomm/ss-react-components'
import { MenuItem, MenuProps } from '@ecomm/ss-react-components'
import { useOptimizelyTrackSiteEvents } from '@ecomm/tracking'
import { trackLeadCapture } from '@ecomm/tracking'
import { TrackingData } from '@ecomm/tracking'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import { selectCurrentPromoCode } from '@simplisafe/ss-ecomm-data/redux/select'
import * as E from 'fp-ts/lib/Either'
import { pipe as fptsPipe } from 'fp-ts/lib/function'
import { navigate } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import always from 'ramda/src/always'
import append from 'ramda/src/append'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import either from 'ramda/src/either'
import equals from 'ramda/src/equals'
import findIndex from 'ramda/src/findIndex'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import pathOr from 'ramda/src/pathOr'
import propEq from 'ramda/src/propEq'
import propOr from 'ramda/src/propOr'
import when from 'ramda/src/when'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { pipe } from 'ts-functional-pipe'

import { ContentfulFooter } from '../../../graphql'
import { locale } from '../../commercetools/utils'
import { GtmData, sendGtmCustomEvent } from '../../util/analytics'

export type FooterComponentProps = {
  readonly id?: string
  readonly data: ContentfulFooter
}

export const formatTelephoneLink = (source = '') =>
  'tel:'.concat(source.split('-').join(''))

function toUrl<
  T extends {
    readonly linkType: string
    readonly linkText: string
    readonly linkUrl: string
  }
>(source: T) {
  const typeIsTelephone = propEq('linkType', 'Telephone', source)

  const linkText: string = prop('linkText', source)

  return ifElse(
    always(typeIsTelephone),
    always(formatTelephoneLink(linkText)),
    prop('linkUrl')
  )(source)
}

const toMenuLink = applySpec<MenuProps>({
  name: prop('linkText'),
  url: toUrl
})

export const toFooterLinks = (
  contentfulFooter: ContentfulFooter
): readonly FooterLinkProps[] => {
  const toMenuItem = applySpec<FooterLinkProps>({
    menus: pipe(prop('links'), ifElse(isNil, always([]), map(toMenuLink))),
    title: prop('groupCategory'),
    titleUrl: prop('linkURL')
  })
  return map(toMenuItem, prop('footerGroup', contentfulFooter) || [])
}

export const toOffersData = (
  leadGenerationForm: any,
  handleEmailSubmit: any,
  isSuccess: any,
  defaultEmail: string
) => {
  const inputLabel = prop('title', leadGenerationForm)

  const placeHolder = path(
    ['formInput', '0', 'placeholderText'],
    leadGenerationForm
  )

  const buttonText = path(['button', 'text'], leadGenerationForm)

  const ctaContent = (
    <ContentfulRichText raw={leadGenerationForm?.ctaNote?.raw} />
  )

  const fieldValidation = pathOr(
    {},
    ['formInput', '0', 'fieldValidation'],
    leadGenerationForm
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const errorMsg = propEq('requirement', 'Invalid Input')(fieldValidation)
    ? // errorMessage does not exist on fieldValidation
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      prop('errorMessage', fieldValidation)
    : ''
  const success = {
    message: defaultTo('')(prop('successMessageTitle', leadGenerationForm)),

    title: defaultTo('')(
      path(
        ['successMessageDescription', 'successMessageDescription'],
        leadGenerationForm
      )
    )
  }
  const offersDataSpec = {
    buttonText,
    ctaContent,
    defaultEmail,
    errorMsg,

    inputLabel,

    onEmailSubmit: handleEmailSubmit,

    placeHolder,

    title: inputLabel
  }

  return isSuccess
    ? {
        ...offersDataSpec,
        success
      }
    : offersDataSpec
}

const toLegalLinks = (contentfulFooter: ContentfulFooter): readonly Links[] => {
  const toLegalLink = applySpec<Links>({
    linkText: prop('linkText'),
    linkUrl: prop('linkUrl'),
    trackingEvent: prop('trackingEvent')
  })
  return map(toLegalLink, prop('tertiaryLinks', contentfulFooter) || [])
}

const toLegalLinkMenu = (
  legalLinks: readonly Links[]
): readonly MenuProps[] => {
  return map(toMenuLink, legalLinks)
}

const toSocialIcon = (icon: any) => {
  return (
    icon && (
      <GatsbyImage
        alt={''}
        image={getImage(icon)}
        style={{
          height: '16px',
          width: '100%'
        }}
      />
    )
  )
}

export const toSocialLinks = (
  contentfulFooter: ContentfulFooter
): readonly SocialLinks[] => {
  const toSocialLink = applySpec<SocialLinks>({
    id: prop('id'),
    image: pipe(path(['icon', '0']), toSocialIcon),
    linkText: prop('linkText'),
    linkUrl: prop('linkUrl')
  })
  return map(toSocialLink, prop('socialMediaIcons', contentfulFooter) || [])
}

const toFormImg = (x: any) => {
  return (
    <GatsbyImage
      alt=""
      image={getImage(prop('linkIcon', x))}
      // @ts-expect-error TS(2322) FIXME: Type '{ image: IGatsbyImageData; style: { height: ... Remove this comment to see the full error message
      style={{
        height: '12px',
        marginTop: '6px',
        width: '20px'
      }}
    />
  )
}

const toListItem = applySpec<ItemIconDropDown>({
  href: prop('linkUrl'),
  srcIcon: x => {
    return toFormImg(x)
  },
  subtitle: prop('linkSubtext'),
  title: prop('linkText')
})

const toLeftBottomLinks = (data: any) => {
  const toLeftBottomLink = applySpec<Links>({
    extraClassName: prop('className'),
    linkText: prop('linkText'),
    linkUrl: prop('linkUrl')
  })

  return map(toLeftBottomLink, data)
}

const getCountryInformation = (data: any, locale: string) => {
  const isUS = equals('en-US', locale)
  const labelForUS = ifElse(
    equals(true),
    always('United States'),
    always('United Kingdom')
  )(isUS)

  const listItems = !data ? [] : map(toListItem)(data)
  const currentLocationIdx = pipe(
    findIndex(propEq('title', labelForUS)),
    when(equals(-1), always('0'))
  )(listItems)
  // TODO these 2 currentLocationIdx are not on listItems
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const image = path([currentLocationIdx, 'srcIcon'], listItems)
  // @ts-expect-error TS(2322) FIXME: Type 'number | "" | ItemIconDropDown | ((...items:... Remove this comment to see the full error message
  const label: string = path([currentLocationIdx, 'subtitle'], listItems) || ''
  const positionDropDown: 'down' | 'up' = 'up'
  const eventShowDropDown: 'click' | 'hover' = 'click'
  return {
    a11yLabel: 'Current country is: ',
    eventShowDropdown: eventShowDropDown,
    label: label,
    listItem: listItems,
    positionDropdown: positionDropDown,
    srcIcon: image
  }
}

const toFooterData = (
  contentfulFooter: ContentfulFooter,
  handleEmailSubmit: (email: string) => void,
  isSuccess: any,
  locale: string,
  defaultEmail: string
): FooterProps => {
  const legalLinks = toLegalLinks(contentfulFooter)
  const copyRightText = contentfulFooter.copyrightText
  const leadForm = propOr(undefined, 'leadGenerationForm', contentfulFooter)
  const offers = leadForm
    ? toOffersData(
        contentfulFooter.leadGenerationForm,
        handleEmailSubmit,
        isSuccess,
        defaultEmail
      )
    : undefined
  const footerLinkList = ifElse(isNil, always([]), () =>
    toFooterLinks(contentfulFooter)
  )(prop('footerGroup', contentfulFooter))

  const legalLink: FooterLinkProps = {
    menus: toLegalLinkMenu(legalLinks),
    title: 'Legal' // TODO: this should not be hard coded
  }

  const socialLinks: readonly SocialLinks[] | undefined = ifElse(
    isNil,
    always(undefined),
    () => toSocialLinks(contentfulFooter)
  )(prop('socialMediaIcons', contentfulFooter))
  const footerLinks: readonly FooterLinkProps[] = append(
    legalLink,
    footerLinkList
  )
  const countrySelector = pipe(
    ifElse(isNil, always(undefined), data => {
      return getCountryInformation(data, locale)
    })
  )(path(['countrySelector', 'listOfCountries'], contentfulFooter))
  const leftBottomLink = ifElse(
    either(isNil, isEmpty),
    always(undefined),
    toLeftBottomLinks
  )(prop('leftBottomLink', contentfulFooter))
  return {
    footerData: {
      copyRightText: copyRightText || '',
      countrySelector,
      footerLinks,
      leftBottomLink,
      legalLinks,
      offers,
      socialLinks
    }
  }
}

const setLinkGtmCustomEvent = (menuItem: MenuItem) => {
  const gtmData: GtmData = {
    event: 'buttonClick',
    eventAction: 'click',
    eventCategory: 'footer',
    eventLabel: prop('name', menuItem)
  }
  sendGtmCustomEvent(gtmData)
}

function FooterComponent({ data }: FooterComponentProps) {
  const leadDataCookie = getLeadData()
  const promoCode = useSelector(selectCurrentPromoCode)
  const [isSuccess, setIsSuccess] = useState(false)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const [defaultEmail, setDefaultEmail] = useState(
    propOr<string, string>('', 'email', leadDataCookie)
  )

  const { trackEvent } = useTracking<TrackingData>({
    appSection: 'newsletterSubmit'
  })

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const { setIsSubmitted } = useLeadCaptureEmailSubmitted()

  const handleSuccess = async (value: LeadGenResponseProps) => {
    setIsSuccess(true)
    trackLeadCapture(value, trackEvent, optimizelyTrackSiteEvents)
    // When a lead is captured from any source, hide PromoBanner lead form ECP-10763
    setIsSubmitted(true)
  }
  const handleError = () => {
    setIsSuccess(false)
    optimizelyTrackSiteEvents({ eventType: 'website_error' })
  }

  const handleEmailSubmit = (email: string) => {
    const leadGenParams: LeadGenCaptureProps = {
      email,
      promoCode: promoCode.getOrElse('NO_CODE'),
      source: 'footer',
      sourceType: 'footer'
    }

    trackEvent({ event: 'submit' })

    leadGenCapture(leadGenParams)()
      .then(res => {
        return fptsPipe(
          res,
          E.match(_ => {
            handleError()
            return {}
          }, handleSuccess)
        )
      })
      .catch(_ => handleError())
  }

  useCookieChange(COOKIE_LEAD_DATA, data =>
    setDefaultEmail(propOr('', 'email', JSON.parse(data)))
  )

  // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  const transformData = toFooterData(
    data,
    handleEmailSubmit,
    isSuccess,
    locale,
    defaultEmail
  )

  const onClickLink = useCallback((menuItem: MenuItem) => {
    navigate(menuItem.url || '', { state: { source: 'footer' } })
    prop('name', menuItem) && setLinkGtmCustomEvent(menuItem)
  }, [])

  const type = defaultTo('Full')(data.type)

  return (
    <Footer
      footerData={transformData.footerData}
      key="Footer"
      onClickLink={onClickLink}
      type={type}
    />
  )
}

export default FooterComponent
