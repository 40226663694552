import { ContentfulRichText } from '@ecomm/shared-components'
import { InformationIcon } from '@ecomm/shared-icons'
import { Modal } from '@ecomm/ss-react-components'
import React, { useState } from 'react'

import type { FeatureModalSchema } from './schema'

type Props = {
  readonly modal: FeatureModalSchema
  readonly onClick?: () => void
}

function FeatureModal({ modal, onClick }: Props) {
  const { headlineText, description, button } = modal
  const [open, setOpen] = useState(false)

  return (
    <>
      <button
        aria-label="More information"
        className="m-0 cursor-pointer border-none bg-transparent p-0"
        onClick={e => {
          e.preventDefault()
          onClick && onClick()
          !open && setOpen(true)
        }}
        role="button"
      >
        <InformationIcon className="-mb-1 h-5 w-5 lg:h-6 lg:w-6" />
      </button>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)} size="small">
        <div className="prose md:prose-md lg:prose-lg prose-p:leading-tight max-w-xs bg-neutral-50 px-4 pb-6 pt-6 md:max-w-md md:px-10 md:py-14">
          <h2 className="mb-4 text-2xl font-bold leading-normal md:mb-6">
            {headlineText}
          </h2>
          <ContentfulRichText {...description} />
          {button ? (
            <button
              className="btn btn-solid-primary mt-4 w-64 lg:w-full"
              onClick={() => setOpen(false)}
            >
              {button.text}
            </button>
          ) : null}
        </div>
      </Modal>
    </>
  )
}

export default FeatureModal
