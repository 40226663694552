import { NativeVideo } from '@ecomm/shared-components'
import { Link } from 'gatsby'
import React from 'react'
import type { TypeOf } from 'zod'

import type { schema } from './schema'

export type VideoBannerProps = TypeOf<typeof schema>

interface Props {
  readonly data: VideoBannerProps
}

function VideoBanner({ data }: Props) {
  const { badge, preTitle, title, text, buttonText, redirectUrl } = data
  return (
    <div
      className="bg-neutral-black grid grid-cols-1 gap-5 rounded-2xl px-4 py-8 md:p-8 lg:grid-cols-2 lg:p-12"
      data-component="VideoBanner"
    >
      <div className="m-auto w-full">
        <NativeVideo
          captionsSrcUrl={data.video.captionsSrc}
          poster={data.video.posterImage}
          videoSrcUrlMp4={data.video.mp4Link}
          videoSrcUrlWebm={data.video.webmLink}
        />
      </div>
      <div className="flex flex-col gap-4 md:mx-0 lg:order-first lg:max-w-[30rem]">
        <div className="bg-primary-100 w-fit rounded-full p-1 px-3 text-sm">
          {badge}
        </div>
        <h4 className=" m-0 text-xl font-bold text-white">{preTitle}</h4>
        <h2 className="max-md:text-heading-3-size max-md:leading-h3-height my-1 text-white">
          {title}
        </h2>
        <p className="text-white">{text}</p>
        <Link
          className="hover:text-btn-secondary w-full rounded-md border-2 border-solid border-white px-4 py-3 text-center text-white no-underline duration-200 ease-in-out lg:w-fit"
          to={redirectUrl}
        >
          {buttonText}
        </Link>
      </div>
    </div>
  )
}

export { VideoBanner }
