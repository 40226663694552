import { Modal, SSButton, Text } from '@ecomm/ss-react-components'
import React, { ReactNode, useState } from 'react'

import { StandardMonitoringModalContent } from '../StandardMonitoringBox/StandardMonitoringModalContent'

type StandardMonitoringBoxProps = {
  readonly atcButton?: ReactNode
  readonly price: string
}

export function StandardMonitoringBox({
  atcButton = null,
  price
}: StandardMonitoringBoxProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const modalButton = (
    <SSButton
      className="min-h-0 w-auto p-0 text-left"
      color="link"
      dataComponent="ModalButton"
      onClick={() => setIsModalOpen(true)}
    >
      Standard Monitoring {price}
    </SSButton>
  )

  return (
    <Text
      className="border-neutral-light-50 mt-6 border border-solid p-5 first:mt-0 md:px-10 lg:mt-8"
      useTailwind={true}
    >
      <p>
        We strongly recommend protecting your home with professional monitoring.
        Looking for a plan that dispatches police without camera recordings or
        video verification? Check out {modalButton}.
      </p>
      <Modal
        appElementId="___gatsby"
        dataComponent="StandardMonitoringModal"
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        size="medium"
      >
        <StandardMonitoringModalContent atcButton={atcButton} price={price} />
      </Modal>
    </Text>
  )
}
