import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const headerRedesignSchema = z.object({
  images: z.object({
    nodes: gatsbyImageSchema.extend({ contentful_id: z.string() }).array()
  }),
  quoteWizard: guidedSystemBuilderSchema
})

export type HeaderRedesignSchema = TypeOf<typeof headerRedesignSchema>
