import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { heroBannerSchema } from '@ecomm/shared-components'
import { coloredSectionSchema } from '@ecomm/shared-sections'
import { addVariationSchema } from '@ecomm/utils'
import { type TypeOf, z } from '@simplisafe/ewok'

import { layoutSchema } from '../../assemblies/Layout/layoutSchema'

export const shopPageLayout = addVariationSchema(
  layoutSchema
    .omit({ header: true })
    .extend({ components: z.array(coloredSectionSchema).nullish() })
)

const productSchema = z.object({
  slug: z.string(),
  name: z.string(),
  isNew: z.boolean(),
  image: gatsbyImageSchema
})

const shopPageSchema = z.object({
  contentful_id: z.string(),
  layout: shopPageLayout,
  heroBanner: heroBannerSchema.optional(),
  updatedAt: z.string(),
  links: z
    .object({
      contentful_id: z.string(),
      url: z.string(),
      image: gatsbyImageSchema,
      title: z.string()
    })
    .array()
    .optional(),
  id: z.string(),
  products: z.array(productSchema),
  title: z.string(),
  slug: z.string(),
  breadcrumbTitle: z.string().nullish(),
  mainTitle: z.string().nullish()
})

export type ShopPageSchema = TypeOf<typeof shopPageSchema>
export type ShopProductSchema = TypeOf<typeof productSchema>

export default shopPageSchema
