import { useLocale } from '@ecomm/data-hooks'
import { ProInstallPromotionBanner } from '@ecomm/promotions-components'
import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { Divider } from '@ecomm/ss-react-components'
import { SSButtonProps } from '@ecomm/ss-react-components/SSButton'
import { prop } from '@simplisafe/ewok'
import { isNotEmpty } from '@simplisafe/ewok'
import {
  selectCart,
  selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ContentfulProductCardAccessories } from 'apps/legacy/graphql'
import classNames from 'classnames'
import { isEmpty } from 'fp-ts/lib/Array'
import { Maybe } from 'monet'
import pipe from 'ramda/src/pipe'
import React, { ReactElement, ReactNode, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { CartApplyCoupon } from './cart-sections/CartApplyCoupon'
import { CartBelowContents } from './cart-sections/CartBelowContents'
import CartEmptyMessage from './cart-sections/CartEmptyMessage'
import CartFooter from './cart-sections/CartFooter'
import CartHeader from './cart-sections/CartHeader'
import CartPartnerCaptureFormSection from './cart-sections/CartPartnerCaptureFormSection'
import CartShippingInfo from './cart-sections/CartShippingInfo'
import CartSubtotal from './cart-sections/CartSubtotal'
import CartWarningMessages from './cart-sections/CartWarningMessages'
import CartWidgets from './cart-sections/CartWidgets'
import { CartLineItem } from './CartLineItem'
import { CartLineItemProps, DiscountProps } from './types'

export type LinkHeaderProps = {
  readonly linkText: string
  readonly linkUrl: string
}

export type CartDetailsProps = {
  readonly itemList: readonly CartLineItemProps[]
  readonly linkHeader: LinkHeaderProps
  readonly titleHeader: string
  readonly affirmPromoMessage?: ReactNode
  readonly affirmMonthlyOption?: ReactNode
  readonly showWarning?: boolean
  readonly cartCouponCode?: string
  readonly showMixedComponentsWarning?: boolean
  readonly mixedComponentsMessage?: ReactNode
  readonly withoutSystemMessage: ReactNode
  readonly freeShippingItem: CartLineItemProps
  readonly checkOutButtonHeader: SSButtonProps
  readonly checkOutButtonFooter: SSButtonProps
  readonly coreComponentNotInStock?: boolean
  readonly coreComponentShipEstimate?: ReactNode
  readonly coreComponentsShipDelayText?: ReactNode
  readonly subTotal?: string
  /** Data for empty Cart Message */
  /** The content of the empty cart */
  readonly emptyCartcontent?: ReactNode
  /** ReactElement to Render the  cartMiniBanner with iconRichText */
  readonly cartMiniBanner?: readonly ReactElement[]
  /** ReactElement to Render the  customerReviewCard for US Site  */
  readonly customerReviewCard?: ReactElement
  /** ReactElement to Render the  guaranteeCard for US Site*/
  readonly guaranteeCard?: ReactElement
  readonly affirmCard?: ReactNode
  readonly tryItTestItCard?: ReactNode
  readonly customerServiceCard?: ReactNode
  /** headerText for cartMiniBanner */
  readonly headerText?: string
  /* creditCardLogoImg reactElement for credit Card Logo Image */
  readonly creditCardLogo: readonly ReactElement[]
  readonly affirmMessage?: ReactElement
  /**  cartMonitoringPlan widget */
  readonly cartMonitoringPlan?: ReactElement
  /* Props related to discount code logic*/
  readonly discountProps: DiscountProps
  /** Renders the partner capture form modal based on partner type */
  readonly partnerCaptureForm?: ReactNode
  /** Component(s) to be rendered directly below the cart line item section */
  readonly belowCartContentsSection?: ReactNode
  /** Handles the applying coupon form display */
  readonly disabledCartSubmit?: boolean
  /** Renders warning about quantity limit for the product */
  readonly quantityLimitMessage?: ReactNode
  readonly mobileLink?: ReactNode
  readonly selfMonitoringInCart?: boolean
  /** Warning to render the newest security warning message */
  readonly showScoutWarning?: boolean
  /** Get max quantity to render the quantity error */
  readonly productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[]
  readonly getQuantityInCart: (sku: string) => number
}

export function CartContent({
  linkHeader,
  titleHeader,
  itemList: itemListProp,
  showWarning,
  showMixedComponentsWarning,
  tryItTestItCard,
  affirmCard,
  customerServiceCard,
  freeShippingItem,
  affirmPromoMessage,
  affirmMonthlyOption,
  coreComponentNotInStock = false,
  coreComponentShipEstimate,
  coreComponentsShipDelayText,
  checkOutButtonHeader,
  cartCouponCode = '',
  discountProps,
  checkOutButtonFooter,
  creditCardLogo,
  subTotal,
  quantityLimitMessage,
  mixedComponentsMessage,
  cartMonitoringPlan,
  partnerCaptureForm,
  belowCartContentsSection,
  withoutSystemMessage,
  disabledCartSubmit = false,
  selfMonitoringInCart,
  showScoutWarning,
  productMaxQuantity,
  getQuantityInCart
}: CartDetailsProps) {
  const isUs = useLocale() === 'en-US'

  const prods = useSelector(
    selectProducts((itemListProp || []).map(item => item.sku))
  )
  const itemList = (itemListProp || []).map(item => {
    const prod = prods.right().find(p => p.sku === item.sku)
    return {
      ...item,
      isIndeterminatePrice: prod?.isIndeterminatePrice ?? false
    }
  })

  const toCartLineItem = (item: CartLineItemProps, idx: number) =>
    item && (
      <CartLineItem
        getQuantityInCart={getQuantityInCart}
        item={item}
        key={idx}
        productMaxQuantity={productMaxQuantity}
      />
    )

  const getListItems = () =>
    itemList &&
    itemList
      // Removes line items that do not have an itemName.
      .filter(pipe(prop('itemName'), isNotEmpty))
      .map(toCartLineItem)

  const renderListItems = useMemo(getListItems, [itemList])

  const cart = useSelector(selectCart)
  const partnerMemberNumber = cart
    .flatMap(c => c.custom)
    .flatMap(c => Maybe.fromUndefined(c.fields.partnerMemberNumber))
  const partnerName = getValueFromPartnerCookie('partnerName')
  const isCydcorAttributionMissing =
    partnerName?.toLowerCase() === 'cydcor' && partnerMemberNumber.isNone()
  const [wasContinueToCheckoutClicked, setWasContinueToCheckoutClicked] =
    useState(false)
  const showAttributionMissingError =
    isCydcorAttributionMissing && wasContinueToCheckoutClicked

  if (isEmpty(itemList)) {
    return <CartEmptyMessage />
  }

  return (
    <div
      className="grid gap-x-28 gap-y-4 lg:grid-cols-10"
      data-component="CartContent"
    >
      <div className="order-1 lg:order-2 lg:col-span-3">
        <CartWidgets
          affirmCard={affirmCard}
          cartMonitoringPlan={cartMonitoringPlan}
          customerServiceCard={customerServiceCard}
          tryItTestItCard={tryItTestItCard}
        />
      </div>
      <div className="col-span-full lg:order-1 lg:col-span-7 lg:row-start-1 lg:row-end-3">
        <CartHeader
          checkoutButton={checkOutButtonHeader}
          disabledCartSubmit={disabledCartSubmit}
          errorMessage={
            showAttributionMissingError
              ? 'Please enter your attribution info'
              : undefined
          }
          link={linkHeader}
          onClickContinueToCheckout={() => {
            setWasContinueToCheckoutClicked(true)
            return !isCydcorAttributionMissing
          }}
          title={titleHeader}
        />
        <div className="lg:pt-[32px]">
          <CartWarningMessages
            mixedComponentsMessage={mixedComponentsMessage}
            quantityLimitMessage={quantityLimitMessage}
            selfMonitoringInCart={selfMonitoringInCart}
            showMixedComponentsWarning={showMixedComponentsWarning}
            showQuantityLimitMessage={!!quantityLimitMessage}
            showScoutWarning={showScoutWarning}
            showWithoutSystemMessage={showWarning}
          />
          <ProInstallPromotionBanner />
          {renderListItems}
          <CartPartnerCaptureFormSection>
            {partnerCaptureForm}
          </CartPartnerCaptureFormSection>
          {showAttributionMissingError ? (
            <div className="text:left text-sale mb-6 mt-4 text-base font-medium">
              Attribution info is required
            </div>
          ) : null}
          <CartApplyCoupon
            cartCouponCode={cartCouponCode}
            discountProps={discountProps}
          />
          {freeShippingItem ? (
            <CartLineItem
              item={freeShippingItem}
              productMaxQuantity={productMaxQuantity}
            />
          ) : undefined}

          {isUs ? (
            <div className="mt-6 lg:mt-8" data-component="Divider">
              <Divider />
            </div>
          ) : null}
          <CartSubtotal
            affirmMonthlyOption={affirmMonthlyOption}
            subtotal={subTotal}
          />
          {coreComponentNotInStock ? (
            <CartShippingInfo
              delayText={coreComponentsShipDelayText}
              estimateText={coreComponentShipEstimate}
            />
          ) : null}
        </div>

        <div
          className={classNames('flex flex-wrap md:justify-end', {
            'mt-6 lg:mt-2': isUs
          })}
        >
          <CartFooter
            affirmPromoMessage={affirmPromoMessage}
            checkOutButtonFooter={checkOutButtonFooter}
            creditCardLogo={creditCardLogo}
            disabledCartSubmit={disabledCartSubmit}
            errorMessage={
              showAttributionMissingError
                ? 'Please enter your attribution info'
                : undefined
            }
            link={linkHeader}
            onClickContinueToCheckout={() => {
              setWasContinueToCheckoutClicked(true)
              return !isCydcorAttributionMissing
            }}
          />
        </div>
      </div>
      <CartBelowContents
        content={belowCartContentsSection}
        itemList={itemList}
      />
    </div>
  )
}
