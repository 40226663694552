import { useCartState } from '@ecomm/data-cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'
import { match } from 'ts-pattern'

export const useIsCartLoaded = (useJotaiCart: boolean) => {
  const reduxCart = useSelector(selectCart)
  const reduxCartLoaded = reduxCart.cata(
    () => false,
    () => false,
    () => false,
    () => true
  )
  const jotaiCartState = useCartState()
  const jotaiCartLoaded = match(jotaiCartState)
    .with('empty', () => true)
    .with('with_items', () => true)
    .with('updating', () => false)
    .with('error', () => false)
    .with('no_cart_id', () => false)
    .with('not_initialized', () => false)
    .exhaustive()

  return useJotaiCart ? jotaiCartLoaded : reduxCartLoaded
}
