import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { footerSchema, heroBannerSchema } from '@ecomm/shared-components'
import { featureSectionSchema } from '@ecomm/shared-sections'
import { TypeOf, z } from '@simplisafe/ewok'

import { contentfulImageQuerySchema } from '../../schemas/contentfulImageQuery'
import { businessFormSchema } from '../../schemas/form'

export const parseBusinessSecuritySchema = <
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>(
  data: T
): BusinessSecuritySchema => {
  const { footer, content } = businessSecuritySchema.parse(data)

  return {
    footer,
    content
  }
}

const gridSchema = z.object({
  __typename: z.literal('ContentfulGrid'),
  gridItems: z
    .object({
      __typename: z.literal('ContentfulSmallText'),
      text: contentfulRichTextSchema
    })
    .array()
})

const packageGridSchema = z.object({
  __typename: z.literal('ContentfulGrid'),
  gridItems: z
    .object({
      __typename: z.literal('ContentfulPackage'),
      sku: z.string(),
      slug: z.string(),
      displayName: z.string(),
      image: contentfulImageQuerySchema,
      products: z.object({ __typename: z.string() }).array()
    })
    .array()
})

const imageLinkSchema = z.object({
  __typename: z.literal('ContentfulImageLink'),
  contentful_id: z.string(),
  title: z.string(),
  url: z.string(),
  image: contentfulImageQuerySchema
})

const smallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: contentfulRichTextSchema
})

const twoColumnSchema = z.object({
  __typename: z.literal('ContentfulTwoColumnSection'),
  leftSide: z.union([gridSchema, businessFormSchema]),
  rightSide: z.union([imageLinkSchema, smallTextSchema])
})

const layoutReferenceSchema = z.object({
  __typename: z.literal('ContentfulLayoutReference'),
  referenceId: z.string()
})

const contentCollectionSchema = z.object({
  __typename: z.literal('ContentfulContentCollection'),
  content: z
    .union([
      twoColumnSchema,
      smallTextSchema,
      featureSectionSchema,
      packageGridSchema,
      layoutReferenceSchema
    ])
    .array()
})

export const businessSecuritySchema = z.object({
  footer: footerSchema.nullish(),
  content: z.object({
    components: z
      .union([heroBannerSchema, contentCollectionSchema, layoutReferenceSchema])
      .array()
  })
})

export type PackageGridSchema = TypeOf<typeof packageGridSchema>
export type TwoColumnSchema = TypeOf<typeof twoColumnSchema>
export type ContentCollectionSchema = TypeOf<typeof contentCollectionSchema>
export type BusinessSecuritySchema = TypeOf<typeof businessSecuritySchema>
