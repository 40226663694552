import { OrderSummaryContainer } from '@ecomm/checkout/order-summary'
import {
  PAYMENT_OPTION_CARD,
  PAYMENT_OPTION_SAVED_CARD,
  PaymentFormWrapper,
  PaymentFormWrapperFragment,
  TermsOfSale,
  ZuoraSubmitButton
} from '@ecomm/checkout/payment-components'
import {
  usePayment,
  useUserSavedPaymentMethods
} from '@ecomm/checkout/payment-hooks'
import { useLocale } from '@ecomm/data-hooks'
import { TrackingData } from '@ecomm/tracking'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/lib/Option'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { debounce } from 'throttle-debounce'

export type PaymentPageWrapperProps = {
  readonly formData: PaymentFormWrapperFragment
  readonly isAffirmMonthlyExperiment: boolean
}

function PaymentPageWrapper({
  formData,
  isAffirmMonthlyExperiment
}: PaymentPageWrapperProps) {
  const { trackEvent } = useTracking()
  const cart = useSelector(selectCart)

  const isUs = useLocale() === 'en-US'

  const trackEventPaymentForm = useCallback(
    (args: TrackingData) => {
      trackEvent({ appSection: 'paymentForm', ...args })
    },
    [trackEvent]
  )

  const {
    handleSubmitAffirmOrder,
    errorMessage,
    handleZuoraFormRender,
    paymentState,
    refreshZuoraForm,
    safeTechSdkUrl,
    zuoraPaymentMethod,
    handleSubmitSavedZuoraPaymentOrder
  } = usePayment(cart, trackEventPaymentForm, isAffirmMonthlyExperiment)

  const { paymentMethods } = useUserSavedPaymentMethods(3)
  const savedPaymentMethods = isUs ? paymentMethods : []
  const hasSavedPaymentMethods = savedPaymentMethods.length > 0

  const [isZuoraPaymentSubmitted, setIsZuoraPaymentSubmitted] = useState(false)
  const [selectedSavedCardId, setSelectedSavedCardId] = useState<string>()

  const handleSelectedSavedCard = (paymentMethodId: string) =>
    setSelectedSavedCardId(paymentMethodId)

  const preselectedPaymentMethod = hasSavedPaymentMethods
    ? PAYMENT_OPTION_SAVED_CARD
    : PAYMENT_OPTION_CARD
  const [selectedPaymentOption, selectPaymentOption] = useState<
    TrackingData['selectedPaymentOption']
  >(preselectedPaymentMethod)

  const pageTitle = 'Payment'

  const submitZuoraNewCard = () => {
    window.Z && window.Z.submit()
  }

  const submitZuoraSavedCard = () => {
    const getDefaultSavedPaymentMethodId = () =>
      pipe(
        O.fromNullable(savedPaymentMethods.find(_ => true)),
        O.map(paymentMethod => paymentMethod.paymentProfileId)
      )

    pipe(
      O.fromNullable(selectedSavedCardId),
      O.alt(getDefaultSavedPaymentMethodId),
      O.match(
        // todo should not happen but handle
        () => void 0,
        pmId => handleSubmitSavedZuoraPaymentOrder(pmId)
      )
    )
  }

  const submitZuoraPaymentForm = debounce(500, () => {
    selectedPaymentOption === 'new card' && submitZuoraNewCard()
    selectedPaymentOption === 'saved card' && submitZuoraSavedCard()
    setIsZuoraPaymentSubmitted(true)
  })

  useEffect(() => {
    if (hasSavedPaymentMethods) {
      selectPaymentOption(PAYMENT_OPTION_SAVED_CARD)
    }
  }, [hasSavedPaymentMethods])

  return (
    <div
      className="flex flex-col lg:flex-row lg:justify-between lg:gap-8"
      data-component="PaymentPageWrapper"
    >
      <div className="w-full grow">
        <section className={'mx-auto mt-3 w-full'}>
          <div>
            <h1 className="my-2 text-4xl font-medium">{pageTitle}</h1>
            <span className="text-[#514F4C]">*Required fields</span>
          </div>
        </section>
        <div className="mt-4 flex flex-col justify-between lg:flex-row">
          <section className={'mr-2 w-full'}>
            <PaymentFormWrapper
              data={formData}
              errorMessage={errorMessage}
              handleSelectedSavedCard={handleSelectedSavedCard}
              handleSubmitAffirmOrder={handleSubmitAffirmOrder}
              handleZuoraFormRender={handleZuoraFormRender}
              isZuoraPaymentSubmitted={isZuoraPaymentSubmitted}
              paymentState={paymentState}
              refreshZuoraForm={refreshZuoraForm}
              safeTechSdkUrl={safeTechSdkUrl}
              savedPaymentMethods={savedPaymentMethods}
              selectPaymentOption={selectPaymentOption}
              selectedPaymentOption={selectedPaymentOption}
              setIsZuoraPaymentSubmitted={setIsZuoraPaymentSubmitted}
              submitZuoraPaymentForm={submitZuoraPaymentForm}
              zuoraPaymentMethod={zuoraPaymentMethod}
            />
          </section>
          <div className="lg:sticky lg:top-0 lg:h-full lg:w-1/2 lg:pl-20">
            <div className="mt-6 text-lg lg:mt-8">
              <h2
                className={`flex w-full shrink-0 items-center whitespace-nowrap text-xl font-medium md:text-2xl`}
              >
                Order summary
              </h2>
            </div>
            <div className="my-2.5 md:my-5">
              <OrderSummaryContainer />

              <div className="my-6 flex flex-col md:flex-row md:justify-between lg:flex-col">
                <div className="mb-6">
                  <TermsOfSale />
                </div>

                <ZuoraSubmitButton
                  className="w-full md:ml-16 md:w-72 lg:m-0 lg:w-full"
                  disabled={
                    isZuoraPaymentSubmitted ||
                    (selectedPaymentOption !== PAYMENT_OPTION_CARD &&
                      selectedPaymentOption !== PAYMENT_OPTION_SAVED_CARD)
                  }
                  onClick={submitZuoraPaymentForm}
                  trackingLabel="above"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentPageWrapper
