import { getNREUM } from '@ecomm/shared-window'
import { voidFn } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

// custom attribute names
export const HOST_PROVIDER_KEY = 'provider'

// values of `provider` custom attribute
export const ECOMM_AWS = 'ecomm-aws'

type NewRelicAgentApi<
  T extends {
    readonly setCustomAttribute: (key: string, providerValue: string) => void
  } = {
    readonly setCustomAttribute: (key: string, providerValue: string) => void
  }
> = NonNullable<T>

const setProviderAttribute =
  (providerValue: string) => (newrelicApi: NewRelicAgentApi) =>
    newrelicApi.setCustomAttribute(HOST_PROVIDER_KEY, providerValue)

/**
 * A helper function to initialize the Browser New Relic Agent with custom attributes.
 *
 * Custom attributes can be found at runtime by accessing: `window.newrelic.info.jsAttributes` or `window.NREUM.info.jsAttributes` from the console.
 */

export function hydrateCustomAttributes() {
  pipe(
    getNREUM(),
    O.bindTo('newrelicAgent'),
    O.fold(voidFn, ({ newrelicAgent }) => {
      setProviderAttribute(ECOMM_AWS)(newrelicAgent)
    })
  )
}
