import { SSButton } from '@ecomm/ss-react-components'
import { Script } from 'gatsby'
import React, { useState } from 'react'

import { VerifyPassDropdownSchema, VerifyPassOptionSchema } from './schema'

interface Props {
  readonly data: VerifyPassDropdownSchema
}
function VerifyPassDropdown({ data }: Props) {
  const [selectedOption, setSelectedOption] = useState<VerifyPassOptionSchema>(
    data.options[0]
  )
  const handleOptionClick = (index: number) => {
    const option = data.options[index]
    !!option && setSelectedOption(option)
  }

  return (
    <div>
      <Script
        data-key="e7d426c1b"
        src="https://cdn.verifypass.com/seller/launcher.js"
        strategy="idle"
      ></Script>
      <p>{data.headerText}</p>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="">
          <select
            className={
              'rounded-base block w-full border p-4 text-base font-normal  text-[#0f2544]  md:text-lg'
            }
            onChange={event => handleOptionClick(+event.target.value)}
          >
            {data.options.map((option, index) => (
              <option key={option.label} value={index}>
                {option.label}
              </option>
            ))}
          </select>
          <p className="md:hidden">{selectedOption?.extraText}</p>
        </div>
        <SSButton className={selectedOption.buttonClassName}>
          {selectedOption.buttonText}
        </SSButton>
      </div>
      <p className="hidden md:block">{selectedOption?.extraText}</p>
    </div>
  )
}

export default VerifyPassDropdown
