import { useLocale } from '@ecomm/data-hooks'
import React from 'react'

function DiscountBanner() {
  const isUs = useLocale() === 'en-US'

  return isUs ? (
    <div className="rounded-base border-neutral-light-50 bg-neutral-black flex flex-wrap items-center justify-center border border-solid p-8">
      <div className="flex flex-col">
        <div>
          <span className="fontSize44 neutralWhiteTextColor">10% Off</span>
          <span className="fontSize36 neutralWhiteTextColor fontWeightLight">
            {' '}
            for Fast Protect™ Monitoring subscribers
          </span>
        </div>
        <div className="text-center">
          <span className="fontWeightLight neutralWhiteTextColor text-center">
            Discount applies automatically in cart
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div className="rounded-base border-neutral-light-50 bg-neutral-black flex flex-wrap items-center justify-center border border-solid p-8">
      <div className="flex flex-col">
        <div>
          <span className="fontSize44 neutralWhiteTextColor">20% Off</span>
          <span className="fontSize36 neutralWhiteTextColor fontWeightLight">
            {' '}
            for Pro Premium Monitoring subscribers
          </span>
        </div>
        <div className="text-center">
          <span className="fontWeightLight neutralWhiteTextColor text-center">
            Discount applies automatically in cart
          </span>
        </div>
      </div>
    </div>
  )
}

export default DiscountBanner
