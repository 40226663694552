import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage } from '@ecomm/shared-components'
import { useOptimizelyTrackSiteEvents } from '@ecomm/tracking'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import { SmallTextSection } from '@ecomm/ss-react-components'
import type { SmallTextSectionProps } from '@ecomm/ss-react-components'
import classNames from 'classnames'
import { ContentfulRichTextGatsbyReference } from 'gatsby-source-contentful/rich-text'
import applySpec from 'ramda/src/applySpec'
import isEmpty from 'ramda/src/isEmpty'
import join from 'ramda/src/join'
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import trim from 'ramda/src/trim'
import React, { useCallback, useRef, useState } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulSmallTextSection } from '../../../graphql'
import useAffirmBannerIntersection from '../../hooks/useAffirmBannerIntersection'
import { TrackEvent } from '../../util/analytics'
import {
  nullToUndefined,
  strToCamelCase,
  toFirstCharLower
} from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'
import { PageProps } from '../Page'
import { useMediaQuery } from '@ecomm/shared-hooks'

// getClickableBannerEvent bannerClickEvent are temporary solutions
// to capture click tracking events for bofu optimizely experiments:
// All | Home, BMS, Shop | Affirm Banner
// All | Cart | Cart Confidence Bar Banners

export const getClickableBannerEvent = (contentfulId: string) => {
  const clickableBanners = {
    '0% Affirm banner': 'clickAffirmBanner',
    'Affirm card content': 'clickConfidenceBarBanner',
    'Buy now Affirm Banner': 'clickAffirmBanner',
    'Customer Service Card': 'clickConfidenceBarBanner',
    'Try It Test It Card': 'clickConfidenceBarBanner'
  }
  const map = new Map(Object.entries(clickableBanners))
  return map.get(contentfulId) || ''
}

export const bannerClickEvent = (
  trackEvent: TrackEvent,
  contentfulId: string
) => {
  trackEvent({
    action: 'click-banner',
    category: 'banner',
    event: getClickableBannerEvent(contentfulId),
    label: contentfulId
  })
}

type ContentfulSmallTextSectionProps = {
  readonly id: string
  readonly data: Partial<ContentfulSmallTextSection>
  readonly pageContext: PageProps['pageContext']
}

export const toUiTheme = (data: Partial<ContentfulSmallTextSection>) =>
  safeProp('uiTheme', data)
    // @ts-expect-error TS(2345) FIXME: Argument of type '(list: readonly unknown[]) => st... Remove this comment to see the full error message
    .map(toFirstCharLower)
    .map(split(':'))
    .map(x => x.map(trim))
    .map(join(''))
    .orUndefined()

const toBackgroundColor = (data: Partial<ContentfulSmallTextSection>) =>
  safeProp('backgroundColor', data).map(strToCamelCase).getOrElse('none')

const toShadowBorder = (data: Partial<ContentfulSmallTextSection>) =>
  safeProp('shadowBorder', data).map(strToCamelCase).orUndefined()

const getRichTextRaw = (
  { descriptionMobile, description }: Partial<ContentfulSmallTextSection>,
  showInMobile: boolean
): string => {
  const raw = showInMobile ? descriptionMobile?.raw : description?.raw

  return raw || description?.raw
}

const getRichTextReferences = (
  { descriptionMobile, description }: Partial<ContentfulSmallTextSection>,
  showInMobile: boolean
) => {
  const references = showInMobile
    ? descriptionMobile?.references
    : description?.references

  return references || description?.references || []
}

const customGatsbyImageRenderer: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const target = node.data.target
      const gatsbyImageData = target?.gatsbyImageData

      return gatsbyImageData ? (
        <GatsbyImage
          image={gatsbyImageData}
          style={{
            gridRow: '1/span 2',
            maxWidth: '11.75rem'
          }}
        />
      ) : target?.file?.url ? (
        <img src={target.file.url} />
      ) : null
    }
  }
}

const totextColor = pipe(path(['textColor', 'color']), nullToUndefined)
const toSmallTextSectionData = (
  data: Partial<ContentfulSmallTextSection>,
  showInMobile: boolean,
  pageContext: SmallTextSectionProps['pageContext']
): SmallTextSectionProps => {
  // TO-DO: Temporarily appending pageContext to references to avoid prop drilling it. We should not have data in pageContext, so this will need to change.
  const appendPageContext = (obj: ContentfulRichTextGatsbyReference) => ({
    ...obj,
    pageContext
  })

  return applySpec<SmallTextSectionProps>({
    backgroundColor: toBackgroundColor,
    borderLine: pipe(prop('borderLine'), nullToUndefined),
    content: data => {
      const raw = getRichTextRaw(data, showInMobile)
      // @ts-expect-error TS(2345) FIXME: Argument of type '(obj: ContentfulRichTextGatsbyRe... Remove this comment to see the full error message
      const references = getRichTextReferences(data, showInMobile).map(
        appendPageContext
      )

      return raw ? (
        <div className="">
          <ContentfulRichText
            optionsCustom={customGatsbyImageRenderer}
            raw={raw}
            references={references}
          />
        </div>
      ) : null
    },
    listStyle: pipe(prop('listStyle'), nullToUndefined),
    otherDeviceVisibility: pipe(prop('otherDeviceVisibility'), nullToUndefined),
    shadowBorder: toShadowBorder,
    sidePadding: pipe(prop('sidePadding'), nullToUndefined),
    textColor: totextColor,
    theme: toUiTheme,
    verticalPadding: pipe(prop('verticalPadding'), nullToUndefined)
  })(data)
}

// ss-react-components@5.2.0 is injecting a "listStyle" prop on content node the
// problem is that if content node is not a react element, this is an invalid prop
// this utility function checks if content is one of the listed DOM elements and then replaces the
// original content node with a react element that will parse the listStyle prop into a class
// otherwise, it will simply return the original content node
// ideally, this should code should live inside ss-react-components
export const handleListStyleProp = props => {
  const domNodes = ['div', 'span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']

  if (domNodes.includes(props.content?.type)) {
    const WrapperComponent = (injectedProps: {
      readonly [x: string]: unknown
    }) => {
      const { listStyle, className, ...otherProps } = injectedProps
      return React.cloneElement(props.content, {
        className: classNames(props.content.props.className, className, {
          'listStyleCheckmark ': listStyle === 'checkmark'
        }),
        ...otherProps
      })
    }
    return {
      ...props,
      content: <WrapperComponent />
    }
  } else {
    return props
  }
}

export default function SmallTextSections({
  data,
  pageContext
}: ContentfulSmallTextSectionProps) {
  const { trackEvent } = useTracking()
  const showInMobile = !useMediaQuery('TabletAndUp')
  const contentfulTitle = prop('title', data) || ''
  const clickEvent: string = getClickableBannerEvent(contentfulTitle)
  const textColor = data?.textColor?.color
  // @ts-expect-error
  const roundedCorners = data?.hasRoundedCorners
  const props = {
    ...toSmallTextSectionData(data, showInMobile, pageContext),
    pageContext
  }

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const [isAffirmImpacted, setAffirmImpacted] = useState(false)

  const intersectionRef = useRef(null)
  const intersection = useAffirmBannerIntersection(data.id, intersectionRef)

  const fireImpactedFlag = useCallback(() => {
    setAffirmImpacted(true)
    optimizelyTrackSiteEvents({ eventType: 'impacted_23160010080' })
  }, [optimizelyTrackSiteEvents])

  // Fire impacted flag for Affirm banners if it has not been done yet and they are in the viewport
  intersection &&
    intersection.intersectionRatio >= 1 &&
    !isAffirmImpacted &&
    fireImpactedFlag()

  return (
    //TO-DO: This is a hacky way to fix the wrong text visualization. RC SmallTextSection needs a refactor or to be removed since it's only
    //a wrapper now that only applies extra classes (tons of them). As it used a RichText before, it had the ability to style lists as well. So,
    //Frontend SmallTextSection should render lists with checkmarks (Checkout SmallTextSection.module.scss)
    <div
      className={classNames(
        { [`${textColor}TextColor`]: textColor },
        { 'rounded-base overflow-hidden': roundedCorners }
      )}
      ref={intersectionRef}
    >
      {!isEmpty(clickEvent) ? (
        <div
          onClick={() => {
            bannerClickEvent(trackEvent, contentfulTitle)
          }}
        >
          <SmallTextSection {...handleListStyleProp(props)} />
        </div>
      ) : (
        <SmallTextSection {...handleListStyleProp(props)} />
      )}
    </div>
  )
}
