import { ButtonFragment } from '@ecomm/shared-components'
import { SimpleButton } from '@ecomm/ss-react-components'
import { Link } from 'gatsby'
import React from 'react'

function HomeLandingButton({ url, buttonText }: ButtonFragment) {
  return (
    <div className="mx-auto" data-component={HomeLandingButton.name}>
      <Link to={url}>
        <SimpleButton variant="solid">{buttonText}</SimpleButton>
      </Link>
    </div>
  )
}

export default HomeLandingButton
