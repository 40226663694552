import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { gridSchema } from '@ecomm/shared-sections'
import { TypeOf, z } from '@simplisafe/ewok'

export const citiesGridSchema = gridSchema.extend({
  __typename: z.literal('ContentfulGrid'),
  gridItems: z
    .object({
      text: z.string(),
      url: z.string(),
      icon: gatsbyImageSchema
    })
    .array()
})

export type CitiesGridSchema = TypeOf<typeof citiesGridSchema>
