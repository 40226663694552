import { trackContinueToCheckout } from '@ecomm/cdp-tracking'
import { SSButton } from '@ecomm/ss-react-components'
import { SSButtonProps } from '@ecomm/ss-react-components/SSButton'
import {
  useTrackingCartContinueButton,
  useTrackingCartContinueShopping
} from '@ecomm/tracking'
import classNames from 'classnames'
import React from 'react'

export type CartHeaderProps = {
  readonly title: string
  readonly link: {
    readonly linkText: string
    readonly linkUrl: string
  }
  readonly checkoutButton: SSButtonProps
  readonly disabledCartSubmit?: boolean
  /** Handler for clicking checkout link. Return true to follow link and return false to remain on current page. */
  readonly onClickContinueToCheckout?: () => boolean
  readonly errorMessage?: string
}

function Header({
  title,
  link,
  checkoutButton,
  disabledCartSubmit,
  onClickContinueToCheckout = () => true,
  errorMessage
}: CartHeaderProps) {
  const trackContinueButton = useTrackingCartContinueButton()
  const trackContinueShopButton = useTrackingCartContinueShopping()

  return (
    <div className="md:border-y-0 md:px-0 md:pb-[25px] md:pt-[35px] lg:px-0 lg:pb-[25px] lg:pt-0">
      <div className="md:flex md:items-center md:justify-between ">
        <h2 className={classNames('h3', 'text-[24px] md:mb-0 md:text-[36px]')}>
          {title}
        </h2>
        <div className="flex items-center">
          <div
            className="flex"
            data-component="CartContinueShoppingWrapper"
            onClick={() => !disabledCartSubmit && trackContinueShopButton()}
          >
            <SSButton
              className="mr-[16px] min-h-[auto] w-auto p-0 text-[#006fee]"
              color="link"
              href={link?.linkUrl}
              type="link"
            >
              {link?.linkText}
            </SSButton>
          </div>

          <div
            className="hidden items-center justify-between md:!flex"
            data-component="CartButtonWrapperHeader"
            onClick={e => {
              const shouldContinue =
                !disabledCartSubmit && onClickContinueToCheckout()
              shouldContinue && trackContinueButton()
              shouldContinue && trackContinueToCheckout()
              !shouldContinue && e.preventDefault()
            }}
          >
            <SSButton
              {...checkoutButton}
              className="min-h-[auto] w-auto"
              disabled={disabledCartSubmit}
              type="link"
            />
          </div>
        </div>
      </div>
      {errorMessage ? (
        <div className="text:left text-sale mt-4 hidden text-base font-medium md:block md:text-right">
          {errorMessage}
        </div>
      ) : null}
    </div>
  )
}

export default Header
