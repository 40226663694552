import { prop } from '@simplisafe/ewok'
import React, { ErrorInfo, ReactNode } from 'react'

import { logError } from './logError'

type Props = {
  readonly children: ReactNode
}

type State = {
  readonly hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(__error: string) {
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, {
      componentStack: prop('componentStack', errorInfo)
    })
  }

  override render() {
    return this.state.hasError ? (
      <div className="rounded-base bg-neutral-light-100 overflow-hidden px-4 py-8 text-center md:py-8">
        <div
          className="breakLine prose md:prose-md lg:prose-lg max-w-none"
          data-component="Text"
        >
          <h2>Something went wrong.</h2>
          <p className="text-center">Try reloading the page.</p>
        </div>
      </div>
    ) : (
      this.props.children
    )
  }
}

export { ErrorBoundary }
