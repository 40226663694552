//@ts-nocheck
import assoc from 'ramda/src/assoc'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import React, { FC, ReactElement } from 'react'

import CarouselContainer, {
  SSCarouselContainerProps
} from '../CarouselContainer'
import FloatingBadge, { FloatingBadgeProps } from '../FloatingBadge'
import HeroSlide, { HeroSlideProps, LinkItem } from '../HeroSlide'
import useMediaQuery from '../hooks/useMediaQuery'
import * as css from './Carousel.module.scss'

export type CarouselProps = {
  readonly slideData: readonly HeroSlideProps[]
  readonly slideMobileData?: readonly HeroSlideProps[]
  readonly carouselContainerData: SSCarouselContainerProps
  readonly mediaType?: 'image' | 'video'
  readonly floatingBadge?: FloatingBadgeProps
  readonly onClickLink?: (linkItem: LinkItem) => void
  readonly enableInlineArrow?: boolean
  readonly dataComponent?: string
}

export const appElementId = 'carousel-modal'

const getSlide = (
  slideProps: HeroSlideProps,
  onClickLink: ((linkItem: LinkItem) => void) | undefined
): ReactElement => {
  return <HeroSlide {...slideProps} key={slideProps.id} onClick={onClickLink} />
}

/** @deprecated Do not use ss-react-components*/
const Carousel: FC<CarouselProps> = ({
  slideData,
  carouselContainerData,
  mediaType = 'image',
  slideMobileData,
  floatingBadge,
  onClickLink,
  enableInlineArrow,
  dataComponent = Carousel.name
}: CarouselProps) => {
  const isTabletUp = useMediaQuery('TabletAndUp')

  // TODO refactor to use children
  const slides = React.useMemo(() => {
    const slideListData =
      isTabletUp || isNil(slideMobileData) || isEmpty(slideMobileData)
        ? slideData
        : slideMobileData
    return slideListData.map((slideValue: HeroSlideProps) => {
      const newSlideValue = assoc('mediaType', mediaType, slideValue)
      return getSlide(newSlideValue, onClickLink)
    })
  }, [isTabletUp, slideMobileData, slideData, mediaType, onClickLink])
  const playButton = isTabletUp && carouselContainerData.playButton
  const playSpeed = carouselContainerData.autoPlaySpeed
  return (
    <div className={css.imgVideo} data-component={dataComponent}>
      <CarouselContainer
        {...carouselContainerData}
        autoPlaySpeed={playSpeed}
        autoplay={true}
        enableInlineArrow={enableInlineArrow}
        playButton={playButton}
        slides={slides}
      />
      {!isNil(floatingBadge) && (
        <FloatingBadge className={css.badge} {...floatingBadge} />
      )}
    </div>
  )
}

export default Carousel
