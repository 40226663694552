import {
  fetchUserPaymentMethods,
  PaymentMethod
} from '@ecomm/data-simplisafe-api'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useEffect, useState } from 'react'

const useUserSavedPaymentMethods = (
  limit = 3,
  onSuccess?: (paymentMethods: readonly PaymentMethod[]) => void
) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()
  const [paymentMethods, setPaymentMethods] = useState<
    readonly PaymentMethod[]
  >([])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    pipe(
      await fetchUserPaymentMethods()(),
      E.match(
        (rsp: Error) => {
          setError(rsp)
        },
        rsp => setPaymentMethods(filterPaymentMethods(rsp).slice(0, limit))
      )
    )
    setLoading(false)
  }

  const filterPaymentMethods = (payments: readonly PaymentMethod[]) =>
    payments.filter(
      payment => isZuoraCreditCard(payment) && isActivePayment(payment)
    )

  const isZuoraCreditCard = (payment: PaymentMethod) =>
    payment.processor === 'zuora' && payment.paymentType === 'credit'

  const isActivePayment = (payment: PaymentMethod) => {
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth() + 1

    return (
      payment.expYear > currentYear ||
      (payment.expYear === currentYear && payment.expMonth >= currentMonth)
    )
  }

  paymentMethods && onSuccess && onSuccess(paymentMethods)

  return {
    paymentMethods,
    loading,
    error
  }
}

export { useUserSavedPaymentMethods }
