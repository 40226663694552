import { contentfulImageSchema } from '@ecomm/contentful/components'
import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { packageSchema } from '@ecomm/partners-hooks'
import {
  contentfulRichTextSchema,
  footerSchema,
  headerSchema,
  heroBannerSchema,
  imageWithArtDirectionSchema
} from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

import { embeddedPartnerHeader } from '../components/EmbeddedPartnerHeader/schema'

export const partnerTemplateResponseSchema = z.object({
  __typename: z.literal('ContentfulPartnerPageTemplateV2'),
  id: z.string(),
  content: z.array(
    z.object({
      content: z.array(packageSchema)
    })
  ),
  pageTitle: z.string(),
  header: headerSchema,
  heroBanner: heroBannerSchema.nullable(),
  heroText: contentfulRichTextSchema.nullable(),
  heroImage: imageWithArtDirectionSchema.nullable(),
  footer: footerSchema
})

const partnerGroupSchema = z.enum([
  'airlines',
  'insurance',
  'small-business',
  'standard-partner',
  'selective',
  'direct-mail',
  'partner-strategic-referral',
  'refer-a-friend',
  'scout-influencer',
  'influencer',
  'leads',
  'referral',
  'acquisition'
])

const seoSchema = z.object({
  metaKeywords: z.string().array().nullish(),
  metaTitle: z.string(),
  isNoindex: z.boolean(),
  isNofollow: z.boolean(),
  canonicalLink: z.string(),
  metaDescription: z
    .object({
      metaDescription: z.string()
    })
    .nullish()
})

export const partnerPageResponseSchema = z.object({
  __typename: z.literal('ContentfulPartnerPageV2'),
  contentful_id: z.string(),
  pageTitle: z.string().nullable(),
  partnerName: z.string(),
  partnerGroup: z
    .string()
    .transform(val => val.toLowerCase().replaceAll(' ', '-'))
    .pipe(partnerGroupSchema),
  partnerPageTemplate: partnerTemplateResponseSchema,
  heroBanner: heroBannerSchema.nullable(),
  heroText: contentfulRichTextSchema.nullable(),
  heroImage: imageWithArtDirectionSchema.nullable(),
  coBrandLogo: gatsbyImageSchema.nullable(),
  coBrandTitle: z.string().nullable(),
  termsAndConditionsPosition: z.enum([
    'None',
    'Co-Brand Box',
    'Hero Image',
    'Bottom'
  ]),
  termsAndConditionsTitle: z.string().nullable(),
  termsAndConditions: contentfulRichTextSchema.nullable(),
  content: z.array(
    z.object({
      content: z.array(packageSchema)
    })
  ),
  showPartnerPackageAbsoluteDiscountAsRelative: z
    .boolean()
    .nullable()
    .default(false)
})

const contentCollectionCarouselSchema = z.object({
  __typename: z.literal('ContentfulCarousel'),
  items: z
    .object({
      image: contentfulImageSchema
    })
    .array()
})

const carouselSchema = z.object({
  __typename: z.literal('ContentfulCarousel'),
  title: z.string(),
  items: z
    .object({
      headline: z.string(),
      description: z.object({
        raw: z.string()
      }),
      image: gatsbyImageSchema
    })
    .array()
})

const smallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: z.object({
    raw: z.string(),
    references: z.array(
      z.object({
        __typename: z.string(),
        type: z.string()
      })
    )
  })
})

export const formQuerySchema = z.object({
  __typename: z.literal('ContentfulFormV2'),
  contentful_id: z.string().optional(),
  fields: z
    .object({
      label: z.string(),
      placeholder: z.string(),
      type: z.enum(['Text', 'Email', 'Telephone', 'Dropdown']),
      name: z.string()
    })
    .array(),
  button: z.object({
    text: z.string()
  })
})

const contentCollectionSchema = z.object({
  content: z.tuple([
    contentCollectionCarouselSchema,
    smallTextSchema,
    smallTextSchema,
    smallTextSchema,
    smallTextSchema,
    smallTextSchema
  ])
})

const twoColumnSectionSchema = z.object({
  __typename: z.literal('ContentfulTwoColumnSection'),
  rightSide: contentCollectionSchema,
  leftSide: z.object({
    content: z.tuple([smallTextSchema, formQuerySchema])
  })
})

const layoutReferenceSchema = z.object({
  __typename: z.literal('ContentfulLayoutReference'),
  referenceId: z.string()
})

export const embeddedPartnerPageSchema = z.object({
  contentfulEmbeddedPartnerPage: z.object({
    __typename: z.literal('ContentfulEmbeddedPartnerPage'),
    pageTitle: z.string(),
    partnerName: z.string(),
    layout: z.object({
      header: embeddedPartnerHeader,
      footer: footerSchema.optional(),
      components: z
        .array(
          z.union([
            carouselSchema,
            twoColumnSectionSchema,
            layoutReferenceSchema,
            smallTextSchema
          ])
        )
        .default([])
    }),
    seo: seoSchema
  })
})

export type ContentCollectionSchema = TypeOf<typeof contentCollectionSchema>
export type CarouselSchema = TypeOf<typeof carouselSchema>
