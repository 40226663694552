import * as Icons from '@ecomm/shared-icons'
import React from 'react'
import { TypeOf } from 'zod'

import { listItemSchema } from './schema'

type ConfidenceItemProps = TypeOf<typeof listItemSchema>

function ConfidenceItem({
  icon,
  headline,
  mobileHeadline,
  body,
  disclaimer
}: ConfidenceItemProps) {
  const Icon = Icons[icon]
  return (
    <div
      className="flex w-full flex-row items-start gap-4 lg:flex-col lg:gap-0"
      data-component="ConfidenceItem"
      key={headline}
    >
      <div className="flex flex-col">
        <Icon className="text-neutral-black my-2" height={40} width={40} />
      </div>
      <div className="text-neutral-black flex flex-col self-center">
        <h3 className="my-2 hidden text-base font-bold md:text-lg lg:block">
          {headline}
        </h3>
        <h3 className="my-2 text-base font-bold md:text-lg lg:hidden">
          {mobileHeadline ?? headline}
        </h3>
        {body && <p className="my-2 hidden text-lg lg:block">{body}</p>}
        {disclaimer && <p className="my-0 text-xs">{disclaimer}</p>}
      </div>
    </div>
  )
}

export default ConfidenceItem
