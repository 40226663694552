import { useCurrentPromoBannerData } from '@ecomm/promotions-hooks'

import { LeadCapture as LeadCaptureForm } from '../../LeadCaptureForm'
import { LeftLayout } from './LeftLayout'
import { RightLayout } from './RightLayout'

export function ExpandedContent() {
  const { hasCountdown, mobileBannerImage } = useCurrentPromoBannerData()

  const showLeftLayout = mobileBannerImage || hasCountdown

  return (
    <div data-component="ExpandedContent">
      <div className="flex items-center p-5 md:px-16 md:py-6">
        {showLeftLayout ? <LeftLayout hasCountdown={hasCountdown} /> : null}
        <RightLayout />
      </div>
      <LeadCaptureForm />
    </div>
  )
}
