import { useMicroCopy } from '@ecomm/micro-copy'
import { AffirmClient, AffirmLogo } from '@ecomm/shared-components'
import { useOptimizelyTrackSiteEvents } from '@ecomm/tracking'
import { brazeLogCustomEvent } from '@ecomm/tracking'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

export type AffirmCheckoutContentProps = {
  readonly affirmClient?: AffirmClient
  readonly onOrderSubmit: () => void
}

const AFFIRM_CHECKOUT_ID = 'affirm-checkout'

export function AffirmCheckoutContent({
  // FIXME move functions that directly access window to `@ecomm/shared-window`
  // @ts-ignore
  affirmClient = window.affirm,
  onOrderSubmit
}: AffirmCheckoutContentProps) {
  const microCopy = useMicroCopy()
  const { Track, trackEvent } = useTracking()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const onCheckoutButtonClick = useCallback(() => {
    brazeLogCustomEvent('affirm_modal_clicked')
    trackEvent({ event: 'affirmCheckoutClick' })
    optimizelyTrackSiteEvents({ eventType: 'select_affirm_in_checkout' })
    onOrderSubmit()
  }, [onOrderSubmit, optimizelyTrackSiteEvents, trackEvent])

  return affirmClient ? (
    <Track>
      <div className="pt-3" id={AFFIRM_CHECKOUT_ID}>
        <p className="mt-0 md:text-lg">
          {microCopy['affirm-checkout-description']}
        </p>

        <div key="affirm-checkout-link">
          <a
            className="
              rounded-base border-btn-dark flex w-56 items-baseline justify-center border
              border-solid border-gray-400 bg-transparent py-2 text-base text-black no-underline"
            href={`#${AFFIRM_CHECKOUT_ID}`}
            onClick={onCheckoutButtonClick}
          >
            <span className="pr-1 text-base font-medium">
              {microCopy['check-out-with']}
            </span>
            <AffirmLogo height="18px" width="45px" />
          </a>

          <p className="text-sm">{microCopy['redirect-to-affirm-purchase']}</p>
        </div>
      </div>
    </Track>
  ) : null
}
