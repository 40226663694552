import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { gatsbyImageDataSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const iconSchema = gatsbyImageSchema.extend({
  url: z.string(),
  gatsbyImageData: gatsbyImageDataSchema.nullish()
  // gatsbyImageData: gatsbyImageDataSchema
})

export const gridSchema = z.object({
  headline: z.string().nullish(),
  headlineAlignment: z.enum(['left', 'center', 'right']).nullish(),
  showBorders: z.boolean().nullish(),
  showTextOnMobile: z.boolean().nullish(),
  className: z.string().optional(),
  gridItems: z
    .object({
      text: z.union([contentfulRichTextSchema, z.string()]),
      icon: iconSchema.nullish(),
      url: z.string().nullish(),
      internalTitle: z.string().nullish()
    })
    .array(),
  desktopColumns: z.string().nullable(),
  tabletColumns: z.string().nullable(),
  mobileColumns: z.string().nullable(),
  __typename: z.literal('ContentfulGrid')
})

export type GridSchema = TypeOf<typeof gridSchema>
export type IconSchema = TypeOf<typeof iconSchema>
