import classNames from 'classnames'
import React from 'react'

import { AffirmOptionType } from '../../../types/affirm'
import { MonthlyOptionHeader } from './MonthlyOptionHeader'
import { MonthlyOptionPrice } from './MonthlyOptionPrice'
import { MonthlyOptionSubText } from './MonthlyOptionSubText'

type Props = {
  readonly affirmOption: AffirmOptionType
  readonly onClick: () => void
  readonly payAsLowAsMicroCopy: string
  readonly payOverTimeMicroCopy: string
  readonly priceInCents: number
  readonly showMonthlyPrice: boolean
  readonly stacked?: boolean
  readonly discountedPrice?: number
  readonly totalPrice: number
  readonly isPlp?: boolean
  readonly useSystemTermMonths?: boolean
  readonly isAffirmMonthlyExperiment?: boolean
}

export function MonthlyOption({
  affirmOption,
  onClick,
  payAsLowAsMicroCopy,
  payOverTimeMicroCopy,
  priceInCents,
  stacked,
  showMonthlyPrice,
  discountedPrice,
  totalPrice,
  isPlp,
  useSystemTermMonths = true,
  isAffirmMonthlyExperiment = false
}: Props) {
  return (
    <div
      className={classNames('w-full py-1', {
        'md:w-1/2': !stacked
      })}
      data-component={affirmOption}
    >
      <div
        className={classNames(
          'flex',
          isPlp
            ? 'flex-col gap-2 [&>*]:my-1'
            : 'min-h-[28px] flex-row content-center justify-between'
        )}
      >
        {
          <MonthlyOptionHeader
            affirmOption={affirmOption}
            isPlp={isPlp}
            onClick={onClick}
            payAsLowAsMicroCopy={payAsLowAsMicroCopy}
            payOverTimeMicroCopy={payOverTimeMicroCopy}
            priceInCents={priceInCents}
            useSystemTermMonths={useSystemTermMonths}
          />
        }
        {showMonthlyPrice ? (
          <MonthlyOptionPrice
            affirmOption={affirmOption}
            discountedPrice={discountedPrice}
            isAffirmMonthlyExperiment={isAffirmMonthlyExperiment}
            isPlp={isPlp}
            totalPrice={totalPrice}
            useSystemTermMonths={useSystemTermMonths}
          />
        ) : null}
      </div>
      {
        <MonthlyOptionSubText
          affirmOption={affirmOption}
          isAffirmMonthlyExperiment={isAffirmMonthlyExperiment}
          isPlp={isPlp}
          onClick={onClick}
          priceInCents={priceInCents}
          useSystemTermMonths={useSystemTermMonths}
        />
      }
    </div>
  )
}
