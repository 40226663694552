import { useLocale } from '@ecomm/data-hooks'
import {
  useCurrentPromoBannerData,
  useDisplayMonitoringDiscount,
  usePromoDiscountText,
  usePromoWithMonitoringDiscountText,
  useTopBannerVisible
} from '@ecomm/promotions-hooks'
import {
  HeroBanner,
  HeroBannerProps,
  HeroBannerSchema
} from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useMediaQuery } from '@ecomm/ss-react-components'
import React, { useEffect, useState } from 'react'

import { HeroVideoExperimentWrapper } from '../../experiments/heroVideo/HeroVideoExperimentWrapper'

//This component was meant to extract the business logic from the shared HeroBanner component and to prevent having too many lines of code in the template
export default function HomeLandingHeroBanner({
  extraContent,
  ...heroBannerProps
}: HeroBannerProps) {
  const locale = useLocale()
  const isDesktop = useMediaQuery('DesktopAndUp')
  const isPromoTopBanner = useTopBannerVisible()

  const [placeholders, setPlaceholders] = useState<string | null>(null)
  const displayMonitoringDiscount = useDisplayMonitoringDiscount()
  const discountTextWithMonitoring = usePromoWithMonitoringDiscountText()
  const discountTextWithoutMonitoring = usePromoDiscountText()
  const discountText = displayMonitoringDiscount
    ? discountTextWithMonitoring
    : discountTextWithoutMonitoring

  const { heroStickerImage } = useCurrentPromoBannerData()

  const mappedMainExperiences = mapExperiences<HeroBannerSchema>(
    heroBannerProps.nt_experiences
  )

  useEffect(() => {
    discountText.cata(
      () => setPlaceholders(null),
      text => setPlaceholders(text.replace('%', ''))
    )
  }, [discountText])

  const discountPercentageText = !isPromoTopBanner ? placeholders : null

  return locale === 'en-US' ? (
    <Experience
      {...heroBannerProps}
      component={
        heroBannerProps.backgroundVideo
          ? HeroVideoExperimentWrapper
          : HeroBanner
      }
      experiences={mappedMainExperiences}
      id={heroBannerProps.id}
      key={heroBannerProps.id}
      passthroughProps={{
        floatingBadgeDiscount: discountPercentageText,
        heroStickerImage: heroStickerImage,
        isFullWidth: isDesktop ? heroBannerProps.isFullWidth : true,
        extraContent: extraContent
      }}
    />
  ) : (
    <HeroBanner
      {...heroBannerProps}
      floatingBadgeDiscount={discountPercentageText}
      heroStickerImage={heroStickerImage}
      isFullWidth={isDesktop ? heroBannerProps.isFullWidth : true}
    />
  )
}
