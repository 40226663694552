import React, { FC, ReactNode } from 'react'

import AdditionalOptionItems from '../AdditionalOptionItems'
import { AdditionalOptionItemsProps } from '../AdditionalOptionItems/types'

export type MiniCartOptionsProps = {
  readonly additionalOptionItemsProps: AdditionalOptionItemsProps
  readonly dataComponent?: string
  readonly totalPrice?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const MiniCartOptions: FC<MiniCartOptionsProps> = ({
  additionalOptionItemsProps,
  dataComponent = MiniCartOptions.name,
  totalPrice
}: MiniCartOptionsProps) => (
  <div data-component={dataComponent}>
    <AdditionalOptionItems
      theme="column"
      {...additionalOptionItemsProps}
      totalPrice={totalPrice}
    />
  </div>
)

export default MiniCartOptions
