//@ts-nocheck
import classNames from 'classnames'
import React, {
  ComponentType,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'

import { IconWithTextSize } from '../IconWithText'
import SSButton, { ButtonColor } from '../SSButton'
import * as css from './TestimonialBanner.module.scss'

export type CompanyReview = {
  readonly companyName: string
  readonly companyReview: string
  readonly companyIcon?: ReactElement | ReactNode
  readonly iconSize?: IconWithTextSize
}

export type TestimonialBannerProps = {
  /** An optional class to apply */
  readonly className?: string
  readonly companyReviewList: readonly CompanyReview[]
  /** @deprecated A CTA button. Use buttonElement instead, to be able to embed Gatsby links in it. */
  readonly button?: {
    readonly text: string
    readonly url: string
    readonly color: ButtonColor
  }
  /** ReactElement that will be rendered at the bottom of this component. Intended to be used as a CTA button */
  readonly buttonElement?: ReactElement
  /** Data-component attribute value */
  readonly dataComponent?: string
  /** To get the gatsby-background-image component for rendering background banner image */
  readonly BackgroundComponent?: ComponentType<{
    readonly [x: string]: unknown
  }>
  /** Props of gatsby-background-image component for rendering background banner image */
  readonly backgroundComponentProps?: Record<string, unknown>
  readonly sidePaddingSize?: SidePaddingSize
}

const timers = 3000

export const getIconClassNames = (iconSize?: IconWithTextSize) => ({
  [css.small]: iconSize === 'small',
  [css.medium]: iconSize === 'medium',
  [css.large]: iconSize === 'large',
  [css.responsive]: iconSize === 'responsive'
})

type SidePaddingSize = 'large' | 'medium' | 'small'

export const getSidePaddingClassNames = (size?: SidePaddingSize) => ({
  [css.mediumSidePadding]: size === 'medium',
  [css.largeSidePadding]: size === 'large'
})

// eslint-disable-next-line @typescript-eslint/ban-types -- legacy code
const renderLogoItems = (
  companyList: readonly CompanyReview[],
  updateReviewText: Function,
  highlightLogo: Function
) => {
  return companyList.map((company: CompanyReview, index: number) => {
    return company['companyIcon'] ? (
      <li key={company['companyName']}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
        <a
          className={classNames(
            highlightLogo(index),
            getIconClassNames(company.iconSize)
          )}
          href="#"
          onBlur={() => updateReviewText(0, false)}
          onClick={e => e.preventDefault()}
          onFocus={() => updateReviewText(index, true)}
          onMouseEnter={() => updateReviewText(index, true)}
          onMouseLeave={() => updateReviewText(0, false)}
        >
          {company.companyIcon}
        </a>
      </li>
    ) : (
      <li key={company['companyName']}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
        <a
          className={classNames(
            highlightLogo(index),
            'h4',
            getIconClassNames(company.iconSize)
          )}
          href="#"
          onBlur={() => updateReviewText(0, false)}
          onClick={e => e.preventDefault()}
          onFocus={() => updateReviewText(index, true)}
          onMouseEnter={() => updateReviewText(index, true)}
          onMouseLeave={() => updateReviewText(0, false)}
        >
          {company['companyName']}
        </a>
      </li>
    )
  })
}

/** @deprecated Do not use ss-react-components*/
const TestimonialBanner: FC<TestimonialBannerProps> = ({
  className,
  companyReviewList,
  button,
  buttonElement,
  BackgroundComponent,
  backgroundComponentProps,
  dataComponent = TestimonialBanner.name,
  sidePaddingSize
}: TestimonialBannerProps) => {
  const [isHover, setIsHover] = useState(false)
  const [hoverIndex, setHoverIndex] = useState(0)

  const updateReviewText = useCallback((index: number, setHover: boolean) => {
    setHoverIndex(index)
    setIsHover(setHover)
  }, [])

  const highlightLogo = (index: number) => {
    return !isHover && hoverIndex === index ? css.highlight : css.halfOpacity
  }

  useEffect(() => {
    const loops =
      !isHover &&
      setInterval(() => {
        const index = (hoverIndex + 1) % companyReviewList.length
        setHoverIndex(index)
      }, timers)

    return () => {
      loops && clearInterval(loops)
    }
  }, [hoverIndex, isHover, companyReviewList])

  const renderTestimonialContent = () => {
    return (
      <>
        <div
          className={classNames(
            css.testimonialsSection,
            getSidePaddingClassNames(sidePaddingSize)
          )}
        >
          <div className={classNames(css.testimonialsActive)}>
            <h2
              aria-live={isHover ? 'polite' : 'off'}
              className={classNames('h2', 'alignCenter')}
            >
              {companyReviewList[hoverIndex].companyReview}
            </h2>
          </div>
          <ul>
            {renderLogoItems(
              companyReviewList,
              updateReviewText,
              highlightLogo
            )}
          </ul>
        </div>
        {button && (
          <div className={classNames(css.btnSize)}>
            <SSButton
              className={classNames(css.addtoCart)}
              color={button.color}
              href={button.url}
              type="link"
            >
              {button.text}
            </SSButton>
          </div>
        )}
        {buttonElement && (
          <div className={classNames(css.btnSize)}>{buttonElement}</div>
        )}
      </>
    )
  }

  return BackgroundComponent ? (
    <BackgroundComponent
      {...backgroundComponentProps}
      className={classNames(
        'paddingLarge rounded-base bg-neutral-light-100 overflow-hidden',
        className
      )}
      data-component={dataComponent}
    >
      {renderTestimonialContent()}
    </BackgroundComponent>
  ) : (
    <div
      className={classNames(
        'rounded-base bg-neutral-light-100 overflow-hidden',
        css.wrapper,
        className
      )}
      data-component={`${dataComponent}_noBackground`}
    >
      {renderTestimonialContent()}
    </div>
  )
}

export default TestimonialBanner
