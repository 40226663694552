import { trackPage as trackPageCDP } from '@ecomm/cdp-tracking'
import { useRefreshCookieAtom } from '@ecomm/data-storage'
import {
  getCookie,
  getLogoLinkUrl,
  getPartnerGroup,
  getPartnerName
} from '@ecomm/shared-cookies'
import { getDeployEnv } from '@ecomm/utils'
import {
  get as sessionStorageGet,
  set as sessionStorageSet
} from '@ecomm/utils'
import { useLocation } from '@reach/router'
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useTracking } from 'react-tracking'

import { brazeTrackPageVisit, initBraze } from '../braze'
import {
  generateFacebookEventId,
  useFacebookTrackSiteVisits
} from '../facebook'
import { handleTrackingEvent } from '../handleTrackingEvent'
import { useOptimizelyTrackPageVisit } from '../optimizely'
import {
  nonBounceSessionWasNotTracked,
  trackNonBounceVisit
} from '../trackNonBounceVisit'
import { ReferrerUrlContext } from './useReferrerUrl'

type Props = {
  readonly children: ReactNode
  readonly metaTitle: string
}

export const SESSION_STORAGE_FIRST_VISITED_URL = 'firstVisitedUrl'

const getPartnerData = () => {
  const partnerName = getPartnerName()
  const partnerGroup = getPartnerGroup()
  const logoLinkUrl = getLogoLinkUrl()
  const partnerUrl = logoLinkUrl !== '/' ? { partnerUrl: logoLinkUrl } : {}
  const partnerData = {
    partnerName,
    partnerGroup,
    ...partnerUrl
  }

  return partnerData
}

/**
 * TrackingProvider is a wrapper for react-tracking's tracking logic
 * and contains any events triggered on page visit
 */
export function TrackingProvider({ children, metaTitle }: Props) {
  const location = useLocation()
  const { href, pathname, search } = location
  const referrer = useContext(ReferrerUrlContext).referrerUrl

  const facebookEventId = generateFacebookEventId()
  const facebookTrackSiteVisits = useFacebookTrackSiteVisits()

  const refreshCookieAtom = useRefreshCookieAtom()

  // The values below will be merged and included in any child component's
  // `trackEvent` calls (unless overridden there).
  // See https://github.com/nytimes/react-tracking#usage-with-react-hooks for docs on how to use
  // trackEvent and the Track component.
  const { Track, trackEvent } = useTracking(
    {
      environment: getDeployEnv(),
      facebookEventId,
      pagePath: pathname,
      pageTitle: metaTitle,
      queryString: search,
      referrer: referrer,
      site: 'fcp'
    },
    { dispatch: handleTrackingEvent(refreshCookieAtom) }
  )

  const optimizelyTrackPageVisit = useOptimizelyTrackPageVisit()

  const [isPageTracked, setIsPageTracked] = useState(false)

  useEffect(() => {
    initBraze()
  }, [])

  const firstVisitedUrlIsSet =
    getCookie('clientIp') !== ''
      ? sessionStorageGet(SESSION_STORAGE_FIRST_VISITED_URL) !== ''
      : false

  !firstVisitedUrlIsSet &&
    sessionStorageSet(SESSION_STORAGE_FIRST_VISITED_URL, pathname)

  const secondPageIsViewed =
    nonBounceSessionWasNotTracked() === true &&
    firstVisitedUrlIsSet &&
    sessionStorageGet(SESSION_STORAGE_FIRST_VISITED_URL) !== pathname

  // Handles any page-level tracking events
  const trackPageVisit = useCallback(() => {
    const { partnerName, partnerGroup, partnerUrl } = getPartnerData()
    // GTM + At-At via react-tracking
    trackEvent({
      event: 'pageLoad',
      firstVisitedUrl: sessionStorageGet(SESSION_STORAGE_FIRST_VISITED_URL),
      facebookEventId,
      partnerName,
      partnerGroup,
      partnerUrl
    })

    secondPageIsViewed && trackNonBounceVisit(trackEvent)

    // Optimizely
    optimizelyTrackPageVisit({ pageUrl: pathname })

    // Braze
    brazeTrackPageVisit()

    // Facebook
    facebookTrackSiteVisits({
      eventId: facebookEventId,
      pageUrl: href
    })

    // All page API params are optional, and we do not currently categorize pages or have canonical page names.
    // Note we pass specific values for referrer, search, title since React will render a template prior to the
    // document updating tile, potentially leading to incorrect values in the event. Similarly, default referrer
    // behavior of Rudder Page API calls does not account for in app navigation accuracy.
    trackPageCDP({
      referrer,
      search,
      title: metaTitle
    })
    // don't include facebookEventId in dependency array
  }, [
    href,
    pathname,
    trackEvent,
    referrer,
    metaTitle,
    optimizelyTrackPageVisit
  ])

  // timer used to ensure the effect is cancellable by re-renders (strict mode, also prevents duplicate calls)
  // and ensures local webpack devserver supports page tracking call.
  useEffect(() => {
    const timer = setTimeout(() => {
      !isPageTracked && trackPageVisit()
      !isPageTracked && setIsPageTracked(true)
    }, 0)
    return () => {
      clearTimeout(timer)
    }
  }, [href, pathname, referrer, metaTitle, isPageTracked, trackPageVisit])

  return <Track>{children}</Track>
}
