//@ts-nocheck
import Slider from '@ant-design/react-slick'
import React, { FC, ReactNode, useRef } from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import { throttle } from 'throttle-debounce'

import useMediaQuery from '../hooks/useMediaQuery'
import * as css from './VerticalScrollCarousel.module.scss'

export const createChangeSlideFunction = (
  sliderRef: React.RefObject<Slider>,
  numChildren: number
) => {
  // progress is a number between 0 and 1 representing position in the ScrollMagic scene
  return throttle(100, (progress: number) => {
    const slideIndex = Math.floor(progress * numChildren)
    sliderRef.current && sliderRef.current.slickGoTo(slideIndex)
  })
}

export type VerticalScrollCarouselProps = {
  /** Speed in milliseconds that a carousel slide displays before changing (for screen sizes with autoplay enabled) */
  readonly autoplaySpeed?: number
  /** Banners to show as slides in the carousel */
  readonly children: ReactNode | readonly ReactNode[]
  /** (For internal use) A unique id for the component */
  readonly id: string
  /** A static banner to show beneath the carousel */
  readonly staticBanner: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const VerticalScrollCarousel: FC<VerticalScrollCarouselProps> = ({
  autoplaySpeed = 4000,
  children,
  id,
  staticBanner,
  dataComponent = VerticalScrollCarousel.name
}: VerticalScrollCarouselProps) => {
  const numChildren = React.Children.count(children)
  const scrollingEnabled = useMediaQuery('DesktopAndUp') && numChildren > 1
  const sliderRef = useRef<Slider>(null)
  const sceneDuration = scrollingEnabled ? numChildren * 300 : 0

  // progress is a number between 0 and 1 representing position in the ScrollMagic scene
  const changeSlide = createChangeSlideFunction(sliderRef, numChildren)

  return (
    <div className={css.verticalScrollCarousel} data-component={dataComponent}>
      <Controller>
        <Scene
          duration={sceneDuration}
          enabled={scrollingEnabled}
          offset={0}
          pin={true}
          reverse={true}
          triggerElement={`#${id}`}
          triggerHook="onLeave"
        >
          {(progress: number) => {
            changeSlide(progress)

            return (
              <div id={id}>
                <Slider
                  arrows={false}
                  autoplay={!scrollingEnabled}
                  autoplaySpeed={autoplaySpeed}
                  dots={true}
                  fade={true}
                  infinite={!scrollingEnabled}
                  pauseOnHover={false}
                  ref={sliderRef}
                  speed={200}
                >
                  {children}
                </Slider>
                <div className={css.spacer} />
                {staticBanner}
              </div>
            )
          }}
        </Scene>
      </Controller>
    </div>
  )
}

export default VerticalScrollCarousel
