import { Breadcrumbs as SharedBreadcrumbs } from '@ecomm/header-redesigned'

export function BreadcrumbsWrapper({
  breadcrumbTitle = '',
  slug
}: {
  readonly breadcrumbTitle?: string | null
  readonly slug: string
}) {
  return breadcrumbTitle ? (
    <SharedBreadcrumbs steps={[{ label: breadcrumbTitle, slug }]} />
  ) : null
}
