//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode, useState } from 'react'

import { Modal } from '..'
import { useMediaQuery } from '../hooks'
import { Info } from '../icons'
import * as css from './BuildMySystemHeroItem.module.scss'

export type BuildMySystemHeroItemProps = {
  readonly title: string
  readonly description: ReactNode
  readonly image: ReactNode
  readonly detailsModalContent?: ReactNode
  readonly onClick?: () => void
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const BuildMySystemHeroItem: FC<BuildMySystemHeroItemProps> = ({
  title,
  description,
  image,
  detailsModalContent,
  onClick,
  dataComponent = BuildMySystemHeroItem.name
}: BuildMySystemHeroItemProps) => {
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false)
  const tabletAndUp = useMediaQuery('TabletAndUp')

  const handleClick = () => {
    onClick && onClick()
    setDetailsModalVisible(true)
  }

  return (
    <div className="flex items-center md:my-4" data-component={dataComponent}>
      <div
        className={classNames('h-20 w-16 flex-none md:h-44 md:w-20', css.image)}
      >
        {image}
      </div>
      <div className="ml-8 md:ml-4 lg:ml-8">
        {
          // show clickable title if info button hidden
          !tabletAndUp && detailsModalContent ? (
            <h3 className={css.title}>
              <button
                className={css.titleButton}
                onClick={() => setDetailsModalVisible(true)}
              >
                {title}{' '}
                <span className="screenReaderText">. show more info</span>
              </button>
            </h3>
          ) : (
            <div className="mb-3 flex items-start">
              <h3 className="m-0">{title}</h3>
              {detailsModalContent ? (
                <span className={css.info}>
                  <Info onClick={handleClick} titleA11y="show more info" />
                </span>
              ) : null}
            </div>
          )
        }
        <div className={css.description}>{description}</div>
      </div>
      <Modal
        isOpen={isDetailsModalVisible}
        onRequestClose={() => setDetailsModalVisible(false)}
      >
        {detailsModalContent}
      </Modal>
    </div>
  )
}

export default BuildMySystemHeroItem
