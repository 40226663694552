import { window } from 'browser-monads-ts'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import type { NewRelicBrowserApi } from './newrelic'

declare global {
  export interface Window {
    readonly newrelic?: NewRelicBrowserApi
  }
}

export function getNewRelic(fn: (t: NewRelicBrowserApi) => void): void {
  return pipe(
    O.fromNullable(window.newrelic),
    O.fold(() => undefined, fn)
  )
}
