import { InitializeStore } from '@ecomm/gatsby-wrappers'
import { Prefetch } from '@ecomm/shared-apollo'
import { useSendExperimentsDataToBraze } from '@ecomm/shared-ninetailed'
import { ReferrerUrlContext, useReferrerUrl } from '@ecomm/tracking'
import { EnvProvider, Locale } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import React, { ReactElement, StrictMode } from 'react'

export type PageContext = {
  readonly locale: Locale
  readonly enableJotai?: boolean
}

type Props = {
  readonly element: ReactElement
  readonly props: {
    readonly pageContext: PageContext
  }
}

function WrapWithContext<P extends Props>({ element, props: p }: P) {
  const { locale, enableJotai } = p.pageContext
  const location = useLocation()
  const referrerUrl = useReferrerUrl(location)
  useSendExperimentsDataToBraze()

  return (
    // StrictMode is inserted here instead of WrapRootElement because it needs
    // to be below @reach/router's BrowserRouter to avoid hydration issues
    // since Gatsby's using an outdated version of @reach/router.
    // https://stackoverflow.com/questions/71832720/link-tag-inside-browserrouter-changes-only-the-url-but-doesnt-render-the-compo/71833424#71833424
    <StrictMode>
      <Prefetch />
      <InitializeStore enableJotai={!!enableJotai}>
        <ReferrerUrlContext.Provider value={referrerUrl}>
          <EnvProvider config={{ locale }}>{element}</EnvProvider>
        </ReferrerUrlContext.Provider>
      </InitializeStore>
    </StrictMode>
  )
}

// Wrap the wrapper in another wrapper to allow the use of React hooks
// https://github.com/gatsbyjs/gatsby/issues/22833#issuecomment-609370401
export function WrapPageElement<P extends Props>(props: P) {
  return <WrapWithContext {...props} />
}
