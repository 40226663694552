import { HeroBanner, HeroBannerProps } from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'

import { useHeroVideoExperimentQuery } from './useHeroVideoExperimentQuery'

type ExperienceContentProps = HeroBannerProps & {
  readonly id: string
  readonly json: { readonly tag: string }
}

function ExperienceContentWrapper({ json, ...rest }: ExperienceContentProps) {
  return json.tag === 'variant' ? (
    <HeroBanner {...rest} />
  ) : (
    <HeroBanner {...rest} backgroundVideo={null} />
  )
}

export function HeroVideoExperimentWrapper(props: HeroBannerProps) {
  const experimentData = useHeroVideoExperimentQuery()

  return (
    <Experience
      {...experimentData}
      component={ExperienceContentWrapper}
      experiences={mapExperiences<ExperienceContentProps>(
        experimentData.nt_experiences
      )}
      passthroughProps={props}
    />
  )
}
