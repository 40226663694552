import type {
  AffirmClient,
  CheckoutFailureResponse,
  CheckoutSuccessResponse
} from '@ecomm/shared-components'
import { TrackMetricProps } from '@ecomm/tracking'
import { safeProp } from '@simplisafe/monda'
import type { AffirmCheckoutData } from '@simplisafe/ss-ecomm-data/affirm'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { AffirmOrderData } from './createOrder'
import { forwardToPaymentErrorUrl, logErrorWithOrderInfo } from './utils/common'

const handleError = (
  error: Error,
  onError: (e: Error) => void,
  trackMetricEvent: TrackMetricProps
) => {
  logErrorWithOrderInfo(error)
  onError(error)
  forwardToPaymentErrorUrl(error, trackMetricEvent)
}

const submitAffirmOrder = ({
  affirmCheckoutData,
  affirmClient,
  createOrder,
  onPaymentCanceled,
  onPaymentError,
  onPaymentProcessing,
  trackMetricEvent
}: {
  readonly affirmCheckoutData: O.Option<AffirmCheckoutData>
  readonly affirmClient?: AffirmClient | null
  readonly createOrder: (orderData: AffirmOrderData) => void
  readonly onPaymentCanceled: () => void
  readonly onPaymentError: (e: Error) => void
  readonly onPaymentProcessing: () => void
  readonly trackMetricEvent: TrackMetricProps
}) => {
  pipe(
    affirmCheckoutData,
    O.chain(affirmCheckoutData =>
      O.of(affirmClient && affirmClient.checkout(affirmCheckoutData))
    )
  )

  affirmClient &&
    affirmClient.checkout.open({
      onFail: (e: CheckoutFailureResponse | undefined) => {
        safeProp('reason', e)
          .filter(reason => reason === 'canceled')
          .cata(() => {
            const error = Error(
              `submitAffirmOrder: Affirm checkout failed - ${JSON.stringify(e)}`
            )
            handleError(error, onPaymentError, trackMetricEvent)
          }, onPaymentCanceled)
      },
      onOpen: onPaymentProcessing,
      onSuccess: (res: CheckoutSuccessResponse | undefined) => {
        safeProp('checkout_token', res).cata(
          () => {
            const error = Error(
              'submitAffirmOrder: Affirm checkout response missing checkout_token'
            )
            handleError(error, onPaymentError, trackMetricEvent)
          },
          token =>
            createOrder({
              affirmCheckoutToken: token,
              type: 'affirm'
            })
        )
      }
    })
}

export default submitAffirmOrder
