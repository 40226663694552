import { useState } from 'react'

import SensorGroup from '../../components/SensorGroup'
import { SensorGroupFragment } from '../../components/SensorGroup/schema'

type Props = {
  readonly sensorGroups: ReadonlyArray<SensorGroupFragment>
}

function SensorGroups({ sensorGroups = [] }: Props) {
  const [currentGroup, setCurrentGroup] = useState(
    (sensorGroups[0] && sensorGroups[0].title) ?? ''
  )

  return (
    <>
      {sensorGroups.map((sensorGroup, i, arr) => (
        <SensorGroup
          {...sensorGroup}
          currentGroup={currentGroup}
          key={sensorGroup.contentful_id}
          nextGroup={arr[i + 1] ? arr[i + 1].title : 'Summary'}
          setCurrentGroup={setCurrentGroup}
        />
      ))}
    </>
  )
}

export default SensorGroups
