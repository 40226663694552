import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  ContentfulRichText,
  GatsbyImage,
  GatsbyImageSchema
} from '@ecomm/shared-components'
import { CheckMark } from '@ecomm/shared-icons'
import { Link } from 'gatsby'

const customFeaturesList: Options = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_, children) => (
      <li className="prose-p:mb-0 my-2 flex items-center">
        <CheckMark className="mr-3 h-5 w-5 flex-shrink-0" />
        {children}
      </li>
    )
  }
}

type SensorFeaturesProps = {
  readonly image?: GatsbyImageSchema | null
  readonly description?: {
    readonly raw: string
  } | null
}

export default function SensorFeatures({
  description,
  image
}: SensorFeaturesProps) {
  const microcopy = useMicroCopy()

  return description && image ? (
    <section className="rounded-base bg-neutral-light-100 flex items-center overflow-hidden md:flex-row">
      <div className="prose-ul:m-0 prose-ul:list-none prose-ul:p-0 basis-full p-6 md:basis-1/2 md:p-8 lg:p-12">
        <h2 className="mb-4 md:mb-6">{microcopy['features']}</h2>
        <ContentfulRichText
          {...description}
          optionsCustom={customFeaturesList}
        />
        <Link
          className="btn btn-solid-primary mt-6 block w-fit px-5 py-4"
          to="/home-security-shop"
        >
          {microcopy['see-packages']}
        </Link>
      </div>
      <div className="hidden h-full md:block md:basis-1/2">
        <GatsbyImage className="h-full w-full" image={image} />
      </div>
    </section>
  ) : null
}
