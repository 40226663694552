import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { footerSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

import { gridSchema } from '../../schemas/grid'
import { imageLinkSchema } from '../../schemas/imageLink'

export const parsePressKitData = <
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>(
  data: T
): PressKitSchema => {
  const { footer, content } = pressKitSchema.parse(data)

  return {
    footer,
    content
  }
}

export const pressKitSchema = z.object({
  footer: footerSchema.nullish(),
  content: z.object({
    contentful_id: z.string(),
    content: z
      .union([
        gridSchema,
        z.object({
          __typename: z.literal('ContentfulSmallText'),
          text: contentfulRichTextSchema
        })
      ])
      .array()
  })
})

export type PressKitSchema = TypeOf<typeof pressKitSchema>
export type PressKitGridSchema = readonly TypeOf<typeof imageLinkSchema>[]
