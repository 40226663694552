import { z } from '@simplisafe/ewok'

/**
 * Contentful stores an entries ID inside of a `sys` object. This schema will transform sys.id into id and verify that it is a string.
 */
export const id = z
  .object({
    sys: z.object({
      id: z.string()
    })
  })
  .transform(({ sys, ...rest }) => ({ id: sys.id, ...rest }))
