import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import safeProp from '../safe/safeProp'
import { VariationFragment } from './types'

/**
 * Gets variations from a fragment and parses each variation using a schema.
 *
 * @package internal use only
 */
function getFragmentVariations<T extends VariationFragment<U>, U, V>(
  fragment: T | undefined,
  parser: (u: U) => V
): readonly V[] {
  const emptyArray: readonly V[] = []
  return pipe(
    O.fromNullable(fragment),
    O.chain(safeProp('variations')),
    O.chain(safeProp('variations')),
    O.map(arr => arr.map(parser)),
    O.getOrElse(() => emptyArray)
  )
}

export default getFragmentVariations
