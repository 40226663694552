import React from 'react'

import type { AccoladesProps } from '..'

export function Accolade({ data }: { readonly data: AccoladesProps }) {
  const { title, disclaimerText, disclaimerLink } = data
  return (
    <>
      <div
        className="mx-5 flex min-h-[300px] min-w-[300px] flex-col justify-center rounded-t-3xl text-3xl md:min-h-[300px] md:max-w-[400px] lg:text-4xl"
        data-component="Accolade"
        style={{ backgroundColor: '#0f2648' }}
      >
        <div className="p-9" data-component="AccoladeContent">
          <div className="text-white">{title}</div>
          {disclaimerLink ? (
            <a
              className="text-xs text-white underline hover:no-underline"
              data-component="DisclaimerLink"
              href={disclaimerLink}
            >
              {disclaimerText}
            </a>
          ) : disclaimerText ? (
            <span className="text-xs text-white">{disclaimerText}</span>
          ) : null}
        </div>
      </div>
      <div
        className="mx-5 h-[22px] rounded-b-3xl bg-[#FFAD29]"
        data-component="AccoladeBottomBanner"
      ></div>
    </>
  )
}
