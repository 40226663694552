import {
  AFFIRM_TERM_MONTHS_SYSTEM,
  AFFIRM_TERM_MONTHS_SYSTEM_EXPERMIENT
} from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { usePriceContext } from '@ecomm/data-price'
import { useProductDeprecated } from '@ecomm/data-products'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  ContentfulRichText,
  GatsbyImage,
  NewFlag
} from '@ecomm/shared-components'
import {
  useTrackingProductDecrease,
  useTrackingProductIncrease
} from '@ecomm/tracking'
import { useTrackingProductOpenModal } from '@ecomm/tracking'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { isString } from 'fp-ts/lib/string'
import { useAtom, useSetAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  decrementItemQtyAtom,
  incrementItemQtyAtom
} from '../../atoms/draftCart/itemQuantityAtom'
import { monitoringAtom } from '../../atoms/draftCart/monitoringPlanAtom'
import { useItemQuantity } from '../../hooks/draftCart/useItemQuantity'
import BackorderMessage from '../BackorderMessage'
import { MonitoringPlan } from '../DraftCart/types'
import { useGiftItemsProductCard } from '../ProductCard/hooks'
import { ProductCardFragment } from '../ProductCard/schema'
import ProductModal from '../ProductModal'
import QuantitySelector from './QuantitySelector'

export default function Product({
  image,
  name,
  shortDescription,
  description,
  proTip,
  productModal,
  sku,
  maxQuantity,
  isNew,
  isAffirmExperience
}: ProductCardFragment) {
  const locale = useLocale()
  const [open, setOpen] = useState(false)
  const [cartUpdated, setCartUpdated] = useState(false)
  const { getFormattedPrice } = usePriceContext()

  const quantity = useItemQuantity(sku)
  const increase = useSetAtom(incrementItemQtyAtom)
  const decrease = useSetAtom(decrementItemQtyAtom)
  const [monitoring] = useAtom(monitoringAtom)
  const trackDecrease = useTrackingProductDecrease(name)
  const trackIncrease = useTrackingProductIncrease(name)
  const trackOpenModal = useTrackingProductOpenModal(name)
  const freeGiftItems = useGiftItemsProductCard(sku)

  const [isSellable, setIsSellable] = useState(true)
  const [isOnStock, setIsOnStock] = useState(true)
  const [restockDate, setRestockDate] = useState('')

  const getPrice = (termMonths: number) =>
    pipe(
      getFormattedPrice(sku, termMonths),
      O.getOrElse(() => '')
    )

  const price = getPrice(1)
  const monthlyPrice = getPrice(
    isAffirmExperience
      ? AFFIRM_TERM_MONTHS_SYSTEM_EXPERMIENT
      : AFFIRM_TERM_MONTHS_SYSTEM
  )

  const product = useProductDeprecated(sku)

  useEffect(() => {
    pipe(
      product,
      O.fold(
        () => {
          setIsSellable(true)
          setIsOnStock(true)
          setRestockDate('')
        },
        p => {
          p.isSellable ? setIsSellable(true) : setIsSellable(false)
          p.isOnStock ? setIsOnStock(true) : setIsOnStock(false)
          setRestockDate(p.restockDate || '')
        }
      )
    )
  }, [product])

  const showBackorderMessage =
    isSellable &&
    !isOnStock &&
    isString(restockDate) &&
    restockDate !== '' &&
    quantity > 0

  const microCopy = useMicroCopy()

  const onDecrease = useCallback(() => {
    decrease(sku)
    setCartUpdated(true)
    trackDecrease()
  }, [sku, decrease])

  const onIncrease = useCallback(() => {
    increase(sku, maxQuantity)
    setCartUpdated(true)
    trackIncrease()
  }, [sku, increase])

  const openModal = () => {
    trackOpenModal()
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const monitoringType = useMemo(() => {
    return monitoring.type === MonitoringPlan.interactive ||
      monitoring.type === MonitoringPlan.odmon247 ||
      monitoring.type === MonitoringPlan.odmonOvernight
      ? 'withMonitoring'
      : 'withoutMonitoring'
  }, [monitoring])
  const freeGiftItem = freeGiftItems[monitoringType]

  return (
    <div className="border-neutral-medium-100 grid grid-cols-12 gap-x-5 border-0 border-b border-solid py-3 md:py-8">
      <div className="relative col-span-3 flex items-center justify-center">
        {image ? (
          <GatsbyImage
            className="h-16 md:h-auto"
            image={image}
            imgStyle={{ objectFit: 'contain' }}
          />
        ) : null}
        {isNew ? (
          <div className="absolute left-0 top-0 md:left-6 md:top-[25%] lg:left-20">
            <NewFlag
              additionalClasses="md:py-1 md:px-2 px-1 py-0.5 text-2xs md:text-lg"
              hasBackground
            />
          </div>
        ) : null}
      </div>
      <div className="col-span-6 md:mb-4">
        <div className="mb-1 flex gap-2.5 md:mb-4">
          <h3 className="my-0 text-base font-normal md:text-2xl md:font-light">
            {name}
          </h3>
          <button
            aria-label="Info"
            className="mt-1.5 flex cursor-pointer items-center border-none bg-inherit p-0 opacity-70 hover:opacity-100"
            onClick={openModal}
          >
            <svg height="18" width="18">
              <title>show more info</title>
              <g fill="none" fillRule="evenodd">
                <path d="M9.228 5.208c0 .078-.052.13-.13.13H7.902c-.078 0-.13-.052-.13-.13V4.077c0-.078.052-.13.13-.13h1.196c.078 0 .13.052.13.13v1.131zm0 7.449c0 .078-.052.13-.13.13H7.902c-.078 0-.13-.052-.13-.13V6.651c0-.078.052-.13.13-.13h1.196c.078 0 .13.052.13.13v6.006zM8.5 0a8.5 8.5 0 100 17 8.5 8.5 0 000-17z" />
                <path
                  d="M9.098 6.521H7.902a.123.123 0 00-.13.13v6.007c0 .078.052.13.13.13h1.196c.078 0 .13-.053.13-.13V6.652c0-.079-.052-.13-.13-.13m0-2.575H7.902c-.078 0-.13.052-.13.13v1.131c0 .078.052.13.13.13h1.196c.078 0 .13-.052.13-.13v-1.13c0-.079-.052-.13-.13-.13"
                  fill="currentColor"
                />
                <circle cx="8.5" cy="8.5" r="7.5" stroke="currentColor" />
              </g>
            </svg>
          </button>
        </div>
        {!shortDescription && (
          <div className="prose-p:my-0 prose-p:text-xs prose-p:lg:text-lg">
            <ContentfulRichText raw={description.raw} />
          </div>
        )}
        {shortDescription ? (
          <div className="prose-p:my-0 prose-p:lg:text-lg hidden md:block">
            <ContentfulRichText raw={description.raw} />
          </div>
        ) : null}
        {shortDescription ? (
          <div className="prose-p:my-0 prose-p:text-xs prose-p:lg:text-lg md:hidden">
            <ContentfulRichText raw={shortDescription.raw} />
          </div>
        ) : null}
        {proTip ? (
          <div className="prose-p:my-0 prose-p:lg:text-lg hidden md:inline">
            <ContentfulRichText raw={proTip.raw} />
          </div>
        ) : null}
        {showBackorderMessage ? (
          <div className="prose-p:text-s">
            <BackorderMessage
              includedInPackage={true}
              restockDate={restockDate}
            />
          </div>
        ) : null}
      </div>
      <div className="col-span-3 flex flex-col lg:col-span-2 lg:col-start-11">
        <p className="mb-2 hidden font-medium md:block lg:text-lg">
          {microCopy['how-many-do-you-need']}
        </p>
        {freeGiftItem && freeGiftItem.title ? (
          <span
            className="pb-2 text-sm text-red-600"
            data-component={freeGiftItem.sku}
          >
            {microCopy['free-item-automatically-added'](freeGiftItem.title)}
          </span>
        ) : null}
        <div className="mb-2 flex flex-col items-center justify-center gap-2 md:flex-row md:justify-between">
          {locale === 'en-US' ? (
            <div className="flex flex-col gap-1">
              <span className="text-lg font-medium">
                {monthlyPrice ? `${monthlyPrice}/mo` : null}
              </span>
              <span className="text-sm font-normal text-[#514F4C]">
                {price ? `or ${price}` : null}
              </span>
            </div>
          ) : (
            <span className="font-medium">{price}</span>
          )}

          <QuantitySelector
            maxQuantity={maxQuantity}
            name={name}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            quantity={quantity}
          />
        </div>
        <p className="my-0 hidden text-xs md:block">
          {microCopy['return-disclaimer']}
        </p>
        <div
          className={classNames('flex items-center md:gap-1', {
            'animate-fadeInOut': cartUpdated,
            hidden: !cartUpdated
          })}
          onAnimationEnd={() => setCartUpdated(false)}
        >
          <p className="text-complementary-blue-100 my-0 text-sm md:text-base">
            {microCopy['cart-updated']}
          </p>
          <svg
            aria-hidden="true"
            className="text-complementary-blue-100 shrink-0"
            height="22"
            width="22"
          >
            <path
              d="M 5.673 11.697 l 6.236 3.6 l 6 -10.392"
              fill="none"
              // fillRule="nonzero"
              stroke="currentColor"
              strokeWidth="3"
            />
          </svg>
        </div>
      </div>
      {productModal ? (
        <ProductModal
          isNew={isNew || false}
          modal={productModal}
          open={open}
          setOpen={closeModal}
        />
      ) : null}
    </div>
  )
}
