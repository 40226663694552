import React, { useState } from 'react'

import { Output as ComponentProps } from '../../hooks/useLocalizedData'
import { AlarmMomentsCta } from './AlarmMomentsCta'
import { AlarmMomentsHeader } from './AlarmMomentsHeader'
import { AlarmMomentSlide } from './AlarmMomentSlide'
import { DetailBubbles } from './DetailBubbles'
import { Illustration } from './Illustration'
import { LegalDisclaimer } from './LegalDisclaimer'
import { schema } from './schema'
import { useOnSelectAlarmMoment } from './useOnSelectAlarmMoment'

type AlarmMomentSlidesProps = ComponentProps<typeof schema>

export function AlarmMoments({
  data
}: {
  readonly data: AlarmMomentSlidesProps
}) {
  const [currentSelected, setCurrentSelected] = useState(0)
  const { header, alarmCategories, cta } = data
  const currentCategory = alarmCategories[currentSelected]
  const onSelectAlarmMoment = useOnSelectAlarmMoment()

  const handleSelectAlarmMoment = (carouselTab: number) => {
    onSelectAlarmMoment(carouselTab)
    setCurrentSelected(carouselTab)
  }

  return (
    <div
      className="flex flex-col items-center justify-center"
      data-component="AlarmMoments"
    >
      <AlarmMomentsHeader
        description={header.description}
        title={header.title}
      />
      {alarmCategories.length > 1 && (
        <div className="inset-x-1/2 ml-[-50vw] mr-[-50vw] flex w-screen max-w-[100vw] justify-center">
          <div className="flex items-center gap-2 overflow-x-auto p-2 pb-4">
            {alarmCategories.map((sensor, i) => (
              <AlarmMomentSlide
                isSelected={i === currentSelected}
                key={sensor.id}
                onClick={() => handleSelectAlarmMoment(i)}
                text={sensor.name}
              />
            ))}
          </div>
        </div>
      )}
      <Illustration image={currentCategory.image} />
      <DetailBubbles bubbles={currentCategory.bubbles} />
      <LegalDisclaimer text={currentCategory.disclaimerText} />
      {cta && <AlarmMomentsCta link={cta.link} text={cta.text} />}
    </div>
  )
}
