import {
  AffirmMonthlyOption,
  AffirmMonthlyOptionProps
} from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import React from 'react'

import {
  AffirmFeatureFlagDraftSchema,
  AffirmFeatureFlagSchema
} from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'

type Props = AffirmFeatureFlagDraftSchema & AffirmMonthlyOptionProps

function AffirmExperimentWrapper(props: Props) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <div
      data-component={
        isAffirmExperience ? 'AffirmMonthlyVariant' : 'AffirmMonthlyControl'
      }
    >
      <AffirmMonthlyOption
        {...props}
        isAffirmMonthlyExperiment={isAffirmExperience}
      />
    </div>
  )
}

export default AffirmExperimentWrapper
