import useFragmentWithVariations from '../../hooks/useFragmentWithVariations'
import alarmSensorSchema from './alarmSensorPageSchema'

export const useSensorAlarmPageFragment = <
  T extends { readonly contentfulAlarmSensorPage: U },
  U
>(
  query: T
) => {
  const alarmSensorPageFragment = query.contentfulAlarmSensorPage

  const alarmSensorPage = useFragmentWithVariations(
    alarmSensorPageFragment,
    alarmSensorSchema
  )

  return alarmSensorSchema.parse(alarmSensorPage)
}
