import { trackCheckoutProgressSteps } from '@ecomm/cdp-tracking'
import { SecureIcon } from '@ecomm/checkout-icons'
import { getLogoLinkUrl } from '@ecomm/shared-cookies'
import { SimpliSafeLogo } from '@ecomm/shared-icons'
import { useTrackLogoClick, useTrackStepClick } from '@ecomm/tracking'
import { useLocation } from '@reach/router'
import * as A from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as R from 'fp-ts/lib/Record'
import { Link, navigate } from 'gatsby'
import { MouseEvent, useMemo } from 'react'

import { Header } from './Header'
import { LabeledProgressBar } from './LabeledProgressBar'
import {
  getStepIndex,
  getStepLocation,
  isOfTypeStep,
  stepsLocation
} from './utils'

export default function SecureHeaderProgressBarComponent() {
  const trackLogoClick = useTrackLogoClick()
  const trackStepClick = useTrackStepClick()
  const pageLocation = useLocation()

  const steps = ['Cart', 'Shipping', 'Payment', 'Order Confirmation']

  const getProgress = (): number => {
    const progressByPath = {
      '^cart$': 4,
      '^cart/checkout$': 26.5,
      '^payment-page$': 52.5,
      '^payment-confirmation$': 100
    }

    const path = pageLocation.pathname
    const cleanPath = path?.replace(/^\/|\/$/g, '')

    const matchPathFragment = (
      pathFragment: string,
      cleanPath: string
    ): boolean => new RegExp(pathFragment).test(cleanPath)

    return pipe(
      Object.entries(progressByPath),
      A.findFirst(([pathFragment, _percentage]) =>
        matchPathFragment(pathFragment, cleanPath)
      ),
      O.map(([_pathFragment, percentage]) => percentage),
      O.getOrElse(() => 0)
    )
  }

  const progress = useMemo(getProgress, [pageLocation])

  const handleStepClick = (e: MouseEvent<HTMLElement>) => {
    const step = e.currentTarget.textContent
    if (step === null || !isOfTypeStep(step)) {
      return null
    } else {
      const url = getStepLocation(step)

      trackStepClick(step)
      trackCheckoutProgressSteps(step)

      url &&
        getStepLocation(step) &&
        pageLocation.pathname !== url &&
        navigate(url)

      return null
    }
  }

  const shouldStepBeClickable = (element: string) => {
    const stepIndex = getStepIndex(pageLocation.pathname)
    if (stepIndex === null) {
      return false
    } else {
      return pipe(
        R.lookup(element, stepsLocation),
        O.map(({ index }) => index < stepIndex),
        O.getOrElse(() => false)
      )
    }
  }

  return (
    <div data-component="SecureHeaderProgressBar">
      <Header>
        <nav className="my-1 flex w-full items-center justify-between px-4 md:w-1/2 md:justify-start md:px-8 lg:w-full lg:px-0">
          <Link
            className="inline-block h-[27px] w-[124px] md:mx-0 md:my-[0.75rem] md:h-[37px] md:w-[192px]"
            data-component="SecureHeaderProgressBarLogoWrapper"
            onClick={() => trackLogoClick()}
            to={getLogoLinkUrl('/')}
          >
            <SimpliSafeLogo className="h-7 w-32 md:h-10 md:w-48" />
          </Link>
          <div className="flex items-center md:pl-2 lg:pl-5">
            <SecureIcon />
            <p className="m-0 pl-1 text-sm font-medium text-white lg:text-sm">
              Secure Checkout
            </p>
          </div>
        </nav>

        <nav className="flex w-full items-center px-4 md:w-1/2 md:justify-end md:px-0">
          <LabeledProgressBar
            percent={progress}
            shouldStepBeClickable={shouldStepBeClickable}
            stepClick={handleStepClick}
            stepList={steps}
          />
        </nav>
      </Header>
    </div>
  )
}
