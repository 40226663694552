import { useProduct } from '@ecomm/data-products'
import {
  useOptimizelyTrackSiteEvents,
  useTrackAddToCart
} from '@ecomm/tracking'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

//Old logic to be changed once IOAddToCart is moved to a separated service
export default function useAddToCart(sku: string, quantity: number) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const product = useProduct(sku)
  const { trackAddToCartEvent } = useTrackAddToCart()
  const [isCartUpdateError, setIsCartUpdateError] = useState(false)
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const [isCartUpdateSuccess, setCartUpdateSuccess] = useState(false)

  const onAddToCart = useCallback(
    (quantity: number, handleSuccess: () => void) => {
      setIsLoading(true)
      setCartUpdateSuccess(false)

      const onFailure = () => {
        setIsLoading(false)
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
        setIsCartUpdateError(true)
      }

      const onSuccess = () => {
        handleSuccess()
        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartEvent(product, quantity)
        setIsLoading(false)
      }

      dispatch(
        IOAddToCart(
          {
            products: [
              {
                quantity,
                sku
              }
            ]
          },
          onFailure,
          onSuccess
        )
      )
    },
    [sku, dispatch]
  )

  useEffect(() => {
    const timer = setTimeout(() => setCartUpdateSuccess(false), 5000)
    return () => clearTimeout(timer)
  }, [isCartUpdateSuccess])

  const addToCart = useCallback(() => {
    onAddToCart(quantity, () => {
      setCartUpdateSuccess(true)
    })
  }, [onAddToCart, quantity])

  return {
    isLoading,
    addToCart,
    isCartUpdateSuccess,
    isCartUpdateError
  }
}
