import { ninetailedFeatureFlagSchema } from '@ecomm/promotions-components'
import { graphql, useStaticQuery } from 'gatsby'

export const useHeroVideoExperimentQuery = () =>
  ninetailedFeatureFlagSchema.parse(
    useStaticQuery(graphql`
      query HomeLandingHeroVideoExperiment {
        heroVideoExperiment: contentfulNinetailedFeatureFlag(
          contentful_id: { eq: "1VxmzEjBlgBMm7YwfzxFij" }
        ) {
          ...homeLandingFeatureFlagBase
          nt_experiences {
            ...homeLandingFeatureFlagExperience
          }
        }
      }
    `).heroVideoExperiment
  )
