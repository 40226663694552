import { ContentfulRichText } from '@ecomm/shared-components'
import { FormTextInput, SimpleButton, Text } from '@ecomm/ss-react-components'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import z from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { EmailSubmissionFormProps } from '../types'

const EMAIL_ERROR = 'Please enter a valid email address.'
const emailSchema = toFormikValidationSchema(
  z.object({
    email: z.string({ required_error: EMAIL_ERROR }).email(EMAIL_ERROR)
  })
)

function EmailSubmissionForm({
  label,
  placeholder,
  onSubmit,
  disclaimerRaw,
  buttonText,
  emailAddress = ''
}: EmailSubmissionFormProps) {
  return buttonText ? (
    <>
      <Formik
        initialValues={{ email: emailAddress }}
        onSubmit={onSubmit}
        validationSchema={emailSchema}
      >
        {({ isValid }) => (
          <Form noValidate>
            <div className="my-4 flex flex-col">
              <label className="my-1" htmlFor="email">
                {label}
              </label>
              <FormTextInput
                name="email"
                placeholder={placeholder}
                type="email"
              />
              <ErrorMessage
                className="text-complementary-red-100"
                component="span"
                name="email"
              />
            </div>
            <SimpleButton
              disabled={!isValid}
              size="medium"
              type="submit"
              variant="solid"
            >
              {buttonText}
            </SimpleButton>
          </Form>
        )}
      </Formik>
      <Text className="prose prose-p:text-sm" useTailwind>
        {disclaimerRaw ? <ContentfulRichText raw={disclaimerRaw} /> : null}
      </Text>
    </>
  ) : null
}

export default EmailSubmissionForm
