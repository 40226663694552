import { useFormStyles, useHandleFormSubmission } from '@ecomm/promotions-hooks'
import Icons from '@ecomm/shared-icons'
import { leadingSlashIt } from '@simplisafe/ewok'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import React from 'react'

import { PromoButton } from '../PromoButton'
import { EmailFormSchema } from './schema'

type LeadCaptureProps = {
  readonly onCrimeNearYouSubmit: () => void
}
/*
  Crime Near You Experiment
  Duplicate of libs/promotions/components/src/lib/LeadCaptureForm
*/
export function LeadCapture({ onCrimeNearYouSubmit }: LeadCaptureProps) {
  const { defaultEmail } = useHandleFormSubmission()
  const { textStyles } = useFormStyles()

  const Icon = Icons['ExclamationCircleOutline']

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ email: defaultEmail }}
      onSubmit={onCrimeNearYouSubmit}
      validationSchema={EmailFormSchema}
    >
      {({ isSubmitting, errors, values }) => (
        <Form data-component="LeadCaptureForm">
          <div className="flex flex-col items-end gap-5 py-6 md:flex-row md:pb-0 md:pt-10">
            <div className="flex w-full flex-col gap-1">
              <label
                className={`font-medium ${textStyles} max-lg:text-neutral-black text-left`}
                htmlFor="email"
              >
                Email address*
              </label>
              <div className="relative flex items-center">
                <Field
                  className={classNames(
                    'h-[51px] flex-grow rounded-lg border border-solid pl-[20px] text-[16px]',
                    {
                      'text-neutral-800': values.email && errors.email,
                      'border-red-800': values.email && errors.email,
                      'bg-red-50': values.email && errors.email
                    }
                  )}
                  name="email"
                  placeholder={'Enter your email address'}
                  type="email"
                />
                {errors.email ? (
                  <div className="absolute right-4 top-1/2 -translate-y-1/2 transform text-red-800">
                    <Icon className="text-red-800" height={22} width={22} />
                  </div>
                ) : null}
              </div>
              <p className="my-1 text-left text-sm font-normal text-red-800 md:hidden">
                {errors.email
                  ? 'Email address must use a valid email format'
                  : null}
              </p>
            </div>
            <PromoButton
              className="w-full"
              isSubmitting={isSubmitting}
              text="See results"
            />
          </div>
          <p className="hidden text-left text-sm font-normal text-red-800 md:block md:pb-7">
            {errors.email
              ? 'Email address must use a valid email format'
              : null}
          </p>
          <p className="m-0 p-0 text-center text-xs font-normal leading-[14px] text-neutral-800 md:text-sm md:leading-[22px]">
            You may receive email offers from us in accordance with our{' '}
            <a
              className={`text-xs font-normal leading-[14px] text-neutral-800 md:text-sm md:leading-[22px]`}
              href={leadingSlashIt('/privacy-policy')}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </p>
        </Form>
      )}
    </Formik>
  )
}
