import { Modal } from '@ecomm/checkout/components'
import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import {
  useTrackPlanWarningModalClose,
  useTrackPlanWarningModalContinue,
  useTrackPlanWarningModalReturnCart,
  useTrackPlanWarningModalView
} from '@ecomm/tracking'
import { navigate } from 'gatsby'
import { useEffect } from 'react'

type Props = {
  readonly open: boolean
  readonly onClose: () => void

  readonly data: CheckoutFormValuesType
  readonly addressValidationFunction: (values: CheckoutFormValuesType) => void
}

export function DowngradeMonitoringPlanModal({
  open,
  onClose,
  data,
  addressValidationFunction
}: Props) {
  const trackNavigateToCart = useTrackPlanWarningModalReturnCart()
  const trackContinue = useTrackPlanWarningModalContinue()
  const trackModalView = useTrackPlanWarningModalView()
  const trackCloseModal = useTrackPlanWarningModalClose()

  const handleNavigateToCart = () => {
    trackNavigateToCart()
    onClose()
    navigate('/cart')
  }

  const handleContinueToPayment = () => {
    trackContinue()
    onClose()
    addressValidationFunction(data)
  }

  const handleCloseModal = () => {
    trackCloseModal()
    onClose()
  }

  useEffect(() => {
    open && trackModalView()
  }, [open, trackModalView])

  return (
    <Modal isOpen={open} onClose={handleCloseModal}>
      <div
        className="flex flex-col p-8 px-4 md:w-[728px] md:px-8"
        data-component="downgrade_modal_content"
      >
        <div className="m-0 mb-0 flex flex-col gap-2">
          <p className="text-neutral-black m-0 text-base lg:text-lg">
            The plan you selected isn&#39;t available at the moment, so
            we&#39;ve set you up with a free month of our most popular 24/7
            monitoring plan. It&#39;s our highest level of protection.
          </p>
          <div className="text-neutral-black m-0 whitespace-normal text-sm md:whitespace-pre-line">
            {
              'After your free month, your plan will cost $29.99/mo. \n No contracts or commitments. Cancel any time.'
            }
          </div>
        </div>
        <div className="mt-6 flex h-full w-full flex-col items-center gap-8 md:mt-4 md:flex-row">
          <div className="flex h-full w-full flex-col-reverse gap-6 md:flex-row md:justify-end">
            <button
              className="cursor-pointer border-0 bg-transparent px-4 py-2 text-base font-medium text-[#0068DF] underline hover:bg-transparent hover:no-underline lg:text-lg"
              onClick={handleNavigateToCart}
            >
              Return to cart
            </button>

            <button
              className="bg-btn-primary w-full cursor-pointer justify-center rounded-lg border-0 px-3 py-[10px] text-white md:w-auto md:px-8"
              data-component="ValidateButton"
              onClick={handleContinueToPayment}
            >
              <span className="justify-center text-base font-medium lg:text-lg">
                Continue to payment
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
