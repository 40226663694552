import { useFragmentWithVariations } from '@ecomm/optimizely-hooks'

import {
  partnerWithUsLayoutSchema,
  partnerWithUsPageContentSchema
} from './schema'

export const usePartnerWithUsPageFragment = <
  T extends { readonly contentfulPartnerWithUsLandingPage: U },
  U
>(
  query: T
) => {
  const partnerWithUsFragment = query.contentfulPartnerWithUsLandingPage

  const partnerWithUsPage = useFragmentWithVariations(
    partnerWithUsFragment,
    partnerWithUsPageContentSchema
  )
  const layout = useFragmentWithVariations(
    partnerWithUsPage.layout,
    partnerWithUsLayoutSchema
  )

  return {
    ...partnerWithUsPageContentSchema.parse(partnerWithUsPage),
    layout: partnerWithUsLayoutSchema.parse(layout)
  }
}
