import { voidFn } from '@simplisafe/ewok'
import React from 'react'

type PromoButtonProps = {
  readonly className?: string
  readonly href?: string
  readonly isSubmitting?: boolean
  readonly onClick?: VoidFunction
  readonly type?: 'button' | 'reset' | 'submit'
  readonly text: string
}
/*
  Crime Near You Experiment
  Duplicate of libs/promotions/components/src/lib/PromoButton/index.tsx
*/
export function PromoButton({
  isSubmitting = false,
  onClick = voidFn,
  type = 'submit',
  text
}: PromoButtonProps) {
  return (
    <button
      className="btn btn-solid-primary h-[51px] w-full cursor-pointer rounded-xl border-none p-4 text-[16px] hover:brightness-90 md:w-[170px]"
      data-component="PromoButton"
      disabled={isSubmitting}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  )
}
