import { ContentfulRichText } from '@ecomm/shared-components'
import { Text as Typography } from '@ecomm/ss-react-components'
import React from 'react'

import { Accolade } from './Accolade'

export type AccoladesProps = {
  readonly title: string
  readonly disclaimerText?: string
  readonly disclaimerLink?: string
}

type MonitoringAccoladesProps = {
  readonly title: string
  readonly monitoringAccoladesData: readonly AccoladesProps[]
}

export function MonitoringAccolades({
  data
}: {
  readonly data: MonitoringAccoladesProps
}) {
  const { monitoringAccoladesData, title } = data

  return (
    <>
      <Typography useTailwind>
        <div className="text-center text-4xl font-bold">
          <ContentfulRichText raw={title} />
        </div>
      </Typography>
      <div className="justify-left flex flex-row overflow-x-scroll lg:justify-center">
        {monitoringAccoladesData.map((accolade, idx) => {
          return (
            <div key={idx}>
              <Accolade data={accolade} />
            </div>
          )
        })}
      </div>
    </>
  )
}
