//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode, useState } from 'react'

import { SSButton } from '..'
import PriceSection from '../PriceSection'
import QuantityChanger, { QuantityChangerProps } from '../QuantityChanger'
import QuantitySelect, { QuantitySelectProps } from '../QuantitySelect'
import type { SSButtonProps } from '../SSButton'
import { SiteColor } from '../types/colors'
import * as css from './SimpleProductCard.module.scss'

export type SimpleProductCardProps = {
  /** Additional copy to display under price, e.g. VAT disclaimer */
  readonly additionalText?: string
  /** Background color. Defaults to none */
  readonly backgroundColor?: SiteColor
  /** Props for the CTA button */
  readonly buttonProps: SSButtonProps
  /** Default value for the quantity selector */
  readonly defaultQuantity?: number
  /** Product image */
  readonly img: ReactNode
  /** If false, product cannot be added to cart */
  readonly isCartable?: boolean
  /** CTA button click handler */
  readonly onButtonClick: (quantity: number) => void
  /** Out of stock messaging if the product is out of stock */
  readonly outOfStockMessage?: ReactNode
  /** Price component that renders the fully formatted price string, including currency symbol and per-unit text */
  readonly price: ReactNode
  /** deprecated: use `quantityChangerProps` */
  readonly quantitySelectProps?: QuantitySelectProps
  /** Props for the quantity changer */
  readonly quantityChangerProps?: QuantityChangerProps // make this prop required when quantitySelectProps is removed
  /** Text color. Defaults to neutral black */
  readonly textColor?: SiteColor
  /** Card header text, e.g. product name */
  readonly title: string
  /** data-component attribute value */
  readonly dataComponent?: string
  readonly errorMessage?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const SimpleProductCard: FC<SimpleProductCardProps> = ({
  additionalText,
  backgroundColor,
  buttonProps,
  defaultQuantity = 1,
  img,
  isCartable = true,
  quantitySelectProps,
  quantityChangerProps,
  onButtonClick,
  outOfStockMessage,
  price,
  textColor,
  title,
  dataComponent = SimpleProductCard.name,
  errorMessage
}: SimpleProductCardProps) => {
  const [quantity, setQuantity] = useState(defaultQuantity)

  const quantityChanger = quantityChangerProps && (
    <QuantityChanger
      {...quantityChangerProps}
      className="neutralBlackTextColor"
      defaultValue={quantity}
      onChange={setQuantity}
    />
  )
  // remove when quantitySelectProps is removed
  const deprecatedQuantitySelector = quantitySelectProps && (
    <QuantitySelect
      {...quantitySelectProps}
      onChange={e => setQuantity(parseInt(e.target.value, 10))}
      selected={quantity}
    />
  )

  return (
    <div
      className={classNames(
        css.simpleProductCard,
        { [`${backgroundColor}BackgroundColor`]: !!backgroundColor },
        { [`${textColor}TextColor`]: !!textColor }
      )}
      data-component={dataComponent}
    >
      <h4 className={classNames(css.title, 'h4', 'mediumText')}>{title}</h4>
      <div className={css.content}>
        <div className={css.image}>{img}</div>
        <div className={css.shop}>
          <div className={css.priceSection}>
            <PriceSection
              price={price}
              priceDisclaimer={additionalText}
              quantitySelector={quantityChanger || deprecatedQuantitySelector}
              spaceBetween={true}
            />
            {outOfStockMessage ? (
              <div
                className={classNames(
                  'disclaimerText',
                  'mediumText',
                  css.outOfStockText
                )}
              >
                {outOfStockMessage}
              </div>
            ) : null}
          </div>
          <div className={css.buttonWrapper}>
            <SSButton
              {...buttonProps}
              className={css.button}
              disabled={!isCartable}
              onClick={() => onButtonClick(quantity)}
            />
          </div>
          {errorMessage ? <div className={'m1_t'}>{errorMessage}</div> : null}
        </div>
      </div>
    </div>
  )
}

export default SimpleProductCard
