import { useMicroCopy } from '@ecomm/micro-copy'
import { useTrackMetricEvent } from '@ecomm/tracking'
import { BannerError, BannerLoading } from '@ecomm/ss-react-components'
import { useEffect } from 'react'

import { PaymentState } from '../ZuoraPaymentForm/ZuoraPaymentForm'

export type PaymentFormBannerProps = {
  readonly paymentState: PaymentState
}

/**
 * Loading any one of the Loader/Error/Payment Form section based on the state.
 * Adding empty element inside BannerLoading for mandatory children.
 */
export function PaymentFormBanner({ paymentState }: PaymentFormBannerProps) {
  const microCopy = useMicroCopy()
  const trackMetricEvent = useTrackMetricEvent()

  useEffect(() => {
    paymentState === 'error' && trackMetricEvent('payment-form-error')
  }, [paymentState, trackMetricEvent])

  return paymentState === 'processing' ? (
    <BannerLoading dataComponent="BannerLoading_paymentProcessing">
      <h3>Please wait while your payment is processing.</h3>
    </BannerLoading>
  ) : paymentState === 'complete' ? (
    <BannerLoading dataComponent="BannerLoading_paymentComplete">
      <h3>Thanks! Please wait while we finalize your order.</h3>
    </BannerLoading>
  ) : paymentState === 'error' ? (
    <BannerError dataComponent="BannerLoading_paymentError" height="responsive">
      <div className="rounded-base overflow-hidden px-4 py-8 md:py-8">
        <h3>Something isn&apos;t right. Try reloading the page.</h3>
        <p>{microCopy['if-the-issue-persists']}</p>
      </div>
    </BannerError>
  ) : null
}
