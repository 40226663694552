import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackingCheckoutLoginClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      event: 'checkout-click-to-login'
    })
  }, [trackEvent])
}
