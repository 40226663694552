import { safePath } from '@simplisafe/monda'
import classNames from 'classnames'
import isNil from 'ramda/src/isNil'
import React, {
  ChangeEvent,
  FocusEventHandler,
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react'

type RadioOptionBaseProps = {
  readonly id?: string
  readonly text: ReactNode
  readonly value: number | string
  readonly content?: ReactNode
  readonly defaultChecked?: boolean
  readonly description?: ReactNode
  readonly disabled?: boolean
  readonly disabledContent?: ReactNode
  readonly className?: string
}

export type radioOptionProps = RadioOptionBaseProps

export type InputRadioProps = {
  readonly id?: string
  readonly label?: string
  readonly required?: boolean
  readonly onChange: (event: ChangeEvent<HTMLInputElement>) => void
  readonly disabled?: boolean
  readonly name?: string
  readonly options: readonly radioOptionProps[]
  readonly dataComponent?: string
}

function InputRadio({
  disabled = false,
  id = '',
  label = '',
  onChange,
  required = false,
  options,
  name = '',
  dataComponent = 'InputRadio'
}: InputRadioProps) {
  const inputIdPrefix = dataComponent

  const [touched, setTouched] = useState(false)
  const [manualSelection, setManualSelection] = useState<
    number | string | null
  >()

  const inputRequired = useMemo(() => {
    return touched && required
  }, [required, touched])

  const handleTouched: FocusEventHandler<HTMLInputElement> = () => {
    setTouched(true)
  }

  const handleClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setManualSelection(e.target.value)
      onChange && onChange(e)
    },
    [onChange]
  )

  const isSelected = (value: number | string, isDefault: boolean): boolean => {
    return manualSelection ? manualSelection === value : isDefault
  }

  const renderRadioItems =
    options &&
    options.map((item: radioOptionProps, idx: number) => {
      const content = safePath(['content'], item).orNull()
      const isDefaultChecked = item.defaultChecked || false
      const noteClass = isSelected(item.value, isDefaultChecked)
        ? 'block'
        : 'hidden'
      const inputId = item.id
        ? item.id
        : inputIdPrefix && `${inputIdPrefix}-${item.value}-${idx}`
      const isDisabled = !!(disabled || item.disabled)

      return (
        <Fragment key={`${item.value}-${idx}`}>
          <div
            className={classNames(
              'flex',
              { ['opacity-30']: isDisabled },
              item.className
            )}
            data-component={`${dataComponent}_radio`}
          >
            <input
              className="border-neutral-medium-100 mr-2 inline-block h-4 w-4 cursor-pointer rounded-full checked:bg-[#006FEE]"
              defaultChecked={item.defaultChecked}
              disabled={isDisabled}
              id={inputId}
              key={`name${idx}`}
              name={name}
              onBlur={handleTouched}
              onChange={handleClick}
              required={inputRequired}
              type="radio"
              value={item.value}
            />
            <label
              className="flex w-full flex-col gap-2 md:flex-row md:gap-4"
              htmlFor={inputId}
              key={name}
            >
              <span className="flex self-start text-lg font-medium md:flex-1 md:self-center">
                {item.text}
              </span>
              {item.description ? (
                <div
                  className="flex flex-1 self-start text-sm md:self-center"
                  data-component={`${dataComponent}_description`}
                >
                  {item.description}
                </div>
              ) : null}
            </label>
          </div>
          <div
            className={noteClass}
            data-component={`${dataComponent}_RichText`}
          >
            {content}
          </div>
          {isDisabled ? item.disabledContent : null}
        </Fragment>
      )
    })

  return (
    <>
      <div
        aria-required={required}
        data-component={`${dataComponent}_container`}
        id={id}
      >
        {label}
        {required ? <span>*</span> : null}
      </div>
      {!isNil(options) && renderRadioItems}
    </>
  )
}
export default InputRadio
