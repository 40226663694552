import { pushToDataLayer } from '@ecomm/shared-window'
import { ToastCheckMarkAlert } from '@ecomm/ss-react-components'
import React from 'react'
import type { Toast } from 'react-hot-toast'
import { toast, ToastBar, Toaster } from 'react-hot-toast'

/**
 * @deprecated NOTE: This code was ported directly from legacy under tight deadline for Scout launch and should be rewritten!
 * See: https://simplisafe.atlassian.net/browse/ECP-7325
 */
export function PageToaster() {
  return (
    <Toaster toastOptions={{ style: { padding: 0 } }}>
      {(t: Toast) => (
        <ToastBar toast={t}>
          {({ message }) => (
            <ToastCheckMarkAlert
              hasCloseButton={true}
              message={message}
              onCloseHandler={() => {
                pushToDataLayer({
                  event: 'toastAlert',
                  action: 'closeToastAlert',
                  category: 'UTMAdditionAlert',
                  label: 'closedToastAlert'
                })()
                toast.dismiss(t.id)
                localStorage.getItem('utm_code') &&
                  localStorage.removeItem('utm_code')
              }}
            />
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
