import { getUserId, getUserToken } from '@ecomm/shared-cookies'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

import { fetchYodaApi } from './lib/fetchYodaApi'

export type UserAddressResponse = {
  readonly city: string
  readonly firstName: string
  readonly lastName: string
  readonly phone: string
  readonly state: string
  readonly street1: string
  readonly street2: string
  readonly zip: string
}

export function isUserAddressResponse(
  addresses: unknown
): addresses is readonly UserAddressResponse[] {
  return !!addresses && typeof addresses === 'object'
}

export function fetchUserShippingAddress() {
  const userToken = getUserToken()
  const userId = getUserId()

  if (!userToken || !userId) {
    return TE.left(Error('User token or user ID is null or empty'))
  } else {
    return pipe(
      fetchYodaApi({
        endpoint: `/v1/users/${userId}/addresses`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        }
      }),
      TE.chain(res =>
        isUserAddressResponse(res) ? TE.right(res) : TE.left(res)
      ),
      TE.mapLeft(res => Error(`Error fetching user address ${res}`))
    )
  }
}
