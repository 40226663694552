import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'
import { navigate } from 'gatsby'

import { MeetTheSystemBannerSchema } from './schema'
import { useMeetTheSystemBannerQuery } from './useMeetTheSystemBannerQuery'
import { useOnButtonClick } from './useOnButtonClick'

const headlineCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <h2 className="m-0 text-2xl font-medium lg:text-5xl">{children}</h2>
    )
  }
}

const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="text-[16px] font-light md:text-[18px] md:leading-[24px]">
        {children}
      </p>
    )
  }
}

export function MeetTheSystemBanner() {
  const data: MeetTheSystemBannerSchema = useMeetTheSystemBannerQuery()

  const onButtonClick = useOnButtonClick()
  const handleOnClick = () => {
    onButtonClick()
    navigate('/meet-the-system')
  }

  return (
    <div
      className="lg:align-stretch flex w-full flex-col lg:flex-row"
      data-component="ScoutPageDeadEnd"
    >
      <div className="min-h-2/4 rounded-t-base w-full lg:h-auto lg:w-2/5">
        <GatsbyImage
          className="rounded-tl-base rounded-tr-base lg:rounded-bl-base lg:rounded-tl-base w-full object-contain object-center lg:h-full lg:rounded-tr-none"
          image={data.image}
        />
      </div>
      <div className="lg:flex-start lg:align-start min-h-2/4 rounded-b-base bg-neutral-light-50 lg:rounded-e-base flex flex-col p-6 lg:h-auto lg:w-3/5 lg:justify-center lg:rounded-bl-none lg:p-12">
        <ContentfulRichText
          optionsCustom={headlineCustomOptions}
          raw={data.headline.text?.raw}
        />
        <ContentfulRichText
          optionsCustom={descriptionCustomOptions}
          raw={data.body.text?.raw}
        />
        <button
          className="btn btn-solid-primary mb-4 lg:w-48"
          onClick={handleOnClick}
        >
          Meet the system
        </button>
      </div>
    </div>
  )
}
