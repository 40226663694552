import {
  AffirmBanner,
  BannerPhone,
  Divider,
  GuidedSystemBuilder,
  ImageWithCaption,
  LinkComponent,
  VerifyPassDropdown,
  VerifyPassFeature,
  withLegacyDataProp
} from '@ecomm/shared-components'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import { PageProps } from 'gatsby'
import React from 'react'
// import ModalComponent from './components/ModalComponent'
// import RichTextWithButtonsComponent from './components/RichTextWithButtonsComponent'

const CardShopComponent = React.lazy(
  () =>
    import('./components/CardShopComponent' /* webpackChunkName: "legacy" */)
)
const AccessoriesCardComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/AccessoriesCardComponent'
    )
)
const AccessoriesCardWrapper = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/AccessoriesCardWrapper'
    )
)
const AccordionGroup = React.lazy(
  () => import(/* webpackChunkName: "legacy" */ './components/AccordionGroup')
)
const AccordionItemComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/AccordionItemComponent'
    )
)
const AddExtraSensorsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/AddExtraSensorsComponent'
    )
)
const AlarmSensorsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/AlarmSensorsComponent'
    )
)
const AuthenticationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/AuthenticationComponent'
    )
)
const BadgeText = React.lazy(
  () => import(/* webpackChunkName: "legacy" */ './components/BadgeText')
)
const BannerTextComponent = React.lazy(
  () =>
    import('./components/BannerTextComponent' /* webpackChunkName: "legacy" */)
)
const BmsHeroItem = React.lazy(
  () => import('./components/BmsHeroItem' /* webpackChunkName: "legacy" */)
)
const BmsSensorGroup = React.lazy(
  () => import('./components/BmsSensorGroup' /* webpackChunkName: "legacy" */)
)
const BmsSensor = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/BmsSensorGroup/BmsSensor'
    )
)
const ButtonComponent = React.lazy(
  () => import('./components/ButtonComponent' /* webpackChunkName: "legacy" */)
)
const CardItemComponent = React.lazy(
  () => import('./components/CardItem' /* webpackChunkName: "legacy" */)
)
const CardKitComponent = React.lazy(
  () => import('./components/CardKitComponent' /* webpackChunkName: "legacy" */)
)
const CarouselComponent = React.lazy(
  () =>
    import('./components/CarouselComponent' /* webpackChunkName: "legacy" */)
)
const CarouselFeaturesComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/CarouselFeaturesComponent'
    )
)
const CartDetailsComponent = React.lazy(
  () =>
    import('./components/CartDetailsComponent' /* webpackChunkName: "legacy" */)
)
const CartSummaryComponent = React.lazy(
  () =>
    import(
      './components/CartSummaryComponent' /* webpackChunkName: "legacyCheckout" */
    )
)
const CheckoutForm = React.lazy(
  () =>
    import('./components/CheckoutForm' /* webpackChunkName: "legacyCheckout" */)
)
const CompanyReviewBannerComponent = React.lazy(
  () =>
    import(
      './components/CompanyReviewBannerComponent' /* webpackChunkName: "legacy" */
    )
)
const ComparisonTableRowComponent = React.lazy(
  () =>
    import(
      './components/ComparisonTableRowComponent' /* webpackChunkName: "legacy" */
    )
)
const ConditionalContent = React.lazy(
  () =>
    import('./components/ConditionalContent' /* webpackChunkName: "legacy" */)
)
const ContactUsForm = React.lazy(
  () => import('./components/ContactUsForm' /* webpackChunkName: "legacy" */)
)
const ContentCollectionComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentCollectionComponent'
    )
)
const ContentfulCardItemBanner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentfulCardItemBanner'
    )
)
const ContentfulComparisonBanner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentfulComparisonBanner'
    )
)
const ContentfulLocationGridComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentfulCrimeLocationGrid'
    )
)
const ContentfulOverlayBanner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentfulOverlayBanner'
    )
)
const ContentfulReviewGridComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentfulReviewGridComponent'
    )
)
const ContentfulVariationContainerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentfulVariationContainerComponent'
    )
)
const ContentSelectorComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentSelectorComponent'
    )
)
const CrimeLocationBanner = React.lazy(
  () =>
    import('./components/CrimeLocationBanner' /* webpackChunkName: "legacy" */)
)
const CrimeLocationResults = React.lazy(
  () =>
    import('./components/CrimeLocationResults' /* webpackChunkName: "legacy" */)
)
const DeviceVariationsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/DeviceVariationsComponent'
    )
)
const DynamicPackageFloorplanBannerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/DynamicPackageFloorplanBannerComponent'
    )
)
const DynamicPackageHeroBannerWrapper = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/DynamicPackageHeroBannerComponent/DynamicPackageHeroBannerWrapper'
    )
)
const EditPackageSensorComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/EditPackageSensorComponent'
    )
)
const EmailOverlayModal = React.lazy(
  () =>
    import('./components/EmailOverlayModal' /* webpackChunkName: "legacy" */)
)
const EnumeratedCardGroupFinancingComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/EnumeratedCardGroupFinancing'
    )
)
const ExpandableComponent = React.lazy(
  () =>
    import('./components/ExpandableComponent' /* webpackChunkName: "legacy" */)
)
const ExpandableMonitoringPlanComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ExpandableMonitoringPlanComponent'
    )
)
const FaqsFinancingComponent = React.lazy(
  () => import('./components/FAQsFinancing' /* webpackChunkName: "legacy" */)
)
const FeedbackForm = React.lazy(
  () => import('./components/FeedbackForm' /* webpackChunkName: "legacy" */)
)
const FloatingBadgeComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/FloatingBadgeComponent'
    )
)
const FloorPlan = React.lazy(
  () => import('./components/FloorPlan' /* webpackChunkName: "legacy" */)
)
const FooterTopSectionComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/FooterTopSectionComponent'
    )
)
const GetAQuoteForm = React.lazy(
  () => import('./components/GetAQuoteForm' /* webpackChunkName: "legacy" */)
)
const GroupSection = React.lazy(
  () => import('./components/GroupSection' /* webpackChunkName: "legacy" */)
)
const HeadingComponent = React.lazy(
  () => import('./components/HeadingComponent' /* webpackChunkName: "legacy" */)
)
const HeroBannerComponent = React.lazy(
  () =>
    import('./components/HeroBannerComponent' /* webpackChunkName: "legacy" */)
)
const HowItWorksInteractiveHouse = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/HowItWorksInteractiveHouse'
    )
)
const IconWithTextComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/IconWithTextComponent'
    )
)
const ImageGalleryComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ImageGalleryComponent'
    )
)
const ImageWithArtDirection = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ImageWithArtDirection'
    )
)
const ImageWithFloatingBadgeComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ImageWithFloatingBadgeComponent'
    )
)
const ImageWithFocalPoint = React.lazy(
  () =>
    import('./components/ImageWithFocalPoint' /* webpackChunkName: "legacy" */)
)
const ImageWithOverlay = React.lazy(
  () => import('./components/ImageWithOverlay' /* webpackChunkName: "legacy" */)
)
const IncludedItemComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/IncludedItemComponent'
    )
)
const IncludedItemsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/IncludedItemsComponent'
    )
)
const ItemContainerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ItemContainerComponent'
    )
)
const LinkAddToCart = React.lazy(
  () => import('./components/LinkAddToCart' /* webpackChunkName: "legacy" */)
)
const CommunicationsContent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/LiveChat/CommunicationsContent'
    )
)
const LiveChatTrigger = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/LiveChat/LiveChatTrigger'
    )
)
const SmsBlock = React.lazy(
  () =>
    import('./components/LiveChat/SmsBlock' /* webpackChunkName: "legacy" */)
)
const LiveGuardForm = React.lazy(
  () => import('./components/LiveGuardForm' /* webpackChunkName: "legacy" */)
)
const ContentfulLocationBannerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/LocationBannerComponent'
    )
)
const Media = React.lazy(
  () => import('./components/Media' /* webpackChunkName: "legacy" */)
)
const ModalComponent = React.lazy(
  () => import('./components/ModalComponent' /* webpackChunkName: "legacy" */)
)
const ModalExitIntentComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ModalExitIntentComponent'
    )
)
const ModalPromoPopupComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ModalPromoPopupComponent'
    )
)
const NativeVideoContainer = React.lazy(
  () =>
    import('./components/NativeVideoContainer' /* webpackChunkName: "legacy" */)
)
const OrderedListComponent = React.lazy(
  () =>
    import('./components/OrderedListComponent' /* webpackChunkName: "legacy" */)
)
const PackageCardComponent = React.lazy(
  () =>
    import('./components/PackageCardComponent' /* webpackChunkName: "legacy" */)
)
const PackageCardGroupFinancingComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/PackageCardGroupFinancing'
    )
)
const PageBannerComponent = React.lazy(
  () =>
    import('./components/PageBannerComponent' /* webpackChunkName: "legacy" */)
)
const PartnerCaptureForm = React.lazy(
  () =>
    import('./components/PartnerCaptureForm' /* webpackChunkName: "legacy" */)
)
const PartnerForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/PartnerOrderIntentForm'
    )
)
const PartnerTemplateComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/PartnerTemplateComponent'
    )
)
const PartnerWithUsForm = React.lazy(
  () =>
    import('./components/PartnerWithUsForm' /* webpackChunkName: "legacy" */)
)

const PerfectSystemBannerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/PerfectSystemBannerComponent'
    )
)
const PhoneCTAComponent = React.lazy(
  () =>
    import('./components/PhoneCTAComponent' /* webpackChunkName: "legacy" */)
)
const PlaceholderComponent = React.lazy(
  () =>
    import('./components/PlaceholderComponent' /* webpackChunkName: "legacy" */)
)
const PlanComparisonTableRows = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/PlanComparisonTableRows'
    )
)
const PopupButton = React.lazy(
  () =>
    import('./components/PopupButtonComponent' /* webpackChunkName: "legacy" */)
)

const ProductPageHero = React.lazy(
  () => import('./components/ProductPageHero' /* webpackChunkName: "legacy" */)
)
const ProductPlanComponent = React.lazy(
  () =>
    import('./components/ProductPlanComponent' /* webpackChunkName: "legacy" */)
)
const QuoteWizardWrapper = React.lazy(
  () =>
    import('./components/QuoteWizardWrapper' /* webpackChunkName: "legacy" */)
)
const ReferAFriendPlaceholderComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ReferAFriendPlaceholderComponent'
    )
)
const ResponsiveContainerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ResponsiveContainerComponent'
    )
)
const RichTextWithButtonsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/RichTextWithButtonsComponent'
    )
)
const RichTextWithOptionsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/RichTextWithOptionsComponent'
    )
)
const SaveMySystemModalComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/SaveMySystemModalComponent'
    )
)
const ScoutHardcodedComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ScoutHardcodedComponent'
    )
)
const SensorProductBannerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/SensorProductBannerComponent'
    )
)
const SimpleBannerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/SimpleBannerComponent'
    )
)
const SimpleCardGroupFinancingComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/SimpleCardGroupFinancing'
    )
)
const SmallTextSection = React.lazy(
  () => import('./components/SmallTextSection' /* webpackChunkName: "legacy" */)
)
const TableContainer = React.lazy(
  () => import('./components/TableContainer' /* webpackChunkName: "legacy" */)
)
const TabNavigationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/TabNavigationComponent'
    )
)
const TestimonialCardComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/TestimonialCardComponent'
    )
)
const TrustpilotBanner = React.lazy(
  () => import('./components/TrustpilotBanner' /* webpackChunkName: "legacy" */)
)
const TwoColumnBanner = React.lazy(
  () => import('./components/TwoColumnBanner' /* webpackChunkName: "legacy" */)
)
const VerifyPassContainer = React.lazy(
  () =>
    import('./components/VerifyPassContainer' /* webpackChunkName: "legacy" */)
)
const VerticalScrollCarouselComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/VerticalScrollCarouselComponent'
    )
)

// A shared mapping of Contentful content types to component to be rendered
const componentMappings = {
  ContentfulAccordionGroup: AccordionGroup,
  ContentfulAccordion: AccordionItemComponent,
  ContentfulAdditionalInfoBanner: ContentfulOverlayBanner,
  ContentfulAlarmSensors: AlarmSensorsComponent,
  ContentfulAuthentication: AuthenticationComponent,
  ContentfulBadgeText: BadgeText,
  ContentfulBannerPhone: BannerPhone,
  ContentfulBanner: SimpleBannerComponent,
  ContentfulBannerText: BannerTextComponent,
  ContentfulBmsHeroItem: BmsHeroItem,
  ContentfulBmsMiniCart: CartSummaryComponent,
  ContentfulBmsSensorGroup: BmsSensorGroup,
  ContentfulBmsSensors: BmsSensor,
  ContentfulButton: ButtonComponent,
  ContentfulCardItem: CardItemComponent,
  ContentfulCardKit: CardKitComponent,
  ContentfulCardShop: CardShopComponent,
  ContentfulCarousel: CarouselComponent,
  ContentfulCarouselFeatures: CarouselFeaturesComponent,
  ContentfulCarouselVerticalScrollAndBanner: VerticalScrollCarouselComponent,
  ContentfulCartDetails: CartDetailsComponent,
  ContentfulCategoryList: TabNavigationComponent,
  ContentfulCheckoutForm: CheckoutForm,
  ContentfulCommunicationsContent: CommunicationsContent,
  ContentfulCompanyReviewBanner: CompanyReviewBannerComponent,
  ContentfulComparisonBanner: ContentfulComparisonBanner,
  ContentfulComparisonTableRow: ComparisonTableRowComponent,
  ContentfulConditionalContent: ConditionalContent,
  ContentfulContactUsForm: ContactUsForm,
  ContentfulContentCollection: ContentCollectionComponent,
  ContentfulContentSelector: ContentSelectorComponent,
  ContentfulCrimeLocationBanner: CrimeLocationBanner,
  ContentfulCrimeLocationBannerWithAddressField:
    ContentfulLocationBannerComponent,
  ContentfulCrimeLocationGrid: ContentfulLocationGridComponent,
  ContentfulCrimeLocationResults: CrimeLocationResults,
  ContentfulDeviceVariations: DeviceVariationsComponent,
  // @ts-expect-error TS(2345) FIXME: Argument of type 'MemoExoticComponent<({ borderWid... Remove this comment to see the full error message
  ContentfulDivider: withLegacyDataProp(Divider),
  ContentfulDynamicPackageFloorplanBanner:
    DynamicPackageFloorplanBannerComponent,
  ContentfulDynamicPackageHeroBanner: DynamicPackageHeroBannerWrapper,
  ContentfulEmailOverlayModal: EmailOverlayModal,
  ContentfulEnumeratedCardGroupFinancing: EnumeratedCardGroupFinancingComponent,
  ContentfulExpandable: ExpandableComponent,
  ContentfulExpandableMonitoringPlan: ExpandableMonitoringPlanComponent,
  ContentfulFaqsFinancing: FaqsFinancingComponent,
  ContentfulFeedbackForm: FeedbackForm,
  ContentfulFindYourPerfectSystem: QuoteWizardWrapper,
  ContentfulFloatingBadge: FloatingBadgeComponent,
  ContentfulFloorPlan: FloorPlan,
  ContentfulFooterTopSection: FooterTopSectionComponent,
  ContentfulGetaQuoteForm: GetAQuoteForm,
  ContentfulGroupSection: GroupSection,
  ContentfulGuidedSystemBuilder: GuidedSystemBuilder,
  ContentfulHeading: HeadingComponent,
  ContentfulHeroBanner: HeroBannerComponent,
  ContentfulHowItWorksInteractiveHouse: HowItWorksInteractiveHouse,
  ContentfulIconWithText: IconWithTextComponent,
  ContentfulImage: Media,
  ContentfulImageGallery: ImageGalleryComponent,
  ContentfulImageWithArtDirection: ImageWithArtDirection,
  ContentfulImageWithCaption: ImageWithCaption,
  ContentfulImageWithFloatingBadge: ImageWithFloatingBadgeComponent,
  ContentfulImageWithFocalPoint: ImageWithFocalPoint,
  ContentfulImageWithOverlay: ImageWithOverlay,
  ContentfulIncludedItem: IncludedItemComponent,
  ContentfulIncludedItems: IncludedItemsComponent,
  ContentfulLink: LinkComponent,
  ContentfulLinkAddToCart: LinkAddToCart,
  ContentfulLiveChatTrigger: LiveChatTrigger,
  ContentfulLiveGuardForm: LiveGuardForm,
  ContentfulModal: ModalComponent,
  ContentfulModalExitIntent: ModalExitIntentComponent,
  ContentfulModalPromoPopup: ModalPromoPopupComponent,
  ContentfulModalSaveYourSystem: SaveMySystemModalComponent,
  ContentfulModalSensorAdditionToSystem: AddExtraSensorsComponent,
  ContentfulNativeVideoContainer: NativeVideoContainer,
  ContentfulOrderedList: OrderedListComponent,
  ContentfulPackageCard: PackageCardComponent,
  ContentfulPackageCardGroupFinancing: PackageCardGroupFinancingComponent,
  ContentfulPageBanner: PageBannerComponent,
  ContentfulPartnerCaptureForm: PartnerCaptureForm,
  ContentfulPartnerForm: PartnerForm,
  ContentfulPartnerTemplate: PartnerTemplateComponent,
  ContentfulPartnerWithUsForm: PartnerWithUsForm,
  ContentfulPdpPackageSensorEdit: EditPackageSensorComponent,
  ContentfulPhoneCta: PhoneCTAComponent,
  ContentfulPlaceholder: PlaceholderComponent,
  ContentfulPlanComparisonTable: PlanComparisonTableRows,
  ContentfulPopupButton: PopupButton,
  // typo is in contentful
  ContentfulPrefectSystemBanner: PerfectSystemBannerComponent,
  ContentfulProduct: ItemContainerComponent,
  ContentfulProductCard: ContentfulCardItemBanner,
  ContentfulProductCardAccessories: AccessoriesCardComponent,
  ContentfulAccessoriesCardWrapper: AccessoriesCardWrapper,
  ContentfulProductPageHeroSensor: SensorProductBannerComponent,
  ContentfulProductPageHeroV2: ProductPageHero,
  ContentfulProductPlan: ProductPlanComponent,
  ContentfulReferAFriendPlaceholder: ReferAFriendPlaceholderComponent,
  ContentfulResponsiveContainer: ResponsiveContainerComponent,
  ContentfulReviewGrid: ContentfulReviewGridComponent,
  ContentfulRichTextWithButtons: RichTextWithButtonsComponent,
  ContentfulRichTextWithOptions: RichTextWithOptionsComponent,
  ContentfulScoutPlaceholder: ScoutHardcodedComponent,
  ContentfulSimpleCardGroupFinancing: SimpleCardGroupFinancingComponent,
  ContentfulSmallTextSection: SmallTextSection,
  ContentfulSmsBlock: SmsBlock,
  ContentfulTable: TableContainer,
  ContentfulTestimonialCard: TestimonialCardComponent,
  ContentfulTrustpilotReviewsBanner: TrustpilotBanner,
  ContentfulTwoColumn: TwoColumnBanner,
  ContentfulVariationContainer: ContentfulVariationContainerComponent,
  ContentfulVerifyPassDropdown: VerifyPassDropdown,
  ContentfulVerifyPassFeature: VerifyPassFeature,
  ContentfulVerifyPassContainer: VerifyPassContainer,
  ContentfulAffirmBanner: AffirmBanner
}

// TODO: Update all mapped components to use this interface so they have a consistent set of standard props defined in one place
export type ComponentMappedProps = {
  readonly data?: ContentfulComponent
  readonly location?: PageProps['location']
  readonly pageContext?: PageProps['pageContext']
}

export type ContentfulComponent = {
  readonly id?: string
  readonly internal?: Partial<Internal>
}

type ComponentMappings = typeof componentMappings
type ComponentKeys = keyof ComponentMappings

function isComponentKey(
  key: ComponentKeys | string | undefined
): key is ComponentKeys {
  const keys = Object.keys(componentMappings)
  return keys.includes(key || '')
}

export function getMappedComponent<Props extends ComponentMappedProps>(
  contentfulComponent: ContentfulComponent
): React.FC<Props>
export function getMappedComponent<T>(
  contentfulComponent: ContentfulComponent
): React.FC<T>
export function getMappedComponent(contentfulComponent: ContentfulComponent) {
  const internalType = path(['internal', 'type'], contentfulComponent)

  return isComponentKey(internalType)
    ? prop(internalType, componentMappings)
    : null
}
