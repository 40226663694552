import { useAtom } from 'jotai'
import React from 'react'

import { itemQuantityAtom } from '../../../atoms/draftCart/itemQuantityAtom'
import DraftCartLineListItem from '../DraftCartLineListItem'

interface Props {
  readonly isAffirmMonthlyExperiment?: boolean
}

function DraftCartLineList({ isAffirmMonthlyExperiment = false }: Props) {
  const [items] = useAtom(itemQuantityAtom)

  return (
    <ul className="m-0 list-none p-0">
      {items.toArray().map(([sku, quantity]) => (
        <DraftCartLineListItem
          isAffirmMonthlyExperiment={isAffirmMonthlyExperiment}
          key={sku}
          quantity={quantity}
          sku={sku}
        />
      ))}
    </ul>
  )
}

export default DraftCartLineList
