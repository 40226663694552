import React, { FC, ReactNode } from 'react'

export type FormSectionProps = {
  /** Section title text */
  readonly name: string
  readonly children: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const FormSection: FC<FormSectionProps> = ({
  name,
  children
}: FormSectionProps) => (
  <>
    <div className="mt-8 text-lg">
      <span className="md:after:bg-neutral-medium-100 after:content-empty flex w-full shrink-0 items-center whitespace-nowrap text-xl after:ml-3 after:mt-2 after:block after:h-0.5 after:w-full md:text-2xl">
        {name}
      </span>
    </div>
    <div className="my-2.5 md:my-5">{children}</div>
  </>
)

export default FormSection
