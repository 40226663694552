import { useField } from 'formik'
import { ReactNode } from 'react'

export type FormFieldProps = {
  /** Field name, note that this prop is being used to wire the field to an specific formik field */
  readonly id: string
  readonly label?: string
  readonly className?: string
  readonly labelClassName?: string
  readonly helperText?: string
  readonly children: ReactNode
}

export function FormField({
  id,
  label = '',
  className = '',
  labelClassName = '',
  children,
  helperText = ''
}: FormFieldProps) {
  const [, meta] = useField(id)

  return (
    <div
      className={`flex flex-col gap-1 ${className}`}
      data-component="FormField"
    >
      {label && (
        <label
          className={`flex text-xs font-bold ${labelClassName}`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {children}
      {helperText && <div>{helperText}</div>}
      {meta.error && meta.touched && (
        <div className="text-sale border-red-800 text-xs">{meta.error}</div>
      )}
    </div>
  )
}
