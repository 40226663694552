import type { ExperienceConfiguration } from '@ninetailed/experience.js-gatsby'
import { ExperienceMapper, Variant } from '@ninetailed/experience.js-utils'

export const mapExperiences = <T extends Variant>(
  experienceData: any
): ExperienceConfiguration<T>[] => {
  return (experienceData || [])
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience)
}
