import {
  useTrackingAddMonitoring,
  useTrackingRemoveMonitoring
} from '@ecomm/tracking'

import useMonitoringPlan from '../../../hooks/IMAPS/useMonitoringPlan'
import { CoreMonitoringLegalDisclaimer } from '../../LegalDisclaimer/CoreMonitoringLegalDisclaimer'
import { ChooseMonitoringBox } from '../ChooseMonitoringBox'
import { OptInOrOutProps } from '../OptInOrOut/types'
import { coreMonitoringLegalRawContent } from './constants'

export function OdmonMAPSCoreMonitoring({
  amountSavedWithServicePlan,
  discountWithServicePlan,
  priceToggles
}: OptInOrOutProps) {
  const trackRemoveMonitoring = useTrackingRemoveMonitoring()
  const trackAddMonitoring = useTrackingAddMonitoring()
  const { hasMonitoring, initialPlan, onSelectMonitoring } = useMonitoringPlan({
    priceToggles
  })

  // This priceToggles.length check is important to support the requirement not to show
  // IMAPS for PLA Packages. The priceToggles prop comes in as the original priceToggles
  // before we hard-code the override so this toggle data is what we want to look at for this
  // type of logic.
  return priceToggles.length > 1 ? (
    <div data-component="DraftCartMonitoringWrapper">
      <ChooseMonitoringBox
        buttonText={hasMonitoring ? 'Remove' : 'Claim free month'}
        content={
          hasMonitoring ? (
            <>
              {discountWithServicePlan ? (
                <>
                  <span className="font-semibold text-orange-700">
                    You’re saving {`${discountWithServicePlan}`} today{' '}
                  </span>
                  <span>and getting </span>
                </>
              ) : (
                <span>You’re getting </span>
              )}
              <span className="font-semibold">
                1 free month of Core Monitoring
              </span>
            </>
          ) : (
            <>
              <p className="m-0 mb-2 font-semibold text-orange-700">
                You have removed your one free month of Core Monitoring
              </p>
              <span>
                Claim your one free month
                <strong> and save up to {amountSavedWithServicePlan}</strong>.
                No contracts, cancel any time.
              </span>
            </>
          )
        }
        hasMonitoring={hasMonitoring}
        key={hasMonitoring ? 'withMonitoring' : 'withoutMonitoring'}
        onButtonClick={() => {
          onSelectMonitoring(hasMonitoring ? '' : initialPlan)
          hasMonitoring ? trackRemoveMonitoring() : trackAddMonitoring()
        }}
      />
      <CoreMonitoringLegalDisclaimer
        modalContent={coreMonitoringLegalRawContent}
      />
    </div>
  ) : null
}
