import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'

export const usePartnershipRewardsValue = (partnerName: string) => {
  const partnerGroup = getValueFromPartnerCookie('partnerGroup')
  const defaultRewardValue = 7000
  const unitedRewardsValue =
    process.env['GATSBY_UNITED_REWARDS_VALUE'] || defaultRewardValue
  return partnerGroup === 'airlines'
    ? partnerName === 'united'
      ? Number(unitedRewardsValue)
      : defaultRewardValue
    : 0
}
