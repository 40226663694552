import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import type { ContentfulRichTextSchema } from '@ecomm/contentful-schemas'
import Icons from '@ecomm/shared-icons'
import classNames from 'classnames'
import React, { type ReactNode } from 'react'

import { ContentfulRichText } from '../ContentfulRichText'

export type DetailBubbleProps = {
  readonly icon: Icons.IconTypes
  readonly text: ContentfulRichTextSchema
}

export type DetailBubbleTheme = 'dark' | 'light'

const options = (theme: DetailBubbleTheme): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_: unknown, children: ReactNode) => (
      <p
        className={classNames('m-0 p-0 text-xs', {
          'text-neutral-black': theme === 'light',
          'text-white': theme === 'dark'
        })}
      >
        {children}
      </p>
    )
  }
})

export function DetailBubble({
  icon,
  text,
  theme
}: DetailBubbleProps & { readonly theme: DetailBubbleTheme }) {
  const Icon = Icons[icon]
  return (
    <div
      className={classNames(
        'box-border flex min-h-[95px] min-w-[240px] max-w-[240px] items-center rounded-[20px] py-3 pr-6',
        {
          'bg-neutral-black': theme === 'dark',
          'bg-neutral-light-50': theme === 'light'
        }
      )}
      data-component="DetailBubble"
    >
      <div className="m-2 mx-3 flex items-center justify-center">
        {icon ? (
          <Icon
            className={classNames('h-8 w-8', {
              'text-white': theme === 'dark',
              'text-neutral-black': theme === 'light'
            })}
          />
        ) : null}
      </div>
      <ContentfulRichText optionsCustom={options(theme)} raw={text.raw} />
    </div>
  )
}
