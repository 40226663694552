import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'
import { Link } from 'gatsby'
import React from 'react'

type Props = {
  readonly image: GatsbyImageSchema
}

function ProductsDisclaimer({ image: disclaimerImage }: Props) {
  return (
    <div className="p-4" data-component="Disclaimer">
      <h2 className="h2">Heads up!</h2>
      <p className="paragraph whitespace-pre-line">
        These sensors only work with the legacy Simplisafe (Gen 2).
      </p>
      <div className="my-4 flex items-center">
        <p className="paragraph mr-8 whitespace-pre-line">
          Does your Base Station look like this?
        </p>
        {disclaimerImage ? (
          <GatsbyImage
            className="mr-12 w-[2rem] md:w-[22rem]"
            image={disclaimerImage}
          />
        ) : null}
      </div>
      <p className="paragraph whitespace-pre-line">
        <Link
          className="text-inherit underline hover:no-underline"
          to="/alarm-sensors/ss3"
        >
          <b>Click here to order your Gen 3 Simplisafe sensors »</b>
        </Link>
      </p>
    </div>
  )
}

export default ProductsDisclaimer
