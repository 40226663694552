import {
  createInstance,
  OptimizelyProvider as DefaultOptimizelyProvider,
  setLogger,
  setLogLevel
} from '@ecomm/optimizely-utils'
import { noValue } from '@ecomm/utils'
import { exists, window } from 'browser-monads-ts'
import { localStorage } from '@simplisafe/ewok'
import React, { ReactNode, useMemo } from 'react'

import { updateFirstSeenUrl, userAttributes } from '.'
import getVisitorId from './getVisitorId'
const { get } = localStorage

type OptimizelyProviderProps = {
  readonly children: ReactNode
  readonly datafile: Record<string, string>
}

/**
 * A wrapper around the default OptimizelyProvider that handles behavior like
 * waiting for the AT-AT cookie to be set before initializing.
 */
export function OptimizelyProvider({
  children,
  datafile
}: OptimizelyProviderProps) {
  const isSSR = useMemo(() => !exists(window), [])

  /** Allows a user to bucket themselves into an Optimizely group for feature testing. */
  const feature_flag = get('feature_flag')

  // Wrapped in a useMemo so we don't re-initialize an Optimizely client on every page transition.
  const optimizely = useMemo(() => {
    setLogLevel(process.env['GATSBY_OPTIMIZELY_LOG_LEVEL'] || 'error')
    isSSR && setLogger(null)

    return createInstance({
      datafile,
      eventBatchSize: 10,
      eventFlushInterval: 1000
    })
  }, [])

  const userInfo = useMemo(() => {
    const id = isSSR ? noValue() : getVisitorId()
    id && updateFirstSeenUrl(id)
    return id
      ? {
          attributes: {
            ...userAttributes(),
            feature_flag
          },
          id
        }
      : noValue()
  }, [])

  return (
    <DefaultOptimizelyProvider optimizely={optimizely} user={userInfo}>
      {children}
    </DefaultOptimizelyProvider>
  )
}
