import { OutOfStockMessage } from '@ecomm/ss-react-components'
import React from 'react'

import { ProductFragment } from '../types'

type Props = {
  readonly items: readonly ProductFragment[]
  readonly message: string
  readonly backorderDate: string
}

function OutOfStock({ items, message, backorderDate }: Props) {
  // Wrap it in a set to avoid duplicates
  const verboseItems = Array.from(new Set(items))
    // Use plural names if there's more than one of a given included item
    .map(item =>
      items.filter(i => item.sku === i.sku).length > 1 && item.namePlural
        ? item.namePlural
        : item.name
    )
    .join(', ')
    .replace(/,([^,]*)$/, ' and$1')

  const description = message.replace('{{products}}', verboseItems)

  return (
    <OutOfStockMessage
      backInStockDate={backorderDate}
      coreComponentNotInStock={true}
      coreComponentsDesc={description}
    />
  )
}

export default OutOfStock
