import * as O from 'fp-ts/lib/Option'
import { useDebugValue } from 'react'

import { useApplyDiscountCodesToCart } from './useApplyDiscountCodesToCart'
import { useCheckAndGetUtmCodes } from './useCheckAndGetUtmCodes'
import { useCurrentPromotionsData } from './useCurrentPromotionsData'
import { useIsCartLoaded } from './useIsCartLoaded'
import { useSetCurrentDiscountCodes } from './useSetCurrentDiscountCodes'

export const useApplyPromoCode = (
  utmCode: O.Option<string>,
  useJotaiCart: boolean
) => {
  const cartLoaded = useIsCartLoaded(useJotaiCart)

  // Access all the data we need
  const {
    currentPromoCode,
    currentPromoCodeWithMonitoring,
    dynamicPromoCodes,
    partnerPromoCodes
  } = useCurrentPromotionsData()

  // Get the UTM codes array if some
  const { hasUtmCode, validUtmCodes } = useCheckAndGetUtmCodes(
    O.getOrElse(() => '')(utmCode),
    useJotaiCart
  )

  // Set all the currently active codes (UTM, Active Promo and Evergreen Promo)
  const { applicablePromoCodes } = useSetCurrentDiscountCodes(
    currentPromoCode,
    currentPromoCodeWithMonitoring,
    dynamicPromoCodes,
    partnerPromoCodes,
    hasUtmCode,
    validUtmCodes
  )

  // Decide which codes to apply to the cart and apply them
  useApplyDiscountCodesToCart(applicablePromoCodes, cartLoaded, useJotaiCart)

  useDebugValue(applicablePromoCodes)

  return Array.from(applicablePromoCodes)
}
