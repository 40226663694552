import { Link } from 'gatsby'
import React, { HTMLAttributes, ReactElement } from 'react'
import { useTracking } from 'react-tracking'
import { z } from 'zod'

import {
  LegalLinkFragment,
  LegalLinksFragment,
  trackingEventSchema
} from './schema'

type LegalLinksProps = HTMLAttributes<HTMLUListElement> & {
  readonly links: LegalLinksFragment
}

function LegalLink({
  link
}: {
  readonly link: LegalLinkFragment
}): ReactElement {
  const { trackEvent } = useTracking<{
    readonly event: z.infer<typeof trackingEventSchema>
  }>()

  return (
    <li className="text-end md:text-center">
      <Link
        className="mr-6 text-xs no-underline hover:underline md:mr-0 lg:ml-4"
        onClick={() =>
          link.trackingEvent &&
          trackEvent({
            event: link.trackingEvent
          })
        }
        to={link.url}
      >
        {link.text}
      </Link>
    </li>
  )
}

export function LegalLinks({
  links,
  className = '',
  style
}: LegalLinksProps): ReactElement {
  return (
    <ul className={`m-0 flex p-3 ${className} items-end`} style={style}>
      {links.map((link, index) => (
        <LegalLink key={`tertiary_links${index}`} link={link} />
      ))}
    </ul>
  )
}
