import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy/src/lib/jsonSchema'
import { TypeOf, z } from '@simplisafe/ewok'

export const privacyHeroBannerImageSchema = z.object({
  tabletAndUpImage: gatsbyImageSchema.optional(),
  mobileImage: gatsbyImageSchema.optional(),
  copy: jsonSchema
})

export type PrivacyHeroBanner = TypeOf<typeof privacyHeroBannerImageSchema>
