import { useTrackAddProductToCart } from '@ecomm/cdp-tracking-utils'
import { useProduct } from '@ecomm/data-products'
import { useOptimizelyTrack } from '@ecomm/optimizely-hooks'
import { useTrackingProductAddToCart } from '@ecomm/tracking'
import { Product } from '@simplisafe/ss-ecomm-data/products'

/**
 * Custom hook that abstracts all the tracking logic for the AddToCartButton component.
 */
export function useAddToCartButtonTracking(sku: string) {
  const reduxProduct: Product | undefined = useProduct(sku)
    .toMaybe()
    .orUndefined()

  const optimizelyTrackAddToCartClicked = useOptimizelyTrack({
    eventType: 'add_to_cart_clicked'
  })
  const optimizelyTrackAddToCartError = useOptimizelyTrack({
    eventType: 'website_error'
  })
  const trackAddToCart = useTrackingProductAddToCart(reduxProduct)
  const { trackProductCardAddToCartEvent } = useTrackAddProductToCart()

  function trackSuccess(quantity: number) {
    optimizelyTrackAddToCartClicked()
    trackProductCardAddToCartEvent(reduxProduct, quantity)
    trackAddToCart(quantity)
  }
  function trackError() {
    optimizelyTrackAddToCartError()
  }

  return {
    trackSuccess,
    trackError
  }
}
