import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackingShippingFormUserErrors() {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(
    (fields: Record<string, string>) => {
      trackEvent({
        event: 'continue-to-payment-click-failure',
        ...fields
      })
    },
    [trackEvent]
  )
}
