import { contentfulImageSchema } from '@ecomm/contentful/components'
import { documentSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

import { guidedSystemBuilderSchema } from '../GuidedSystemBuilder/schema'

const idSchema = z.object({
  id: z.string()
})

export const textNodeSchema = z.object({
  nodeType: z.string(),
  value: z.string().optional(),
  marks: z.array(z.any()).optional(),
  data: z.object({}).optional(),
  content: z.array(z.any()).optional()
})

export const dataSchema = z.object({
  sys: z.object({
    id: z.string(),
    type: z.string(),
    linkType: z.string().optional()
  })
})

export const contentSchema = z.array(
  z.object({
    nodeType: z.string(),
    data: dataSchema.optional(),
    content: z.array(textNodeSchema).optional()
  })
)

const videoSchema = z.object({
  __typename: z.literal('ContentfulVideo'),
  webmLink: z.string(),
  mp4Link: z.string(),
  posterImage: z.string().nullish(),
  captions: z.string().nullish()
})

export enum buttonTypes {
  customPlainText = 'Custom Plain',
  disabled = 'Disabled',
  iconOnly = 'Icon Only',
  link = 'Link',
  primary = 'Primary',
  primaryHollow = 'Primary Hollow',
  primaryOutline = 'Primary Outline',
  promotion = 'Promotion',
  secondary = 'Secondary',
  secondaryOutline = 'Secondary Outline',
  tertiary = 'Tertiary',
  tertiaryOutline = 'Tertiary Outline',
  anchorNav = 'Anchor Nav'
}

export const buttonSchema = z.object({
  sys: idSchema,
  __typename: z.literal('Button'),
  buttonText: z.string().optional(),
  url: z.string(),
  type: z.enum([
    buttonTypes.primary,
    buttonTypes.secondary,
    buttonTypes.tertiary,
    buttonTypes.primaryOutline,
    buttonTypes.secondaryOutline,
    buttonTypes.tertiaryOutline,
    buttonTypes.primaryHollow,
    buttonTypes.link,
    buttonTypes.iconOnly,
    buttonTypes.promotion,
    buttonTypes.anchorNav,
    buttonTypes.customPlainText,
    buttonTypes.disabled
  ])
})

export const embeddedLinksSchema = z.object({
  entries: z.object({
    block: z.array(
      z.union([guidedSystemBuilderSchema.nullish(), buttonSchema.nullish()])
    )
  })
})

export const descriptionSchema = z.object({
  json: documentSchema,
  links: embeddedLinksSchema
})

const colors = z.enum([
  'brandPrimary',
  'complementaryBlue100',
  'neutralBlack',
  'neutralLight100',
  'neutralWhite',
  'neutralLight200',
  'complementaryGray100'
])

// This needs to be imported from new Form
// component as we convert it to use apollo response instead
// of gatsby response
export const formQuerySchema = z.object({
  __typename: z.literal('FormV2'),
  // contentful_id: idSchema.transform(sysObj => sysObj.id),
  sys: idSchema,
  fields: z
    .object({
      label: z.string(),
      placeholder: z.string(),
      type: z.enum(['Text', 'File', 'Text Area', 'Email']),
      name: z.string()
    })
    .array(),
  button: z.object({
    text: z.string()
  })
})

export const contentItemSchema = z.object({
  backgroundColor: colors,
  textColor: colors.nullish(),
  textAlignment: z.enum(['left', 'center', 'right']).nullish(),
  contentVerticalAlignment: z.enum([
    'start',
    'center',
    'end',
    'stretch',
    'baseline'
  ]),
  linkColor: colors.nullish(),
  hasPromoSticker: z.boolean(),
  image: contentfulImageSchema.nullish(),
  imageTablet: contentfulImageSchema.nullish(),
  imageMobile: contentfulImageSchema.nullish(),
  description: descriptionSchema,
  form: formQuerySchema.nullish()
})

// This needs to be part of contentful/schemas as we will be
// pulling that too to use apollo response intead of gastby response
export const promoImageSchema = z.object({
  description: z.string(),
  file: z
    .object({
      url: z.string(),
      details: z.object({
        size: z.number(),
        image: z.object({
          width: z.number(),
          height: z.number()
        })
      }),
      fileName: z.string(),
      contentType: z.string()
    })
    .optional()
})

export const heroBannerSchema = z.object({
  __typename: z.literal('HeroBanner'),
  id: idSchema.transform(sysObj => sysObj.id),
  floatingBadge: z.boolean(),
  floatingBadgeDiscount: z.string().nullish(),
  isFullWidth: z.boolean(),
  backgroundVideo: videoSchema.nullish(),
  heightType: z.enum(['any', 'responsive']),
  heroStickerImage: promoImageSchema.optional(),
  disclaimerText: z.string(),
  disclaimerTextAlignment: z.string().nullish(),
  roundedCorners: z.boolean(),
  contentCollection: z.object({
    items: z.array(contentItemSchema)
  })
})

export type HeroBannerSchema = TypeOf<typeof heroBannerSchema> & {
  readonly className?: string
}

export type GuidedSystemBuilderSchema = TypeOf<typeof guidedSystemBuilderSchema>
export type HeroBannerColumnSchema = TypeOf<typeof contentItemSchema>
export type ButtonFragment = TypeOf<typeof buttonSchema>
export type EmbeddedLinksSchema = TypeOf<typeof embeddedLinksSchema>
export type VideoSchema = TypeOf<typeof videoSchema>
