import { Book, CustomerSupport, Suitcase } from '@ecomm/shared-icons'
import { Modal as SSModal } from '@ecomm/ss-react-components'
import React from 'react'

export type Props = {
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
}

export function Modal({ open, setOpen }: Props) {
  return (
    <SSModal isOpen={open} onRequestClose={() => setOpen(false)}>
      <div
        className="flex flex-col-reverse gap-8 p-8 lg:flex-row lg:pr-60"
        data-component="modal_content"
      >
        <div className="my-auto flex-1 justify-items-start py-0 pl-0 text-xl md:py-4 md:pr-12 lg:max-w-3xl">
          <h1 className="h3" data-component="modal_headline">
            Professional installation
          </h1>
          <p className="text-base md:text-lg">
            Make an appointment with a SimpliSafe certified technician. From
            unboxing and placing sensors to setting up Wi-Fi, connecting to
            professional monitoring and teaching you how to use your
            system—we’ll make sure you and your family are protected 24/7. Just
            check the box for Professional Installation as you shop. You won’t
            pay until you schedule, so you can always choose to set up yourself
            instead.
          </p>

          <div className="grid grid-cols-12 gap-4 md:gap-8">
            <Suitcase className="col-span-2 max-h-[30px] max-w-[30px] self-center md:col-span-1" />
            <p className="col-span-10 m-0 self-center text-sm md:col-span-11">
              Our highly trained experts will come to your home, install your
              system and make sure you’re connected to professional monitoring
            </p>
            <Book className="col-span-2 max-h-[30px] max-w-[30px] self-center md:col-span-1" />
            <p className="col-span-10 m-0 self-center text-sm md:col-span-11">
              Learn about how your new security system works 1:1 with our
              experts during set up
            </p>
            <CustomerSupport className="col-span-2 max-h-[30px] max-w-[30px] self-center md:col-span-1" />
            <p className="col-span-10 m-0 self-center text-sm md:col-span-11">
              Access to SimpliSafe customer support anytime you need it, even
              after installation
            </p>
          </div>
        </div>
      </div>
    </SSModal>
  )
}
