export const AFFIRM_TERM_MONTHS_SYSTEM = 24
export const AFFIRM_MIN_PRICE = 150
export const AFFIRM_PROMO_ID = 'fp_24z'

export const AFFIRM_FINANCING_PROGRAM = 'fp_24z'

export const AFFIRM_PROMO_NO_SYSTEM = 'promo_set_nonsystem'
export const AFFIRM_NO_SYSTEM_FINANCING_PROGRAM = 'nonsystem'

/* EXPERIMENT FOR https://simplisafe.atlassian.net/browse/ECP-10441 */
export const AFFIRM_FINANCING_PROGRAM_36_INSTALLMENTS = 'fp_36z'
export const AFFIRM_PROMO_ID_36_INSTALLMENTS = 'fp_36z'
export const AFFIRM_TERM_MONTHS_SYSTEM_EXPERMIENT = 36
/* EXPERIMENT FOR ECP-10441 */

/**
 * @deprecated
 * EXPERIMENT  https://simplisafe.atlassian.net/browse/ECP-8737
 */
export const AFFIRM_MONTHLY_EXPERIMENT_IMPACTED_EVENT = 'impacted_26719181849'

export const AFFIRM_VARIATION = 'variant-1'
