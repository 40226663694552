import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { safeProp } from '@simplisafe/monda'
import {
  selectPromoDiscountText,
  selectPromoWithMonitoringDiscountText
} from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectCurrentPromoDisplayMonitoringDiscount,
  selectCurrentPromoOverrideDiscountText,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { Column, OfferTag, Row } from '@ecomm/ss-react-components'
import { Just } from 'monet'
import path from 'ramda/src/path'
import React from 'react'
import { useSelector } from 'react-redux'

import { ContentfulPromotionalTagging } from '../../../graphql'
import { getRenderNodeWithPlaceholder } from '../../util/renderNodeWithPlaceholder'
import ContentfulRichText from '../ContentfulRichText'

export type PromoTagProps = {
  readonly data: ContentfulPromotionalTagging
}

function PromoTag({ data }: { readonly data: PromoTagProps }) {
  const displayMonitoringDiscount = useSelector(
    selectCurrentPromoDisplayMonitoringDiscount
  )
  const discountText = useSelector(selectPromoDiscountText)
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const discountWithMonitoringText = useSelector(
    selectPromoWithMonitoringDiscountText
  )

  // @ts-expect-error TS(2339) FIXME: Property 'taggingText' does not exist on type 'Pro... Remove this comment to see the full error message
  const taggingText = data?.taggingText?.raw
  // @ts-expect-error TS(2339) FIXME: Property 'taggingText' does not exist on type 'Pro... Remove this comment to see the full error message
  const tagReferences = data?.taggingText?.references
  const isPartnerAirline =
    getValueFromPartnerCookie('partnerGroup') === 'airlines'

  const isNHWPartner = getValueFromPartnerCookie('partnerName') === 'nhw'

  const isLightspeedPartner =
    getValueFromPartnerCookie('partnerName') === 'lightspeed'

  const discountPlaceholderText = isNHWPartner
    ? Just('50%')
    : isLightspeedPartner
      ? Just('60%')
      : displayMonitoringDiscount
        ? discountWithMonitoringText
        : discountText

  const overrideTextMaybe = useSelector(selectCurrentPromoOverrideDiscountText)
    .chain(safeProp('listingFlag'))
    .chain(val => val)
  const hasOverrideText = !overrideTextMaybe.isNone()

  const placeholderText = displayMonitoringDiscount
    ? hasOverrideText
      ? overrideTextMaybe.some()
      : discountPlaceholderText.getOrElse('')
    : discountPlaceholderText.getOrElse('')

  const modifiedTaggingTextRaw =
    taggingText && taggingText.replace('"value":" OFF"', '"value":""')

  const options = getRenderNodeWithPlaceholder(<>{placeholderText}</>)
  const offerTagRichText = (
    <ContentfulRichText
      optionsCustom={options}
      raw={hasOverrideText ? modifiedTaggingTextRaw : taggingText}
      references={tagReferences}
    />
  )

  // TODO: Gatsby-4-Upgrade - Test new OfferTag and placeholder.
  return discountPlaceholderText.isSome() &&
    isPromoTopBanner &&
    taggingText &&
    !isPartnerAirline ? (
    <div className="lg:-mt-16">
      <Row alignItems="center" padding="small">
        <Column padding="medium" spans={[12, 12, 12]}>
          {/* Hacky way to solve an alignment issue in the shop page ECP-4524 */}
          <OfferTag
            borderColor={path<string>(['textColor'], data)}
            content={offerTagRichText}
            tagBackgroundColor={path<string>(['tagBackgroundColor'], data)}
            tagTextColor={path<string>(['textColor'], data)}
          />
        </Column>
      </Row>
    </div>
  ) : null
}

export default PromoTag
