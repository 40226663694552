import { trackProductAdded } from '@ecomm/cdp-tracking'
import { getLocale } from '@ecomm/utils'
import { useCallback } from 'react'
import {
  getRudderstackDataFromProduct,
  ssProductsToRudderstackProducts
} from './productHelpers'
import { AccessoryAddedBody, ProductAddedBody, ProductSchema } from './schema'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'

export function useTrackAddProductToCart() {
  const trackAddProductToCartEvent = useCallback(
    ({ locale, total, products }: ProductAddedBody) => {
      const currencyCode = locale === 'en-GB' ? 'GBP' : 'USD'
      trackProductAdded({
        currency: currencyCode,
        total,
        products
      })
    },
    [trackProductAdded]
  )

  const trackProductCardAddToCartEvent = useCallback(
    (productDetail?: Product, quantity = 1) => {
      if (!productDetail) {
        return
      }

      const rudderstack = getRudderstackDataFromProduct(productDetail, quantity)
      trackProductAdded(rudderstack)
    },
    [trackProductAdded]
  )

  const trackAddAccessoryToCartEvent = useCallback(
    ({ product, quantity }: AccessoryAddedBody) => {
      const locale = getLocale()
      const currencyCode = locale === 'en-GB' ? 'GBP' : 'USD'
      product.cata(
        () => null,
        product => {
          const productObj: readonly ProductSchema[] = [
            {
              name: product.name[locale] ?? product.name['en-US'],
              sku: product.sku,
              quantity
            }
          ]

          const productPayload = ssProductsToRudderstackProducts(
            productObj,
            false,
            product.price
          )

          const total = product.price * quantity

          trackProductAdded({
            currency: currencyCode,
            products: productPayload,
            total
          })
        }
      )
    },
    [trackProductAdded]
  )

  return {
    trackAddProductToCartEvent,
    trackAddAccessoryToCartEvent,
    trackProductCardAddToCartEvent
  }
}
