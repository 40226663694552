import { useTrackingBmsToggleMonitoring } from '@ecomm/tracking'
import { useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'

import { monitoringAtom } from '../../atoms/draftCart/monitoringPlanAtom'
import {
  getMonitoringPlanType,
  hasMonitoringPlan
} from '../../components/DraftCart/helpers'
import type { PriceToggleProps } from '../../components/IMAPS/types'

const useMonitoringPlan = ({ priceToggles }: PriceToggleProps) => {
  const [monitoringPlan, setMonitoringPlan] = useAtom(monitoringAtom)
  const { plan, type } = monitoringPlan
  const hasMonitoring = hasMonitoringPlan(type)
  const initialPlan = priceToggles[0]?.sku ?? ''
  const trackToggleMonitoring = useTrackingBmsToggleMonitoring()

  useEffect(() => {
    const mpType = getMonitoringPlanType(initialPlan, priceToggles)

    setMonitoringPlan({
      plan: initialPlan,
      type: mpType
    })
  }, [])

  const onSelectMonitoring = useCallback(
    (monitoringPlan: string) => {
      const monitoringPlanType = getMonitoringPlanType(
        monitoringPlan,
        priceToggles
      )

      setMonitoringPlan({
        plan: monitoringPlan,
        type: monitoringPlanType
      })

      trackToggleMonitoring(hasMonitoringPlan(monitoringPlanType))
    },
    [priceToggles, setMonitoringPlan, trackToggleMonitoring]
  )

  return {
    initialPlan,
    hasMonitoring,
    onSelectMonitoring,
    plan,
    type
  }
}

export default useMonitoringPlan
