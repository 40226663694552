import { useDevMode } from '@ecomm/data-storage'
import { OptimizelyDev } from '@ecomm/optimizely-components'
import { lazy, Suspense, useEffect, useState } from 'react'

const LazyJotaiDevTools = lazy(() =>
  import(/* webpackChunkName: "jotai-devtools" */ 'jotai-devtools').then(
    module => ({ default: module.DevTools })
  )
)

export function DevTools() {
  const [isDevMode] = useDevMode()
  const [showDevTools, setShowDevTools] = useState(false)

  useEffect(() => {
    setShowDevTools(isDevMode)
  }, [isDevMode])

  return (
    <>
      {showDevTools && (
        <Suspense fallback={null}>
          <LazyJotaiDevTools />
        </Suspense>
      )}
      {showDevTools && <OptimizelyDev />}
    </>
  )
}
