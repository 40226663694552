import { PaymentFormWrapperFragment } from '@ecomm/checkout/payment-components'
import { useAffirmAPRExperimentLegacy } from '@ecomm/shared-hooks'
import React from 'react'

import PaymentPageWrapper from '../PaymentPageWrapper'

interface Props {
  readonly formData: PaymentFormWrapperFragment
}
function AffirmWrapper({ formData }: Props) {
  const isAffirmMonthlyExperiment = useAffirmAPRExperimentLegacy()

  return (
    <PaymentPageWrapper
      formData={formData}
      isAffirmMonthlyExperiment={isAffirmMonthlyExperiment}
    />
  )
}

export default AffirmWrapper
