import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { LeadGenResponseProps } from '@ecomm/data-leads'
import { logError } from '@ecomm/error-handling'
import { COOKIE_LEAD_DATA, setCookie } from '@ecomm/shared-cookies'
import {
  fbTrackLeadCreated,
  handleBrazeTrackingEvent,
  OptimizelyEvent
} from '@ecomm/tracking'
import { Locale } from '@ecomm/utils'
import { MaybeT } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { leadGenUpdate } from '@simplisafe/ss-ecomm-data/leads/quoteWizard'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import {
  leadGenCapture,
  LeadGenCaptureParams,
  leadGenUnsub
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'

import { isSiteUS } from '..'
import { sendDataToBrazeOnAbandonCheckout } from '.'

/**
 * Send event to Braze on submit
 */

export const handleCaptureLead =
  (
    promoCode: string,
    locale: Locale,
    optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void,
    { firstName, lastName, email, phone, offerAndTip }: CheckoutFormValuesType,
    loginSuccess: boolean,
    channel: string
  ) =>
  (cart: RemoteData<ImmutableCart>, trackSubmitLead: () => void) => {
    const trackLeadSuccess = () => {
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackSubmitLead()
    }

    const leadHandleSuccess = (value: MaybeT<LeadGenResponseProps>) => {
      const firstSubmission = value.orUndefined()?.message?.includes('created')
      // Always cookie lead data and pass data to Braze
      setCookie(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined(), channel, firstSubmission)

      // we track lead when offerAndTip is true AND user is not logged in
      const shouldTrackLeadEvent = offerAndTip && !loginSuccess
      shouldTrackLeadEvent && trackLeadSuccess()

      // we unsubscribe when offerAndTip is false AND user is not logged in
      const shouldUnsubscribe = !offerAndTip && !loginSuccess
      shouldUnsubscribe && leadGenUnsub({ email })(() => null)(() => null)

      value.forEach(async response => {
        response.email && (await fbTrackLeadCreated(response.email))
        // We are updating the phone and name in the Braze profile, so we need to do the same in ss_leads.
        isSiteUS(locale) &&
          leadGenUpdate({
            leadId: Number(response.leadId),
            responses: { name: `${firstName} ${lastName}`, phone }
          })(() =>
            logError(
              Error('something went wrong while trying to update lead profile')
            )
          )(() => null)

        response.email &&
          response.externalId &&
          sendDataToBrazeOnAbandonCheckout(
            cart,
            locale
          )({
            firstName,
            lastName,
            phone,
            email: response.email,
            leadId: response?.leadId, // we don't get the leadId in the response when it's an existing lead
            externalId: response.externalId
          })
      })
    }

    const leadHandleError = () =>
      optimizelyTrackSiteEvents({ eventType: 'website_error' })

    const leadGenParams: LeadGenCaptureParams = {
      email,
      leadSource: 'exit_intent',
      locale,
      promoCode,
      source: 'checkout',
      sourceType: 'cart'
    }
    leadGenCapture(leadGenParams)(leadHandleError)(leadHandleSuccess)
  }
