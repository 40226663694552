import {
  trackHeroVideoPause,
  trackHeroVideoPlay,
  useVideoTracking
} from '@ecomm/cdp-tracking'
import { CaretForward, Pause } from '@ecomm/shared-icons'
import { useTrackingVideo } from '@ecomm/tracking'
import { nullToUndefined } from '@simplisafe/ewok'
import { useEffect, useRef, useState } from 'react'

import { VideoSchema } from './schema'

type EventProps = React.SyntheticEvent<HTMLVideoElement, Event>

export default function HeroBackgroundVideo({
  mp4Link,
  webmLink,
  posterImage,
  className = ''
}: VideoSchema & { readonly className?: string }) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [loopCount, setLoopCount] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const { handleTrackingVideoPause, handleTrackingVideoPlay } =
    useTrackingVideo()
  const { trackVideoPlay, trackVideoPause, trackVideoComplete } =
    useVideoTracking()

  const onVideoPlay = (event: EventProps) => {
    setIsPlaying(true)
    handleTrackingVideoPlay(event)
    trackVideoPlay(event)
  }

  const onVideoPause = (event: EventProps) => {
    setIsPlaying(false)
    handleTrackingVideoPause(event)
    trackVideoPause(event)
  }

  const onVideoEnd = (event: EventProps) => {
    const finishedLoop = loopCount + 1
    finishedLoop >= 3 ? videoRef.current?.pause() : videoRef.current?.play()
    setLoopCount(finishedLoop)
    trackVideoComplete(event)
  }

  useEffect(() => {
    /* Attempt auto-play if not disabled by user preference.
      Uses imperative api to avoid showing system play button in
      low-power mode in Safari */
    const prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)'
    ).matches
    const timeout = prefersReducedMotion
      ? 0
      : setTimeout(async () => {
          try {
            await videoRef.current?.play()
          } catch (e) {}
        }, 0)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const pauseVideo = () => {
    videoRef.current?.pause()
    trackHeroVideoPause()
  }

  const playVideo = () => {
    videoRef.current?.play()
    trackHeroVideoPlay()
  }

  const playPauseVideo = () => {
    videoRef.current?.paused ? playVideo() : pauseVideo()

    setLoopCount(0)
  }

  return (
    <div
      className={`group absolute left-0 top-0 h-full w-full [&_*::-webkit-media-controls-start-playback-button]:!hidden [&_video::-webkit-media-controls]:!hidden ${className}`}
    >
      <video
        className="block h-full w-full object-cover"
        controls={false}
        controlsList="nodownload nopictureinpicture noplaybackrate nospeed"
        crossOrigin="anonymous"
        data-component="native-video"
        disablePictureInPicture
        disableRemotePlayback
        muted
        onEnded={onVideoEnd}
        onPause={onVideoPause}
        onPlay={onVideoPlay}
        playsInline
        poster={nullToUndefined(posterImage)}
        ref={videoRef}
      >
        <source src={webmLink} type="video/webm" />
        <source src={mp4Link} type="video/mp4" />
      </video>
      <button
        aria-label={isPlaying ? 'Pause Video' : 'Play Video'}
        className="animate-fadeIn absolute bottom-6 right-6 z-[10] flex h-9 w-9 cursor-pointer items-center justify-center rounded-full border-none bg-black bg-opacity-30 p-0 md:bottom-8 md:right-8 md:h-12 md:w-12 lg:bottom-10 lg:right-10 lg:h-[54px] lg:w-[54px]"
        onClick={playPauseVideo}
      >
        {!isPlaying ? (
          <CaretForward className="h-5 w-5 p-1 text-white md:h-7 md:w-7 lg:h-8 lg:w-8" />
        ) : (
          <Pause className="h-5 w-5 text-white md:h-7 md:w-7 lg:h-8 lg:w-8" />
        )}
      </button>
    </div>
  )
}
