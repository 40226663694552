import { getExpertReviews } from '@ecomm/lander'
import { QuizBanner } from '@ecomm/lander'
import { parseContentfulJson } from '@ecomm/micro-copy'
import { useSetPartnerLogic } from '@ecomm/partners-hooks'
import {
  AppWidget,
  ComparisonTable,
  ComparisonTableProps,
  ConfidenceBar,
  ExpertSection,
  ExpertsReviews,
  ExpertsReviewsProps,
  GuaranteeSection,
  parseJSONDataFromContentful,
  transformToComparisonTableData
} from '@ecomm/scout'
import {
  TrustpilotBanner,
  trustPilotUKHomePageCarousel,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { UserReviews } from '@ecomm/shared-components'
import { useEnv } from '@ecomm/utils'
import React from 'react'

import { CeoComment } from '../components/CeoComment'
import { NhwDescripton } from '../components/NhwDescripton'
import {
  appRatingImages,
  getAppWidgetData,
  getConfidenceBarData,
  getUserReviewsData
} from './hardcodedData'
import { useNeighbourhoodWatchQuery } from './useNeighbourhoodWatchQuery'

export default function NeighbourhoodWatch() {
  const { locale } = useEnv()
  const data = useNeighbourhoodWatchQuery()

  const expertReviewsData: ExpertsReviewsProps = parseJSONDataFromContentful(
    data,
    'expertReviews'
  )
  const expertReviews = getExpertReviews(
    data.expertReviewsAssets.nodes,
    locale,
    expertReviewsData.reviews
  )
  const appWidgetData = {
    tabs: getAppWidgetData(data.appFeaturesCarouselAssets.nodes, locale),
    title: 'You’re in control with the SimpliSafe® App',
    appRatingImages
  }
  const guaranteeSectionCopy = parseContentfulJson(data, 'guaranteeCopy')
  const confidenceBarData = getConfidenceBarData(locale)
  const comparisonTableData: ComparisonTableProps =
    transformToComparisonTableData(data)
  const userReviewsData = getUserReviewsData(locale)

  useSetPartnerLogic(
    'nhw',
    'partner-strategic-referral',
    '/neighbourhood-watch'
  )

  return (
    <>
      <TrustpilotUKTopBanner className="mt-4 md:mt-8"></TrustpilotUKTopBanner>
      <ConfidenceBar data={{ listItems: confidenceBarData }} />
      <QuizBanner
        image={data.quoteWizardAsset}
        quoteWizard={data.quoteWizard}
      />
      <NhwDescripton
        asset={data.nhwLogo}
        data={parseContentfulJson(data, 'nhwDescription')}
      />
      <CeoComment data={parseContentfulJson(data, 'ceoComment')} />
      <TrustpilotBanner
        className="pt-4 md:pt-8"
        widget={trustPilotUKHomePageCarousel}
      />
      <ComparisonTable data={comparisonTableData} />
      <AppWidget data={{ ...appWidgetData }} />
      <UserReviews reviews={userReviewsData} />
      <ExpertsReviews reviews={expertReviews} />
      <GuaranteeSection
        copy={guaranteeSectionCopy}
        image={data.guaranteeAsset}
        quoteWizard={data.quoteWizard}
      />
      <ExpertSection data={{ image: data.expertSectionAsset }} />
    </>
  )
}
