import { footerSchema } from '@ecomm/shared-components'
import { TypeOf } from '@simplisafe/ewok'

export const footerData: TypeOf<typeof footerSchema> = {
  copyrightText: '© 2022 SimpliSafe, Inc.',
  leadGenForm: {
    button: {
      text: 'Submit'
    },
    form: [
      {
        placeholder: 'Enter email',
        title: 'Email',
        type: 'Email'
      }
    ],
    note: {
      raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"You may receive email offers from us in accordance with our ","nodeType":"text"},{"data":{"uri":"/privacy-policy"},"content":[{"data":{},"marks":[],"value":"Privacy Policy","nodeType":"text"}],"nodeType":"hyperlink"},{"data":{},"marks":[],"value":".","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
    },
    title: 'Get offers and security advice'
  },
  legalLinks: [
    {
      text: 'Legal',
      url: '/legal',
      trackingEvent: null
    },
    {
      text: 'Privacy Policy',
      url: '/privacy-policy',
      trackingEvent: 'link-click-privacy-footer'
    },
    {
      text: 'State Licensing',
      url: '/licenses',
      trackingEvent: null
    },
    {
      text: 'Accessibility Statement',
      url: '/accessibility_statement',
      trackingEvent: null
    }
  ],
  menus: [
    {
      links: [
        {
          text: 'Meet The System',
          url: '/meet-the-system',
          trackingEvent: 'link-click-meet-the-system-footer'
        },
        {
          text: 'Outdoor Security Camera',
          url: '/outdoor-security-camera',
          trackingEvent: null
        },
        {
          text: 'Indoor Camera',
          url: '/smart-alarm-wireless-indoor-camera',
          trackingEvent: null
        },
        {
          text: 'Smart Lock',
          url: '/smart-lock',
          trackingEvent: null
        },
        {
          text: 'Video Doorbell Pro',
          url: '/video-doorbell-pro',
          trackingEvent: null
        },
        {
          text: 'Protectors Discount',
          url: '/protectors',
          trackingEvent: 'link-click-protectors-footer'
        },
        {
          text: 'Expand My System',
          url: '/alarm-sensors',
          trackingEvent: null
        }
      ],
      title: 'Shop',
      titleUrl: '/home-security-shop'
    },
    {
      links: [
        {
          text: 'Privacy Promise',
          url: '/privacy',
          trackingEvent: 'link-click-privacy-promise-footer'
        },
        {
          text: 'Careers',
          url: 'https://careers.simplisafe.com/',
          trackingEvent: null
        },
        {
          text: 'Press Kit',
          url: 'https://press.simplisafe.com/',
          trackingEvent: null
        },
        {
          text: 'Partner with Us',
          url: '/partner-with-us',
          trackingEvent: null
        },
        {
          text: 'Locations',
          url: '/location',
          trackingEvent: 'link-click-locations-footer'
        },
        {
          text: 'Blog',
          url: '/blog',
          trackingEvent: null
        }
      ],
      title: 'Company',
      titleUrl: null
    },
    {
      links: [
        {
          text: 'Contact Us',
          url: '/contact-us',
          trackingEvent: null
        },
        {
          text: 'Help Center',
          url: 'https://support.simplisafe.com/hc/en-us',
          trackingEvent: null
        },
        {
          text: 'User Manuals',
          url: '/manual',
          trackingEvent: 'link-click-manual-footer'
        },
        {
          text: '888-910-1458',
          url: 'tel:888-910-1458',
          trackingEvent: null
        },
        {
          text: 'Reviews',
          url: '/reviews',
          trackingEvent: 'link-click-reviews-footer'
        }
      ],
      title: 'Support',
      titleUrl: 'https://support.simplisafe.com/?lang=en_US'
    },
    {
      links: [
        {
          text: 'Legal',
          url: '/legal',
          trackingEvent: null
        },
        {
          text: 'Privacy Policy',
          url: '/privacy-policy',
          trackingEvent: 'link-click-privacy-footer'
        },
        {
          text: 'State Licensing',
          url: '/licenses',
          trackingEvent: null
        },
        {
          text: 'Accessibility Statement',
          url: 'https://simplisafe.com/accessibility_statement',
          trackingEvent: null
        }
      ],
      title: 'Legal',
      titleUrl: null
    }
  ],
  socialLinks: [
    {
      icon: {
        description: '',
        gatsbyImageData: {
          height: 128,
          images: {
            fallback: {
              src: 'https://images.ctfassets.net/v6awd0kabi65/6mQNXJicH3fux0NFaL7IE9/a0a621d29b4c18c270db600e13f43464/footer_twitter-logo.png?w=128&h=128&q=50&fm=png'
            },
            sources: [
              {
                media: '',
                srcSet:
                  'https://images.ctfassets.net/v6awd0kabi65/6mQNXJicH3fux0NFaL7IE9/a0a621d29b4c18c270db600e13f43464/footer_twitter-logo.png?w=32&h=32&q=50&fm=webp 32w,\nhttps://images.ctfassets.net/v6awd0kabi65/6mQNXJicH3fux0NFaL7IE9/a0a621d29b4c18c270db600e13f43464/footer_twitter-logo.png?w=64&h=64&q=50&fm=webp 64w,\nhttps://images.ctfassets.net/v6awd0kabi65/6mQNXJicH3fux0NFaL7IE9/a0a621d29b4c18c270db600e13f43464/footer_twitter-logo.png?w=128&h=128&q=50&fm=webp 128w',
                type: 'image/webp'
              }
            ]
          },
          layout: 'constrained',
          width: 128
        },
        title: 'Icon Twitter'
      },
      text: 'Twitter',
      url: 'https://twitter.com/simplisafe'
    },
    {
      icon: {
        description: '',
        gatsbyImageData: {
          height: 128,
          images: {
            fallback: {
              src: 'https://images.ctfassets.net/v6awd0kabi65/5EIPbWqI2Us8REZdGWfiF1/7dc7e3b359421bf112efa2cfa3ada3d5/footer_facebook-logo.png?w=128&h=128&q=50&fm=png'
            },
            sources: [
              {
                media: '',
                srcSet:
                  'https://images.ctfassets.net/v6awd0kabi65/5EIPbWqI2Us8REZdGWfiF1/7dc7e3b359421bf112efa2cfa3ada3d5/footer_facebook-logo.png?w=32&h=32&q=50&fm=webp 32w,\nhttps://images.ctfassets.net/v6awd0kabi65/5EIPbWqI2Us8REZdGWfiF1/7dc7e3b359421bf112efa2cfa3ada3d5/footer_facebook-logo.png?w=64&h=64&q=50&fm=webp 64w,\nhttps://images.ctfassets.net/v6awd0kabi65/5EIPbWqI2Us8REZdGWfiF1/7dc7e3b359421bf112efa2cfa3ada3d5/footer_facebook-logo.png?w=128&h=128&q=50&fm=webp 128w',
                type: 'image/webp'
              }
            ]
          },
          layout: 'constrained',
          width: 128
        },
        title: 'Icon Facebook'
      },
      text: 'Facebook',
      url: 'https://www.facebook.com/SimpliSafe'
    },
    {
      icon: {
        description: '',
        gatsbyImageData: {
          height: 128,
          images: {
            fallback: {
              src: 'https://images.ctfassets.net/v6awd0kabi65/4IXB3UVo3SrhoRFXXhm4VY/c5807ef1c12f30bfc0a56f93c846ca74/footer_youtube-logo.png?w=128&h=128&q=50&fm=png'
            },
            sources: [
              {
                media: '',
                srcSet:
                  'https://images.ctfassets.net/v6awd0kabi65/4IXB3UVo3SrhoRFXXhm4VY/c5807ef1c12f30bfc0a56f93c846ca74/footer_youtube-logo.png?w=32&h=32&q=50&fm=webp 32w,\nhttps://images.ctfassets.net/v6awd0kabi65/4IXB3UVo3SrhoRFXXhm4VY/c5807ef1c12f30bfc0a56f93c846ca74/footer_youtube-logo.png?w=64&h=64&q=50&fm=webp 64w,\nhttps://images.ctfassets.net/v6awd0kabi65/4IXB3UVo3SrhoRFXXhm4VY/c5807ef1c12f30bfc0a56f93c846ca74/footer_youtube-logo.png?w=128&h=128&q=50&fm=webp 128w',
                type: 'image/webp'
              }
            ]
          },
          layout: 'constrained',
          width: 128
        },
        title: 'Icon Youtube'
      },
      text: 'Youtube',
      url: 'https://www.youtube.com/channel/UCJwuGzZokYefxt8LYjW8BDA'
    }
  ],
  disclaimerLink: {
    text: 'Do Not Sell My Personal Information',
    url: ''
  }
}
