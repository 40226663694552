import { GatsbyImage } from '@ecomm/shared-components'
import classNames from 'classnames'

import { useTrackingPagerClick } from '../hooks/useTrackingPagerClick'
import { ImageSchema } from './schema'

export function ImageGalleryPager({
  productImages,
  currentImage,
  onPagerClick: onPagerClickProp
}: {
  readonly productImages: readonly ImageSchema[]
  readonly currentImage: number
  readonly onPagerClick: (index: number) => void
}) {
  const trackPagerClick = useTrackingPagerClick()

  const handlePagerFocus = (index: number) => {
    onPagerClickProp(index)
  }

  const handlePagerClick = (index: number) => {
    onPagerClickProp(index)
    trackPagerClick(index + 1)
  }

  return (
    <div
      className="order-last col-span-12 mt-4 flex justify-start md:order-first md:col-span-2 md:mt-0 md:flex-col md:items-start lg:order-first lg:col-span-2 lg:mt-0 lg:flex-col lg:items-center"
      data-component="ImageGalleryPager"
    >
      {productImages.map((image, idx) => (
        <div
          className={classNames(
            'mr-2 aspect-square h-14 cursor-pointer overflow-hidden rounded border-2 border-solid transition-all hover:border-[var(--complementary-blue-100)] md:mb-2 md:mr-6 md:h-auto lg:mx-6',
            {
              'border-[var(--neutral-dark-100)]': idx === currentImage,
              'border-white': idx !== currentImage
            }
          )}
          key={idx}
          onClick={() => handlePagerClick(idx)}
          onFocus={() => handlePagerFocus(idx)}
          tabIndex={0}
        >
          <GatsbyImage
            className="h-full w-full object-contain object-center"
            image={image}
            key={idx}
          />
        </div>
      ))}
    </div>
  )
}
